import React from 'react';
import './NewProperty.css';
import pubsuc from '../../../assets/images/publish-success.png';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';

export default function NewPropertyFinal({ history }) {

    const goSee = () => {
        history.push('/');
    }

    const goNew = () => {
        history.push('/myproperties');
    }

    const goServices = () => {
        history.push('/services');

    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 &&
                <NavBarWeb history={history} />
            }
            <div className="new-property">
                <div className="fields final" style={{ marginTop: screenSizeWidth > 1030 ? "75px" : "0px" }} >
                    <img className="publish-success" src={pubsuc} alt="Inmueble listo" />
                    <h3>¡Ya está listo tu inmueble!</h3>
                    <p className="final-text created-text">Tu propiedad ha sido cargada con éxito. Para poder editarla o despublicarla, solo deberás acceder a Mis Propiedades.</p>
                </div>
                <div className="buttons final">
                    <button className="see" type="submit" onClick={goNew}>ver mis propiedades</button>
                    <button className="new" type="button" onClick={goSee}>buscar una propiedad</button>
                </div>

                <div className="fields final" >
                    <h3>¡Potencia tu propiedad!</h3>
                </div>
                <div className="buttons final">
                    <button className="services-button-final" type="submit" onClick={goServices}>adquirir servicios</button>
                </div>
            </div>
        </div>
    )
}
