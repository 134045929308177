import React, { useContext, useEffect, useState } from 'react';
import './NewProperty.css';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import { Context } from '../../../Context';
import axios from 'axios';
import URL from '../../../config/urls';
//import InternalProperty from '../InternalProperty/InternalProperty';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';


import TopNewProperty from './TopNewProperty';
import TopStepper from './TopStepper';
//import NewPropertyTip from './NewPropertyTip';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutorenewIcon from '@material-ui/icons/Autorenew';


const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);



const useStyles = makeStyles((theme) => ({
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  }));


export default function NewPropertyFinal({ history }) {

    const classes = useStyles();

    const { newProperty } = useContext(Context);
    const [propertyCode, setPropertyCode] = useState('');
    const [loaderNext, setLoaderNext] = useState(false);
    const [error, seterror] = useState(false)
    const [errorNumber, seterrorNumber] = useState(false)
    const [Count, setCount] = useState(40)
    const [disponible, setdisponible] = useState(false)
    const [permitido, setpermitido] = useState(true)

    const [permi, setpermi] = useState(false)

    const [recargar, setrecargar] = useState(false)
    const [modificar, setmodificar] = useState(false)
    const [loading, setloading] = useState(false)
    const [validateCode, setvalidateCode] = useState(true)

    const [CountInput, setCountInput] = useState(40)
    const [firstWord, setfirstWord] = useState("")
    const [secondWord, setsecondWord] = useState("")
    const [data, setdata] = useState({
        palabra1: "",
        palabra2: ""
    })

    const { palabra1, palabra2 } = data

    const token = localStorage.getItem('token');
    const BASE_URL = URL + 'properties/' + newProperty.propertyId;
    const headers = { headers: { "token": token, } };

    let screenSizeWidth = window.innerWidth;

    let regex = new RegExp("^[a-zA-Z]+$");



    useEffect(() => {


        if((!regex.test(palabra1) == true && palabra1.length > 0 )|| (!regex.test(palabra2) == true && palabra2.length > 0 )) {
            seterrorNumber(true)
        } else {
            seterrorNumber(false)
        }



        if(screenSizeWidth > 1050){
            setCount(30)
            setCountInput(30)
        } else {
            setCount(40)
            setCountInput(40)
        }

        if(palabra1.length > 6 ||  palabra2.length > 6){
            return seterror(true)
         }

         if(palabra1.length <= 6  &&  palabra2.length <= 6){

             return seterror(false)
         }

    },[data, errorNumber ])



    const onChange = (e) => {

        const { name, value } = e.target;
          setdata({
            ...data,
            [name]: value
          });
          setdisponible(false)
          setpermi(false)
          setpermitido(true)
    }

    const BASE_URL_RESET = URL + 'properties/newPropertyCode';

    const nuevaClave = () => {
        setvalidateCode(true)
        setpermitido(true)
        setpermi(false)
        setrecargar(true)
        setdisponible(false)

        axios.get(BASE_URL_RESET, headers)
        .then(res => {
            setrecargar(false)
            const data = res.data;
                
                let datos = data.propertyCode.split('.')
                setfirstWord(datos[0])
                setsecondWord(datos[1])

                setdata({
                    palabra1: datos[0],
                    palabra2: datos[1]
                })
   
        });


    }

    const modificarValue = () => {
        setvalidateCode(true)
        setpermitido(true)
        setpermi(false)
        setmodificar(true)
        setdisponible(false)
    }


    const validate = () => {
        setvalidateCode(true)
        setpermitido(true)
        setpermi(false)
        setloading(true)
        let params = {
            propertyCode: [ palabra1 + '.' + palabra2 ]
        }

        axios.post(BASE_URL_RESET + "/validate", params, headers)
        .then(res => {
            setloading(false)
            if(res.data.available == true){
                setdisponible(true)
                setpermi(true)
                
            } else {
                setpermitido(false)
            }

        });

    }

    const goPublic = () => {
        let params =
        {
            mainData: {
                data: {
                    isPublished: true,
                }
            }
        }

        let datos = {
            propertyCode: [ palabra1 + '.' + palabra2],
            propertyId:newProperty.propertyId
        }


        if (newProperty.propertyId) {
            setLoaderNext(true);
            axios.patch(BASE_URL, params, headers)
                .then(res => {
                    axios.post(BASE_URL_RESET + "/update", datos, headers)
                    .then((data) => {
                        setLoaderNext(false);
                        // const data = res.data;
                        history.push('/newproperty/created');
                        return res.data
                    })
                });
        }

    }

    const goSave = () => {
        let params =
        {
            mainData: {
                data: {
                    isPublished: false,
                }
            }
        }

        if (newProperty.propertyId) {
            setLoaderNext(true);
            axios.patch(BASE_URL, params, headers)
                .then(res => {
                    setLoaderNext(false);
                    //const data = res.data;
                    history.push('/newproperty/created');
                    return res.data
                });
        }
    }

    const goPrev = () => {
        history.push('/newproperty/5');
    }

    const BASE_URL_2 = URL + 'properties/propertyId/' + newProperty.propertyId;

    useEffect(() => {
 
        setpermi(false)
        setloading(true)
        axios.get(BASE_URL_2, headers)
            .then(res => {
                setvalidateCode(false)
                setloading(false)
                setdisponible(true)
                const data = res.data;
                setPropertyCode(data.responseData.propertyCode[0]);
            });

            if(recargar == false){
                let datos = propertyCode.split('.')
                setfirstWord(datos[0])
                setsecondWord(datos[1])

               data.palabra1 = datos[0]
               data.palabra2 = datos[1]
            }

// eslint-disable-next-line
    }, [propertyCode])



    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }
            <div className="new-property">
                <TopNewProperty step={6} action={newProperty.action} screenSizeWidth={screenSizeWidth} />
                <div className="properties-container-pc">
                    <TopStepper step={6} />
                    <div className="properties-inputs">
                        <div className="fields">
                            <h3>¡Ya tenemos todos los datos!</h3>
                            <p className="final-text">Ya podés publicar tu inmueble y visualizar una previa vista abajo. También podés no publicar los datos del inmueble, quedará guardado y podrás publicarlo en otro momento.</p>
                            <h3>Tu propiedad tiene un código</h3>
                            <p className="final-text">El código de propiedad es una identificación única de cada inmueble. La función principal del código es diferenciar a las diferentes propiedades ya sea en los carteles de vía pública entregados por Hi Propi, o en usos digitales dentro de la plataforma.</p>
                            {/* <h3>{propertyCode}</h3> */}
                        </div>
                        <div className='contenedor-final-new-prop'>
                            <h3><strong style={{color: "black"}} >Alias de propiedad</strong> </h3>
                            {/* <h2><strong style={{color: "#e32085", paddingLeft: "5px"}} >{propertyCode}</strong></h2> */}

                            <div>
                            {/* <h3><strong style={{color: "black"}} > ...O generá tu propio alias</strong> </h3> */}

                             {
                                 loading ? <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center', width: '60%', height: 'auto', paddingBottom: '10px', marginBottom: "30px" }}>
                                 <CircularProgress size={30} />
                                 </div> : <div className='div-imput-final-newprop' >
                                 <input
                             type="text"
                             style={{width: `${Count}%`, color: modificar ? "black": "" , fontWeight:  "bold"}}
                             id="palabra1"

                             name="palabra1"
                             placeholder="Palabra 1"
                             value={
                                modificar ? data.palabra1 : firstWord
                             }
                             onChange={onChange}
                             />
                             <h3 style={{paddingLeft: "10px", paddingRight: "10px"}} >.</h3>
                             <input
                             type="text"
                             style={{width: `${Count}%`, color: modificar ? "black": "", fontWeight: "bold"}}
                             id="palabra2"
                             name="palabra2"
                             placeholder="Palabra 2"
                             value={
                               modificar ? data.palabra2 : secondWord
                              }
                             onChange={onChange}
                             />


      <BootstrapTooltip title="Generar nuevo alias">
      <button style={{width: '40px', outline: "none"}} className={ recargar?  "GenerateButton loading style-1" : "GenerateButton"} id="animation-hover" type="button" onClick={() => nuevaClave()}>
        <AutorenewIcon aria-label="delete" />  </button>
      </BootstrapTooltip>

                             </div>

                             }

                            <button style={{width: `${CountInput}%`, outline: "none"}} className={"validate-button-newprop-v"} type="button" onClick={() => modificarValue()}>Modificar</button>
                            <button style={{width: `${CountInput}%`, marginLeft: "20px", outline: "none"}} className={ (error || errorNumber) ? "validate-button-newprop-error" : !validateCode ? "validate-button-newpropValidate" : "validate-button-newprop"  } type="button" onClick={() => (error || errorNumber) ? " " : !validateCode ? " " : validate()}>{ screenSizeWidth > 1050 ? "Validar alias" : "Validar"}</button>
                            </div>
                            {
                                permitido == false && <p style={{color: '#e84141', fontSize: '20px', fontWeight: '600', margin: 0, padding: 10}}>Alias no disponible</p> 
                            }
                            {
                                permi && <p style={{color:  "#06d6a0", fontSize: '20px', fontWeight: '600', margin: 0, padding: 10}}>Alias disponible</p>
                            }
                            {
                            errorNumber && <p style={{color: '#e84141', fontSize: '16px', fontWeight: '600', margin: 0, padding: 0}}>El alias no debe contener numeros, espacios o caracteres especiales.</p>
                            }
                            {
                             error && <p style={{color: '#e84141', fontSize: '16px', fontWeight: '600', margin: 0, padding: 0}}>El maximo permitido es de 6 caracteres.</p>
                            }

                        </div>
                    </div>
                </div>
                {/* <InternalProperty dataFooter={() => { }} uploading={true} /> */}
                <div className="buttons">
                    {loaderNext ?
                        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
                            <CircularProgress size={30} />
                        </div> :
                        <>
                            <button className="prev" type="button" onClick={goPrev}>atrás</button>
                            <div className="last-buttons">
                                <button className={disponible ? "publish" : "save"} type="submit" onClick={disponible ? goPublic : " "}>publicar</button>
                                <button className="save" type="button" onClick={goSave}>no publicar</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
