import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '83%',
    maxHeight: 374,
    height: 'auto',
    background: '#ffffff',
    border: 'solid 1px #707070',
    borderRadius: 5,
    padding: '20px 25px 25px 25px',
    outline: 'none',
    display: 'inline-block',
    textAlign: 'center'
  },
  paperWeb: {
    width: '30%',
    maxHeight: 374,
    height: 'auto',
    background: '#ffffff',
    border: 'solid 1px #707070',
    borderRadius: 5,
    padding: '20px 25px 25px 25px',
    outline: 'none',
    display: 'inline-block',
    textAlign: 'center'
  },
  p: {
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#232f34',
    margin: '10px 0 10px 0'
  },
  label: {
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#232f34',
  },
  yes: {
    height: 50,
    borderRadius: 25,
    boxShadrow: '3px 3px 15px 0 rgba(0, 0, 0, 0.16)',
    background: '#e32085',
    width: '83%',
    border: 'none',
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'uppercase',
    marginTop: 20,
    outline: 'none',
    cursor: 'pointer'
  },
  no: {
    height: 47,
    borderRadius: 25,
    boxShadrow: '3px 3px 15px 0 rgba(0, 0, 0, 0.15)',
    background: 'rgba(89, 99, 204, 0.05)',
    width: '83%',
    border: 'solid 1px #5963cc',
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#5963cc',
    textTransform: 'uppercase',
    marginTop: 15,
    outline: 'none',
    cursor: 'pointer'
  }
}));

const ModalWarning = ({ history, title, text, acept, customLink, image, noButton, callToAction, styleButton, close, openModal, actionButton, handleCloseOut }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let screenSizeWidth = window.innerWidth;

  function goBack() {
    // history.goBack();
    history.go(0)
  }

  const handleOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    handleCloseOut && handleCloseOut();
  };

  useEffect(() => {
    openModal && setOpen(openModal);
  }, [openModal])

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableEnforceFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={open} style={{ maxHeight: '500px' }}>
          <div className={screenSizeWidth < 1030 ? classes.paper : classes.paperWeb}>
            {image && <img alt='modal-img' src={image} />}
            <p className={classes.p}> {title} </p>
            <label className={classes.label} > {text} </label>
            <div>
              {
                customLink ?
                  <Link to={customLink}>
                      <button className={classes.yes} > {acept} </button>

                  </Link>
                  :
                  (
                    acept && <button className={classes.yes} onClick={handleClose} > {acept} </button>
                  )
                  
              }
            </div>
            <div>
   
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalWarning;