import "date-fns";
import React, { useEffect, useState } from "react";
import Select from "../Common/Select/Select";
import RouterColumn from "../RouterColumn/RouterColumn";
// import Formulario from "./formulario.js";
import URL from '../../../../config/urls'
import axios from 'axios'
import Image from "./image";
import ImageMobile from "./imageMobile";
import { useHistory } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    width: '23%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: "fixed",
    bottom: "3%",
    left: "3%",
    zIndex: 10000, 
  },
}));

export default function Usuarios() {

  const classes = useStyles();

  let history = useHistory();
  let rol = localStorage.getItem("rol");
  const [imgWeb, setimgWeb] = useState([])
  const [imgMobile, setImageMobile] = useState([])
  const [sucess, setsucess] = useState(false)
  const [create, setcreate] = useState(false)
  const [id, setid] = useState("")

  let word = [];


  const token = localStorage.getItem('tokenAdmin');

  const handleGet = () => {

    try {

      const request = axios.get(URL + 'emprendimientos/getAllEmprendimientos', { headers: { 'token': token } } )
      .then((res) => {
        if(res.data.responseData[0].dataMobile && res.data.responseData[0].dataWeb){
          setid(res.data.responseData[0].id)
          setImageMobile(res.data.responseData[0].dataMobile)
          setimgWeb(res.data.responseData[0].dataWeb)
        }

      })
      .catch((err) => {

          console.log(err)
      })

  } catch (error) {
      
      console.log(error)

  }
  

}


  const handleUpdate = () => {

    try {

      let params = {

        dataWeb: imgWeb,
        dataMobile: imgMobile

      }

      
      const request = axios.put(URL + 'emprendimientos/updateEmprendimiento/' + id , params, { headers: { 'token': token } } )
      .then((res) => {
          setsucess(true)
      })
      .catch((err) => {

          console.log(err)
      })

  } catch (error) {
      
      console.log(error)

  }

  }
 

  useEffect(() => {

    handleGet()

  },[create])

  const handleSend = () => {
    try {

      let params = {

        dataWeb: imgWeb,
        dataMobile: imgMobile

      }

      
      const request = axios.post(URL + 'emprendimientos/createEmprendimiento', params, { headers: { 'token': token } } )
      .then((res) => {
        setcreate(true)
      })
      .catch((err) => {

          console.log(err)
      })

  } catch (error) {
      
      console.log(error)

  }
  
  }



  return (
    <div>
      <RouterColumn word={"Usuarios"} />

      <div>
        <Select Word={word} palabra={"Emprendimientos"} />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "150px" }}>
        <ImageMobile handleUpdate={handleUpdate} setimgPrincipal={setImageMobile} imgPrincipal={imgMobile} />
        </div>

        <div style={{ marginLeft: "-90px" }}>
          <Image handleUpdate={handleUpdate} setimgWeb={setimgWeb} imgWeb={imgWeb} />
        </div>
        {
         <div style={{marginTop: "-40px"}}>
           <button className="editButtonDash3" onClick={ id.length === 0 ? handleSend : handleUpdate}>Guardar cambios</button>
         </div>
        }

      </div>
      {
        create && <div className={classes.root}>

        <Alert onClose={() => setcreate(false)} variant="filled" severity="success">
          Creado exitosamente.
        </Alert>
      </div>
      }
      {
        sucess && <div className={classes.root}>

        <Alert onClose={() => setsucess(false)} variant="filled" severity="success">
          Modificado exitosamente.
        </Alert>
      </div>
      }
    </div>
  );
}
