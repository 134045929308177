import React from 'react';
import exclamationError from '../../../../assets/images/exclamation.svg';

const ErrorNotification = ({ errorText }) => {
    return (
        <div className='errorNotificationCreateAccount'>
            <img alt='error' className='errorImg-CA' src={exclamationError} />
            <label className='errorLabel-CA'> {errorText} </label>
        </div>
    )
};

export default ErrorNotification;