import React, { useState, useEffect } from 'react';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';
import './Verification.css';
import '../../../FullAccountValidation/FullAccountValidation.css';
import axios from 'axios';
import URL from '../../../../../config/urls';
import Fade from 'react-reveal/Fade';

const VerifyPhone = ({ history }) => {

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];

    let token = localStorage.getItem('token');
    const headers = { headers: { token: token } };

   
  

    const BASE_URL = URL + 'userVerifications/phone';

    const [ checkPhone, setCheckPhone ] = useState(false);
    const [ phone, setPhone ] = useState();

    // eslint-disable-next-line 
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        axios.get(URL + 'users/' + userIdFromUrl)
        .then(response => {
            setPhone(response.data.responseData.phone)
        })
        .catch(e => {
            console.log(e);
        })
        // eslint-disable-next-line 
    }, [phone])

    async function sendSMS() {
        setLoading(true);
        setTimeout(() => history.push('/myprofile/verificated/verifyPhone/code?userId=' + userIdFromUrl), 500)
         try {
             await axios.post(BASE_URL, { phone: phone, id: userIdFromUrl }, headers );
             /* setTimeout(() => history.push('/myprofile/verificated/verifyPhone/code'), 500) */
             setTimeout(() => history.push('/myprofile/verificated/verifyPhone/code?userId=' + userIdFromUrl), 500)
         } catch(e) {
             console.log('no funciono', e)
         }
    }

    function handleCheckPhone(value) {
        if(value === 'yes') {
            setCheckPhone(true);
        } else {
            history.push('/myprofile/mydata?userId=' + userIdFromUrl)
        }
    }

    return (
        <div>
            <BlueTopBar
            title='Verificación'
            link={'/myprofile/verificated?userId=' + userIdFromUrl }
            />
            <Fade right>
            <div className='emailVerificationContent'>
                <div className='emailVerificationComponent'>
                    <div className='emailVerificationTitle'>
                        <h1> Validá tu teléfono </h1>
                    </div>
                    <p className='p-email-validate-text'>
                    ¡Necesitamos que verifiques tu cuenta!
                    Esto te permitirá validar tu cuenta y poder contactarte con
                    aquellos propietarios que sólo acepten mensajes de usuarios validados.
                    </p>
                </div>
                { !checkPhone &&
                <>
                    <div className='validate-input-container'>
                        <label className='p-email-validate-text' style={{ marginTop: 10, marginBottom: 10 }}>
                            ¿Este es tu número de teléfono?
                        </label>
                        <input disabled value={ phone && phone } />
                    </div>
                    <div className='buttonContainerEmailVerification'>
                        <button onClick={ (e) => handleCheckPhone('no')}>Modificar</button>
                        <button onClick={ (e) => handleCheckPhone('yes')}>Sí</button>
                    </div>
                </>
                }
                { checkPhone &&
                <>
                    <p className='p-email-validate-text'>
                    Haz click en el siguiente botón, y te enviaremos un SMS a tu celular.
                    Dentro del mismo habrá un código que te habilitará tu cuenta de HiPropi.
                    </p>
                    <div className='buttonSendEmailContainer'>
                        <button
                        onClick={sendSMS}
                        className='sendEmailValidation'>
                            Enviar SMS
                        </button>
                    </div>
                </>
                }
            </div>
            </Fade>
        </div>
    )
};

export default VerifyPhone;