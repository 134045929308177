import React, { useEffect, useState } from 'react';
import './Accommodations.css'
import URL from '../../../../../config/urls';
import axios from 'axios';

const CustomAccommodations = ({ accommodation }) => {
    return (
        <div className='item-rooms-content' >
            <div className='item-rooms-content-content' >
                <img src={require('../../../../../assets/images/check.PNG')} alt='' />
                <span> {accommodation} </span>
            </div>
        </div>
    )
}

let lang = navigator.language || navigator.userLanguage;
lang = lang.split('-')

const BASE_URL = URL + `dictionaries/${lang[0]}`

const Accommodations = ({ accommodations }) => {

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        axios.get(BASE_URL)
            .then(response => {
                const data = response.data.responseData.dict.accommodationsDict
                setDictionary(data)
            })
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })
    }, []);

    let customDictAccommodations = [];
    for (let accommodation in accommodations) {
        if (accommodations[accommodation] && typeof accommodations[accommodation] !== 'number') {
            customDictAccommodations.push(dictionary[accommodation])
        }
    }

    return (
        <div className='items-room-container' >
            {customDictAccommodations.map((accommodation, index) => <CustomAccommodations accommodation={accommodation} key={index} />)}
        </div>
    )
}

export default Accommodations;