import React from 'react';
import './Filter.css';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            <Box p={0}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function FilterOne(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /* const handleChangeIndex = index => {
        setValue(index);
    }; */

    const MyTabs = withStyles({
        root: {
            borderRadius: "100px !important"
        },
        indicator: {
            backgroundColor: 'transparent',
        },
    })(Tabs);

    let data = ['DATOS', 'MAPA']

    return (
        <div>
            <div className="container-principalsearch">
                <AppBar position="static" color="default">
                    <MyTabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {data.map(label => <Tab className="tabColor" label={label} key={label} />)}
                    </MyTabs>
                </AppBar>
            </div>
            <TabPanel value={value} index={0} dir={theme.direction}>
                {props.internalProperty}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                {props.map}
            </TabPanel>
        </div>
    );
}