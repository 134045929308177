import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import { withStyles } from '@material-ui/core/styles';

const MyMobileStepper = withStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "transparent",
      padding: 0,
    },
    progress: {
      width: "100%",
      border: "1px solid #5963cc",
      height: "10px",
      borderRadius: "100px",
      backgroundColor: "#eeeffa",
    },
    dotActive: {
      color: "red",
    }
  })(MobileStepper);
  
const Steps = ({ steps, activeStep }) => {

    return (
      <MyMobileStepper
        variant="progress"
        steps={steps}
        position="static"
        activeStep={activeStep}
      />
    );
  }

export const Stepper = ({ title, byStep, steps, activeStep }) => {
    return (
        <div className="top-new-property" style={{ width: '83%', margin: 'auto' }}>
            <div className="title-property">
                <p> { title } </p>
                <p> { byStep } </p>
            </div>
            <Steps steps={steps} activeStep={activeStep} />
        </div>
    )
};