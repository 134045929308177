import React from 'react'
import { useHistory } from 'react-router-dom';
import hands from "./img/hands.svg";
import surface from "./img/surface1.svg";
import "./PaymentsPage.css";

export const WebPayment = () => {
    let screenSizeWidth = window.innerWidth;

    const history = useHistory()

    const handleGo = (args) => {
      
      if(args == 'tarjeta' ){
        history.push('/data/user')
      }
      if(args == 'transf'){
        history.push('/data/user/transf')
      }

    }

    return (
        <div>
            <div className="services-web-container-card">
        <div className="services-web-content-card">
          <div className="servicesContainer">
            {screenSizeWidth > 1030 && (
              <div
                className="report-title-container"
                style={{
                  marginTop: screenSizeWidth > 1030 ? "35px" : "0px",
                  marginBottom: "30PX",
                  // marginLeft: "-30px",
                  width: "90%",
                }}
              >
                <label> ¿Como te gustaría abonar tu pack documentos? </label>
              </div>
            )}
            <div className="container-payment-page">
              <div className="tarjeta-payment">
                <div className="img-payment-tarjeta">
                  <img src={surface} />
                </div>
                <div className="contenedor-parrafo-div">
                  <strong className="parrafo-pay-div">
                    Tarjeta de crédito o dédito
                  </strong>
                </div>
                <div className="container-parrafo-data">
                  <p className="text-payment-data">
                    Aboná tu servicio de documentación con tu tarjeta de crédito
                    o débito. La pasarela de pago es Mercado Pago, por lo que
                    los recargos y cuotas son ajenos a HiPropi.com
                  </p>
                </div>
                <div className="button-payment-data">
                  <button className="buttonSearch" onClick={() => handleGo('tarjeta')}  style={{ width: "200px", fontSize: "10px"}}>ABONAR CON TARJETAS</button>
                </div>
              </div>
              <div className="line-payment">
                  <hr width="1" size="350" className="hr-data-payment" />
              </div>

              <div className="tarjeta-payment">
                <div className="img-payment-tarjeta">
                  <img src={hands} />
                </div>
                <div className="contenedor-parrafo-div">
                  <strong className="parrafo-pay-div">
                    Transferencia bancaria
                  </strong>
                </div>
                <div className="container-parrafo-data">
                  <p className="text-payment-data">
                    También podés abonar realizando una transferencia o depósito
                    bancario. Una vez realizado el pago, contactate con nosotros
                    al 351 239-9518 y envianos tu comprobante.
                  </p>
                </div>
                <div className="button-payment-data">
                  <button className="buttonSearch" onClick={() => handleGo('transf')}  style={{ width: "200px", fontSize: "10px"}}>ABONAR CON TRANSFERENCIA</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}
