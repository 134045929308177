import React, { useEffect, useState, useContext } from "react";
import Select from "../../Common/Select/Select";
import RouterColumn from "../../RouterColumn/RouterColumn";
import Formulario from "./formulario.js";
import { Context } from '../../../../../Context'
import Image from "./image";
import { useHistory } from "react-router-dom";

export default function Usuarios() {

  let history = useHistory()
 
  const { dataBlog, 
    setdataBlog } = useContext(Context);

  const [imgPrincipal, setimgPrincipal] = useState([])
  const [DataPrincipal, setDataPrincipal] = useState({
    postTitle: "",
    postSubtitle: "",
    author:""
  })
  
  const [postBody, setpostBody] = useState([])

  let date = new Date()


  useEffect(() => {

    if(dataBlog.length == 0){
      history.push("/admin/UpdateBlog")
    }

    setDataPrincipal(dataBlog.principalData)
    setimgPrincipal([dataBlog.principalImg && dataBlog.principalImg[0]])
    setpostBody(dataBlog.postBody)
  },[])


  let word = [{ nombre: "Crear nota", link: "/admin/blog"}];

  // /admin/UpdateBlog

  return (
    <div>
      <RouterColumn word={"Usuarios"} />

      <div>
        <Select Word={word} palabra={"Editar nota"} />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "255px" }}>
          <Formulario id={dataBlog.id}  setDataPrincipal={setDataPrincipal} date={date} DataPrincipal={DataPrincipal} setparagrapth={setpostBody} postBody={postBody} imgPrincipal={imgPrincipal} />
        </div>

        <div>
          <Image setimgPrincipal={setimgPrincipal} imgPrincipal={imgPrincipal} />
        </div>
      </div>
    </div>
  );
}
