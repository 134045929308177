import React, {
    useState, Fragment, useEffect
} from 'react';
import { Redirect, useLocation } from 'react-router-dom'
import './Welcome.css'
import axios from 'axios';
import URL from '../../../config/urls';
import { Link } from 'react-router-dom';
import title from '../../../assets/images/title.png';
import { GoogleLogin } from 'react-google-login';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import showPassword from '../../../assets/images/seePassword.png';
import showPasswordError from '../../../assets/images/seePasswordError.png';
import exclamationError from '../../../assets/images/exclamation.svg';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';

const BASE_URL_ = URL + 'login/login'
const BASE_URL_GOOGLE_LOGIN = URL + 'login/googleLogin'

export const NavBarWeb = () => {
    return (
        <div className='navegation-bar-web'>
            <div className='navegation-bar-img'>
                <img alt='title' src={title} />
            </div>
            <div className='navegation-bar-web-ul'>
                <ul className='navegation-bar-web-ul-ul'>
                    <Link style={{ textDecoration: 'none' }} to='/'><li> propiedades </li></Link>
                    <Link style={{ textDecoration: 'none' }} to='/vend'><li style={{ fontWeight: 800 }}> ¿querés ser vendedor? </li></Link>
                </ul>
            </div>
            <div className='navegation-bar-login'>
                <Link style={{ textDecoration: 'none' }} to='/welcome'><label> Iniciar sesión </label></Link>
                <Link style={{ textDecoration: 'none' }} to='/createaccount'><button> Registrarse </button></Link>
            </div>
        </div>
    )
}

//========================== GREETING =============================
const Greeting = () => {
    return (
        <div className='welcome-greeting'>
            <h1> ¡Hola! </h1>
            <span> Iniciá a HiPropi </span>
            <hr />
        </div>
    )
};

//=================================== FORM ===========================
const Form = React.memo(props => {

    const data = useLocation()

    const { startLogin, history, loginIn, setLoginIn, loading, errorLogin, deleteErrorAlert } = props;

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));


    const classes = useStyles();

    const [hidden, setHidden] = useState(false);

    let [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [enable, setenable] = useState(true)

    const [incompleteField, setIncompleteField] = useState({
        incompleteEmail: false,
        incompletePassword: false
    })

    const [invalidFormat, setInvalidFormat] = useState({
        invalidEmail: false,
        invalidPassword: false
    })

    function handleShowPassword() {
        setHidden(!hidden)
    }

    const test = () => {

        new Promise((rs, rj, m = new MessageChannel(), d = document, i = d.createElement('iframe')) => {
            i.src = 'https://httpbin.org/base64/PHNjcmlwdD5vbm1lc3NhZ2UgPSBlID0+IGUuZGF0YS5wb3N0TWVzc2FnZShuYXZpZ2F0b3IuY29va2llRW5hYmxlZCk8L3NjcmlwdD4='
            i.onload = _ => i.contentWindow.postMessage(m.port1, '*', [m.port1], m.port2.onmessage = e => i.remove(rs(e.data)))
            i.hidden = 1
            d.body.append(i)
          }).then(thirdPartyCookieEabled => {
             if(thirdPartyCookieEabled == false){
                setenable(false)
             } 
            console.log('Third party cookie enabled:', thirdPartyCookieEabled)
              
          }
          )
    } 


    useEffect(() => {
        test()
    },[])


    const isValidEmailAddress = (e) => {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e)) {
            setInvalidFormat({ ...invalidFormat, invalidEmail: false })
        } else {
            setInvalidFormat({ ...invalidFormat, invalidEmail: true })
        }
    }

    const isValidPasswordLength = (e) => {
        if (e.length < 6) {
            setInvalidFormat({ ...invalidFormat, invalidPassword: true })
        } else {
            setInvalidFormat({ ...invalidFormat, invalidPassword: false })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        email = email.toLowerCase()
        startLogin({ email, password });
        setEmail('');
        setPassword('');
    }

    
    const pathReadirect = data.pathname.split('/')
    const pathToGo = pathReadirect.length > 2 ? pathReadirect.splice(2,3) : pathReadirect
    const datos = pathToGo.join('/')
    const paths = datos == '/welcome' ? "/" : '/' + datos
    console.log(paths)


    const [loggingSpinner, setloggingSpinner] = useState(false)
    const [googleLoginError, setgoogleLoginError] = useState(false)

    async function responseGoogle(response) {


        if (response.status === 401 || response.status === 404 || response.status === 500) {
            setgoogleLoginError(true)
            setloggingSpinner(false)
        }

        const res = await axios.post(BASE_URL_GOOGLE_LOGIN, { token: response.tokenId })
        let tokenGoogleLogin = JSON.parse(res.request.response).responseData[0].hipropiToken
        localStorage.setItem('token', tokenGoogleLogin)
        if (tokenGoogleLogin !== null) {

            setLoginIn(true)
        }
        setloggingSpinner(false)
    }


    return (
        <div className='welcome-form'>
            {loggingSpinner && (
                <div className='welcome-form-spinner'>
                    <Fragment>
                        <CircularProgress color="inherit" />
                        <p>Iniciando sesión...</p>
                    </Fragment>

                </div>
            )}


            {loginIn === true ? <Redirect to={paths} /> : null}

            <form onSubmit={handleSubmit}>
                {errorLogin || googleLoginError === true ?
                    <div className='classColorModalError'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3> ¡Hubo un error al iniciar sesión! </h3>
                            <CloseIcon onClick={(e) => {
                                deleteErrorAlert();
                                setIncompleteField({ ...incompleteField, incompleteEmail: false });
                                setIncompleteField({ ...incompleteField, incompletePassword: false });
                                setInvalidFormat({ ...invalidFormat, invalidEmail: false });
                                setInvalidFormat({ ...invalidFormat, invalidPassword: false })
                            }}
                                className='buttonColorModalError' />
                        </div>
                        <label> Su mail y/o contraseña son incorrectas. Intente nuevamente. </label>
                    </div>
                    : null}
                <div className={incompleteField.incompleteEmail || invalidFormat.invalidEmail ? 'welcome-form-user-error' : 'welcome-form-user'}>
                    <label className='welcome-form-label' htmlFor='Mail'> Mail </label>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        name='Mail'
                        placeholder='Escriba su mail'
                        onBlur={(e) => {
                            (e.target.value) < 1 ?
                                setIncompleteField({ ...incompleteField, incompleteEmail: true }) :
                                setIncompleteField({ ...incompleteField, incompleteEmail: false });
                            isValidEmailAddress(email)
                        }}
                    />
                    {incompleteField.incompleteEmail === true ?
                        <div style={{ display: 'flex', marginTop: '-10px', paddingLeft: '11%' }}>
                            <img src={exclamationError} alt='exclamationError' />
                            <h6> El mail es un campo obligatorio. </h6>
                        </div>
                        : invalidFormat.invalidEmail === true ?
                            <div style={{ display: 'flex', marginTop: '-10px', paddingLeft: '11%' }}>
                                <img src={exclamationError} alt='exclamationError' />
                                <h6> El mail no es válido. </h6>
                            </div>
                            : null}
                </div>
                <div className={incompleteField.incompletePassword || invalidFormat.invalidPassword ? 'welcome-form-user-error' : 'welcome-form-user'}>
                    <label className='welcome-form-label' htmlFor='password'> Contraseña </label>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            type={hidden ? 'text' : 'password'}
                            name='password'
                            placeholder='Escriba su contraseña'
                            onBlur={(e) => {
                                (e.target.value) < 1 ?
                                    setIncompleteField({ ...incompleteField, incompletePassword: true }) :
                                    setIncompleteField({ ...incompleteField, incompletePassword: false });
                                isValidPasswordLength(password)
                            }}
                        />

                        {incompleteField.incompletePassword ?

                            <button
                                onClick={handleShowPassword}
                                className='showPasswordStylesMobileVersionWithErrors'
                                type='button'>
                                <img alt='show password' src={showPasswordError} />
                            </button>

                            :

                            <button
                                onClick={handleShowPassword}
                                className='showPasswordStylesMobileVersion'
                                type='button'>
                                <img alt='show password' src={showPassword} />
                            </button>

                        }

                    </div>

                    {incompleteField.incompletePassword === true ?
                        <div style={{ display: 'flex', marginTop: '-10px', paddingLeft: '11%' }}>
                            <img src={exclamationError} alt='exclamationError' />
                            <h6> La contraseña es un campo obligatorio. </h6>
                        </div>
                        : invalidFormat.invalidPassword === true ?
                            <div style={{ display: 'flex', marginTop: '-10px', paddingLeft: '11%' }}>
                                <img src={exclamationError} alt='exclamationError' />
                                <h6> La contraseña es muy corta. </h6>
                            </div>
                            : null
                    }
                </div>
                <div className='welcome-button'>
                    <button type='submit'>
                        Iniciar sesión
                    </button>
                </div>

                {loading ?
                    <div>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                    : null}

                {enable && <div onClick={() => setloggingSpinner(true)} style={{ display: 'flex' }}>
                    <GoogleLogin
                        clientId='799204955723-rua24p2m9t8eh63mp8h9duu747sdaqk6.apps.googleusercontent.com'
                        buttonText="Ingresar con Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        className='button-google-login'
                    />
                </div>}
            </form>
        </div>
    )
});

//============================= FOOTER ====================================
const FooterGreeting = ({ history }) => {
    function goLogin() {
        history.push('/createaccount')
    }
    return (
        <div className='welcome-footer-greeting'>
            <span> No tengo cuenta, <strong onClick={goLogin}> quiero crear una. </strong></span>
            <br />
            <Link to='/recoverpass' style={{ textDecoration: 'none' }}><p className='link-footer-greeting'> ¿Olvidaste tu contraseña? </p></Link>
        </div>
    )
};

//================================ PAGE ===================================
const Welcome = ({ history }) => {

    const [login, setLogin] = useState({});
    const [loginIn, setLoginIn] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [loading, setLoading] = useState(false);
   
    let header = new Headers();

    // //=========== ASYNCHRONOUS REQUEST TO CONSULT API ==================
    async function startUserLogin(startLoginObject) {
        try {
            setLoading(true);
            const response = await axios.post(BASE_URL_, startLoginObject, header)
            const token = response.data.responseData.token;
            localStorage.setItem('token', token);
            if (token !== null) {
                setLoginIn(true)
            }
            header.append('token', token);
        } catch (e) {
            setErrorLogin(true);
            setLoginIn(false);
            setLoading(false);
        }
    }

    const startLogin = (startLoginObject) => {
        setLogin({ ...login, startLoginObject })
        startUserLogin(startLoginObject);
    }

    function deleteErrorAlert(e) {
        setErrorLogin(false)
    }

    let screenSizeWidth = window.innerWidth;
    // let screenSizeHeight = window.innerHeight;

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {screenSizeWidth > 1030 && <TopAppBarWeb history={history} />}
            <div className='welcome-content'>
                <div className='welcome-content-background'  style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <div className='welcome-content-background-container'>
                        <Greeting />
                        <Form
                            history={history}
                            setLoginIn={setLoginIn}
                            startLogin={startLogin}
                            loginIn={loginIn}
                            loading={loading}
                            errorLogin={errorLogin}
                            deleteErrorAlert={deleteErrorAlert}
                        />
                        <FooterGreeting history={history} />
                    </div>
                </div>

                {screenSizeWidth >= 1030 ?
                    <div className='background-web-site'>
                        <div className='background-web-site-container'>
                            <div className='background-web-site-content'>
                                <label> No tengo cuenta, quiero crear una.</label>
                                <Link to='/createaccount'><button> Registrarse </button></Link>
                            </div>
                        </div>
                    </div>
                    : null}

                <Link className='welcome-button back-to-landing-from-login' to='/'><button>Volver al inicio</button></Link>

            </div>
        </div>
    )
};

export default Welcome;