import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import arrowBack from '../../../../../assets/images/arrowBack.svg';
import './Complaint.css';
import ModalWarning from '../../../../commons/Modals/ModalWarning';
import alertforbidden from '../../../../../assets/images/forbidden-property.svg';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import URL from '../../../../../config/urls';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppBarWeb from '../../../TopAppBarWeb/TopAppBarWeb';

export const ReportComponent = React.memo(props => {

    const { params, selectedOtherReason, selectedReason, reportReason, handleSubmit, reasons, header } = props;

    return (
        <div className='report-component-container'>
            <div className='report-component-content'>
                <label style={{ fontSize: 14 }}>
                    {header}
                </label>

                <p className='complaintPage-question' > ¿Cuál es la razón de la denuncia? </p>

                {reasons.map((reason, index) =>
                    <div key={index}>
                        <input
                            id={ reason.r }
                            name={ reason.r }
                            type='checkbox'
                            className='complaintPage-input'
                            checked={ reason.state === '' ? false : true }
                            onChange={ (e) => selectedReason(e.target.name) }
                        />
                        <label htmlFor={ reason.r } className='complaintPage-label' />
                        <h5 className='complaintPage-h5'> { reason.text } </h5>
                    </div>
                )}

                {reportReason.other !== '' &&
                    <div className='another-reason-container'>
                        <Fade opposite>
                            <h5 className='complaintPage-h5' style={{ margin: 0, padding: 0 }}> Escriba un texto breve describiendo la situación. </h5>
                            <textarea
                                placeholder='Escriba un texto breve.'
                                onChange={ (e) => selectedOtherReason(e.target.value) }
                                style={ { marginTop: 10, fontSize: 14, height: 100 } }  
                            />
                        </Fade>
                    </div>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <button
                        disabled={ params ? false : true }
                        className={ params ? 'complaint-button' : 'complaint-button-disabled' }
                        type='submit'
                        onClick={handleSubmit}>
                        denunciar
                    </button>
                </div>
            </div>
        </div>
    )
});

const ComplaintPage = ({ history }) => {

    let propertyIdFromUrl = window.location.href.split('=')[1].split('?')[0];

    let token = localStorage.getItem('token');

    const useStyles = makeStyles(theme => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
    }));

    const classes = useStyles();

    const [ reportReason, setReportReason ] = useState({
        duplicated: '',
        inmob: '',
        other: '',
        text: ''
    })

    const [ openSecondModal, setOpenSecondModal ] = useState(false);
    const [ openFirstModal, setOpenFirstModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    let reasons = [
        {
            r: 'duplicate property',
            text: 'La propiedad está duplicada.',
            state: reportReason.duplicated
        },
        {
            r: 'real estate property',
            text: 'La propiedad pertenece a una inmobiliaria.',
            state: reportReason.inmob
        },
        {
            r: 'another reason',
            text: 'Otra razón.',
            state: reportReason.other
        }
    ];

    function handleSelectedOtherReason(value) {
        setReportReason({ ...reportReason, text: value })
    }

    function handleSelectedReason(value) {
        switch(value) {
            case 'duplicate property':
                setReportReason({ duplicated: 'true', inmob: '', other: '', text: '' })
                break;
            
            case 'real estate property':
                setReportReason({ duplicated: '', inmob: 'true', other: '', text: '' })
                break;

            case 'another reason':
                setReportReason({ duplicated: '', inmob: '', other: 'true', text: '' })
                break;

            default:
                console.log('No se encontró valor.')
        }
    }

    let header = 'Gracias por ayudarnos denunciando aquellas propiedades que incumplen con las normas de HiPropi.'

    function handleActionButton() {
        history.goBack();
    }

    function reportConfirm() {
        setOpenFirstModal(true)
    }

    function sendReport() {
        setOpenFirstModal(false);
        setLoading(true);
        let report = { 
                        duplicated: reportReason.duplicated, 
                        inmob: reportReason.inmob, 
                        other: reportReason.other,
                        text: reportReason.text
                    };
        if(report) {
            axios.post(URL + 'denounce/properties/' + propertyIdFromUrl, report, { headers: { 'token': token } })
            .then( response => {
                setReportReason({ duplicated: '', inmob: '', other: '', text: '' })
                setTimeout(() => setLoading(false), 500)
                setTimeout(() => setOpenSecondModal(true), 500)
            })
        }
    }

    let params = 
        reportReason.duplicated !== '' ||
        reportReason.inmob !== '' ||
        reportReason.other !== '';

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            { openFirstModal &&
                <ModalWarning
                    title='¿Está seguro de denunciar esta propiedad?'
                    acept='Estoy seguro'
                    cancel='Cancelar'
                    image={alertforbidden}
                    noButton={true}
                    close={true}
                    openModal={openFirstModal}
                    actionButton={sendReport}
                />
            }
            <ModalWarning
                title='¡Denuncia enviada!'
                text='Gracias por ayudarnos para mantener HiPropi una mejor comunidad.'
                acept='Entendido'
                cancel='false'
                image={alertforbidden}
                noButton={true}
                close={true}
                openModal={openSecondModal}
                actionButton={handleActionButton}
            />

            { screenSizeWidth > 1030 ?

                <TopAppBarWeb history={history} />

            :

                <div className="topBar">
                    <Link to='/'><img src={arrowBack} alt={arrowBack} className="filterIcon" /></Link>
                    <h3> Denunciar </h3>
                </div>
            }

            <div className='report-property-container-body'>
                { screenSizeWidth > 1030?
                <div className='report-property-content-body'>
                    <div className='report-title-container'>
                        <label> Denunciar </label>
                    </div>

                    <ReportComponent
                    selectedOtherReason={handleSelectedOtherReason}
                    selectedReason={handleSelectedReason}
                    reportReason={reportReason}
                    handleSubmit={reportConfirm}
                    reasons={reasons}
                    header={header}
                    params={params}
                    />
                </div>
                :
                <Fade right>
                    <ReportComponent
                    selectedOtherReason={handleSelectedOtherReason}
                    selectedReason={handleSelectedReason}
                    reportReason={reportReason}
                    handleSubmit={reportConfirm}
                    reasons={reasons}
                    header={header}
                    params={params}
                    />
                </Fade>
                }
            </div>

            { loading &&
                <div>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div> 
            }
            
        </div>
    )
}

export default ComplaintPage;