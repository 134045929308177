import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../Context'
import TopBar from '../../commons/BlueTopBar/BlueTopBar';
// import Card from '../../pages/Services/components/ServiceCard';
import Card from '../../pages/Services/components/ServiceBigCard';
import './components/Services.css';
import { servicesDataBigCard } from '../../../data/servicesData';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import URL from '../../../../../frontend/src/config/urls';
import axios from 'axios';

const Services = ({ history }) => {

    const { setloadingService, setbutton } = useContext(Context);

    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true)
    const [buttonService, setbuttonService] = useState(true)

    let token = localStorage.getItem('token');

    function handleOnClick(id) {

        console.log(id)

        switch(id) {
            case 203:
                if(!token){
                   return history.push('/welcome/services/signal')
                }
                history.push('/services/signal')
                break;
            case 204:
                if(!token){
                    return history.push('/welcome/services/photographer')
                 }
                history.push('/services/photographer')
                break;
            case 205:
                if(!token){
                    return history.push('/welcome/question/pack1')
                 }
                history.push('/question/pack1')
                break;
            case 206:
                if(!token){
                    return history.push('/welcome/services/comercializacion')
                 }
                 history.push('/services/comercializacion')
                break;    
            default:
                console.log('no hay valores.')
        }
    };

    const headers = { headers: { "token": token, } };


    // get user properties
    useEffect(() => {
        const BASE_URL = URL + 'myProperties';
        axios.get(BASE_URL, headers).then(response => {
            const { responseData } = response.data
            setProperties(...properties, responseData)
            setLoading(false)
            setbuttonService(true)
            setloadingService(false)
            setbutton(true)
        })
        .catch(() => {
            setLoading(false)
            setloadingService(false)
            setbuttonService(false)
            setbutton(false)
        })
        
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let screenSizeWidth = window.innerWidth;


    return (
        <div  >
            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
            :
                <TopBar 
                    title='Servicios' 
                    link='/'
                /> 
            }
            <div className='services-web-container-card'  >
                <div className='services-web-content-card'>
                    
                    
                    <div className='servicesContainer'>
                    { screenSizeWidth > 1030 &&
                        <div className='report-title-container' style={{ margin: 'auto' }} style={{ marginTop: screenSizeWidth > 1030 ? "35px" : "0px", marginBottom: "30PX"}}>
                            <label> Servicios </label>
                        </div>
                    }
                        <div className='servicesContentText'>
                            <label> Aquí encontrarás los servicios exclusivos de HiPropi que te ayudarán a comercializar tus inmuebles de manera directa y simple. </label>
                        </div>
                        <div className='services-card-container-card' >
                            { servicesDataBigCard.map((service, index) =>
                            <div className='servicesContent'>
                                <Card 
                                    key={index} 
                                    service={service} 
                                    handleOnClick={handleOnClick}
                                    button={buttonService}
                                    properties={properties}
                                    loading={loading} 
                                />
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Services;