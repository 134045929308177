import React from 'react';
import './App.css';

// style of phone input used in create user form and myProfile/myData form.
import 'react-phone-input-2/lib/style.css';

import ContextProvider from './Context';

import Routes from './Routes';

function App() {
  return (
    <div className="App">
		<ContextProvider>
			<Routes />
		</ContextProvider>
    </div>
  );
}

export default App;