import React from 'react';
import './HeaderWeb.css';

const HeaderWeb = React.memo(props=> {

    const { text, w } = props;

    return (
        <div className='header-web-container'>
            <div className='header-web-content' style={{ width: w }}>
                <label> {text} </label>
            </div>
        </div>
    )
});

export default HeaderWeb;