import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import URL from "../../../../../../config/urls";
import axios from "axios";
import ModalResult from "../../../Common/ModalResult/ModalResult";
import "./ModalData.css"
// import './../Modal.css'

// Select
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";

const names = ["Nuevo", "Pendiente", "Finalizado"];

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        style={{ marginTop: "10px", paddingTop: "20px" }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ datos, func }) {
  const [open, setOpen] = React.useState(true);
  const [Data, setData] = React.useState([]);
  const [status, setStatus] = useState("");
  const [DatosPago, setDatosPago] = useState({});
  const [loading, setloading] = useState(false);
  const [alertData, setalertData] = useState("");
  const [openAlert, setopenAlert] = useState(false);

  const classes = useStyles();

  const token = localStorage.getItem("tokenAdmin");

  useEffect(() => {
    setData([datos]);
  }, []);

  useEffect(() => {
    if (Data) {
      setOpen(true);
    }
  }, []);

  const handleChangeCartel = (event) => {
    setStatus(event.target.value);
  };

  const handleSelectType = (e, tipo) => {
    let data = e.target.value;
    data.type = tipo;

    setDatosPago(data);
  };

  useEffect(() => {
    setStatus(DatosPago.itemStatus);
  }, [DatosPago]);
  const handleClose = () => {
    func(false);
    setOpen(false);
  };

  

  console.log(DatosPago)

  const onUpdate = () => {

    let BASE_URL;

    if(DatosPago.itemName == "servicio-comercializacion"){
      BASE_URL = URL + "admin/dashboard/servicesHired/commercialization";
    } else {
      BASE_URL = URL + "admin/dashboard/servicesHired/documents";
    }

    let valorData;

    if (DatosPago.type == "MERCADO PAGO") {
      valorData = DatosPago.preferenceId;
    } else {
      valorData = DatosPago.paymentId;
    }

    let parametros = {
      serviceId: valorData,
      serviceStatus: status,
    };

    try {
      setloading(true);

      const request = axios.put(
        BASE_URL + `/${datos.userId}`,
        parametros,
        { headers: { token: token } }
      );
      request.then((res) => {
        console.log(res);
        setloading(false);
        setalertData("pass");
        setopenAlert(true);
      });
      request.catch((err) => {
        setloading(false);
        setopenAlert(true);
        setalertData("error");
        console.log(err);
      });
    } catch (error) {
      console.log("Hubo un error");
    }
  };

  const getLengthOfObject = (obj) => {
    let lengthOfObject = Object.keys(obj).length;
    return lengthOfObject;
  };

  return (
    <div>
      <Dialog
        style={{ marginTop: "20px" }}
        maxWidth="lg"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Typography
            style={{
              backgroundColor: "#5963cc",
              height: "5px",
              marginTop: "-10px",
            }}
          ></Typography>
        </DialogContent>

        <DialogTitle onClose={handleClose}></DialogTitle>

        {getLengthOfObject(DatosPago) == 0 ? (
          <DialogContent className="div-modal-content">
            <div style={{ marginTop: "-20px", marginLeft: "90px" }}>
              <Typography component={"span"} className="titulo-modal-admin">
                NOMBRE USUARIO{" "}
              </Typography>

              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {datos.userFullName}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <FormControl
                className={classes.formControl}
                style={{ marginRight: "50px", marginTop: "10px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  MERCADO PAGO
                </InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  // value={DatosPago}
                  onChange={(e) => handleSelectType(e, "MERCADO PAGO")}
                >
                  {datos.usersMPArray.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name.itemName} ({name.requestDate}) {name.itemStatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <FormControl
                className={classes.formControl}
                style={{ marginRight: "50px", marginTop: "10px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  TRANSFERENCIA
                </InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  // value={DatosPago}
                  onChange={(e) => handleSelectType(e, "TRANSFERENCIA")}
                >
                  {datos.usersTransferArray.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name.itemName} ({name.requestDate}) {name.itemStatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>{" "}
          </DialogContent>
        ) : (
          <DialogContent className="div-modal-content">
            <div
              className="titulo-modal-admin"
              style={{ marginTop: "-20px", marginLeft: "90px" }}
            >
              <Typography>TIPO DE SERVICIO</Typography>

              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {DatosPago.itemName}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <Typography> FORMA DE PAGO</Typography>

              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {DatosPago.type}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <br />
              <Button
                variant="contained"
                onClick={() => setDatosPago({})}
                color="secondary"
              >
                volver
              </Button>
            </div>{" "}
          </DialogContent>
        )}

        <div className="hr-content">
          <hr style={{ borderColor: "#e6e6e6" }} />
        </div>

        {getLengthOfObject(DatosPago) == 0 ? (
          <DialogContent
            className="div-modal-content"
            style={{ marginTop: "20px" }}
          >
            <div
              className="titulo-modal-admin"
              style={{ marginTop: "-20px", marginLeft: "90px" }}
            >
              <Typography> NOMBRE </Typography>
              <br />
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {datos.userFullName}{" "}
              </h6>
            </div>
          </DialogContent>
        ) : (
          <DialogContent
            className="div-modal-content"
            style={{ marginTop: "20px" }}
          >
            <div
              className="titulo-modal-admin"
              style={{ marginTop: "-20px", marginLeft: "90px" }}
            >
              <Typography> NOMBRE </Typography>
              <br />
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {DatosPago.userFullName}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <Typography> TELEFONOS </Typography>
              <br />
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {DatosPago.phone} -{" "}
                {DatosPago.phoneAlternative.length == 0
                  ? "No hay alternativo"
                  : DatosPago.phoneAlternative}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <Typography> EMAIL </Typography>
              <br />
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {DatosPago.userEmail}
              </h6>
            </div>
          </DialogContent>
        )}
        <div className="hr-content">
          <hr />
        </div>

        {loading ?(
          <DialogContent
          className="div-modal-content"
          style={{ marginTop: "20px" }}
        >
          <div
            className="messages-loading-screen"
            style={{ marginTop: "100px" }}
          >
            <CircularProgress />
          </div>
            </DialogContent>
        ) : getLengthOfObject(DatosPago) == 0 ?  (
          <DialogContent
            className="div-modal-content"
            style={{ marginTop: "20px" }}
          >
            <div
              className="titulo-modal-admin"
              style={{ marginTop: "-20px", marginLeft: "90px" }}
            >
              <Typography>FECHA DE SOLICITUD</Typography>
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {datos.orderDate}{" "}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <Typography>INFO DE ESTADO</Typography>
              <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                {" "}
                {datos.serviceStatus}
              </h6>
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <h6
                className="data-modal-admin"
                style={{ marginTop: "10px" }}
              ></h6>
            </div>
          </DialogContent>
        ) : (
          <DialogContent
            className="div-modal-content"
            style={{ marginTop: "20px" }}
          >
            {DatosPago.type == "MERCADO PAGO" ? (
              <div
                className="titulo-modal-admin"
                style={{ marginTop: "-20px", marginLeft: "90px" }}
              >
                <Typography> ESTADO DE PAGO</Typography>

                <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                  {" "}
                  {datos.orderDate}{" "}
                </h6>
              </div>
            ) : (
              <div
                className="titulo-modal-admin"
                style={{ marginTop: "-20px", marginLeft: "90px" }}
              >
                <Typography> NOMBRE DE TITULAR DE CUENTA</Typography>

                <h6 className="data-modal-admin" style={{ marginTop: "10px" }}>
                  {" "}
                  {DatosPago.nameBankAccount}{" "}
                </h6>
              </div>
            )}

            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <Typography> ESTADO DE LA OPERACION </Typography>
              <br />
              {status && status.length == 0 ? (
                <div
                  className="messages-loading-screen"
                  style={{ marginTop: "230px" }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <FormControl
                  className={classes.formControl}
                  style={{ marginRight: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {status}
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    value={status}
                    onChange={handleChangeCartel}
                    input={<Input />}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="titulo-modal-admin" style={{ marginTop: "-20px" }}>
              <br />
              <h6
                className="data-modal-admin"
                style={{ marginTop: "10px" }}
              ></h6>
            </div>
          </DialogContent>
        )}

        {/* {loading && (
          <div
            className="messages-loading-screen"
            style={{ marginTop: "230px" }}
          >
            <CircularProgress />
          </div>
        )} */}

        {!loading && openAlert && (
          <ModalResult open={openAlert} data={alertData} />
        )}

        <DialogActions>
          <Button autoFocus onClick={onUpdate} color="primary">
            Guardar cambios
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
