import React, { useRef, useState, useCallback } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker
} from "react-google-maps";

function Map(props) {

  const [center, setCenter] = useState(props.defaultLocation);
  const refMap = useRef(null);


  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter();
    setCenter(mapCenter);
    props.updatePosition(mapCenter);
  };

  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={13}
       onBoundsChanged={handleBoundsChanged}
      center={props.defaultLocation}
    >
      <Marker position={center} />
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(Map));
