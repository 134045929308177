import React, { useEffect, useState } from 'react';
import URL from '../../../config/urls';
import axios from 'axios';

const DispositionSelector = ({ stateContext, handleChange }) => {

  const [disposition, setDisposition] = useState([]);
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {

    // /dispositions is a private backend route, it requires the user to be authenticated
    const config = {
      headers: {
        'token': localStorage.getItem('token')
      }
    }

    let BASE_URL = URL + 'dispositions';
    axios.get(BASE_URL, config)
      .then(res => {
        setDisposition(res.data.responseData);
      })

    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-')

    BASE_URL = URL + `dictionaries/${lang[0]}`
    axios.get(BASE_URL)
      .then(response => {
        setDictionary(response.data.responseData.dict.dispositions)
      })

  }, [])

  return (
    <div>
      <select name="disposition" value={stateContext.disposition ? stateContext.disposition : ''} onChange={handleChange} >
        <option value="">Seleccione la disposición</option>
        {
          disposition.map((type, i) =>
            <option value={type} key={i} > {dictionary[type]} </option>
          )
        }
      </select>
    </div>
  )
}

export default DispositionSelector
