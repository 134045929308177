import React from 'react'
import TopAppBar from "../../../commons/TopBar/TopAppBar";
import surface from "../img/surface1.svg";
import './mobilePay.css'


export const TarjetaMobile = () => {
    return (
        <div>
        <TopAppBar  />
        <div className="contenedor-tarejta-pay-mobiledata" >
           <div className="img-data-tarj-mobi" >
               <img src={ surface }/>
           </div>

           <div className="text-tarj-mobi" >
               <strong className="blue-strong-pay" > Tarjeta de crédito o débito </strong>
           </div>
           <div className="text-data-tarj-mobile" >
               <p className="p-data-pay-mobi" >
               Aboná tu servicio de documentación con tu tarjeta de crédito o débito.
La pasarela de pago es Mercado Pago, por lo que los recargos y cuotas son ajenos a HiPropi.com
               </p>
           </div>
           <div className="button-payment-data">
             <button className="buttonSearch" style={{ width: "250px", fontSize: "14px", height: "50px"}}>ABONAR CON TARJETAS</button>
           </div>
        </div>
   </div>
    )
}
