import React, { useState, useEffect } from 'react';
import './TopAppBarWeb.css';
import hipropiLogo from '../../../assets/images/hipropiBlanco.png';
import messages from '../../../assets/images/mail.svg';
import Badge from '@material-ui/core/Badge';
import axios from 'axios';
import URL from '../../../config/urls';
import SessionModal from '../Modals/SessionModal';
import SearchBarWeb from './SearchBarWeb'
import mailicon from '../../../assets/images/chatsLogo.svg';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import InstagramIcon from '@material-ui/icons/Instagram';
const TopAppBarWeb = ({ history }) => {

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token);

    const [online, setOnline] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);
    const [unviewed, setunviewed] = useState(0);


    let onlineUser = localStorage.getItem('token');

    const getMessages = async () => {
        const response = await axios.get(URL + 'login/verifyToken', { headers: { 'token': onlineUser } })
        try {
          let tokenIsValid = response.data.tokenIsValid
          if (onlineUser && !tokenIsValid) {
            setOpenModal(true)
          } else {
            let decoded = jwt.decode(onlineUser).data.id;
            const messages = await axios.get(URL + 'messages/getById', { headers: { 'token': onlineUser } })
    
            let countUnviewed = 0;
            if (messages.data.responseData.length > 0) {
              messages.data.responseData.forEach((chat) => {
                chat.messages.chat.forEach((m) => {
    
                  if (!m.viewed && m.receiver === decoded) {
                    countUnviewed++;
                  }
                })
              })
            };
            setunviewed(countUnviewed);
    
          }
        } catch (error) {
    
          console.log('No se pudo realizar la petición:', error)
    
        }

      }

    useEffect(() => {getMessages()})  

    useEffect(() => {
        axios.post(URL + 'properties/sendMailToDate')
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })

    },[])

    useEffect(() => {
        getMessages()
        if (decoded !== null) {
            setOnline(true)
        } else {
            setOnline(false)
        }
        axios.get(URL + 'login/verifyToken', { headers: { 'token': token }} )
        .then( response => {
          let tokenIsValid = response.data.tokenIsValid
          if(online && !tokenIsValid) {
            setOpenModal(true)
            localStorage.removeItem('token')
          }
        })
        .catch(e =>
          console.log('No se pudo realizar la petición:', e )
        )


        // eslint-disable-next-line
    }, [online, unviewed])

    const goFacs = async () => {
        await history.push('/');
        const goTo = document.getElementById('go-faqs').getBoundingClientRect().top + window.pageYOffset + -80;
        window.scrollTo({ top: goTo, behavior: 'smooth' });
    } 

    function handleNavegationOptions(value) {
        switch (value) {
            case 'appraiser':
                history.push('/appraiser');
                break;
            case 'services':
                history.push('/services');
                break;
            case 'faqs':
                // goFacs();
                history.push('/preguntas');
                break;
            case 'login':
                history.push('/welcome');
                break;
            case 'signIn':
                history.push('/createaccount');
                break;
            case 'myProfile':
                if (decoded.data.id !== null) {
                    history.push('/myprofile?userId=' + decoded.data.id);
                }
                break;
            case 'favorites':
                history.push('/favorites');
                break;
            case 'myProperties':
                history.push('/myproperties');
                break;
            case 'consult':
                history.push('/consult');
                break;
            case 'messages':
                history.push('/messages');
                break;
            case 'document':
                history.push('/question/pack1');
                break;
            case 'signOut':
                setTimeout(() => localStorage.removeItem('token'), 500);
                setTimeout(() => setOnline(false), 500);
                setTimeout(() => history.push('/welcome'), 500)
                break;
            default:
                history.push('/')
        }
    }

    function handleActionButton() {
        history.push('/welcome')
    };

    return (
        <>
            <SessionModal 
            noButton={true}
            openModal={openModal}
            title='¡Tu sesión caducó!'
            text='Ha pasado mucho tiempo desde la última vez que iniciaste sesión. Ingresa nuevamente a tu cuenta para poder acceder a todas las secciones de HiPropi o continúa navegando offline.'
            acept='Iniciar sesión'
            cancel='cerrar'
            close={true}
            actionButton={handleActionButton}
            />

{/* https://www.flaticon.es/icono-gratis/comentario_1380338?term=chat&page=1&position=8&page=1&position=8&related_id=1380338&origin=search */}

            {online ?

                <div className='topAppBarWebContainer'  >

<div className='topAppBarWebContentTop'>
                     
                     <div  className='top-app-bar-web-width-top '>
                         
                         <div className='topAppBarWebNavegationOptions ' >
                         <div className='topAppBarWebIcon'>
                             <img onClick={() => handleNavegationOptions('home')} src={hipropiLogo} alt='' />
                         </div>
                             <div  className='topAppBarWebNavegationOptionsTop'>
                                <SearchBarWeb />
                                
                              </div>
                             

                             <div className="topAppBarWebLoginAndSignIn"  style={{marginRight:"-30px"}} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <Badge  badgeContent={unviewed == 0? null : unviewed } color={unviewed == 0 ? "" : "primary"} >
                                <img alt='message icon'  src={mailicon} className='messageIconNavBar' onClick={() => handleNavegationOptions('messages')}/>   
                                {/* <MessageRoundedIcon 
                                className='messageIconNavBar' style={{fontSize: "35px"}} onClick={() => handleNavegationOptions('messages')} /> */}
                                </Badge>
                            </div >

                               
                                <label className="cerrarSesionHome"  style={{color: "white", width: "200px"}} onClick={() => handleNavegationOptions('signOut')}> Cerrar sesión </label>
           
                         </div>
                     </div>
                     </div>


                    <div className='topAppBarWebContent'>
                        <div className='top-app-bar-web-width'>
                            <div className='topAppBarWebNavegationOptionsOnline'  >
                                <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('appraiser')}> TASADOR </label>
                                <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('services')}> SERVICIOS </label>
                                <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('faqs')}> PREGUNTAS FRECUENTES </label>
                                <label style={{fontWeight: "bold", color: "gray"}}  onClick={() => handleNavegationOptions('consult')}> CONTACTO </label>
                               
                                <label style={{ color: "#e32085", fontSize: "30px", fontWeight: "revert", height: "50px"}} > | </label>
                              
                                
                                <label style={{fontWeight: "bold", color: "gray"}} className="fontOptions" onClick={() => handleNavegationOptions('myProfile')}> MI PERFIL </label>
                                <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('favorites')}> FAVORITOS </label>
                                <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('myProperties')}> MIS PROPIEDADES </label>
                                {/* <label id="options" style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('document')}> DOCUMENTOS </label> */}

                                <a rel="noopener noreferrer"  href='https://www.instagram.com/hipropi/' target="_blank" >  
                                <InstagramIcon alt='message icon' className="icon-insta-nav"  />  
                                </a>
                                 
                            </div>
                        </div>
                    </div>
                </div>

                :
                <div className='topAppBarWebContainer'  >

                     <div className='topAppBarWebContentTop'>
                     
                      <div className='top-app-bar-web-width-top'>
                          <div className='topAppBarWebIcon'>
                              <img onClick={() => handleNavegationOptions('home')} src={hipropiLogo} alt='' />
                          </div>
                          <div className='topAppBarWebNavegationOptions'>
                              <div className='topAppBarWebOptions'>
                              <div className='topAppBarWebNavegationOptionsTop'>
                                <SearchBarWeb />
                                
                              </div>
                              </div>
                              <div className='topAppBarWebLoginAndSignIn' style={{marginRight:"-30px"}} >
                                  <label style={{color: "white"}} onClick={() => handleNavegationOptions('login')}> Iniciar sesión </label>
                                  <button style={{color: "white"}} onClick={() => handleNavegationOptions('signIn')}> Registrarse </button>
                              </div>
                          </div>
                      </div>
                      </div>
                   
                    <div className='topAppBarWebContentConect'>

                        <div className='top-app-bar-web-width'>

                            <div className='topAppBarWebNavegationOptions'>
                                <div className='topAppBarWebNavegationOptionsOnline'>
                                    <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('appraiser')}> TASADOR </label>
                                    <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('services')}> SERVICIOS </label>
                                    <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('faqs')}> PREGUNTAS FRECUENTES </label>
                                    <label style={{fontWeight: "bold", color: "gray"}} onClick={() => handleNavegationOptions('consult')}> CONTACTO </label>
                                    <a rel="noopener noreferrer"  href='https://www.instagram.com/hipropi/' target="_blank" >  
                                <InstagramIcon alt='message icon'  className="icon-insta-nav"   />  
                                </a> 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default TopAppBarWeb;