import 'date-fns';
import React, {useState} from 'react';
import Select from '../../Common/Select/Select'
import DatePicker from '../../Common/DatePicker/DatePicker'
import RouterColumn from '../../RouterColumn/RouterColumn'

// import './Usuario.css'  


export default function Usuarios() {


  let word = [{ nombre: "Usuarios", link: "/admin/denuncias"}]

  

  return (
 <div>
        <RouterColumn word={"Usuarios"} />

        <div>
          <Select Word={word} palabra={"Propiedades"} />
        </div>
        <div>
          <DatePicker componente={"denounceProperties"} palabra1={"Propiedades"} palabra2={"Denunciadas"} style={{marginLeft: "800px", marginRight: "50px"}} />
        </div>

    </div>
  );
}