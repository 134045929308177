import 'date-fns';
import React, { useState, useEffect, useContext } from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../../config/urls'
import './Date.css'
import TotalCount from '../TotalCount/TotalCount'
import Tables from '../Tables/Tables'
import Chart from '../../Graficos/Grafico'
import moment from 'moment';
import { Context } from '../../../../../Context'

export default function MaterialUIPickers({componente, palabra1, palabra2}) {
  // The first commit of Material-UI
  let date = new Date();
  let date2 = new Date()
  // let d = date2.setDate( date2.getDate() - 30 )
  date2.setMonth(date2.getMonth()-1);

  const [selectedDate, setSelectedDate] = React.useState(date2);
  const [selectedDateTwo, setSelectedDateTwo] = React.useState(date);
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [words, setwords] = useState([])
  const [datos, setdatos] = useState({})

  const { 
    setdataBuscador, 
    dataBuscador,
    setmodal
   } = useContext(Context);

  const token = localStorage.getItem('tokenAdmin');

  

  useEffect(() => {
    console.log(dataBuscador.length >= 1)
  },[dataBuscador])

  const handleSend = () => {
  
    let params = {
      selectedDate,
      selectedDateTwo
    }



    if( palabra2 == "DENUNCIADOS" || palabra2 == "Denunciadas" ){

      
      try {
        setloading(true)
        const request = axios.get(URL + `admin/dashboard/${componente}`, { headers: { 'token': token } })
        .then((res) => {
          setloading(false)
          setdata(res.data.responseData[0])

        })
        .catch((err) => {
          console.log('Hubo un error')

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }
 
    }

    else {
      setmodal(false)
      setdataBuscador([])

      try {
        setloading(true)
        const request = axios.post(URL + `admin/dashboard/${componente}`, params, { headers: { 'token': token } })
        .then((res) => {
          setloading(false)
          setdata(res.data.responseData[0])

        })
        .catch((err) => {
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

    }


  }

  const getDataPerMonth = () => {

    setwords([])
    setdatos({})

    const dates = []
    const repeticiones = {};

    data.forEach((e) => {
      
      if(e.dateCreate ? dates.includes(e.dateCreate.slice(0,5)) : e.dateAccess ? dates.includes(e.dateAccess.slice(0,5)) : dates.includes(e.lastDateMessages.slice(0,5)) ){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.dateCreate ? !dates.includes(e.dateCreate.slice(0,5)) : e.dateAccess ? !dates.includes(e.dateAccess.slice(0,5)) : !dates.includes(e.lastDateMessages.slice(0,5)) ) {
        dates.push(e.dateAccess ? e.dateAccess.slice(0,5) : e.dateCreate ? e.dateCreate.slice(0,5) : e.lastDateMessages.slice(0,5))
      
      }

      repeticiones[ e.dateAccess ? e.dateAccess.slice(0,5) :  e.dateCreate ? e.dateCreate.slice(0,5) : e.lastDateMessages.slice(0,5) ] = ( repeticiones[ e.dateAccess ? e.dateAccess.slice(0,5) :  e.dateCreate ? e.dateCreate.slice(0,5) : e.lastDateMessages.slice(0,5) ] || 0 ) + 1;
    
    })

    setwords(dates)
    setdatos(repeticiones)
  }

  const getDataChartPerYear = () => {

    const dates = []
    const repeticiones = {};


    data.forEach((e) => {

      if(e.dateCreate ? dates.includes(e.dateCreate.slice(3,10)) : e.dateAccess ? dates.includes(e.dateAccess.slice(3,10)) : dates.includes(e.lastDateMessages.slice(3,10))){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.dateCreate ? !dates.includes(e.dateCreate.slice(3,10)) : e.dateAccess ? !dates.includes(e.dateAccess.slice(3,10)) : !dates.includes(e.lastDateMessages.slice(3,10)) ) {
        dates.push(e.dateAccess ? e.dateAccess.slice(3,10) : e.dateCreate ? e.dateCreate.slice(3,10) : e.lastDateMessages.slice(3,10))
      
      }

      repeticiones[ e.dateAccess ? e.dateAccess.slice(3,10) : e.dateCreate ? e.dateCreate.slice(3,10) : e.lastDateMessages.slice(3,10) ] = ( repeticiones[ e.dateAccess ? e.dateAccess.slice(3,10) : e.dateCreate ? e.dateCreate.slice(3,10) : e.lastDateMessages.slice(3,10) ] || 0 ) + 1;
    
    })
    setwords(dates)
    setdatos(repeticiones)

  }


  
  useEffect(() => {
    handleSend()
  },[ selectedDate, selectedDateTwo])

 
useEffect(() => {
    if(componente == "conectUsers" || componente == "newUsers" || componente == "chats"){
      let fecha1 = moment(selectedDate);
      let fecha2 = moment(selectedDateTwo);
  
      
      if(fecha1.diff(fecha2, 'days') >= -51){
        getDataPerMonth()
      }
  
      if(fecha1.diff(fecha2, 'days') < -51){
        getDataChartPerYear()
      }
  
    }


    // getDataChartPerYear()
    // getDataPerMonth()
  },[data])


  return (
      <div>
        <div style={{marginLeft: "980px", marginRight: "50px", marginTop: "-70px",}} >
          <TotalCount palabra1={palabra1} palabra2={palabra2} total={data.length} />
        </div>

      <div className="div-datepicker" style={{ marginTop: "-120px"}} >

       <DatePicker
           showPopperArrow={false}
           selected={selectedDate}
           onChange={(selectedDate) => setSelectedDate(selectedDate)}
           className='datePicker-1'
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           fixedHeight
       />        

         <ArrowForwardIosIcon style={{marginTop: "14px", fontSize: "small", marginLeft: "-50px", color: "gray"}} />
         <DatePicker
           showPopperArrow={false}
           selected={selectedDateTwo}
           onChange={(selectedDateTwo) => setSelectedDateTwo(selectedDateTwo)}
           minDate={selectedDate}
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           className='datePicker-2'
       />   
  
    </div>

  {

    (componente == "conectUsers" || componente == "newUsers" || componente == "chats") && <Chart words={words} datos={datos} />

  }



   {
     !loading && <Tables name={componente} rowsData={dataBuscador.length >= 1 ? dataBuscador : data} handle={handleSend} />
   } 

    {
        loading && <div className='messages-loading-screen'>
        <CircularProgress />
        </div>
    }
    </div>
  );
}