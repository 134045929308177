import React, { Fragment } from 'react';
import './MyProperties.css';
import arrowBack from '../../../assets/images/arrowBack.svg';
import empty from '../../../assets/images/empty.png';
import { useState, useContext, useEffect } from 'react';
import ViewGrid from '../../commons/ViewGrid/ViewGrid';
import { Context } from '../../../Context';
import axios from 'axios';
import URL from '../../../config/urls';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalWarning from '../../commons/Modals/ModalWarning';
import alertdelete from '../../../assets/images/delete-property.svg';
import alertsave from '../../../assets/images/save-property.svg';
import alertpublish from '../../../assets/images/publish-property.svg';
import alertforbidden from '../../../assets/images/forbidden-property.svg';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import { Redirect } from 'react-router-dom';

export default function MyProperties({ history }) {
 
    const [myPropertiesUpload, setMyPropertiesUpload] = useState([]);
    const [myPropertiesSave, setMyPropertiesSave] = useState([]);
    const { newProperty, setNewProperty } = useContext(Context);
    const [searchDonde, setSearchDone] = useState(false);
    const [venta, setventa] = useState(false)
    const [alquiler, setAlquiler] = useState(false)
    const [ordenado, setordenado] = useState(true)
    const [modal, setModal] = useState({});
    const [userHasVerifiedEmail, setUserHasVerifiedEmail] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [ reverseAr, SetReverseAr ] = useState(false)
    const [ Arr, SetArr ] = useState(false)

    let jwt = require('jsonwebtoken');
    const token = localStorage.getItem('token');
    let decoded = jwt.decode(token);
    const headers = { headers: { "token": token, } };

    let GET_USER_ENDPOINT = URL + 'users/' + decoded.data.id

    const getUserData = async () => {
        const res = await axios.get(GET_USER_ENDPOINT, headers)
        // setUserHasVerifiedEmail(res.data.responseData.emailIsVerified)
        setUserHasVerifiedEmail(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
       
    }

    const useStyles = makeStyles((theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 200,
        },
      }));

    const classes = useStyles();  

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line
    }, [decoded])


    function goBack() {
        history.push('/')
    }

    const verifyEmail = () => {
        history.push(`/myprofile/verificated/verifyEmail?userId=${decoded.data.id}`)
    }

    function goNew() {

        // if (!userHasVerifiedEmail) {
        //     setModal({
        //         ...modal,
        //         open: true,
        //         title: 'Ups! No puedes cargar propiedades aún',
        //         text: 'Verifica tu email para poder comenzar a cargar propiedades',
        //         acept: 'Verificar email',
        //         cancel: 'Cerrar',
        //         image: alertforbidden,
        //         action: () => verifyEmail()
        //     });
        //     return
        // }

        // eslint-disable-next-line
        Object.entries(newProperty).map(value => {
            typeof value[1] === "boolean" ?
                setNewProperty(prevState => { return { ...prevState, [value[0]]: false } }) :
                setNewProperty(prevState => { return { ...prevState, [value[0]]: '' } });
        })
        setNewProperty(prevState => {
            return {
                ...prevState,
                action: 'upload',
            }
        });

        history.push('/newproperty/1');
    }

    function goEdit(myProperty) {
        // eslint-disable-next-line
        Object.entries(myProperty).map(variable => {
            if (typeof variable[1] === 'object' && !Array.isArray(variable[1])) {
                // eslint-disable-next-line

               variable[1] !== null && Object.entries(variable[1]).map(subVariable => {
                    typeof newProperty[subVariable[0]] !== 'undefined' && setNewProperty(prevState => { return { ...prevState, [subVariable[0]]: /^\d+$/.test(subVariable[1]) ? parseInt(subVariable[1]) : subVariable[1] } });
                })
            } else {
                typeof newProperty[variable[0]] !== 'undefined' && setNewProperty(prevState => { return { ...prevState, [variable[0]]: /^\d+$/.test(variable[1]) ? parseInt(variable[1]) : variable[1] } });
            }
            setNewProperty(prevState => {
                return {
                    ...prevState,
                    propertyId: myProperty.propertyId,
                    action: 'edit'
                }
            });
        })

        history.push('/newproperty/1');
    }

    const handleCloseOut = () => {
        setModal({ ...modal, open: false });
    }

    function tryGoSave(myProperty) {
        setModal({
            ...modal,
            open: true,
            title: '¿Estas seguro que querés despublicar esta propiedad?',
            text: 'Ya no se podrá ver en las búsquedas pero quedará guardada en "Mis propiedades".',
            acept: 'Si, despublicar',
            cancel: 'No despublicar',
            image: alertsave,
            action: () => goPublishSave(myProperty)
        });
    }

    function tryGoPublish(myProperty) {

        if (myProperty.images.low.length >= 3) {
            setModal({
                ...modal,
                open: true,
                title: '¿Estas seguro que querés publicar esta propiedad?',
                text: 'Se podrá ver en las búsquedas y podrás modificarla en cualquier momento.',
                acept: 'Si, publicar',
                cancel: 'No publicar',
                image: alertpublish,
                action: () => goPublishSave(myProperty)
            });
        } else {
            setModal({
                ...modal,
                open: true,
                title: 'No podés publicar tu propiedad',
                text: 'Tenés que ir al botón editar y completar los datos obligatorios que faltan.',
                acept: 'Entendido',
                cancel: 'false',
                image: alertforbidden,
                action: 'handleClose'
            });
        }
    }

    function goPublishSave(myProperty) {

        let params =
        {
            "data": {
                "propertyId": myProperty.propertyId
            }
        }

        const BASE_URL = URL + 'publish';

        axios.patch(BASE_URL, params, headers)
            .then(res => {
                window.location.reload();
            });

    }

    function tryGoDelete(myProperty) {
        setModal({
            ...modal,
            open: true,
            title: '¿Estas seguro que querés eliminar esta propiedad?',
            text: 'Se eliminará y ya no la vas a poder recuperar.',
            acept: 'Si, eliminar',
            cancel: 'No eliminar',
            image: alertdelete,
            action: () => goDelete(myProperty)
        });
    }

    function goDelete(myProperty) {

        const BASE_URL = URL + 'properties/' + myProperty.propertyId;

        axios.delete(BASE_URL, headers)
            .then(res => {
                window.location.reload();
            });
    }

    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    useEffect(() => {

        const BASE_URL = URL + 'myProperties';

        if(reverseAr == false){
        axios.get(BASE_URL, headers)
            .then(res => {
                const data = res.data;
                // eslint-disable-next-line
                data.responseData.map(property => {
                    property &&
                        (property.isPublished ?
                            setMyPropertiesUpload(myPropertiesUpload => { return [...myPropertiesUpload, property] }) :
                            setMyPropertiesSave(myPropertiesSave => { return [...myPropertiesSave, property] }));
                })
                setSearchDone(true);
            });}
        // eslint-disable-next-line
    }, []);

    let dataProp = myPropertiesUpload


    const noReverseArray = () => {


        if(reverseAr == true || alquiler == true || venta == true ) {
            SetArr(true)
            setAlquiler(false)
            setventa(false)
            
            SetReverseAr(false)
            if(ordenado == false){
                dataProp = myPropertiesUpload.reverse()
                setordenado(true)
            }
            setIsLoading(true)
            
            setTimeout(() => {
            setIsLoading(false)
           
            }, 3000)
        }

    }

    const ProperySell = () => {
      
        if(venta == false) {
        SetArr(false)    
        setAlquiler(false)
        SetReverseAr(false)
        setventa(true)
        setIsLoading(true)
        setMyPropertiesUpload([])
        myPropertiesUpload.map(e => {
            if(e.publishedFor.sell){
            setMyPropertiesUpload(myPropertiesUpload => { return [...myPropertiesUpload, e] }) 
        }})
        setTimeout(() => {
            setIsLoading(false)
            }, 3000)}
    }

    const ProperyRent = () => {
        SetArr(false) 
        SetReverseAr(false)
        setventa(false)
        setAlquiler(true)
        setIsLoading(true)
        setMyPropertiesUpload([])
        myPropertiesUpload.map(e => {
            if(e.publishedFor.rent){
            setMyPropertiesUpload(myPropertiesUpload => { return [...myPropertiesUpload, e] }) 
        }})
        setTimeout(() => {
            setIsLoading(false)
            }, 3000)
    }

    const reverseArray = () => {

    if(reverseAr == false ){
        SetReverseAr(true)
        SetArr(false) 
        setIsLoading(true)
        if(ordenado == true) {
            dataProp = myPropertiesUpload.reverse()
            setordenado(false)
        }
        setTimeout(() => {
        setIsLoading(false)
        }, 3000)
    }    
    }

    let screenSizeWidth = window.innerWidth;

    return (

        <div  >

            <ModalWarning
                title={modal.title}
                text={modal.text}
                acept={modal.acept}
                cancel={modal.cancel}
                image={modal.image}
                noButton={true}
                close={true}
                openModal={modal.open}
                actionButton={modal.action}
                handleCloseOut={handleCloseOut}
            />
            {
                screenSizeWidth > 1030 &&
                <NavBarWeb history={history} />
            }
            {/* {isLoading &&
                <div className="myProperties-loading-spinner-container">
                    <CircularProgress />
                </div>
            } */}
            <div className="myproperties-separation-pc">
                <div className="topBar" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                    <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />
                    <h3 > Mis propiedades</h3>
                 
                </div>
                <div className="container-myproperties" >
                <div className='styleSelect'   >                   
    <FormControl style={{ paddingLeft: "0px"}} className={classes.formControl }>
        <InputLabel htmlFor="grouped-select">Filtrar propiedades</InputLabel>
        <Select  defaultValue="" id="grouped-select">

          <MenuItem value={1}  > <button style={{border: "0"}} onClick={() => {noReverseArray()}} > mas antiguo a mas nuevo</button> </MenuItem>
          <MenuItem value={2}  ><button style={{border: "0"}} onClick={() => {reverseArray()}} > mas nuevo a mas antiguo</button></MenuItem>
          <MenuItem value={3}  ><button style={{border: "0"}} onClick={() => {ProperySell()}} > En venta </button></MenuItem>
          <MenuItem value={4}  ><button style={{border: "0"}} onClick={() => {ProperyRent()}} > En alquiler </button></MenuItem>
        </Select>
      </FormControl>
      </div>

                    <div className="container-add-new-property">
                        <button className="add-new-property" onClick={goNew}><AddIcon className="icon-add" /> Cargar una propiedad</button>
                        
                    </div>

                <div >
                    <h3>Propiedades publicadas</h3>
                    {
                        !searchDonde ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '180px' }}>
                                <CircularProgress size={70} />
                            </div> :
                            myPropertiesUpload.length === 0 ?
                                <div className="container-empty">
                                    <div className="empty-card">
                                        <img className="empty-img" src={empty} alt={empty} />
                                        <p className="empty-text">Todavía no tenés <br /> ninguna propiedad publicada</p>
                                    </div>
                                </div> :
                                <div className="container-fullP" >
                                    {
                                         isLoading ?
                                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '180px' }}>
                                             <CircularProgress size={70} />
                                         </div> : reverseAr ? dataProp.map((property, i) =>
                                            property.publishedFor.sell && property.publishedFor.rent ?
                                                <Fragment key={i}>
                                                    <div>
                                                        <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'sell'} goDelete={() => tryGoDelete(property)} />
                                                        <div className="edit-save-delete-group">
                                                            <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                            <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'rent'} goDelete={() => tryGoDelete(property)} />
                                                        <div className="edit-save-delete-group">
                                                            <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                            <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <div key={i}  >
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                        publishedFor={
                                                            property.publishedFor.sell ? 'sell' :
                                                                property.publishedFor.rent ? 'rent' :
                                                                    'tmpRent'
                                                        } />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                        ) : reverseAr == false && Arr ? dataProp.map((property, i) =>
                                        property.publishedFor.sell && property.publishedFor.rent ?
                                            <Fragment key={i}>
                                                <div>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'sell'} goDelete={() => tryGoDelete(property)} />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'rent'} goDelete={() => tryGoDelete(property)} />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                            </Fragment> :
                                            <div key={i}  >
                                                <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                    publishedFor={
                                                        property.publishedFor.sell ? 'sell' :
                                                            property.publishedFor.rent ? 'rent' :
                                                                'tmpRent'
                                                    } />
                                                <div className="edit-save-delete-group">
                                                    <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                    <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                </div>
                                            </div>
                                        ):  venta && alquiler == false ? myPropertiesUpload.map((property, i) =>
                                        property.publishedFor.sell ?
                                            <Fragment key={i}>
                                                <div>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'sell'} goDelete={() => tryGoDelete(property)} />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                            </Fragment> :
                                            <div key={i}  >
                                                <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                    publishedFor={
                                                        property.publishedFor.sell ? 'sell' :
                                                            property.publishedFor.rent ? 'rent' :
                                                                'tmpRent'
                                                    } />
                                                <div className="edit-save-delete-group">
                                                    <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                    <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                </div>
                                            </div>
                                        ): alquiler ? myPropertiesUpload.map((property, i) =>
                                        property.publishedFor.sell ?
                                            <Fragment key={i}>
                                                 <div>
                                                        <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'rent'} goDelete={() => tryGoDelete(property)} />
                                                        <div className="edit-save-delete-group">
                                                            <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                            <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                        </div>
                                                </div>
                                            </Fragment> :
                                            <div key={i}  >
                                                <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                    publishedFor={
                                                        property.publishedFor.sell ? 'sell' :
                                                            property.publishedFor.rent ? 'rent' :
                                                                'tmpRent'
                                                    } />
                                                <div className="edit-save-delete-group">
                                                    <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                    <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                </div>
                                            </div>
                                        ): venta == false && alquiler == false &&  myPropertiesUpload.map((property, i) =>
                                        property.publishedFor.sell && property.publishedFor.rent ?
                                            <Fragment key={i}>
                                                <div>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'sell'} goDelete={() => tryGoDelete(property)} />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'rent'} goDelete={() => tryGoDelete(property)} />
                                                    <div className="edit-save-delete-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                    </div>
                                                </div>
                                            </Fragment> : 
                                            <div key={i}  >
                                                <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                    publishedFor={
                                                        property.publishedFor.sell ? 'sell' :
                                                            property.publishedFor.rent ? 'rent' :
                                                                'tmpRent'
                                                    } />
                                                <div className="edit-save-delete-group">
                                                    <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                    <button className="delete-button" onClick={() => tryGoSave(property)}>Despublicar</button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                    }
                    </div>
                    <div>
                    <h3>Propiedades guardadas</h3>
                    {
                        !searchDonde ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '180px' }}>
                                <CircularProgress size={70} />
                            </div> :
                            myPropertiesSave.length === 0 ?
                                <div className="container-empty">
                                    <div className="empty-card">
                                        <img className="empty-img" src={empty} alt={empty} />
                                        <p className="empty-text">Todavía no tenés <br /> ninguna propiedad guardada</p>
                                    </div>
                                </div> :
                                <div className="container-fullP">
                                    {
                                        myPropertiesSave.map((property, i) =>
                                            property.publishedFor.sell && property.publishedFor.rent ?
                                                <Fragment key={i}>
                                                    <div>
                                                        <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'sell'} goDelete={() => tryGoDelete(property)} />
                                                        <div className="edit-save-group">
                                                            <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                            <button className="save-button" onClick={() => tryGoPublish(property)}>Publicar</button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ViewGrid property={property} handleClick={handleClick} myproperty={true} publishedFor={'rent'} goDelete={() => tryGoDelete(property)} />
                                                        <div className="edit-save-group">
                                                            <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                            <button className="save-button" onClick={() => tryGoPublish(property)}>Publicar</button>
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <div key={i}>
                                                    <ViewGrid property={property} handleClick={handleClick} myproperty={true} goDelete={() => tryGoDelete(property)}
                                                        publishedFor={
                                                            property.publishedFor.sell ? 'sell' :
                                                                property.publishedFor.rent ? 'rent' :
                                                                    'tmpRent'
                                                        } />
                                                    <div className="edit-save-group">
                                                        <button className="edit-button" onClick={() => goEdit(property)}>Editar</button>
                                                        <button className="save-button" onClick={() => tryGoPublish(property)}>Publicar</button>
                                                    </div>
                                                </div>
                                        )}
                                </div>
                    }
                    </div>
                </div>
            </div>
            
        </div>
    );
}