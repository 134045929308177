import React, { useEffect, useState } from 'react';
import './Amenities.css'
import URL from '../../../../../config/urls';
import axios from 'axios';

const CustomAmenities = ({ amenity }) => {
    return(
        <div className='item-rooms-content' >
            <div className='item-rooms-content-content' >
                <img src={require('../../../../../assets/images/check.PNG')} alt='' />
                <span> {amenity} </span>
            </div> 
        </div>
    )
}

let lang = navigator.language || navigator.userLanguage;
lang = lang.split('-')

const BASE_URL = URL + `dictionaries/${lang[0]}` 

const Amenities = ({ amenities }) => {

    const [ dictionary, setDictionary ] = useState({});

    useEffect(() => {
        axios.get(BASE_URL)
        .then(response => {
            const data = response.data.responseData.dict.accommodationsDict
            setDictionary(data)
        })
        .catch(e => {
            console.log('La petición no se ha realizado con éxito')
        })
      }, []);
       
    let customDictAmenities = [];
    for( let amenity in amenities) { 
        if(amenities[amenity]) {
            customDictAmenities.push(dictionary[amenity])
        }
    }

    return (
        <div className='items-room-container' >
            { customDictAmenities.map(( amenity, index) => <CustomAmenities amenity={amenity} key={index} /> )}
        </div>
    )
}

export default Amenities;