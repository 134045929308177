import React, {useContext} from 'react';
import { useHistory } from 'react-router-dom';
import './Services.css';
import { Context } from '../../../Context'

const Services = ({ icon, title, text, link }) => {
    const history = useHistory();
    

    const { newProperty, setNewProperty } = useContext(Context);

    const goGetPhotographer = () => {
        setNewProperty({...newProperty, route: 'getPhotographer'})
        history.push(link)
    }

    return (
        <div className="services-container">
            <div className="services-info-container">
                <div className="services-icon-container">
                    <img src={icon} alt={icon} />
                </div>
                <div className="services-text-container">
                    <h3 className="services-title">{title}</h3>
                    <p className="services-text">{text}</p>
                </div>
            </div>
            <button className="services-button" onClick={goGetPhotographer}>Adquirir</button>
        </div>
    )
}

export default Services;