import React, { useState, useContext } from 'react';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import FilterAll from '../Filter/FilterAll';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import { ExpansionPanels } from '../Filter/FilterAllDetails';
import { Context } from '../../../Context';

const PropertiesList = ({ history }) => {

    const { applyFilters, setApplyFilters, page, setPage } = useContext(Context);

    // const [page, setPage] = useState({
    //     number: 1,
    //     total: 0,
    //     size: 21
    // });

    let screenSizeWidth = window.innerWidth;

    // const goApplyFiltersWeb = (setPage,setApplyFilters, applyFilters) => {
    //     setPage({
    //         number: 1,
    //         total: 0,
    //         size: 21
    //     })

    //     setApplyFilters(!applyFilters);
    // }

    let propertiesFromLocalStorage = localStorage.getItem('propertiesByPropertiesSection')
    let propertiesFromPropertiesSection = JSON.parse(propertiesFromLocalStorage);

    return (
        <div>

            {screenSizeWidth > 1030 ?

                <div className='PropertyListWebPage'>
                    <NavBarWeb history={history} />
                    <FilterAll history={history} 
                    applyFilters={applyFilters} 
                    propertiesFromPropertiesSection={propertiesFromPropertiesSection}
                    // page={page}
                    // setPage={setPage}
                    />
                    <div className='propertyExpansionPanels'>
                        <ExpansionPanels 
                        // goApplyFiltersWeb={goApplyFiltersWeb}                    
                        //  page={page}
                        //  setPage={setPage}
                         />
                    </div>
                </div>

                :

                <div>
                    <TopAppBar />
                    <FilterAll 
                    history={history}
                    // goApplyFiltersWeb={goApplyFiltersWeb}
                    propertiesFromPropertiesSection={propertiesFromPropertiesSection}
                    // page={page}
                    // setPage={setPage}
                    />
                </div>

            }
        </div>
    )
}

export default PropertiesList;