import React from 'react'
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import check from './img/check.svg'
import logo from './img/logo.svg'
import mobile from './img/mobile.svg'
import ReactWhatsapp from 'react-whatsapp';
import WspImage from '../../../assets/images/whatsapp-logo.png'
import pc from './img/pc.svg'
import './Success.css'

const SuccessPay = ({history}) => {

    let screenSizeWidth = window.innerWidth;
    
    const servicioType = localStorage.getItem('serviceBuy')

    return (
        <div>
 {screenSizeWidth > 1030 ? (
        <TopAppBarWeb history={history} />
      ) : (
        <TopAppBar />
      )}
      {
          screenSizeWidth > 1030 ? 
          (
              <div >
                  <div className="contenedor-success-check" >
                      <img src={check} className="check-success" />
                      {
                        servicioType == 'MECA' ? <strong className="pago-confirmado">Pago Confirmado</strong> : <strong className="pago-confirmado">Solicitud Confirmada</strong>
                      }
                      
                      <div className='buy-signal-successful-button-container contenedor-success-pay' >
                        <button onClick={() => history.push('/services') } style={{width: "300px"}}> Pedir otro servicio </button>
                        <button onClick={ () =>history.push('/') } style={{width: "300px"}} > Volver al Inicio </button>
                        <p style={{fontWeight: "bold"}} >
                            ¡Si pagaste tu servicio por medio de una transferencia envianos el comprobante por whatsapp!
                        </p>
                        <ReactWhatsapp style={{border: "0px", backgroundColor: "transparent" }} element="button" number={ "+54 9 351 239-9518" } message="Hola! Quiero enviar el comprobante de mi pago." >
                             
                             <button  style={{width: "300px", display: "flex", alignItems: "center", justifyContent: "center"}} ><img style={{height: "20px", width: "20px", marginRight: "10px" }} src={WspImage} /> Hablar con un asesor </button>
                        </ReactWhatsapp>
                       
                  </div>
                  </div>
               
              </div>
          ) : (
              <div> 
                  <div className="contenedor-success-check-mobile" >
                      <img src={check} className="check-success-mobile" />
                      {
                        servicioType == 'MECA' ? <strong className="pago-confirmado">Pago Confirmado</strong> : <strong className="pago-confirmado">Solicitud Confirmada</strong>
                      }
                      <div className='buy-signal-successful-button-container contenedor-success-pay' >
                        <button onClick={() => history.push('/services') } style={{width: "250px"}}> Pedir otro servicio </button>
                        <button onClick={ () =>history.push('/') } style={{width: "250px"}} > Volver al Inicio </button>
                        <p style={{fontWeight: "bold"}} className="texto-succes-pay-sucess" >
                            ¡Si pagaste tu servicio por medio de una transferencia envianos el comprobante por whatsapp!
                        </p>
                        <ReactWhatsapp style={{border: "0px", backgroundColor: "transparent" }} element="button" number={ "+54 9 351 239-9518" } message="Hola! Quiero enviar el comprobante de mi pago." >
                             
                             <button  style={{width: "300px", display: "flex", alignItems: "center", justifyContent: "center"}} ><img style={{height: "20px", width: "20px", marginRight: "10px" }} src={WspImage} /> Hablar con un asesor </button>
                        </ReactWhatsapp>
                  </div>
                  </div>
                  <div className="contenedor-success-check">
                  {/* <img  className="footer-success-logo" src={logo} /> */}
                  <img  className="footer-success" src={mobile} />
                  </div>
              </div>
          )
      }
        </div>
    )
}

export default SuccessPay
