import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import "./ViewGrid.css";
import bed from "./../../../assets/images/property/bed.svg";
import trash from "../../../assets/images/trash-nc.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import URL from "../../../config/urls";
import defaultImg from "../../../assets/images/no_property_photo_placeholder.png";
import ModalWarning from "../../commons/Modals/ModalWarning";
import alertIcon from "../../../assets/images/favorites.svg";

let screenSizeWidth = window.innerWidth;

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    margin: "auto",
    width: "85%",
    minHeight: 145,
    maxHeight: 145,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 10,
    boxShadow: "2px 2px 15px 0 rgba(0, 0, 0, 0.1)",
  },
  cardWeb: {
    display: "flex",
    width: "100%",
    minHeight: 145,
    maxHeight: 145,
    borderRadius: 10,
    boxShadow: "2px 2px 15px 0 rgba(0, 0, 0, 0.1)",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    position: "relative",
  },
  cover: {
    width: "100%",
  },
}));

const ItemIcon = ({
  myproperty,
  goDelete,
  handleFavorite,
  isFavorite,
  propertyId,
  publishedFor,
}) => {
  return (
    <div className="view-grid-icon">
      {myproperty ? (
        <ItemDel goDelete={goDelete} />
      ) : (
        <ItemFav
          id="holita"
          handleFavorite={handleFavorite}
          isFavorite={isFavorite}
          propertyId={propertyId}
          publishedFor={publishedFor}
        />
      )}
    </div>
  );
};

const ItemPrice = ({
  price,
  currency,
  sellDiscount,
  rentDiscount,
  tmpDiscount,
  transaction,
}) => {
  return (
    <div>
      {/* <div className='view-grid-head'>
                <div className="view-grid-price-line-through"> ${new Intl.NumberFormat("de-DE").format(20000)} </div>
            </div> */}
      {transaction === "Venta" ? (
        <div className="view-grid-price-line-through">
          {`${currency} ${new Intl.NumberFormat("de-DE").format(
            Math.round(sellDiscount)
          )}`}
        </div>
      ) : (
        <div className="view-grid-price-not-line-through">
          {transaction === "Alquiler" ? rentDiscount : tmpDiscount}
        </div>
      )}
      <div className="view-grid-price">
        {`${currency} ${new Intl.NumberFormat("de-DE").format(
          Math.round(price)
        )}`}
      </div>
    </div>
  );
};

const ItemFav = ({ handleFavorite, isFavorite, propertyId, publishedFor }) => {
  return (
    <div>
      <input
        type="checkbox"
        id={propertyId + publishedFor}
        name={propertyId}
        className="item-fav-input"
        defaultChecked={isFavorite}
        onChange={handleFavorite}
      />
      <label htmlFor={propertyId + publishedFor} className="item-fav-label" />
    </div>
  );
};

const ItemDel = ({ goDelete }) => {
  return (
    <button className="myproperty-trash-button" onClick={goDelete}>
      <img className="myproperty-trash-img" src={trash} alt={trash} />
    </button>
  );
};

const ItemDetails = ({ card, color, transaction }) => {


  return (
    <div className="item-details-content">
      <p className={color}>{transaction}</p>

      {
         (card.accommodations.hasBedroomsNumber !== null && card.accommodations.hasBedroomsNumber == -1) ?
          <p className='view-grid-characters'>
              | <img src={bed} alt={bed} /> {"M"}
          </p>
          :  card.accommodations.hasBedroomsNumber ?
          <p className='view-grid-characters'>
          | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
          </p>
          : null
      }
      {/* {
          card.accommodations.hasBedroomsNumber != -1 &&
          <p className='view-grid-characters'>
              | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
          </p>
      } */}

      {/* {card.accommodations.hasBedroomsNumber && (
        <p className="view-grid-characters">
          | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
        </p>
      )} */}
    </div>
  );
};

const ItemAddress = ({ card }) => {
  return (
    <div>
      <p className="view-grid-address">
        {card.address.street}, <span> {card.address.city}.</span>
      </p>
    </div>
  );
};

const CustomCard = ({
  card,
  publishedFor,
  handleClick,
  myproperty,
  goDelete,
  isFavorite,
  showModal,
}) => {
  const classes = useStyles();

  const [color, setColor] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    if (publishedFor === "sell") {
      setTransaction("Venta");
      setColor("Venta");
      setPrice(card.price.sellPrice);
      setCurrency(card.price.sellCurrency);
    }
    if (publishedFor === "rent") {
      setTransaction("Alquiler");
      setColor("Alquiler");
      setPrice(card.price.rentPrice);
      setCurrency(card.price.rentCurrency);
    }
    if (publishedFor === "tmpRent") {
      setTransaction("Temporario");
      setColor("Temporario");
      setPrice(card.price.tmpPrice);
      setCurrency(card.price.tmpCurrency);
    }
    // eslint-disable-next-line
  }, []);

  const handleFavorite = (e) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { token: token } };
    let params = {
      propertyId: e.target.name,
      transaction: publishedFor,
    };

    if (token) {
      if (e.target.checked) {
        const BASE_URL = URL + "favourites/like";
        axios.post(BASE_URL, params, headers).then(() => {});
      } else {
        const BASE_URL = URL + "favourites/unlike";
        axios.post(BASE_URL, params, headers).then(() => {});
      }
    } else {
      e.target.checked = false;
      showModal();
    }
  };


  // const BASE_URL_IMAGES = URL + 'properties/';

  return (
    <div style={{ width: "100%" }}>
      <Card className={screenSizeWidth < 1030 ? classes.card : classes.cardWeb}>
        <CardMedia
          onClick={() => handleClick(card.propertyId, publishedFor)}
          className={classes.cover}
          image={
            card.images.medium.length ? card.images.medium[0].link : defaultImg
          }
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <div onClick={() => handleClick(card.propertyId, publishedFor)}>
              <ItemPrice
                price={price}
                currency={currency}
                sellDiscount={card.price.sellDiscount}
                rentDiscount={card.price.rentDiscount}
                tmpDiscount={card.price.tmpDiscount}
                transaction={transaction}
              />
              <ItemDetails
                card={card}
                color={color}
                transaction={transaction}
              />
              <ItemAddress card={card} />
            </div>

            <ItemIcon
              myproperty={myproperty}
              goDelete={goDelete}
              handleFavorite={handleFavorite}
              isFavorite={isFavorite}
              propertyId={card.propertyId}
              publishedFor={publishedFor}
            />
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

const ViewGrid = ({
  property,
  publishedFor,
  handleClick,
  myproperty,
  goDelete,
  isFavorite,
}) => {
  const [card, setCard] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(!openModal);
  };


  useEffect(() => {
    setCard(property);
    // eslint-disable-next-line
  }, []);

  const history = useHistory();
  const goSignIn = () => {
    history.push("/welcome");
  };

  return (
    <div style={{ width: "100%" }}>
      <ModalWarning
        title="Ups, no podés guardar favoritos"
        text="Necesitas estar adentro de una cuenta para guardar propiedades favoritos."
        acept="Iniciar Sesión"
        cancel="Ahora no"
        image={alertIcon}
        noButton={true}
        close={true}
        openModal={openModal}
        actionButton={goSignIn}
        
      />
      {card === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
          }}
        >
          <CircularProgress size={70} />
        </div>
      ) : (
        <CustomCard
          card={card}
          handleClick={handleClick}
          publishedFor={publishedFor}
          myproperty={myproperty}
          goDelete={goDelete}
          isFavorite={isFavorite}
          showModal={showModal}
        />
      )}
    </div>
  );
};

export default ViewGrid;
