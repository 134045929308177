import React, { useEffect, useState } from 'react';
import './Furnisheds.css'
import URL from '../../../../../config/urls';
import axios from 'axios';

const CustomFurnisheds = ({ furnished }) => {
    return (
        <div className='item-rooms-content' >
            <div className='item-rooms-content-content' >
                <img src={require('../../../../../assets/images/check.PNG')} alt='' />
                <span> {furnished} </span>
            </div>
        </div>
    )
}

let lang = navigator.language || navigator.userLanguage;
lang = lang.split('-')

const BASE_URL = URL + `dictionaries/${lang[0]}`

const Furnisheds = ({ furnisheds }) => {

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        axios.get(BASE_URL)
            .then(response => {
                const data = response.data.responseData.dict.accommodationsDict
                setDictionary(data)
            })
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })
    }, []);

    let customDictFurnisheds = [];
    for (let furnished in furnisheds) {
        if (furnisheds[furnished] && typeof furnisheds[furnished] !== 'number') {
            customDictFurnisheds.push(dictionary[furnished])
        }
    }

    return (
        <div className='items-room-container' >
            {customDictFurnisheds.map((furnished, index) => <CustomFurnisheds furnished={furnished} key={index} />)}
        </div>
    )
}

export default Furnisheds;