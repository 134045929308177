import "date-fns";
import React, { useEffect, useState } from "react";
import Select from "../Common/Select/Select";
import URL from "../../../../config/urls";
import RouterColumn from "../RouterColumn/RouterColumn";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import "./Aranceles.css";

export default function Aranceles() {
  let history = useHistory();
  let rol = localStorage.getItem("rol");
  const token = localStorage.getItem("tokenAdmin");

  const [data, setData] = useState([]);
  const [dollarValue, setDollarValue] = useState({});

  const [success, setSuccess] = useState(false);

  useEffect(async () => {
    if (rol == 2) {
      history.push("/admin");
    }

    try {
      const { data } = await axios.get(
        URL + "admin/dashboard/priceDocumentsPack",
        { headers: { token: token } }
      );
      console.log(JSON.stringify(data.responseData[0]));

      const ordered = {};
      Object.keys(data.responseData[0])
        .sort()
        .forEach(function (key) {
          ordered[key] = data.responseData[0][key];
        });

      setData(ordered);

      // setData(data.responseData[0]);
    } catch (error) {
      console.log(error);
    }

    try {
      const { data } = await axios.get(URL + "admin/dashboard/dollarPrice", {
        headers: { token: token },
      });
      setDollarValue(data.responseData[0]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const wordsAranceles = [
    {
      name: "[Alquiler] Consulta Juridica",
      key: "AlquilerConsultaJuridica",
    },
    {
      name: "[Alquiler] Contrato",
      key: "AlquilerContrato",
    },
    {
      name: "[Alquiler] Informe Inmueble",
      key: "AlquilerInformeInmueble",
    },
    {
      name: "[Alquiler] Informa Personal",
      key: "AlquilerInformePersonal",
    },
    {
      name: "[Alquiler] Seña",
      key: "AlquilerSeña",
    },
    {
      name: "Asesor Comercial",
      key: "AsesorComercial",
    },
    {
      name: "[Temporario] Consulta Juridica",
      key: "TemporarioConsultaJuridica",
    },
    {
      name: "[Temporario] Contrato",
      key: "TemporarioContrato",
    },
    {
      name: "[Temporario] Informe Personal",
      key: "TemporarioInformePersonal",
    },
    {
      name: "[Temporario] Seña",
      key: "TemporarioSeña",
    },
    {
      name: "[Venta] Boleto",
      key: "VentaBoleto",
    },
    {
      name: "[Venta] Consulta Juridica",
      key: "VentaConsultaJuridica",
    },
    {
      name: "[Venta] Informe Inmueble ",
      key: "VentaInformeInmueble",
    },
    {
      name: "[Venta] Informe Personal",
      key: "VentaInformePersonal",
    },
    {
      name: "[Venta] Seña",
      key: "VentaSeña",
    },
    {
      name: "Fotografo",
      key: "arancelFotografo",
    },
    {
      name: "Carteleria",
      key: "arancelCarteleria",
    },
  ];

  let word = [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: Number(value),
    });
  };

  const handleSaveValue = async () => {
    console.log(data);
    try {
      const value = await axios.put(
        URL + "admin/dashboard/priceDocumentsPack",
        data,
        { headers: { token: token } }
      );

      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <RouterColumn word={"Usuarios"} />
      {success && (
        <div className="success-data-aranceles">
          <Alert
            onClose={() => setSuccess(false)}
            variant="filled"
            severity="success"
          >
            Guardado exitosamente.
          </Alert>
        </div>
      )}
      <div>
        <Select Word={word} palabra={"Aranceles"} />
        <div className="div-cotizacion-dolar-aranceles">
          <span className="span-data-admin-aranceles-dollar">
            Cotizacion dolar blue (promedio)
          </span>
          <div className="div-aranceles-dollar">
            <span className="span-dollar-value-aranceles">
              ${dollarValue && dollarValue.dollarBluePriceAverage}
            </span>
          </div>
        </div>
      </div>

      <div className="container-span-input-data">
        {data &&
          Object.keys(data).map((e, i) => {
            const arancel = wordsAranceles.filter((word) => word.key === e);
            return (
              <div className="div-container-aranceles-admin">
                <p className="span-data-admin-aranceles">
                  {" "}
                  <span>{arancel[0].name}</span>{" "}
                </p>
                <span style={{ padding: "5px" }}>$</span>
                <input
                  type="number"
                  defaultValue={data[e]}
                  name={`${e}`}
                  onChange={handleInputChange}
                  className="input-aranceles-data-admin"
                />
                <button
                  className="buttonSearchAranceles"
                  onClick={handleSaveValue}
                >
                  {" "}
                  GUARDAR{" "}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}
