import React, { useState } from 'react';
import './RecoverPass.css';
import TopAppBar from './../../../commons/TopBar/TopAppBar';
import URL from '../../../../config/urls';
import axios from 'axios';
import ConfirmationModal from '../../../commons/ConfirmationModal/ConfirmationModal';
import ErrorNotification from '../../../commons/ErrorNotification/ErrorNotification';
import NavBarWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb';

const RecoverPassProcess = ({ history, secondStep, setError, ok, recoverPass, sendCode, error, loading, setLoading }) => {


    const [email, setEmail] = useState('');
    const [emailSentResponse, setEmailSentResponse] = useState(null)

    // eslint-disable-next-line
    const [code, setCode] = useState('');

    const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);

    function hasInvalidEmailFormat(value) {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setInvalidEmailFormat(false);
        } else {
            setInvalidEmailFormat(true);
        }
    }

    const handleSubmitEmail = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const res = await axios.post(`${URL}userVerifications/forgot/password`, { email: email })
            setEmailSentResponse(res.data)
            setLoading(false)
        } catch (error) {
            setError(true)
        }
        
        
    }

    /*  const handleSubmitCode = (e) => {
         e.preventDefault();
         sendCode({ code });
         localStorage.setItem('code', code);
         setTimeout(() => history.push('/successful'), 500)
     } */

    let params =
        invalidEmailFormat === false &&
        email.length > 1;

/*     function goLogin() {
        history.push('/welcome')
    } */

    return (
        <div className='create-account-content-app'>
            {!emailSentResponse ?
                <div>
                    <div className="container-wrapper-recover" style={{ marginTop: "55px" }} >
                        <h1 className="recover-pass-title-web">Recuperación de cuenta</h1>
                        <h1 className='recover-pass-title'>
                            Enviaremos una contraseña
                            nueva a tu mail para recuperar
                            tu cuenta
                    </h1>
                        <p className='recover-pass-paragraph'>
                            Escribí el mail que usaste para crear tu cuenta HiPropi.
                    </p>
                    </div>
                    <form onSubmit={handleSubmitEmail} className="container-wrapper-recover">
                        <label style={{ marginLeft: '5%', marginBottom: 5 }}> Correo electrónico </label>
                        <input
                            value={email}
                            className={invalidEmailFormat ? 'errorsInput' : null}
                            onChange={(e) => { setEmail(e.target.value); setError(false); hasInvalidEmailFormat(e.target.value) }}
                            name='email' type='text'
                            placeholder='correo@hipropi.com' />

                        {invalidEmailFormat && <ErrorNotification text='Mail inválido.' />}

                        {error &&
                            <div style={{ width: '100%' }}>
                                <ConfirmationModal
                                    modalType='error'
                                    title='¡Ocurrió un error!'
                                    description='El mail ingresado no se encuentra registrado en HiPropi'
                                />
                            </div>
                        }

                        <div className='button-validate-container-button'>
                            <div className="button-validate-container-button-inside">
                                <button type='submit' disabled={params ? false : true} className={!params ? 'button-validate-false' : 'button-validate-recover'}> Enviar mi mail </button>
                            </div>
                        </div>
                    </form>
                </div>
                :
                <div>
                    <div className="container-wrapper-recover" style={{ marginTop : "55px"  }}>
                        <h1 className="recover-pass-title-web">Recuperación de cuenta</h1>
                        <h1 className='recover-pass-title'> Recuperar tu contraseña </h1>
                        <p className='recover-pass-paragraph'>
                            No te preocupes, nos pasa a todos.
                        Hemos enviado un código a {email} para que puedas ingresar a tu cuenta.
                        </p>

                        <p className='recover-pass-paragraph'>
                            {emailSentResponse}
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

const RecoverPass = ({ history }) => { // parent component 

    const BASE_URL = URL + 'userVerifications/resendEmail';

    const [secondStep, setSecondStep] = useState(false);
    const [send, setSend] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    async function recoveryPass(object) {
        try {
            const response = await axios.post(BASE_URL, object)
            if (response) {
                setSecondStep(true)
            }
        } catch (e) {
            setTimeout(() => setError(true), 500)
            setTimeout(() => setLoading(false), 500)
        }
    }

    const recoverPass = (send) => {
        setSend({ send });
        recoveryPass(send)
    }

    const sendCode = ({ code }) => {
        setSend({ code })
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }
            <RecoverPassProcess
                send={send}
                setSend={setSend}
                recoverPass={recoverPass}
                secondStep={secondStep}
                setSecondStep={setSecondStep}
                sendCode={sendCode}
                history={history}
                error={error}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
            />
        </div>
    )
};

export default RecoverPass;