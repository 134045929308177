import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ModalResult from '../../Common/ModalResult/ModalResult'
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../../config/urls'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({datos, funct, handle}) {
    const [Data, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [alertData, setalertData] = React.useState("")
    const [openAlert, setopenAlert] = React.useState(false)
    const [loading, setloading] = useState(false)

    useEffect(() => {

        setData([datos])

        if(Data){
          setOpen(true)
          if(datos.userBan){
            setChecked(datos.userBan)            
          }
          
          if(datos.propertyId){
            setChecked(datos.propertyBan)
          }

        } 
    
    },[])


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    funct(false)
    setOpen(false);
    handle()
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const token = localStorage.getItem('tokenAdmin');

  const handleUpdate = () => {

    if(datos.userId){

      let body = {
        userId: datos.userId,
        userBan: checked
    }


    try {
        setloading(true)
        const request = axios.put(URL + `admin/dashboard/updateDenounceUsers`, body , { headers: { 'token': token } })
        .then((res) => {
        setloading(false)
        setalertData("pass")
        setopenAlert(true)
        })
        .catch((err) => {
          setloading(false)
          setopenAlert(true)
          setalertData("error")  
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

    }

    if(datos.propertyId){

      let body = {
        propertyId: datos.propertyId,
        propertyBan: checked
    }


    try {
        setloading(true)
        const request = axios.put(URL + `admin/dashboard/updateDenounceProperties`, body , { headers: { 'token': token } })
        .then((res) => {
        setloading(false)
        setalertData("pass")
        setopenAlert(true)
        })
        .catch((err) => {
          setloading(false)
          setopenAlert(true)
          setalertData("error")  
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

    }

  }


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          <strong> Denuncias </strong>
        </DialogTitle>
        {
          datos.userId &&         <DialogContent dividers style={{ height: "150%" }} >
          <Typography gutterBottom>
           <strong >Email</strong>: {datos.userEmail}
          </Typography>
          <Typography gutterBottom>
          <strong >ID</strong>: { datos.userId }
          </Typography>
          <Typography gutterBottom>
          <strong >Numero de denuncias</strong>: { datos.numberDenounce }
          </Typography>
          <Typography gutterBottom>
          <strong >Denuncias:</strong> { datos.denounces.map((e) => (

            <div>
              <br/>
            <Typography> <strong>Tipo: </strong>  { e.inappropriate == true ? "Contenido inapropiado" : e.spam == true? "Spam": e.other == true ? "Otro": " " }</Typography>
           
           {
             e.other == true && <Typography>- {e.txt} </Typography>  
           }
            
            </div>
          )) }
          </Typography>
          <Typography gutterBottom>
          <strong >Estado</strong>: habilitado  
          <Switch checked={checked} onChange={toggleChecked} color="secondary" />
          deshabilitado
          </Typography>
          {
        loading && 
        <CircularProgress style={{marginLeft: "130px"}} />
      
       }
        {
     !loading && openAlert && <ModalResult open={openAlert} data={alertData} />
        } 

        </DialogContent>
        }

{/* Property */}


       {
         datos.propertyId && <DialogContent dividers style={{ height: "150%" }} >
         <Typography gutterBottom>
          <strong >Email del propietario</strong>: {datos.ownerPropertyEmail}
         </Typography>
         <Typography gutterBottom>
         <strong >ID</strong>: { datos.propertyId }
         </Typography>
         <Typography gutterBottom>
         <strong >Numero de denuncias</strong>: { datos.numberDenounce }
         </Typography>
         <Typography gutterBottom>
         <strong >Denuncias:</strong> { datos.denounces.map((e) => (

           <div>
             <br/>
           <Typography> <strong>Tipo: </strong>  { e.duplicated == "true" ? "La propiedad está duplicada." : e.inmob == true? "La propiedad pertenece a una inmobiliaria.": e.other == "true" ? "Otro": " " }</Typography>
          
          {
            e.other == "true" && <Typography>- {e.txt} </Typography>  
          }
           
           </div>
         )) }
         </Typography>
         <Typography gutterBottom>
         <strong >Estado</strong>: habilitado  
         <Switch checked={checked} onChange={toggleChecked} color="secondary" />
         deshabilitado
         </Typography>
         {
       loading && 
       <CircularProgress style={{marginLeft: "130px"}} />
     
      }
       {
    !loading && openAlert && <ModalResult open={openAlert} data={alertData} />
       } 

       </DialogContent>
       }

        <DialogActions>
          <Button autoFocus onClick={handleUpdate} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}