export let quantityBedrooms= [
    {
        id: 1,
        value: '1',
        name: 'M',

    },
    {
        id: 2,
        value: '2',
        name: '1'
    },
    {
        id: 3,
        value: '3',
        name: '2'
    },
    {
        id: 4,
        value: '4',
        name: '3'
    },
    {
        id: 5,
        value: '5',
        name: '4+'
    }
];

export let quantityBathrooms= [
    {
        id: 6,
        value: '1',
        name: '1',

    },
    {
        id: 7,
        value: '2',
        name: '2'
    },
    {
        id: 8,
        value: '3',
        name: '3'
    },
    {
        id: 9,
        value: '4',
        name: '4+'
    }
];

export let quantityGarages = [
    {
        id: 10,
        value: '1',
        name: '0'
    },
    {
        id: 11,
        value: '2',
        name: '1'
    },
    {
        id: 12,
        value: '3',
        name: '2'
    },
    {
        id: 13,
        value: '4',
        name: '3'
    },
    {
        id: 14,
        value: '5',
        name: '4+'
    }
];

export let typeTransaction = [
    {
        id: 15,
        value: 'sell',
        name: 'Venta'
    },
    {
        id: 16,
        value: 'rent',
        name: 'Alquiler'
    },
    {
        id: 17,
        value: 'tmpRent',
        name: 'Alquiler temporario'
    }
];

export let quantityEdified = [
    {
        id: 18,
        name: 'totalArea',
        text: 'Superficie del terreno:'
    },
    {
        id: 19,
        name: 'edifiedArea',
        text: 'Superficie cubierta:'
    },
    {
        id: 20,
        name: 'semiEdifiedArea',
        text: 'Superficie semi-cubierta:'
    }
];

export let poolData = [
    {
        id: 21,
        value: '1',
        name: 'No tiene'
    },
    {
        id: 22,
        value: '2',
        name: 'Pequeña'
    },
    {
        id: 23,
        value: '3',
        name: 'Mediana'
    },
    {
        id: 24,
        value: '4',
        name: 'Grande'
    }
];

export let isFurnished = [
    {
        id: 25,
        value: '1',
        name: 'No'
    },
    {
        id: 26,
        value: '2',
        name: 'Completa'
    },
    {
        id: 27,
        value: '3',
        name: 'Súper equipada'
    }
];

export let antiquityOfProperty = [
    {
        id: 28,
        value: '1',
        name: 'A estrenar'
    },
    {
        id: 29,
        value: '2',
        name: 'Menor a 5 años'
    },
    {
        id: 30,
        value: '3',
        name: 'Entre 5 y 15 años'
    },
    {
        id: 31,
        value: '4',
        name: '+15 años'
    }
];

export let constructionCond = [
    {
        id: 32,
        value: '1',
        name: 'Malo'
    },
    {
        id: 33,
        value: '2',
        name: 'Regular'
    },
    {
        id: 34,
        value: '3',
        name: 'Bueno'
    },
    {
        id: 35,
        value: '4',
        name: 'Muy bueno'
    },
    {
        id: 36,
        value: '5',
        name: 'Excelente'
    }
]

export let isCreditSuitable = [
    {
        id: 37,
        value: '1',
        name: 'No'
    },
    {
        id: 38,
        value: '2',
        name: 'Si'
    },
]

export let whatKindDeed = [
    {
        id: 39,
        value: '1',
        name: 'Escritura'
    },
    {
        id: 40,
        value: '2',
        name: 'Boleto'
    },
];

export let forSelectPropertyType = [
    {
        id: 41,
        value: 'house',
        name: 'Casa'
    },
    {
        id: 42,
        value: 'deptoCondo',
        name: 'Departamento o Condominio'
    },
    {
        id: 50,
        value: 'countryHouse',
        name: 'Casa Country'
    }
]

export let forSelectCountry = [
    {
        id: 43,
        value: 'Argentina',
        name: 'Argentina'
    },
    {
        id: 44,
        value: 'Uruguay',
        name: 'Uruguay'
    }
];

export let forSelectState = [
    {
        id: 45,
        value: 'Córdoba',
        name: 'Córdoba'
    },
    {
        id: 46,
        value: 'Santa fe',
        name: 'Santa Fe'
    }
]

export let hasAmenities = [
    {
        id: 51, 
        value: '1',
        name: 'No'
    },
    {
        id: 52,
        value: '2',
        name: 'Pileta'
    },
    {
        id: 53,
        value: '3',
        name: 'Pileta y quincho'
    },
    {
        id: 54,
        value: '4',
        name: 'Pileta, quincho y gimnasio'
    }
]

export let neighborhoodType = [
    {
        ratingValue: 1,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F01.png?alt=media&token=1deee201-6e4f-4f0f-ba03-676a2262fcf2',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | calles de tierra.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas muy estándar | poco consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'económicas.' 
            }
        ] 
    },
    {
        ratingValue: 2,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F02.png?alt=media&token=6a255c79-f36a-4581-bcf4-17ebf555fbcf',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | calles de tierra | pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas estándar | semi consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'estandar.' 
            }
        ] 
    },
    {
        ratingValue: 3,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F03.png?alt=media&token=b0c0ede2-de85-4b00-8f01-106f159ba5e4',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | calles de tierra o pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas tipo medio | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'medias.' 
            }
        ] 
    },
    {
        ratingValue: 4,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F04.png?alt=media&token=6513c01f-ebc7-4d0a-8793-b32dcf652924',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas tipo medio bueno | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'medias buenas.' 
            }
        ] 
    },
    {
        ratingValue: 5,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F05.png?alt=media&token=610dd2c7-400c-4bdc-a527-ad23d1622223',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas tipo bueno | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'buenas.' 
            }
        ] 
    },
    {
        ratingValue: 6,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F06.png?alt=media&token=51add094-a902-41d1-9738-2579e8e0ff11',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas tipo medioexcelente | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'medio excelente.' 
            }
        ] 
    },
    {
        ratingValue: 7,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F07.png?alt=media&token=03441787-0e2c-4383-a593-de4ca48c76a7',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas excelente | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'excelente.' 
            }
        ] 
    },
    {
        ratingValue: 8,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F08.png?alt=media&token=b0ce2b85-9100-414d-b8b1-5891b024d45f',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas premium | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'premium.' 
            }
        ] 
    },
    {
        ratingValue: 9,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2Fneighborhood%2F09.png?alt=media&token=fab2d777-1a49-431f-8eaf-090d7ae47be1',
        text: 
        [
            { 
                title: 'Servicios:',
                description: 'luz | agua | gas | calles pavimento.' 
            },
            { 
                title: 'Entorno:',
                description: 'casas super premium | consolidado.' 
            },
            { 
                title: 'Calidades:',
                description: 'super premium.' 
            }
        ] 
    }
];

export let constructionType = [
    {
        ratingValue: 1,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F001.png?alt=media&token=1ce10cb4-c662-465f-bcdc-aaadbceb54e4',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'muy estándar | sin terminar | súper económicas.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico | graníticos o rústicos.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque grueso o fino sin pintura.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa económica | losa plana.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles básicos | líneas muy económica o bajo costo.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas muy económica o de bajo costo.' 
            },
        ] 
    },
    {
        ratingValue: 2,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F002.png?alt=media&token=81d4bf5f-06a2-4ef2-89c9-b5ba18bbfdba',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'estándar | económica | equipamiento económico.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico | granítico.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque grueso o fino con pintura.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | revoque pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles estándar | líneas básicas.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas económica o bajo costo.' 
            }
        ] 
    },
    {
        ratingValue: 3,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F003.png?alt=media&token=1864e1f3-2baf-44ff-99ad-482cc4b70ac4',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'estándar | equipamiento medio.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque fino con pintura.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | revoque pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles líneas medias.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas estándar | media.' 
            },
        ] 
    },
    {
        ratingValue: 4,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F004.png?alt=media&token=e836926c-7e84-4b51-9e01-5aefbfb5a219',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'estándar | equipamiento medio | buenas terminaciones.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico | porcelanatto económico.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque fino con pintura | buenas terminaciones.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | revoque pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles líneas medias | buenas terminaciones.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas estándar | media | accesorios de buenas terminaciones.' 
            },
        ] 
    },
    {
        ratingValue: 5,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F005.png?alt=media&token=719d9ccb-6cd6-437d-98ba-f35fbef4dcd4',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'buenas | equipamiento bueno | buenas terminaciones.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico | porcelanatto.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque fino con pintura | yeso | buenas terminaciones.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | yeso | revoque pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles | líneas medias superior | buenas terminaciones accesorios.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas media alta | accesorios de buenas terminaciones.' 
            },
        ] 
    },
    {
        ratingValue: 6,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F006.png?alt=media&token=2c709fc8-c2b5-4ab1-bcef-4f4b6a08b207',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'muy buenas | equipamiento y terminaciones muy buenas.' 
            },
            { 
                title: 'Pisos:',
                description: 'cerámico | porcelanatto | pisos alisados.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque fino con pintura | yeso | buenas terminaciones.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | yeso | pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles con líenas muy buenas | terminaciones y accesorios.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas muy buenas | accesorios de muy buenas terminaciones.' 
            },
        ] 
    },
    {
        ratingValue: 7,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F007.png?alt=media&token=c84890cb-f9c6-42cc-ae18-2f5c8a41335c',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'excelentes | equipamiento y terminaciones excelentes.' 
            },
            { 
                title: 'Pisos:',
                description: 'porcelanatto | pisos alisados | granitos.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque yeso | excelentes terminaciones.' 
            },
            { 
                title: 'Techos:',
                description: 'chapa | madera | yeso | pintado.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles con líneas excelentes | importante equipamiento.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas excelentes | accesorios de muy buenas terminaciones.' 
            },
        ] 
    },
    {
        ratingValue: 8,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F008.png?alt=media&token=a69b4a9b-126f-4045-8874-32a60060020d',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'premium | equipamiento y terminaciones premium.' 
            },
            { 
                title: 'Pisos:',
                description: 'porcelanatto | pisos alisados | mármoles | granitos.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque yeso | terminaciones premium.' 
            },
            { 
                title: 'Techos:',
                description: ' madera | yeso | pintado | terminaciones premium.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles con líneas premium | equipamiento superior.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas premium | accesorios | hidromasajes.' 
            },
        ] 
    },
    {
        ratingValue: 9,
        img: 'https://firebasestorage.googleapis.com/v0/b/hipropi.appspot.com/o/appImages%2FconstructionQuality%2F009.png?alt=media&token=937a23ab-3d34-49a5-96ab-c819587c6231',
        text: 
        [
            { 
                title: 'Calidades:',
                description: 'super premium | equipamiento y terminaciones super premium.' 
            },
            { 
                title: 'Pisos:',
                description: 'porcelanatto | pisos alisados | mármoles | granitos.' 
            },
            { 
                title: 'Paredes:',
                description: 'revoque yeso | terminaciones super premium.' 
            },
            { 
                title: 'Techos:',
                description: 'madera | yeso | pintado | terminaciones super premium.' 
            },
            { 
                title: 'Cocina:',
                description: 'muebles con líneas super premium | equipamiento premium.' 
            },
            { 
                title: 'Baños:',
                description: 'líneas premium | accesorios | hidromasajes.' 
            },
        ] 
    }
];