import React, { Fragment } from 'react';
import './Terms.css';
//import TopAppBar from '../../commons/TopBar/TopAppBar';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import arrowBack from '../../../assets/images/arrowBack.svg';

const Terms = ({ history }) => {

    let screenSize = window.innerWidth;

    const goBack = () => {
        history.goBack();
    }

    const goTerm = (term) => {
        const y = screenSize > 1030 ? 70 : 20;
        const goTo = document.getElementById(term).getBoundingClientRect().top + window.pageYOffset - y;
        window.scrollTo({ top: goTo, behavior: 'smooth' });
    }

    const allTerms = [
        {
            title: `Términos y condiciones del uso del Sitio Web`,
            text: `Lea detallada y detenidamente los presentes Términos y Condiciones de uso del Sitio Web (“Términos”), ya que contienen información importante acerca de sus derechos legales, recursos y obligaciones. <br/>
            Cuando surja de los presentes Términos la persona de “HiPropi®”, el mismo se refieren a la sociedad HiPropi® con la que suscribe el Contrato. La entidad con la que suscribe el contrato generalmente se determinará por su país de residencia o lugar de establecimiento, quedando los presentes términos y condiciones sujetos a la normativa del país a donde se encuentre el bien inmueble ofrecido, y subsidiariamente a la nacionalidad del sujeto oferente
            Este contrato describe los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables al uso de los servicios que ofrece HiPropi®, ("Servicios") dentro del sitio web (www.hipropi.com). 
            Cualquier usuario (persona humana o jurídica) que desee ingresar y/o utilizar el sitio o los Servicios que provee el mismo, podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen HiPropi y que son incorporados al presente en los términos y condiciones generales y en los correspondientes anexos.
            El solo hecho de utilizar el sitio y/o los servicios brindados en el mismo implican la aceptación expresa o tácita de las presentes condiciones generales, obligatorias y vinculantes. <br/>          
            A efectos del presente, existen dos tipos de Usuarios: El Usuario Registrado, quién debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Privacidad así como en los demás documentos incorporados a los mismos por referencia, previo a su registración como tal del sitio HiPropi®; y El Usuario No Registrado, quién debe leer, comprender y aceptar los Términos y condiciones del uso del Sitio Web HiPropi®.`
        },
        {
            title: `01 - Capacidad`,
            text: `Toda persona que tenga capacidad legal para contratar podrá utilizar los Servicios de Hipropi®. 
            Quien no tenga capacidad de hecho y de derecho, no podrá utilizar los Servicios. 
            Queda terminantemente prohibido utilizar los servicios a todo menor de edad o a toda persona que, por violación a los términos y condiciones de Hipropi®, haya sido inhabilitado temporal o definitivamente para utilizar los servicios del sitio web. 
            Toda persona humana que actúe en nombre y representación de determinada persona jurídica, deberá tener capacidad plena para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo`
        },
        {
            title: `02 - Registración`,
            text: `Al momento de registrarse, es obligatorio para la persona humana completar íntegramente el formulario preestablecido en todos sus campos, sea que registre su propia persona o a una persona jurídica que represente. 
            La información brindada será exacta, precisa y verdadera, en carácter de declaración jurada y asumiendo la obligación de que, en caso de modificarse fáctica o jurídicamente alguno de los términos declarados, deberá actualizar los Datos conforme resulte necesario y dentro del plazo fatal de 72hs desde modificado el mismo. 
            El Usuario presta expresa conformidad con que Hipropi® utilice diversos medios para identificar y verificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados. 
            Hipropi® NO se responsabiliza por la certeza o falsedad de los Datos Personales por los Usuarios declarados. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de todos los datos brindados a la plataforma.
            Hipropi® podrá requerir una registración adicional a los Usuarios que operen en nombre y representación de toda persona jurídica (sea esta de naturaleza comercial, asociación civil, fundación, etc.). Hipropi® se reserva el derecho de solicitar toda documentación respaldatoria o dato adicional a efectos de corroborar los Datos de la Persona Jurídica, así como también se reserva la facultad de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados. 
            En estos casos de inhabilitación, se dará de baja todos los artículos publicados, así como las ofertas realizadas, sin que ello genere algún derecho a resarcimiento. <br/>
            El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su Apodo o e-mail y clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad.
            La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario registre o posea más de una Cuenta. En caso que Hipropi® detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas.
            El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a Hipropi® en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. 
            La cuenta generada por el usuario, y hasta que Hipropi® resuelva cambiar tal política comercial, será gratuita, por lo que queda prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la reputación) bajo ningún título. <br/>
            Hipropi® se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.`
        },
        {
            title: `03 - Modificaciones de los Términos y Condiciones`,
            text: `Lea detallada y detenidamente los presentes Términos y Condiciones de uso del Sitio Web (“Términos”), ya que contienen información importante acerca de sus derechos legales, recursos y obligaciones. 
            Cuando surja de los presentes Términos la persona de “HiPropi®”, el mismo se refieren a la sociedad HiPropi® con la que suscribe el Contrato. 
            La entidad con la que suscribe el contrato generalmente se determinará por su país de residencia o lugar de establecimiento, quedando los presentes términos y condiciones sujetos a la normativa del país a donde se encuentre el bien inmueble ofrecido, y subsidiariamente a la nacionalidad del sujeto oferente
            Este contrato describe los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables al uso de los servicios que ofrece HiPropi®, ("Servicios") dentro del sitio web (www.hipropi.com). 
            Cualquier usuario (persona humana o jurídica) que desee ingresar y/o utilizar el sitio o los Servicios que provee el mismo, podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen HiPropi y que son incorporados al presente en los términos y condiciones generales y en los correspondientes anexos.
            El solo hecho de utilizar el sitio y/o los servicios brindados en el mismo implican la aceptación expresa o tácita de las presentes condiciones generales, obligatorias y vinculantes.            
            A efectos del presente, existen dos tipos de Usuarios: El Usuario Registrado, quién debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Privacidad así como en los demás documentos incorporados a los mismos por referencia, previo a su registración como tal del sitio HiPropi®; 
            y El Usuario No Registrado, quién debe leer, comprender y aceptar los Términos y condiciones del uso del Sitio Web HiPropi®.`
        },
        {
            title: `04 - Listado de Servicios`,
            subtitles: [
                {
                    subtitle: `4.1 Publicación de bienes inmuebles`,
                    text: `El Usuario deberá ofrecer en venta, alquiler o alquiler temporario bienes de su propiedad y/o su dominio, de acuerdo a las categorías y subcategorías apropiadas. 
                    Las publicaciones podrán incluir textos descriptivos, gráficos, fotografías y otros contenidos y condiciones pertinentes para la venta del bien, siempre que no violen ninguna disposición de este acuerdo o demás políticas de Hipropi®. 
                    El bien ofrecido por el Usuario vendedor debe ser exactamente descrito en cuanto a sus condiciones y características relevantes. 
                    Se entiende y presume que mediante la inclusión del bien o servicio en Hipropi®, el Usuario acepta que tiene la intención y el derecho de vender el bien, o está facultado para ello por su titular. Asimismo, el Usuario acepta que tiene disponible el bien publicado para su entrega inmediata o para el plazo especificado en la publicación. 
                    Se establece que los precios de los bienes publicados deberán ser expresados incluyendo la totalidad de los impuestos directos que graven la operación, cuando corresponda la aplicación de los mismos, y en moneda de curso legal. Hipropi® podrá remover cualquier publicación cuyo precio no sea expresado de esta forma para evitar confusiones o malos entendidos en cuanto al precio final del bien. 
                    Se deja expresamente establecido que ninguna descripción podrá contener datos personales o de contacto, tales como, y sin limitarse a, números telefónicos, dirección de e-mail, dirección postal, direcciones de páginas de Internet que contengan datos como los mencionados anteriormente. 
                    El sistema prevé un procedimiento de seguridad para el contacto entre oferente e interesado, por lo que la publicación directa de información de contacto que eluda dicho procedimiento será bajo exclusiva responsabilidad del oferente. 
                    El presente sitio Web cumple con su cometido al lograr la venta, alquiler o alquiler temporario de los bienes, independientemente de la forma de pago acorado entre las partes. En caso que se infrinja cualquiera de las disposiciones establecidas en esta cláusula, Hipropi® podrá editar el espacio, solicitar al Usuario que lo edite, o dar de baja la publicación donde se encuentre la infracción sin necesidad de aviso previo.`
                },
                {
                    subtitle: `4.2 Inclusión de imágenes y fotografías`,
                    text: `Desde la creación de la publicación, el usuario autoriza a Hipropi® y sus compañías asociadas a utilizar, publicar, reproducir y/o adaptar las imágenes y fotografías incluidas en sus publicaciones. <br/>
                    En particular, el usuario otorga a Hipropi® y a sus sociedades relacionadas una autorización gratuita, irrevocable, no exclusiva, internacional y sin límite temporal para publicar, reproducir y/o adaptar las imágenes y fotografías con el fin de ser usadas en todos los sitios y aplicaciones de Hipropi®, redes sociales y/o en cualquier medio masivo y no masivo de comunicación, incluyendo sin limitación, 
                    plataformas y cualquier otro medio digital o físico que Hipropi® considere oportuno o con aquellas otras plataformas o sitios de Internet con los cuales Hipropi® haya realizado una alianza, para identificar ofertas, clasificar productos, crear catálogos, realizar acciones publicitarias y de marketing y sublicenciar el uso a terceros para estos fines.
                    El usuario declara y garantiza que es titular o licenciatario de los derechos necesarios sobre las imágenes y fotografías contenidas en sus publicaciones y cuenta con los derechos y facultades necesarias para conceder la autorización detallada en esta cláusula, siendo responsable exclusivo por cualquier infracción a derechos de terceros. <br/>
                    Hipropi® podrá eliminar la publicación de las imágenes y fotografías, e incluso del bien ofrecido, si interpretara, a su exclusivo criterio, que la imagen no cumple con los presentes Términos y Condiciones. Las imágenes y fotografías de bienes publicados bajo la modalidad Premium deberán cumplir con algunos requisitos adicionales como condición para ser expuestas en la Página Principal del Sitio Web.`
                },
                {
                    subtitle: `4.3 Bienes Prohibidos`,
                    text: `Sólo podrán ser ingresados en las listas de bienes, aquellos cuya venta, alquiler y/o  alquiler temporario no se encuentre tácita o expresamente prohibida en los Términos y Condiciones Generales y demás políticas de Hipropi® o por la ley vigente del país donde se encuentre ubicado el bien. 
                    Para obtener mayor información sobre bienes prohibidos, se pueden consultar nuestras Políticas de Bienes Prohibidos de Hipropi®. <br/>
                    Queda expresamente prohibido ofrecer bienes en venta, alquiler y/o alquiler temporario que sean ajenos, de propiedad de terceros, y de los que no se cuente con la autorización legal para disponer de los mismos. 
                    Hipropi® no se hará responsable de la publicación de bienes en venta, alquiler y/o alquiler temporario que pertenezcan a otra. A tal fin, el sitio web dispone de la posibilidad de denunciar cuando un bien ofrecido es de terceros. 
                    La responsabilidad de todo bien publicado recaerá directamente sobre el titular de la cuenta oferente del msimo.`
                },
                {
                    subtitle: `4.4 Programa de protección de visitas al inmueble`,
                    text: `Hipropi® ha desarrollado un Programa (en adelante, "PPV") destinado a brindar a los oferentes la seguridad de que se encuentra debidamente registrado quién visita el bien. A tal fin, Hipropi® cuenta con documentación que acredita la titularidad del visitante, fotos del rostro del visitante, gestos que acreditan que la foto de acreditación se compadece con el visitante, geolocalización del mismo en tiempo real y dirección de IP desde donde contactó al oferente. 
                    Cualquier siniestro sucedido en motivo de la visita será de exclusiva responsabilidad del oferente y del interesado, pero el sitio web procederá a brindar toda la información necesaria para contribuir con el esclarecimiento de los hechos a través de la localización de las partes. 
                    Hipropi® se reserva el derecho de adoptar todas las medidas que entienda adecuadas, lo que puede incluir la aplicación de sanciones sobre la cuenta y publicaciones del usuario que cause el siniestro, como también brindar datos personales del usuario, tal como se describe en las Políticas de Privacidad. <br/>
                    Los Usuarios entienden y aceptan que la responsabilidad por las denuncias, suspensión y eliminación de publicaciones realizadas a través de PPV es únicamente de los miembros del Programa.
                    Al solicitar la adhesión al PPV estarás aceptando los Términos y Condiciones del programa, y la información brindada tendrá carácter de declaración jurada.`
                }
            ]
        },
        {
            title: `05 - Privacidad de la Información`,
            text: `Para utilizar los Servicios ofrecidos por Hipropi®, los Usuarios deberán facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. 
            La información de carácter público será claramente determinada por el sitio web. 
            La información de carácter privado, estará claramente establecida en el sitio web, y solo podrá ser utilizada por Hipropi® en casos de denuncias o del Programa de Protección de Visitas.`
        },
        {
            title: `06 - Obligaciones de los Usuarios`,
            subtitles: [
                {
                    subtitle: `6.1 Obligaciones del Visitante Interesado del Sitio`,
                    text: `Durante la vigencia de la publicación de venta, alquiler y/o alquiler temporario de un bien inmueble, fijado por el Usuario Oferente, los Usuarios Visitantes interesados podrán comunicarse dentro del sitio web con el Oferente, efectuando consultas del caso y realizando de forma privada ofertas de compra / alquiler para los bienes que permitan la contratación en el Sitio, y podrán utilizar el servicio del Programa de Protección de Visitas, contactando a los oferentes. 
                    La oferta de venta se cierra una vez que el Oferente da de baja la publicación, por las distintas causales previstas para la baja de la misma o por el vencimiento del plazo de la publicación.
                    La instrumentación de la venta, alquiler y / o alquiler temporario será de exclusiva responsabilidad de las partes, siendo los únicos y exclusivos responsables por los contenidos de dichos instrumentos. Hipropi® recomienda a sus usuarios concurrir con sus asesores legales a efectos de instrumentar los mismos, encontrándose facultados exclusivamente para la transferencia de los inmuebles los Escribanos Públicos Nacionales y Provinciales.
                    Al ofertar por un bien o contratar un servicio, el Usuario acepta quedar obligado por las condiciones de venta incluidas en la publicación, en la medida en que las mismas no infrinjan las leyes o los Términos y Condiciones Generales y demás políticas de Hipropi®. <br/>
                    La oferta de compra de un bien o el alquiler de un inmueble es irrevocable salvo en circunstancias excepcionales, tales como que el vendedor cambie sustancialmente la descripción del bien o servicio después de realizada alguna oferta, que exista un claro error tipográfico, o que no pueda verificar la identidad del vendedor. <br/>
                    Impuestos. Tal como lo establece la normativa fiscal vigente, el locatario debe exigir factura o ticket al locador como comprobante de la operación. El vendedor no estará obligado a emitir factura, recomendando a los mismos asistirse y asesorarse por profesionales contables habilitados.`
                },
                {
                    subtitle: `6.2 Obligaciones del vendedor`,
                    text: `El usuario vendedor debe tener capacidad legal para vender el bien o servicio objeto de su oferta. Asimismo, debe cumplir con todas las obligaciones regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o autorizaciones exigidos por la normativa aplicable para la venta de los bienes y servicios ofrecidos. Si el usuario vendedor ha recibido una oferta queda obligado a intentar comunicarse con el comprador y completar la operación. <br/>
                    Hipropi® tendrá el derecho de requerir, conforme los criterios que considere pertinentes, que ciertos usuarios vendedores solamente anuncien sus bienes en el Sitio Web mediante la utilización de los Servicios de Gestión de Pagos online y/o otras herramientas ofrecidas por Hipropi® para el cobro del bien vendido y de las tarifas por la utilización de los servicios, importando eventualmente en el pago de tarifas aplicables por la utilización de los mismos. <br/>
                    Dado que Hipropi® es un punto de encuentro entre comprador y vendedor y no participa de las operaciones que se realizan entre ellos, el vendedor será responsable por todas las obligaciones y cargas impositivas que correspondan por la venta de sus bienes y/o servicios, sin que pudiera imputársele a Hipropi® algún tipo de responsabilidad por incumplimientos en tal sentido.
                    Impuestos. Como se menciona anteriormente, Hipropi® sólo pone a disposición de los usuarios un espacio virtual que les permite comunicarse mediante Internet para encontrar una forma de vender o comprar y alquilar bienes. Hipropi® no tiene participación alguna en el proceso de negociación y perfeccionamiento del contrato definitivo entre las partes. 
                    Por eso, Hipropi® no es responsable por el efectivo cumplimiento de las obligaciones fiscales o impositivas establecidas por la ley vigente.
                    En las ventas y/o compras de monedas virtuales (Bitcoins) es necesario que el usuario guarde el código de transacción, ya que Hipropi® puede pedir el comprobante que certifique la transferencia entre las partes. Si el vendedor y/o comprador no presenta la información requerida, Hipropi® podrá descontar de la cuenta los valores correspondientes.`
                }
            ]
        },
        {
            title: `07 - Prohibiciones`,
            text: `Los usuarios no podrán: (a) manipular los precios de los artículos; (b) mantener ningún tipo de comunicación por e-mail, o por cualquier otro medio (incluyendo las redes sociales) durante la oferta del bien con ninguno de los Usuarios que participan en la misma, salvo en la sección de Preguntas y Respuestas y en las categorías correspondientes que así lo permitan; 
            (c) dar a conocer sus datos personales o de otros usuarios a través de la sección de Preguntas y Respuestas y/o por algún otro medio (incluyendo pero sin limitar Twitter, Facebook y/ o cualquier otra red social), salvo en las categorías que así lo permitan; (d) aceptar datos personales proporcionados por otros usuarios a través de la sección de Preguntas y Respuestas y/o algún otro medio (incluyendo pero sin limitar Twitter, Facebook y/o cualquier otra red social); 
            (e) publicar o vender artículos prohibidos por los Términos y Condiciones Generales, demás políticas de Hipropi® o leyes vigentes; (f) insultar o agredir a otros Usuarios; (g) utilizar su reputación, calificaciones o comentarios recibidos en el sitio de Hipropi® en cualquier ámbito fuera de Hipropi®; (h) publicar artículos idénticos en más de una publicación; (i) publicar productos idénticos en más de una publicación.
            Este tipo de actividades será investigado por Hipropi® y el infractor podrá ser sancionado con la suspensión o cancelación de la oferta e incluso de su inscripción como usuario de Hipropi® y/o de cualquier otra forma que estime pertinente, sin perjuicio de las acciones legales a que pueda dar lugar por la configuración de delitos o contravenciones o los perjuicios civiles que pueda causar a los usuarios oferentes. <br/>
            Los usuarios que infrinjan las normas de comunicación a la hora de comercializar determinado bien o bienes, se harán exclusivamente responsables de cualquier siniestro ocasionado en las visitas al inmueble y / o por el propio intercambio de datos.`
        },
        {
            title: `08 - Violaciones del Sistema o Bases de Datos`,
            text: `No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de Hipropi® como en las ofertas, descripciones, cuentas o bases de datos de Hipropi®. 
            Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.`
        },
        {
            title: `09 - Sanciones. Suspensión de operaciones`,
            text: `Sin perjuicio de otras medidas, Hipropi® podrá advertir, suspender en forma temporal o definitivamente la Cuenta de un Usuario o una publicación, aplicar una sanción que impacte negativamente en la reputación de un Usuario, iniciar las acciones que estime pertinentes y/o suspender la prestación de sus Servicios si 
            (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de los Términos y Condiciones Generales y demás políticas de Hipropi®; (b) si incumpliera sus compromisos como Usuario; (c) si se incurriera a criterio de Hipropi® en conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere errónea; 
            (e) Hipropi® entendiera que las publicaciones u otras acciones pueden ser causa de responsabilidad para el Usuario que las publicó, para Hipropi® o para los demás Usuarios en general. En el caso de la suspensión de un Usuario, sea temporal o definitiva, todos los artículos que tuviera publicados serán removidos del sistema.`
        },
        {
            title: `10 - Responsabilidad`,
            text: `Hipropi® sólo pone a disposición de los Usuarios un espacio virtual que les permite ponerse en comunicación mediante Internet para encontrar una forma de vender o comprar bienes. 
            Hipropi® no es el propietario de los artículos ofrecidos, no tiene posesión de ellos ni los ofrece en venta. Hipropi® no interviene en el perfeccionamiento de las operaciones realizadas entre los Usuarios ni en las condiciones por ellos estipuladas para las mismas, por ello no será responsable respecto de la existencia, calidad, cantidad, estado, integridad o legitimidad de los bienes ofrecidos, adquiridos o enajenados por los Usuarios. 
            Cada Usuario conoce y acepta ser el exclusivo responsable por los bienes y servicios que publica para su venta y/o compras que realiza.
            Debido a que Hipropi® no tiene ninguna participación durante todo el tiempo en que el bien o servicio se publica para la venta, ni en la posterior negociación y perfeccionamiento del contrato definitivo entre las partes, no será responsable por el efectivo cumplimiento de las obligaciones asumidas por los Usuarios en el perfeccionamiento de la operación. 
            El Usuario conoce y acepta que al realizar operaciones con otros Usuarios o terceros lo hace bajo su propio riesgo. En ningún caso Hipropi® será responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que haya podido sufrir el Usuario, debido a las operaciones realizadas o no realizadas por artículos publicados a través de Hipropi®. <br/>
            Hipropi® recomienda actuar con prudencia y sentido común al momento de realizar operaciones con otros Usuarios. El Usuario debe tener presentes, además, los riesgos de contratar con menores o con personas que se valgan de una identidad falsa. 
            Hipropi® NO será responsable por la realización de ofertas y/o operaciones con otros Usuarios basadas en la confianza depositada en el sistema o los Servicios brindados por Hipropi®.
            En caso que uno o más Usuarios o algún tercero inicien cualquier tipo de reclamo o acciones legales contra otro u otros Usuarios, todos y cada uno de los Usuarios involucrados en dichos reclamos o acciones eximen de toda responsabilidad a Hipropi® y a sus directores, gerentes, empleados, agentes, operarios, representantes y apoderados. 
            Los Usuarios deberán recurrir a la normativa vigente del país donde se radique el bien comercializado o el que estipule la normativa internacional para partes de distintas nacionalidades para iniciar un reclamo contra otro u otros Usuarios. <br/>
            En virtud que el usuario vendedor tiene la facultad para eliminar preguntas o impedir a un usuario hacer preguntas u ofertas en sus publicaciones, se deja aclarado que en ese caso, el usuario será el exclusivo responsable por esa decisión y las consecuencias que pudieran acarrear.`
        },
        {
            title: `11 - Alcance de los servicios de Hipropi®`,
            text: `Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre Hipropi® y el usuario. 
            El usuario reconoce y acepta que Hipropi® no es parte en ninguna operación, ni tiene control alguno sobre la calidad, seguridad o legalidad de los bienes y servicios anunciados, la veracidad o exactitud de los anuncios, la capacidad de los Usuarios para vender o comprar artículos. 
            Hipropi® no puede asegurar que un usuario completará una operación ni podrá verificar la identidad o datos personales ingresados por los usuarios. 
            Hipropi® no garantiza la veracidad de la publicidad de terceros que aparezca en el sitio y no será responsable por la correspondencia o contratos que el usuario celebre con dichos terceros o con otros usuarios. <br/>
            De igual forma, el usuario reconoce y acepta que Hipropi® no es parte, no interviene ni tiene control alguno sobre los servicios de protección extendida que son comercializados y prestados en forma exclusiva.`
        },
        {
            title: `12 - Fallas en el sistema`,
            text: `Hipropi® no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por fallas en el sistema, en el servidor o en Internet. Hipropi® tampoco será responsable por cualquier virus que pudiera infectar el equipo del usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. 
            Los usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. 
            Hipropi® no garantiza el acceso y uso continuado o ininterrumpido de su sitio. 
            El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a Hipropi®; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. 
            Hipropi® no será responsable por ningún error u omisión contenidos en su sitio web.`
        },
        {
            title: `13 - Tarifas. Facturación`,
            text: `Hipropi® se reserva el derecho de tomar las medidas judiciales y extrajudiciales que estime pertinentes para obtener el pago del monto debido. <br/>
            Hipropi® se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes, en cualquier momento, lo cual será notificado a los Usuarios, en la forma establecida en la Cláusula 3. 
            Sin embargo, Hipropi® podrá modificar temporalmente la Política de Tarifas y las tarifas por sus servicios por razón de promociones, siendo efectivas estas modificaciones cuando se haga pública la promoción o se realice el anuncio. <br/>
            En caso de haberse facturado cargos que no hubiesen correspondido, el usuario deberá comunicarse con nuestro equipo de Atención al Cliente para resolver dicha cuestión.`
        },
        {
            title: `14 - Sistema de reputación`,
            text: `Debido a que la verificación de la identidad de los Usuarios en Internet es difícil, Hipropi® no puede confirmar ni confirma la identidad pretendida de cada Usuario. 
            Por ello el Usuario Vendedor cuenta con un sistema de reputación de Usuarios que es actualizado periódicamente en base a datos vinculados con su actividad en el sitio. Este sistema de reputación, además constará de un espacio donde los Usuarios compradores podrán hacer comentarios sobre la experiencia de compra. 
            Dichos comentarios serán incluidos bajo exclusiva responsabilidad de los Usuarios que los emitan.<br/>
            Hipropi® no tiene obligación de verificar la veracidad o exactitud de los mismos y NO se responsabiliza por los dichos allí vertidos por cualquier Usuario, por las ofertas de compras o ventas que los Usuarios realicen teniéndolos en cuenta o por la confianza depositada en las opiniones de los compradores o por cualquier otro comentario expresado dentro del sitio o a través de cualquier otro medio incluido el correo electrónico. 
            Hipropi® se reserva el derecho de editar y/o eliminar aquellos comentarios que sean considerados inadecuados u ofensivos. Hipropi® mantiene el derecho de excluir a aquellos Usuarios que sean objeto de comentarios negativos provenientes de fuentes distintas.`
        },
        {
            title: `15 - Propiedad intelectual - Licencia - Enlaces`,
            text: `Hipropi® y/o sus sociedades controlantes, controladas, filiales o subsidiarias se reservan todos los derechos, incluyendo los derechos de propiedad intelectual e industrial, asociados con los servicios de Hipropi®, sus sitios web, los contenidos de sus pantallas, programas, bases de datos, redes, códigos, desarrollo, software, arquitectura, hardware, contenidos, información, tecnología, fases de integración, funcionalidades, dominios, 
            archivos que permiten al Usuario acceder y crear su Cuenta, herramientas de venta, marcas, patentes, derechos de autor, diseños y modelos industriales, nombres comerciales, entre otros, y declara que están protegidos por leyes nacionales e internacionales vigentes. <br/>
            En ningún caso se entenderá que el Usuario tendrá algún tipo de derecho sobre los mismos excepto para utilizar el servicio de Hipropi® conforme a lo previsto en estos Términos y Condiciones Generales. El uso indebido o contrario a la normativa vigente de los derechos de propiedad intelectual e industrial de Hipropi®, así como su reproducción total o parcial, queda prohibido, salvo autorización expresa y por escrito de Hipropi®. <br/>
            Los Usuarios tampoco podrán comunicar que los productos o servicios que ofrecen son patrocinados, promovidos, producidos, ofrecidos y/o vendidos por Hipropi® y deberán abstenerse de realizar cualquier acto que pudiera causar un daño, pérdida de reputación, o disminución del valor de los derechos de propiedad intelectual e industrial de Hipropi®. <br/>
            El sitio puede contener enlaces a sitios web de terceros. En virtud que Hipropi® no tiene control sobre tales sitios, no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de éstos, causados directa o indirectamente. 
            La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de Hipropi® con dichos sitios y sus contenidos.`
        },
        {
            title: `16 - Indemnidad`,
            text: `El Usuario mantendrá indemne a Hipropi®, así como a sus filiales, empresas controladas y/o controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados, por cualquier reclamo iniciado por otros Usuarios, 
            terceros o por cualquier Organismo, relacionado con sus actividades en el Sitio, el cumplimiento y/o el incumplimiento de los Términos y Condiciones Generales o demás Políticas, así como respecto de cualquier violación de leyes o derechos de terceros. <br/>
            A tal fin, el Usuario faculta a Hipropi® a: i) intervenir y representarlo en dichos reclamos, pudiendo celebrar los acuerdos transaccionales que considere oportunos y que tiendan a evitar mayores costos y/o evitar eventuales contingencias sin limitación, en su nombre y representación; 
            ii) retener y debitar de su Cuenta de Mercado Pago y/o de la(s) cuenta(s) bancaria(s) registrada(s) en la Cuenta Mercado Pago los fondos existentes y/o futuros para recuperar cualquier gasto incurrido en relación a los supuestos del párrafo anterior, los cuales podrán incluir, entre otros, los honorarios de los letrados intervinientes y las costas judiciales en una cantidad razonable,
            y/o iii) generar débitos específicos en su facturación.`
        },
        {
            title: `17 - Anexos`,
            text: `Forman parte integral e inseparable de los Términos y Condiciones Generales, los siguientes documentos y/o secciones de Hipropi® incorporados por referencia, donde se detallan políticas y/o Términos y Condiciones de diferentes servicios ofrecidos en el sitio. Los mismos se podrán consultar dentro del sitio mediante el enlace abajo provisto o accediendo directamente a las páginas correspondientes: <br/>
            • Artículos prohibidos <br/>
            • Artículos que violan derechos de propiedad intelectual <br/>
            • Políticas de publicación <br/>
            • Política de privacidad <br/>
            • Política de elección de apodo <br/>
            • Tarifas y facturación <br/>
            • Sistema de reputación <br/>
            • Inhabilitación o suspensión de usuarios <br/>
            • Términos y condiciones de contratación de Hipropi® publicidad <br/>
            • Sistema de opiniones de bienes <br/>
            • Términos y condiciones para la compraventa internacional <br/>
            • Términos y condiciones para la adhesión del Programa "Ahora 12"`
        },
        {
            title: `18 - Jurisdicción y Ley Aplicable`,
            text: `Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina. <br/>
            Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida ante la Justicia Nacional Ordinaria.`
        },
        {
            title: `19 - Domicilio`,
            text: `Se fija como domicilio de Hipropi® el de: Villa Allende, Córdoba, República Argentina. <br/>
            Si tienes alguna duda sobre los Términos y Condiciones Generales o demás políticas y principios que rigen Hipropi® consulta nuestra página de Ayuda.`
        }
    ]

    return (
        <div>
            {screenSize > 1030 && <NavBarWeb history={history} />}
            <div className="terms-container" >
                <div className="terms-content" style={{ marginTop: screenSize > 1030 ? "45px" : "0px" }} >
                    <div className="terms-web-content">
                        <div className="topBar">
                            <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />
                            <h3>Términos y Condiciones</h3>
                        </div>
                        <div className="terms-container-info">
                            <div className="index">
                                <ul className="index-list">
                                    {
                                        allTerms.map((item, key) =>
                                            <Fragment key={key}>
                                                <li className="index-item" onClick={() => goTerm(item.title)}>{item.title}</li>
                                                {
                                                    item.subtitles &&
                                                    <ul className="index-sublist">
                                                        {
                                                            item.subtitles.map((subitem, key) =>
                                                                <li className="index-subitem" onClick={() => goTerm(subitem.subtitle)} key={key}>{subitem.subtitle}</li>
                                                            )
                                                        }
                                                    </ul>
                                                }
                                            </Fragment>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="terms-info">
                                {
                                    allTerms.map((item, key) =>
                                        <div className="terms-item" key={key}>
                                            <h4 className="terms-title" id={item.title}>{item.title}</h4>
                                            <p className="terms-text" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                            {item.subtitles &&
                                                item.subtitles.map((subitem, key) =>
                                                    <div className="terms-subitem" key={key}>
                                                        <h4 className="terms-subtitle" id={subitem.subtitle}>{subitem.subtitle}</h4>
                                                        <p className="terms-subtext" dangerouslySetInnerHTML={{ __html: subitem.text }}></p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;
