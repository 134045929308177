import React from 'react';
import './BuySignal.css';
import TopBar from '../../../../../commons/TopBar/TopAppBar';
import smarthphone from '../../../../../../assets/images/smarthphone.svg';
import TopAppBarWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';

const BuySignalSuccessfull = ({ history }) => {  
    
    function handleRedirection(value) {
        if( value === 'anotherService') {
            history.push('/services')
        } else {
            history.push('/')
        }
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <>
            {
                screenSizeWidth > 1030 ?
                    <TopAppBarWeb history={history} />
                :
                    <TopBar />
            }
            <div className='buy-signal-successful-container'>
                <div className='buy-signal-successful-content'  style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <img src={smarthphone} alt='smarthphone-img' />
                    <h3> ¡Ya está pedido tu cartel! </h3>
                    <label> Tu cartel está en proceso! Pronto nos pondremos en contacto con vos para validar la fecha y hora de entrega. Muchas gracias! </label>
                    <div className='buy-signal-successful-button-container'>
                        <button onClick={() => handleRedirection('anotherService')} > Pedir otro servicio </button>
                        <button onClick={ () => handleRedirection('goHome')} > Volver al Inicio </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BuySignalSuccessfull;