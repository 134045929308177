import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Stepper } from '../../../../../commons/Stepper/Stepper';
import Input from '../../../../../commons/Input/Input';
import Select from '../../../../../commons/Select/Select';
import Checkbox from '../../../../../commons/Checkbox/Checkbox';
import './BuySignal.css';
import './../../../../../commons/ExpansionPanels/components/Complaint/Complaint.css';
import TopAppBarMobile from '../../../../../commons/TopBar/TopAppBar';
import { Link } from 'react-router-dom';
import whiteRightArrow from '../../../../../../assets/images/whiteRightArrow.png';
import greenLeftArrow from '../../../../../../assets/images/greenLeftArrow.png';
import { subDays, getDay } from 'date-fns';
import DatePicker from "react-datepicker";
import '../../../../../commons/DatePicker/DatePicker.css';
import "react-datepicker/dist/react-datepicker.css";
import {Context} from '../../../../../../Context'
import axios from 'axios';
import URL from '../../../../../../config/urls';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';
import StepByStep from '../../../../../commons/StepByStep/StepByStep';
import HeaderWeb from '../../../../../commons/HeaderWeb/HeaderWeb';
import ViewGrid from '../../../../../commons/ViewGrid/ViewGrid';
import Location from '../../../../../commons/Location/Location';
import Modal from '../../../../../commons/Modals/ModalWarning';


const BuySignal = ({ history }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token)
    const headers = { headers: { "token": token, } };

    // attributes 
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [hour, setHour] = useState('');
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [properties, setProperties] = useState([]);
    const [termsAndContidionsAcepted, setTermsAndContidionsAcepted] = useState(false);
    const [tooMuchProperties, setTooMuchProperties] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setcode] = useState('')
    const [telefono, settelefono] = useState('')

    // properties from request API
    const [propertiesIdFromApi, setPropertiesIdFromApi] = useState([]);
    const [countriesFromApi, setCountriesFromApi] = useState([])
    const [statesFromApi, setStatesFromApi] = useState([])

    
    const [openModal, setOpenModal] = useState(false);

    const { dataLocation, setDataLocation } = useContext(Context);

    const datos = JSON.parse(localStorage.getItem('dataLocation'))


    useEffect(() => {

        let dataFromLocalStorage = localStorage.getItem('dataPersistence');
        let dataPersistence = JSON.parse(dataFromLocalStorage);
        if (dataPersistence) {
            // setCountry(dataPersistence.country)
            // setState(dataPersistence.state)
            // setCity(dataPersistence.city)
            setStreet(dataPersistence.street)
            setNumber(dataPersistence.number)
        }

        axios.get(URL + 'users/' + decoded.data.id, headers)
            .then(response =>{
                setPropertiesIdFromApi(response.data.responseData.myProperties)
            });
        // eslint-disable-next-line
    }, [])

    // Get user properties
    useEffect(() => {
        const BASE_URL = URL + 'myProperties';
        axios.get(BASE_URL, headers).then(response => {
            const { responseData } = response.data
            setProperties(...properties, responseData)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const isWeekday = date => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const [startDate, setStartDate] = useState('');

    function handleOptions(value) {
        if (value === 'nextStep') {
            history.push('/services/signal/buy/confirm')
        } else {
            history.push('/services/signal')
        }
    }

    function removeItemFromArr(arr, value) {
        let newArray = arr.filter(element => element !== value)
        setSelectedProperties(newArray)
    }

    function searchElement(value) {
        return selectedProperties.includes(value)
    }

    function handleProperties(value) {
        let ifExists = searchElement(value);
        if (ifExists) {
            if (ifExists && selectedProperties.length > 4) {
                removeItemFromArr(selectedProperties, value);
                setTooMuchProperties(true)
            } else {
                removeItemFromArr(selectedProperties, value)
                setTooMuchProperties(false)
            }
        } else {
            if (selectedProperties.length < 3) {
                setSelectedProperties([...selectedProperties, value])
                setTooMuchProperties(false)
            } else {
                setSelectedProperties([...selectedProperties, value])
                setTooMuchProperties(true)
            }
        }
    }

    const getCountries = async () => {

        const BASE_URL = URL + 'locations/countries';
        const res = await axios.get(BASE_URL)
        let { countries } = res.data.responseData
        let countriesArray = []
        countries.forEach(country => {
            countriesArray.push({
                value: country.name,
                name: country.name,
                id: country.name
            })
        });
        setCountriesFromApi(...countriesFromApi, countriesArray)
    }

    const getStates = async (country) => {

        const BASE_URL = URL + `locations/countryStates/${country}`;
        const res = await axios.get(BASE_URL)
        let { states } = res.data.responseData

        let statesArray = []

        states.forEach(state => {
            statesArray.push({
                value: state.name,
                name: state.name,
                id: state.name
            })
        });

        setStatesFromApi(...statesFromApi, statesArray)
    }

    useEffect(() => {
        getCountries()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
       if (country) {
        getStates(country)
       }
   
        // eslint-disable-next-line
    }, [country])


    function handleData(name, value) {
        switch (name) {
            case 'city':
                setCity(value)
                break;

            case 'streetName':
                setStreet(value)
                break;

            case 'number':
                setNumber(value)
                break;
            case 'NumeroTelefono':
                settelefono(value)
                break;    

            default:
                console.log('no hay valores.')
        }
    }

    let date;

    function formatDate() {
        let day = startDate.getDate()
        let month = startDate.getMonth() + 1
        let year = startDate.getFullYear()

        if (month < 10) {
            date = day + '/0' + month + '/' + year
        } else {
            date = day + '/' + month + '/' + year
        }
    }

    let parametros =  datos.country == 'Argentina' 
    let data = datos.district == "Villa Allende" || datos.district == "Cordoba"
    let states = datos.state == "Córdoba" 

    function sendOrder() {

        if(!parametros || !data || !states){
            return setOpenModal(true)
        }

        let order = {
            country: datos.country, 
            state: datos.state, 
            city: datos.district, 
            street, number, hour, date, selectedProperties, code, telefono
        }
        if (order) {
            let dataPersistence = JSON.stringify(order)
            localStorage.setItem('dataPersistence', dataPersistence)
            // axios.post(URL + 'services/cartel', order, { headers: { 'token': token } })
            //     .then(response =>
            history.push('/services/signal/buy/confirm')
            // .catch(e =>
            //     history.push('/services/signal/buy/confirm')
            //     //contemplar errores
        }
    }

    function handleSubmit() {

        setLoading(true);
        formatDate();
        setTimeout(() => sendOrder(), 1000)
    }

    let params =
        datos.country === '' ||
        datos.state === '' ||
        datos.district === '' ||
        street === '' ||
        number === '' ||
        hour === '' ||
        code === '' ||
        date === '' ||
        selectedProperties.length < 0 ||
        termsAndContidionsAcepted === false ||
        tooMuchProperties === true;

    let screenSizeWidth = window.innerWidth;



    function handleActionButton() {
        history.push('/')
      }


    return (
        <div>
            {screenSizeWidth > 1030 ?
                <TopAppWeb history={history} />
                :
                <>
                    <TopAppBarMobile history={history} />
                    <div className='buyServicesHeaderContainer'>
                        <label> Adquisición de servicios: </label>
                        <Stepper title='Cartelería' byStep='1/2' steps={5} activeStep={2} />
                    </div>
                </>
            }

     <Modal
        noButton={true}
        openModal={openModal}
        title='¡Carteleria no disponible!'
        text='Este servicio aún no se encuentra disponible en tu región'
        acept='Volver'
        cancel='cerrar'
        close={false}
        history={history}
        actionButton={handleActionButton}
      />

            <div className='buyServicesBodyContainer'>
                <div className='buy-services-photographer-body-content'>
                    <div className='buy-services-signal-width'>
                        {screenSizeWidth > 1030 ?
                            <HeaderWeb
                                w='75%'
                                text='Servicios: Cartelería' /> : null
                        }
                        <div className='buy-services-signal-body01'>
                            {screenSizeWidth > 1030 ?
                                <div className='buy-services-steps'>
                                    <StepByStep
                                        steps={[
                                            {
                                                name: 'Datos de la entrega',
                                                number: 1,
                                                key: 1
                                            },
                                            {
                                                name: 'Confirmación',
                                                number: 2,
                                                key: 2
                                            }
                                        ]}
                                        stepNumber={1}
                                    />
                                </div> : null
                            }
                            <div className='buy-services-body-content' >
                                <h2> Datos de la entrega </h2>
                                <h3> Necesitamos tus datos para realizar la entrega a domicilio. </h3>
                                <h3> Todos los campos con <strong>*</strong> son obligatorios. </h3>
                                <h4> Escriba la dirección para la entrega del cartel </h4>
                                {countriesFromApi.length > 0 ? (
                                    <Fragment>

                    <div className="container-living">
                        <Location stateContext={dataLocation} setStateContext={setDataLocation} signal={true} />
                    </div>

                                        {/* <label> País <strong>*</strong></label>
                                        {countriesFromApi.length > 0 && (
                                            <Select
                                                options={countriesFromApi}
                                                defValue={country !== '' ? null : 'Seleccione un país'}
                                                handleChange={setCountry}
                                            />
                                        )}


                                        <label> Provincia <strong>*</strong></label>

                                        <Select
                                            options={statesFromApi.length > 0 ? (statesFromApi) : ([])}
                                            defValue={state !== '' ? null : 'Seleccione una provincia'}
                                            handleChange={setState}
                                        />

                                        <label> Ciudad <strong>*</strong></label>
                                        <Input
                                            attribute={{
                                                id: 'city',
                                                name: 'city'
                                            }}
                                            type='text'
                                            description='Ingrese la ciudad donde vive'
                                            handleChange={handleData}
                                            value={city}
                                        /> */}

                                        <label> Nombre de la calle <strong>*</strong></label>
                                        <Input
                                            attribute={{
                                                id: 'streetName',
                                                name: 'streetName'
                                            }}
                                            type='text'
                                            description='Ingrese nombre de la calle'
                                            handleChange={handleData}
                                            value={street}
                                        />

                                        <label> Número de la calle <strong>*</strong></label>
                                        <Input
                                            attribute={{
                                                id: 'number',
                                                name: 'number'
                                            }}
                                            type='number'
                                            description='Ingrese número de dirección'
                                            handleChange={handleData}
                                            value={number}
                                        />

                                    </Fragment>

                                ) : (
                                        <div className='buy-services-body-content-loading-container'>
                                            <div className="buy-services-body-content-loading-container-spinner">
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    )}


                                <h4> Seleccione el horario </h4>
                                <label> Hora <strong>*</strong></label>
                                <Select
                                    options={[
                                        {
                                            id: '9 to 12 hs.',
                                            value: '9 a 12 hs.',
                                            name: '9 a 12 hs.'
                                        },
                                        {
                                            id: '12 to 15 hs.',
                                            value: '12 a 15 hs.',
                                            name: '12 a 15 hs.'
                                        },
                                        {
                                            id: '15 to 18 hs',
                                            value: '15 a 18 hs',
                                            name: '15 a 18 hs.'
                                        }
                                    ]}
                                    defValue='Seleccione un horario'
                                    handleChange={setHour}
                                />

                                <label> Fecha <strong>*</strong></label>
                                <DatePicker
                                    showPopperArrow={false}
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    minDate={subDays(new Date(), -5)}
                                    filterDate={isWeekday}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Seleccione una fecha'
                                />

                                <h4> Detalles del cartel </h4>
                                <label>Texto del cartel <strong>*</strong></label>
                                <Select
                                    options={[
                                        {
                                            id: '1',
                                            value: 'Telefono',
                                            name: 'Telefono'
                                        },
                                        {
                                            id: '2',
                                            value: 'Codigo',
                                            name: 'Codigo'
                                        }
                                    ]}
                                    defValue='Seleccione el texto del cartel'
                                    handleChange={setcode}
                                />

                              {
                                  code == 'Telefono' && 
                                  <div>
                                  <label> Numero de contacto <strong>*</strong></label>
                                  <Input
                                      attribute={{
                                          id: 'NumeroTelefono',
                                          name: 'NumeroTelefono'
                                      }}
                                      type='text'
                                      description='Ingrese su telefono'
                                      handleChange={handleData}
                                      value={telefono}
                                  />
                                  </div>
                              }  

                                <h4> Seleccione la propiedad </h4>
                                {tooMuchProperties && <h6 className='tooMuchPropError'> ¡Máximo tres propiedades! </h6>}


                                <div>
                                    
                                    {propertiesIdFromApi.length > 0 && propertiesIdFromApi.map(id =>
                                        <div key={id} className='property-item'>


                                            {
                                                // We want to match each property with the id passed previously
                                                properties.map(property => {
                                                    
                                                   return property.servicesRequested && (
                                                        (property.propertyId == id && property.servicesRequested.poster == false) && (
                                                            <Fragment key={id}>

                                                                <Checkbox
                                                                    attribute={{
                                                                        id: id,
                                                                        value: id,
                                                                    }}
                                                                    param={searchElement(id) ? id : ''}
                                                                    key={id}
                                                                    handleChange={() => handleProperties(id)}
                                                                    classNames='buy-signal-property-checkbox'
                                                                />

                                                                <p>{property.id}</p>
                                                                <ViewGrid handleClick={() => null} property={property} myproperty={false}
                                                                    publishedFor={
                                                                        property.publishedFor.sell ? 'sell' :
                                                                            property.publishedFor.rent ? 'rent' :
                                                                                'tmpRent'
                                                                    } />

                                                            </Fragment>
                                                        )
                                                    )


                                                }

                                                )
                                            }


                                        </div>
                                    )}
                                </div>


                                <h4> Permisos del servicio <strong>*</strong></h4>
                                <div className='termsAndConditionsContainer'>
                                    <div className='termsAndCondCheckbox'>
                                        <Checkbox
                                            attribute={{
                                                id: 'termsAndCond',
                                                value: true
                                            }}
                                            param={termsAndContidionsAcepted}
                                            handleChange={() => setTermsAndContidionsAcepted(!termsAndContidionsAcepted)}
                                        />
                                    </div>
                                    <div className='termsAndCondLabel'>
                                        <label> Acepto los <Link style={{ textDecoration: 'none', fontWeight: 'bold', color: '#232f34' }} to='/terms'> términos y condiciones</Link>.</label>
                                    </div>
                                </div>
                                <div className='buySignalButtonsContainer'>
                                    {screenSizeWidth > 1030 ? null :
                                        <button className='buySignalBackButton' onClick={() => handleOptions('back')}>
                                            <img alt='green arrow icon' src={greenLeftArrow} />
                                            <label> Atrás </label>
                                        </button>
                                    }
                                    <button
                                        disabled={params ? true : false}
                                        className={params ? 'buySignalNextButtonDisabled' : 'buySignalNextButton'}
                                        onClick={handleSubmit}>
                                        <label> Siguiente </label>
                                        {!params && <img alt='white arrow icon' src={whiteRightArrow} />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading &&
                    <div>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                }

            </div>
        </div>
    )
};

export default BuySignal;