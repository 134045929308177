import React, { useState, useEffect } from 'react';
import './ModCons.css';
import URL from '../../../../../config/urls';
import axios from 'axios';

//IMAGES
import bed from '../../../../../assets/images/property/bed.svg';
import bathroom from '../../../../../assets/images/property/bathroom.svg';
import garage from '../../../../../assets/images/property/garage.svg';
import pool from '../../../../../assets/images/property/pool.svg';
import room from '../../../../../assets/images/property/room.svg';
import volume from '../../../../../assets/images/property/volume.svg';
import date from '../../../../../assets/images/property/date.svg';
import compass from '../../../../../assets/images/property/compass.svg';
import card from '../../../../../assets/images/property/card.svg'
import cube from '../../../../../assets/images/property/surface.png';

const ModCons = ({ property }) => {

    let dateAntiquity;

    function antiquity(date) {
        //variable to get date from system
        let presentYear = new Date().getFullYear();
        //number
        //let oldYear = new Date(Number(date)).getFullYear();

        let difference = Math.round(presentYear - date);
        return difference
    }

    dateAntiquity = antiquity(property.buildingYear);

    /* let numberRooms = new Array();

    if (property.accommodations.hasBedroomsNumber) {
        numberRooms.push(property.accommodations.hasBedroomsNumber)
    }
    if (property.accommodations.hasDeskRoom === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasDiningRoom === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasKitchen === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasLaudry === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasLivingRoom === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasOffice === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasSuiteBedroom === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.hasServiceUnit === true) {
        numberRooms.push(1)
    }
    if (property.accommodations.parkingPlaces > 0) {
        numberRooms.push(property.accommodations.parkingPlaces)
    }
    if (property.accommodations.toiletsNumber > 0) {
        numberRooms.push(property.accommodations.toiletsNumber)
    }

    let totalRooms = 1;
    for (let i = 0; i < numberRooms.length; i++) {
        totalRooms += i;
    } */

    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-');
    const BASE_URL = URL + `dictionaries/${lang[0]}`;
    const [acomDic, setAcomDic] = useState({});

    useEffect(() => {
        axios.get(BASE_URL)
            .then(response => {
                setAcomDic(response.data.responseData.dict.accommodationsDict);
            })
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })
        // eslint-disable-next-line
    }, []);

    return (

        <div>

            {property === null ? null

                : (
                    <div className='mod-const-containt'>
                        {/* {
                            property.accommodations.hasBedroomsNumber &&
                            <div className='bed-content'>
                                <img src={bed} alt={bed} />
                                <label> {property.accommodations.hasBedroomsNumber} </label>
                                <p> dormitorios </p>
                            </div>
                        } */}

                            {
                             property.accommodations?.hasBedroomsNumber == -1 ?
                             <p className='bed-content'>
                                  <img src={bed} alt={bed} /> 
                                  <label>{"M"}</label>
                                <p> dormitorios </p>
                             </p>
                             :  property.accommodations?.hasBedroomsNumber ?
                             <p className='bed-content'>
                              <img src={bed} alt={bed} /> 
                              <label> {property.accommodations.hasBedroomsNumber} </label>
                                <p> dormitorios </p>
                             </p>
                             : null
                            }


                        {
                            property.accommodations?.toiletsNumber &&
                            <div className='bed-content'>
                                <img src={bathroom} alt={bathroom} />
                                <label> {property.accommodations.toiletsNumber} </label>
                                {property.accommodations.toiletsNumber <= 1 ?
                                    <p> baño </p>
                                    : <p> baños </p>}
                            </div>
                        }
                        {
                            property.accommodations?.parkingPlaces &&
                            <div className='bed-content'>
                                <img src={garage} alt={garage} />
                                <label> {property.accommodations.parkingPlaces} </label>
                                <p> cochera </p>
                            </div>
                        }
                        {
                            property.amenities?.hasPool &&
                            <div className='bed-content'>
                                <img src={pool} alt={pool} />
                                <label> sí </label>
                                <p> pileta </p>
                            </div>
                        }
                        {
                            property.accommodationsNumber &&
                            <div className='bed-content'>
                                <img src={room} alt={room} />
                                <label> {property.accommodationsNumber} </label>
                                <p> ambientes </p>
                            </div>
                        }
                        {
                            property.buildingYear &&
                            <div className='bed-content'>
                                <img src={date} alt={date} />
                                {
                                    // eslint-disable-next-line
                                    dateAntiquity != 0 ?
                                        <><label> {dateAntiquity} </label>
                                            <p> años </p></> :
                                        <label> A estrenar </label>
                                }
                            </div>
                        }
                        {
                            property.disposition &&
                            <div className='bed-content'>
                                <img src={compass} alt={compass} />
                                <label> {acomDic[property.disposition]} </label>
                                <p> disposición </p>
                            </div>
                        }
                        {
                            property.orientation &&
                            <div className='bed-content'>
                                <img src={compass} alt={compass} />
                                <label> {acomDic[property.orientation]} </label>
                                <p> orientación </p>
                            </div>
                        }
                        {
                            property.creditSuitable &&
                            <div className='bed-content'>
                                <img src={card} alt={date} />
                                <label> sí </label>
                                <p> apto crédito </p>
                            </div>
                        }
                        {
                            property.totalArea &&
                            <div className='bed-content'>
                                <img src={cube} alt={cube} />
                                <label> {property.totalArea}m<sup>2</sup> </label>
                                <p> superficie terreno </p>
                            </div>
                        }
                        {
                            property.edifiedArea &&
                            <div className='bed-content'>
                                <img src={volume} alt={volume} />
                                <label> {property.edifiedArea}m<sup>2</sup> </label>
                                <p> superficie cubierta </p>
                            </div>
                        }
                    </div>
                )}

        </div>
    )
};

export default ModCons;