import "date-fns";
import React, { useEffect, useState } from "react";
import Select from "../Common/Select/Select";
import RouterColumn from "../RouterColumn/RouterColumn";
import Formulario from "./formulario.js";
import Image from "./image";
import { useHistory } from "react-router-dom";

export default function Usuarios() {
  let history = useHistory();
  let rol = localStorage.getItem("rol");
  const [imgPrincipal, setimgPrincipal] = useState([])
  const [DataPrincipal, setDataPrincipal] = useState({
    postTitle: "",
    postSubtitle: "",
    author:""
  })
  
  const [postBody, setpostBody] = useState([])

  let date = new Date()

  useEffect(() => {
    if (rol == 2) {
      history.push("/admin");
    }
  }, []);

  let word = [{ nombre: "Ver nota", link: "/admin/UpdateBlog"}];

  // /admin/UpdateBlog

  return (
    <div>
      <RouterColumn word={"Usuarios"} />

      <div>
        <Select Word={word} palabra={"Crear nota"} />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "255px" }}>
          <Formulario setDataPrincipal={setDataPrincipal} setimgPrincipal={setimgPrincipal} date={date} DataPrincipal={DataPrincipal} setparagrapth={setpostBody} postBody={postBody} imgPrincipal={imgPrincipal} />
        </div>

        <div>
          <Image setimgPrincipal={setimgPrincipal} imgPrincipal={imgPrincipal} />
        </div>
      </div>
    </div>
  );
}
