import React from 'react';
import VirtualAppraiser from './VirtualAppraiser';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';

let screenSizeWidth = window.innerWidth;

const Appraiser = ({ history }) => {
    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb  history={history} /> :
                    <TopAppBar />
            }
           
            <VirtualAppraiser />
          
        </div>
    )
}

export default Appraiser;