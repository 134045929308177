import React from 'react';

const RoundCheckbox = ({ param, attribute, handleChange }) => {
    return (
        <div className='appraiserRoundCheckbox'>
            <input
                id={attribute.id}
                type='checkbox'
                name={attribute.value}
                className='appraiserRoundCheckbox-input'
                checked={param === attribute.value ? true : false}
                onChange={(e) => handleChange(e.target.name)}
            />
            <label
                htmlFor={attribute.id}
                className='appraiserRoundCheckbox-label'
            >
                <h4 className={param === attribute.value ?
                    'appraiserRoundCheckbox-h4-check' :
                    'appraiserRoundCheckbox-h4'}
                    style={{ margin: 0 }}>
                    {attribute.name}
                </h4>
            </label>
        </div>
    )
}

export default RoundCheckbox;