import React, { useState, useCallback } from "react";
import imageCompression from "browser-image-compression";
import URL from "../../../../config/urls";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import CircularProgress from '@material-ui/core/CircularProgress';
import arrayMove from "array-move";
import axios from "axios";

import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from '../../../pages/NewProperty/ImgDialog'
import getCroppedImg from '../../../pages/NewProperty/cropper'
import { styles } from '../../../pages/NewProperty/styles'
import Dialog from './DialogLink'

const Image = ({handleUpdate, setimgPrincipal, imgPrincipal }) => {

  const [loading, setloading] = useState(false)

  const [editPhoto, seteditPhoto] = useState(false)
  const [imgName, setimgName] = useState("")
  const [dataImg, setdataImg] = useState('')
  const [infoIndex, setinfoIndex] = useState(0)
  const [link, setlink] = useState(false)
  const [nameLink, setnameLink] = useState({})

  // const [img, setimg] = useState([])
  const token = localStorage.getItem("tokenAdmin");

  const addImage = async (e) => {
    setloading(true)
    let imgs = [];
    let files = e.target.files;
    imgs = [...imgPrincipal];

    for (let i = 0; i < files.length; i++) {
      let formData = new FormData();

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(files[i], options);

        // Since compressed file is an instance of Blob the backend will throw a type error. Thus, we have to add properties to this object so it can be sent correctly to our backend
        var readyForBackendFile = new File([compressedFile], [files[i].name]);

        formData.append(`img.${i}`, readyForBackendFile);

        const res = await axios.post(
          URL + "emprendimientos/uploadImagesStorage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Acecpt: "*/*",
              "Cache-Control": "no-cache",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          }
        );

        let img = res.data.responseData[0];
        img.linkEmprendimiento = ""

        imgs.push( img );
        
      } catch (error) {
        console.log(error);
        // setErrorWhileUploadingPhoto(true)
        i = 16;
      }
    } //For loop end
    setimgPrincipal(imgs);
    setloading(false)


  };




  const addImageCrop = async (files) => {
    setloading(true)
    let imgs = [];
    imgs = [...imgPrincipal];

    for (let i = 0; i < files.length; i++) {
      let formData = new FormData();

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(files[i], options);

        // Since compressed file is an instance of Blob the backend will throw a type error. Thus, we have to add properties to this object so it can be sent correctly to our backend
        var readyForBackendFile = new File([compressedFile], [files[i].name]);

        formData.append(`img.${i}`, readyForBackendFile);

        const res = await axios.post(
          URL + "emprendimientos/uploadImagesStorage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Acecpt: "*/*",
              "Cache-Control": "no-cache",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          }
        );

        let img = res.data.responseData[0];
        img.linkEmprendimiento = ""

        imgs.push( img );
      } catch (error) {
        console.log(error);
        // setErrorWhileUploadingPhoto(true)
        i = 16;
      }
    } //For loop end
    setimgPrincipal(imgs);
    setloading(false)

  };



  const SecondaryImage = ({
    image,
    data

  }) => {

    const editImg = ( edit) => {
        setdataImg(image.imageName)
        setimgName(image.linkStorage)
        seteditPhoto(edit)
        setinfoIndex(data)
    }

    const linkImage = () => {
      setnameLink(image)
      setlink(true)

  }
  // handleUpdate()

    return (
      <div style={{ display: "flex" }}>
        <div className="cutimage">
          <img
            className="image-thumbnail"
            alt="imagen de la propiedad"
            src={image.linkStorage.replace(/&amp;/g, '&')}
          />
        </div>
        <div className="div-button-dash" >
        <button
          // style={{marginLeft:"-50px"}}
            className="deleteButton"
            onClick={() => deleteImg(data)}
          >
            Eliminar
          </button>
          <button className="editButtonDash2" onClick={() => linkImage()}>Enlace</button>
          <button className="editButtonDash2" onClick={() => (editImg(true))}>Editar</button>
        </div>

      </div>
    );
  };

  const SortableImage = SortableElement(({ image, deleteImg, data }) => {
    return (
      <SecondaryImage
        image={image}
        data={data}

      />
    );
  });

  const SortableImagesList = SortableContainer(({ images }) => {
    return (
      <ul>
        { images.map((image, i) => (
          <SortableImage
            image={image}
            index={i}
            data={i}
            key={i}
            // deleteImg={deleteImg}
          />
        ))}
      </ul>
    );
  });

  const SortableImages = () => {

    
    const onSortEnd = ({ oldIndex, newIndex }) => {
      setimgPrincipal(arrayMove(imgPrincipal, oldIndex, newIndex));
    };
    return <SortableImagesList images={imgPrincipal} onSortEnd={onSortEnd} />;
  };


  const deleteImg = (index) => {
    setloading(true)
    imgPrincipal.splice(index, 1)
    // handleUpdate()
    setTimeout(() => {
      setloading(false)
    },1000)

  }






  //-------------------------------------------->



  const Demo = ({ classes }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
  
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])
  


    const showCroppedImage = useCallback(async () => {

        deleteImg(infoIndex)

      try {
        const croppedImage = await getCroppedImg(
          imgName,
          dataImg,
          croppedAreaPixels,
          rotation
        )
        seteditPhoto(false)
        setCroppedImage(croppedImage)
        addImageCrop([croppedImage])

      } catch (e) {
        console.error(e)
      }
    }, [croppedAreaPixels, rotation])
  
    const onClose = useCallback(() => {
      setCroppedImage(null)
    }, [])


    return (
      <div>
        <div className={classes.cropContainer}>
          <Cropper
            image={imgName}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={80 / 50}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer} >
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
 
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <br/>
          {/* <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Rotación
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              classes={{ root: classes.slider }}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </div> */}
          <button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            className='cutButton'
            style={{marginTop: "20px"}}
          >
            Recortar
          </button>
        </div>
        <ImgDialog img={croppedImage} onClose={onClose} />
      </div>
    )
  }
  
  const StyledDemo = withStyles(styles)(Demo)



  return (
    <div className="new-property">
      {
        link && <Dialog  setlink={setlink}  link={link} 
        // setimgPrincipal={setimgPrincipal} imgPrincipal={ imgPrincipal} 
        nameLink={nameLink}
        handleUpdate={handleUpdate}
        />
      }
      <div className="properties-container-pc">
        <div className="properties-inputs" style={{ marginTop: "-70px" }}>
        {
          editPhoto &&  <StyledDemo/>
        }    
          <div
            className="fields"
            id="fields"
            style={{ width: "600px", marginRight: "-200px" }}
          >
            <h3 className="small-title">Imagenes Mobile </h3>

            <input
              type="file"
              className="add-image-file"
              id="image-file"
              name="image-file"
              accept="image/x-png,image/jpg,image/jpeg"
              onChange={(e) => addImage(e)}
              multiple
            />
            <label htmlFor="image-file" className="add-image">
              Seleccioná las imágenes
            </label>
           {
             loading ? <CircularProgress style={{marginLeft: "130px", marginTop: "60px"}}/> :<div className="all-images">
             {imgPrincipal.length > 0 ? (
               <SortableImages />
             ) : (
               <h2>No hay imagenes cargadas.</h2>
             )}
              </div>
           } 
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image;
