import React from 'react'
import { useHistory } from 'react-router-dom';
import hands from "./img/hands.svg";
import surface from "./img/surface1.svg";
import "./PaymentsPage.css";

export const MobilePayment = () => {

    let screenSizeWidth = window.innerWidth;

    const history = useHistory()

    const handleGo = (args) => {
      
        if(args == 'tarjeta' ){
          history.push('/data/user')
        }
        if(args == 'transf'){
            history.push('/data/user/transf')
        }
  
      }

    return (
        <div>
            <div className="services-web-container-card">
        <div className="services-web-content-card">
          <div className="servicesContainer">

            <div className="container-payment-page-mobile">

                <div className="tarjeta-contenedor-mobile" onClick={()=>handleGo('tarjeta')} >
                    <div className="contenedor-mobile-img" >
                        <img src={surface} className="img-mobile-pay" />
                    </div>
                    <div className="contendeor-text-card-mobile" >
                        <strong className="title-data-mobile-pay">Tarjeta de crédito o débito</strong>
                    </div>
                    <div className="line-contendor-card-mobile" >

                    </div>
                </div>
                
                <div className="tarjeta-contenedor-mobile" onClick={()=>handleGo('transf')} >
                <div className="contenedor-mobile-img"  >
                        <img src={hands} className="img-mobile-pay" />
                    </div>
                    <div className="contendeor-text-card-mobile" >
                        <strong className="title-data-mobile-pay">Transferencia bancaria</strong>
                    </div>
                    <div className="line-contendor-card-mobile" >

                    </div>
                </div>

            </div>
          </div>
        </div>
      </div>
        </div>
    )
}
