import React, { useState, useEffect, useRef } from 'react';
import './Contact.css';
import arrowBack from '../../../assets/images/arrowBack.svg';
// import { Link } from 'react-router-dom';
import VERIFIED from '../../../assets/images/isVerified.svg';
import URL from '../../../config/urls';
import axios from 'axios';
import ViewGrid from '../../commons/ViewGrid/ViewGrid';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import Messages from '../Messages/Messages';
import { CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button'
import BlockIcon from '@material-ui/icons/Block';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


const Request = ({ message }) => {

    let date = parseInt(message.createdAt)
    let getMeDate = new Date(date * 1000);
    let getMonth = getMeDate.getMonth() + 1 

    let meMinutesSystem = String(getMeDate.getMinutes()).length == '1'? "0" + getMeDate.getMinutes() : getMeDate.getMinutes()

    let getMeHours = getMeDate.getHours() + ':' + meMinutesSystem 
    let getMeDay = getMeDate.getUTCDate() + '/' + getMonth
 
    let systemDate = new Date();
    let minutesSystem = String(systemDate.getMinutes()).length == '1'? "0" + systemDate.getMinutes() : systemDate.getMinutes()
    let getMeSystemHours = systemDate.getHours() + ':' + minutesSystem
    

    return (
        <div className='message-item-request'>
            <div className='message-item-container'>
            <p> {getMeHours === NaN + ':' + NaN ? getMeSystemHours : getMeHours} </p>
            </div>
            
            <label><div> {message.txt} </div></label>
        </div>
    )
};

const Response = ({ message }) => {

    let date = parseInt(message.createdAt)
    let getMeDate = new Date(date * 1000);
    let getMonth = getMeDate.getMonth() + 1 

    let meMinutesSystem = String(getMeDate.getMinutes()).length == '1'? "0" + getMeDate.getMinutes() : getMeDate.getMinutes()

    let getMeHours = getMeDate.getHours() + ':' + meMinutesSystem  
    let getMeDay = getMeDate.getUTCDate() + '/' + getMonth

    let systemDate = new Date();
    let minutesSystem = String(systemDate.getMinutes()).length == '1'? "0" + systemDate.getMinutes() : systemDate.getMinutes()
    let getMeSystemHours = systemDate.getHours() + ':' + minutesSystem
    
    return (
        <div className='message-item-response'>
            <div className='message-item-container'>
            <p> {getMeHours === NaN + ':' + NaN ? getMeSystemHours : getMeHours} </p>
            </div>
            <label><div> {message.txt} </div></label>
        </div>
    )
};

const Contact = ({ history }) => {

    let urla = window.location.href;
    let ownerIdFromUrl = urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] ? urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] : urla.split('=')[4].split('?')[0].split('%C2%AB%C2%')[1];
    let propertyIdFromUrl = urla.split('=')[1].split('?')[0];
    let transactionFromUrl = urla.split('=')[2].split('?')[0];
    let parce_0 = urla.split('=');
    let chatId = parce_0[parce_0.length - 1];
    let interestedIdFromUrl = urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] ? urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[0] : urla.split('=')[4].split('?')[0].split('%C2%AB%C2%')[0]
    const BASE_URL = URL + 'properties/propertyId/' + propertyIdFromUrl;
    const BASE_URL_ = URL + 'messages'

    const [property, setProperty] = useState('');
    const [userContact, setUserContact] = useState('');
    const [avatar, setAvatars] = useState('');
    const [name, setName] = useState('');
    const [messagesLoading, setmessagesLoading] = useState(true)
    // aux variable
    const [message, setMessage] = useState('');

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };

    let decoded = jwt.decode(token);


    const emitterId = decoded.data.id;



    //array container
    const [messages, setMessages] = useState([]);

    const messageScrollEnd = useRef(null);

    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    function scrollToBottom() {
        if (messageScrollEnd.current) {
            messageScrollEnd.current.scrollIntoView({ behavior: "smooth" })
    }
}

useEffect(scrollToBottom, [messages]);

    let messagesData;
    async function getMessages() {
        setmessagesLoading(true)
        try {
            let aux = await axios.get(BASE_URL_ + '?chatId=' + chatId, { headers: { "token": token } });
            //first message
            setmessagesLoading(false)
            if (!aux.data.responseData[0]) {
                return false;
            }
            // messagesData =  aux.data.responseData[0].messages;
            messagesData = aux.data;
            if (messagesData) {
                return messagesData
            }
            else {
                return []
            };
            //  scrollToBottom();
        } catch (e) {
            console.log('Problemas para refrescar mensajes', e)
            throw e;
        }
    }



    async function getAvatarAndName(idUser, idContact, data, messages) {
        let id;       
        //nos basamos en que el primer mensaje nunca es emitido por el owner de la property
        if (idUser === idContact) {
            //user es owner, saco el id del otro por la data de mensajería
            id = messages.responseData[0].messages.ids[0];
        } else {
            //user no es owner, saco la data del otro por la data de property
            id = data.responseData.contact.userId ? data.responseData.contact.userId : idContact;
        }

        const user = await axios.get(URL + `users/${id}`, headers);
        const avatar = user.data.responseData.avatar ? user.data.responseData.avatar : 'https://www.computerhope.com/jargon/g/guest-user.jpg';
        const name = user.data.responseData.firstName;
        return { avatar, name, idUser };
    }
    useEffect(() => {

        let msj = []; 

        scrollToBottom();
        setMessages([])
        axios.get(BASE_URL)
            .then(response => {
                const data = response.data;
                // setAvatars(messages.responseData[0].messages.avatars);
                setProperty(data.responseData)
                setUserContact(data.responseData.contact)
                getMessages(data.responseData.contact)
                    .then(async messages => {
                        const { avatar, name, idUser } = await getAvatarAndName(emitterId, ownerIdFromUrl, data, messages)
                        setAvatars(avatar);
                        setName(name);
                        setmessagesLoading(false)
                        //first message
                        if (!messages) { return };

                        messages.responseData[0].messages.chat.map((e) => {

                            if(emitterId == interestedIdFromUrl){
                                
                                if(e.isActiveInterested == true){
                                    msj.push(e)
                                }

                            }
                            if(emitterId == ownerIdFromUrl){

                                if(e.isActiveOwner == true){
                                    msj.push(e)
                                }

                            }
    

                        })


                        setMessages(msj);
                        return messages.responseData[0].messages.chat
                    })
                    .then(async res => {
                        if (res) {
                            const chatId = res[0].chatId;
                            axios.get(URL + `messages/viewed-to-true?chatId=${chatId}`, { headers: { 'token': token } })
                        }
                    })
            })
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })
        // eslint-disable-next-line
    }, [window.location.href]);


    async function sendAMessage(newMessage) {
        try {
            const response = await axios.post(BASE_URL_, newMessage, { headers: { "token": token } })
            if (response) {
                scrollToBottom()
            }
        } catch (e) {
            console.log('No se pudo enviar el mensaje.')
        }

            try {
                const response2 = await axios.post(BASE_URL_ + '/checkViewedMessage', {emitterId} , { headers: { "token": token } })
               
                if (response2) {
                    scrollToBottom()
                }
            } catch (e) {
                console.log('No se pudo enviar el mensaje.')
            }



    }

    const sendMessage = (newMessage) => {
        setMessages([...messages, newMessage]);
        sendAMessage(newMessage)
    }

    const handleSubmit = async (e) => {
        // por posibles inconsistencias en los datos (eg: usuarios sin id en property.contact)
        const ownerId = userContact.userId ? userContact.userId : ownerIdFromUrl;
        e.preventDefault();
        // nos basamos en que en el primer mensaje siempre es decoded.data.id === userContact.userId = false;
        sendMessage({
            txt: message,
            propertyId: property.propertyId,
            receiver: decoded.data.id === userContact.userId ? messages[0].emitter : ownerId,
            receiverFirstName: decoded.data.id === userContact.userId ? messages[0].emmiterFirstName : userContact.firstName,
            receiverLastName: decoded.data.id === userContact.userId ? messages[0].emmiterLastName : userContact.lastName,
            avOwn: userContact.avatar ? userContact.avatar : 'https://www.computerhope.com/jargon/g/guest-user.jpg',
            avInt: decoded.data.id === userContact.userId ? messages[0].avatarInterested : decoded.data.avatar,
            transaction: transactionFromUrl
        })
        scrollToBottom()
        setMessage('');

    }

    function handleLink() {
        history.goBack();
    }
   

    function goReportUserPage() {

        if(ownerIdFromUrl == emitterId){
            history.push('/reportUser?oId=' + interestedIdFromUrl)
        } 
        if(interestedIdFromUrl == emitterId){
            history.push('/reportUser?oId=' + ownerIdFromUrl)
        } 

    }

    function deleteChat(){
        try {
            const response = axios.post( BASE_URL_ + `/deleteChat/${chatId}`,{ emitterId }, { headers: { "token": token } })
            if (response) {
                setTimeout(function () {
                    history.push('/' + 'messages' )
                },1000)
                
            }
        } catch (e) {
            console.log('No se pudo enviar el mensaje.')
        }
    }

    let screenSizeWidth = window.innerWidth;
    return (
        <div>
            <div className='webContainer'>
                {screenSizeWidth > 1030 &&
                    <div className='webHeader'>
                        <NavBarWeb history={history} />
                    </div>
                }

                <div className='webBodyMessages'>
                    {screenSizeWidth > 1030 &&
                        <div className='webBoxMessages' >
                            <Messages history={history} />
                        </div>
                    }

                    {property === '' || userContact === '' ?
                        <div className='messages-loading-screen'>
                            <CircularProgress />
                        </div> :
                        <div className='webChat' style={{ marginTop: screenSizeWidth > 1030 ? "65px" : "0px" }}>
                            {messages && !messagesLoading ? (
                                <div className='contact-container'>
                                    <div className={screenSizeWidth > 1030 ? 'topBarWeb' : "topBar"} style={{ width: '100%', height: '50px', zIndex: 3, position: "fixed" }}>
                                        {screenSizeWidth < 1030 && <img onClick={handleLink} src={arrowBack} alt={arrowBack} className="filterIcon" />}

                                        <div className='div-contenedor-contact'  >

                                            <img src={avatar}
                                                alt='avatar'
                                                style={{ width: 33, height: 33, borderRadius: '50%', marginRight: 10, marginTop: "0.5rem", marginLeft: "-10px" }} />
                                            
                                            <div className='contact-name-div' >
                                            <h3 style={{ fontSize: '14px' }}> {decoded.data.id === userContact.userId
                                                ? name :
                                                userContact.firstName
                                            } </h3>
                                            {userContact.emailIsVerified === false && userContact.phoneIsVerified === false ? null :
                                                <img alt='verified' src={VERIFIED} style={{ marginLeft: 10 }} />
                                            }

                                            </div>

                                            <div className='report-container' >

                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                  {(popupState) => (
                                                    <div>
                                                      <Button style={{paddingTop: "10px", color: "white"}} {...bindTrigger(popupState)}>
                                                        <MoreVertIcon />
                                                      </Button>
                                                      <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                        }}
                                                      >
                                                        <Box p={1}>
                                                          <button onClick={goReportUserPage} style={{border: 0, backgroundColor: "white"}} >
                                                          <Typography>Denunciar usuario </Typography>
                                                          </button>  

                                                        </Box>
                                                        <Box p={1}>
                                                            <button onClick={deleteChat} style={{border: 0, backgroundColor: "white"}} >
                                                            <Typography>Eliminar chat </Typography>
                                                            </button>
                                                        </Box>
                                                      </Popover>
                                                    </div>
                                                  )}
                                                </PopupState>
                                                    
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className='messagesContainer'>
                                        <label style={{ textAlign: 'center' }}> Este mensaje es sobre la propiedad: </label>
                                        <div className='viewGridWeb'>
                                            <ViewGrid property={property} publishedFor={transactionFromUrl} handleClick={handleClick}/>
                                        </div>

                                        {messages.map((message, index) => (message.emitter === emitterId) || emitterId !== message.receiver ?
                                            <Request ref={messageScrollEnd} message={message} key={index} /> //consulta
                                            : <Response ref={messageScrollEnd} message={message} key={index} />) //respuesta
                                        }


                                        <div ref={messageScrollEnd} />
                                    </div>

                                    <div className='contact-input-container'>
                                        <form onSubmit={handleSubmit} className='contact-input-content'>
                                            <input value={message} onChange={(e) => setMessage(e.target.value)} type='text' placeholder='Escribí tu mensaje' />
                                        </form>
                                        <button  onClick={handleSubmit}> ENVIAR </button>
                                    </div>


                                </div>
                            ) : (
                                    <div className='messages-loading-screen'>
                                        <CircularProgress />
                                    </div>
                                )}

                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Contact;