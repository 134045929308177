const inhabitable = ['', 'casa', 'casaCountry', 'quinta', 'duplexPh', 'condoHousing', 'departamento', 'oficina', 'localComercial'];
const live = ['', 'casa', 'casaCountry', 'quinta', 'duplexPh', 'condoHousing', 'departamento'];
const business = ['', 'oficina', 'localComercial'];
const bigLive = ['', 'casa', 'casaCountry', 'quinta', 'condoHousing'];
// const apartment = ['', 'oficina', 'departamento'];

const SRT = ['','sell', 'rent', 'tmp', 'sell&rent'];
const RT = ['', 'rent', 'tmp', 'sell&rent'];

export const accommodations = [
  // {
  //   attribute: 'handicappedPlatform',
  //   condition: {
  //     propertyType: inhabitable,
  //     transaction: SRT,
  //     search: true
  //   },
  // },
  {
    attribute: 'hasClosetsInHall',
    condition: {
      propertyType: inhabitable,
      transaction: SRT
    },
  },
  {
    attribute: 'hasClosets',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasBalcony',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasKitchen',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasDiningRoom',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasServiceUnit',
    condition: {
      propertyType: live,
      transaction: SRT
    },
  },
  {
    attribute: 'hasBreakfastBar',
    condition: {
      propertyType: live,
      transaction: SRT
    },
  },
  {
    attribute: 'hasSuiteBedroom',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasDeskRoom',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasOffice',
    condition: {
      propertyType: live,
      transaction: SRT
    },
  },
  {
    attribute: 'hasGarden',
    condition: {
      propertyType: bigLive,
      transaction: SRT
    },
  },
  {
    attribute: 'hasKitchenette',
    condition: {
      propertyType: business,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasLaudry',
    condition: {
      propertyType: live,
      transaction: SRT
    },
  },
  {
    attribute: 'hasLivingRoom',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasBackyard',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasConferenceRoom',
    condition: {
      propertyType: business,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasTerrace',
    condition: {
      propertyType: bigLive,
      transaction: SRT
    },
  },
  {
    attribute: 'hasDressingRoom',
    condition: {
      propertyType: live,
      transaction: SRT
    },
  }
];

export const amenities = [
  {
    attribute: 'accessToPublicTransportation',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'handicappedSuitable',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasInternalAccess',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'isPetFriendly',
    condition: {
      propertyType: live,
      transaction: RT,
      search: true
    },
  },
  {
    attribute: 'hasWaterNetworkService',
    condition: {
      propertyType: ['terreno'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'waterNetwork',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasAcconditionatedAir',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'furnished',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasPlayingArea',
    condition: {
      propertyType: live,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasGrill',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasElevator',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasWaterHeater',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasHeater',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasTennisCourt',
    condition: {
      propertyType: live,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasCineArea',
    condition: {
      propertyType: live,
      transaction: SRT,
    },
  },
  {
    attribute: 'cistern',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasSewerService',
    condition: {
      propertyType: ['terreno'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasVisitParquing',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasGasNetworkService',
    condition: {
      propertyType: ['terreno'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'gasNetwork',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasGym',
    condition: {
      propertyType: live,
      transaction: SRT,
    },
  },
  {
    attribute: 'hasJaccuzzi',
    condition: {
      propertyType: live,
      transaction: SRT,
    },
  },
  {
    attribute: 'telephoneLine',
    condition: {
      propertyType: ['terreno'],
      transaction: SRT,
    },
  },
  {
    attribute: 'hasElectricityService',
    condition: {
      propertyType: ['terreno'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasPool',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasAutomaticGate',
    condition: {
      propertyType: ['cochera'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasBarbequeArea',
    condition: {
      propertyType: live,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'hasPartyRoom',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
    },
  },
  {
    attribute: 'isRoofed',
    condition: {
      propertyType: ['cochera'],
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'secClosedNeighborhood',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'secCamera',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'secComplexBuilding',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'secGuard',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  },
  {
    attribute: 'secDoorbellIntercom',
    condition: {
      propertyType: inhabitable,
      transaction: SRT,
      search: true
    },
  }
];

export const furnishedAttributes = [
  {
    attribute: 'hasWaterHeater',
    condition: {
      search: true
    },
  },
  {
    attribute: 'cableTV',
    condition: {
      search: true
    },
  },
  {
    attribute: 'wifi',
    condition: {
      search: true
    },
  },
  {
    attribute: 'cradle',
    condition: {
      search: true
    },
  },
  {
    attribute: 'washer',
    condition: {
      search: true
    },
  },
  {
    attribute: 'hasAcconditionatedAir',
    condition: {
      search: true
    },
  },
  {
    attribute: 'dryer',
    condition: {
      search: true
    },
  },
  {
    attribute: 'linenSheets',
    condition: {
      search: true
    },
  },
  {
    attribute: 'hasClosetsInHall',
    condition: {
      search: true
    },
  }
];

export const furnishedBeds = [
  {
    attribute: 'bedSingle',
    condition: {
      search: true
    },
  },
  {
    attribute: 'bedDouble',
    condition: {
      search: true
    },
  },
  {
    attribute: 'bedQueen',
    condition: {
      search: true
    },
  },
  {
    attribute: 'bedSKing',
    condition: {
      search: true
    },
  },
];