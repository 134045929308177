import React, { useState } from 'react';
import '../components/Services.css';
import Card from '../components/ServiceBigCard';
// import signal from '../../../../assets/images/signal.png';
// import signal from '../../../../assets/images/signal.jpg';
import signal from '../../../../assets/images/services/cartel.png';

import TopAppBar from '../../../commons/BlueTopBar/BlueTopBar';
import TopAppWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb';
import Modal from '../../../commons/Modals/ModalWarning';

const SignalPage = ({ history }) => {

    const [ openModal, setOpenModal ] = useState(false);

    let token = localStorage.getItem('token')

    function handleOnClick() {
        if(token) {
            history.push('/services/signal/buy')
        } else {
            setOpenModal(true)
        }
    };

    function actionButton() {
        history.push('/welcome')
    };

    function handleCloseOut() {
        setOpenModal(false)
    };

    let screenSizeWidth = window.innerWidth;

    return (
        <div>

            <Modal
            noButton={true}
            acept='Iniciar sesión'
            cancel='Cerrar'
            openModal={openModal}
            actionButton={actionButton}
            handleCloseOut={handleCloseOut}
            close='true'
            title='¡No estás logueado!'
            text='Para adquirir los diversos servicios que ofrece HiPropi, primero debes iniciar sesión.'
            />

            { screenSizeWidth > 1030 ?
                <TopAppWeb history={history} />
            :
                <TopAppBar 
                    title='Cartelería'
                    link='/services'
                />
            }

            <div className='serviceSignalContainer'>
                <div className='services-signal-content-body' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    { screenSizeWidth > 1030 && 
                    <div className='section-title-header-services'>
                        <div className='report-title-container' style={{ margin: 'auto'}}>
                            <label> Servicios: cartelería </label>
                        </div>
                    </div>
                    }
                    <div className='services-signal-container-header'>
                        <div className='services-signal-content-header'>
                            <div className='services-signal-content'>
                                <Card 
                                    service={{
                                        id: 203,
                                        img: signal,
                                        type: 'Servicio',
                                        title: 'Cartelería',
                                        description: 'Sabemos que la gente que busca comprar o alquilar, también lo hace de manera activa cuando va a su trabajo o camina por la ciudad. Con nuestro servicio único de cartel a domicilio podrás incrementar tus posibilidades de comercializar tu propiedad.'
                                    }}
                                />
                            </div>
                            <div className='serviceSignalDescriptionContent'>
                                <label>
                                    Una vez que hagas click en “Lo quiero!”, tendrás que completar un pequeño formulario con una dirección de entrega, que no es necesario que esa dirección sea la misma de tu propiedad, ya que hay mucha gente que trabaja todo el día en otro lado. Por eso vos podrás disponer de cualquier dirección para que te lo enviemos. 
                                    Una vez que lo solicites te avisaremos la fecha y hora aproximada de entrega. 
                                    Junto a la leyenda "vende" o "alquila", tu cartel tendrá un código para que cualquier persona pueda buscar tu inmueble de manera simple y directa. 
                                    Este servicio es totalmente gratis para la Ciudad de Córdoba, y los tiempos de entrega son entre 5 y 7 días hábiles desde que lo solicitas. 
                                    Una vez que lo recibas, solo tendrás que ponerlo en una ventana, reja o en el lugar mas visible posible para identificar rápidamente a tu propiedad entre las otras. 
                                </label>
                                <div className='servicesSignalPropsDescription'>
                                    <h5 className='propsTitle'> Características del cartel </h5>
                                    <label className='propsDescription'>
                                        100cm de ancho por 60cm de alto. 
                                    </label>
                                    <div className='servicesSecondPropsDescription'>
                                        <h5 className='propsTitle'> Material: </h5><label className='propsDescription'> alto impacto </label>
                                    </div>
                                </div>
                                <div className='serviceSignalButtonContainer'>
                                    <button onClick={handleOnClick} > ¡Lo quiero! </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SignalPage;