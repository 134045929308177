import React from 'react';
import ErrorNotification from './CustomNotification';

const InputComponent = React.memo( props => { 
    
    const { autofocus, text, inputName, param, handleChange, inputType, ph, errorText, handleOnBlur, inputState } = props;

    return (
        <>
            <label htmlFor={inputName}> {text} </label>
            <input 
            id={inputName} 
            autoFocus={ autofocus && autofocus }
            className={ param && 'errorsInput' } 
            onChange={ (e) => handleChange(e.target.name, e.target.value) }
            onBlur={ (e) => handleOnBlur(e.target.name, e.target.value) }
            name={inputName}
            value={inputState}
            type={inputType} 
            placeholder={ param === false && ph}
            />
            { param && <ErrorNotification errorText={errorText} /> }
        </>
    )
});

export default InputComponent;