import React, { useEffect, useState, Fragment } from 'react';
import './Messages.css'
import arrowBack from '../../../assets/images/arrowBack.svg';
import URL from '../../../config/urls';
import axios from 'axios';
import MessageContainer from './MessageContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import TopAppBar from '../../commons/TopBar/TopAppBar'
import empty from '../../../assets/images/empty.png';
let jwt = require('jsonwebtoken');
let decoded;
let token;
let BASE_URL;




const Messages = ({ history }) => {

    const [messages, setMessages] = useState(null);
    const [showNavBar, setShowNavBar] = useState(false)
    const [View, setView] = useState(true)

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };

    let decoded = jwt.decode(token);


    const emitterId = decoded.data.id;

    let urla = window.location.href;
    // let ownerIdFromUrl = urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] ? urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] : urla.split('=')[4].split('?')[0].split('%C2%AB%C2%')[1];
    // let interestedIdFromUrl = urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[1] ? urla.split('=')[4].split('?')[0].split('%C2%AB%C2%BB')[0] : urla.split('=')[4].split('?')[0].split('%C2%AB%C2%')[0]
   

    let allUserMessages; //contiene la response data 
    let chatsInbox = []; // contiene nuevo objeto con últimos mensajes, ids interactuantes y mensajes no vistos

    useEffect(() => {
        window.location.href.includes('contact/') ? setShowNavBar(false) : setShowNavBar(true)

        token = localStorage.getItem('token');
        decoded = jwt.decode(token);

        if (token !== null) {
            BASE_URL = URL + 'messages/'
        }



    }, [])

    const getMessages = async () => {

        let ids; 
        let chat

        const response = await axios.get(BASE_URL + 'getbyid', { headers: { 'token': token } })
        try {
            allUserMessages = JSON.parse(response.request.response).responseData;
            allUserMessages.forEach(element => {
                for (let i in element) {
                    let chats = element[i].chat
                    let auxIds = element[i].ids
                    ids = auxIds
                    chat = chats

                    let views = chats.filter(msg => {
                        return msg.viewed === false
                    })
                    if(chats.length > 0){
                        if(emitterId == ids[1]){
                            if(chats[chats.length - 1].isActiveOwner == true){
                                chatsInbox.push({ lastMessage: chats[chats.length - 1], interactingIds: auxIds, viewsFalse: views, transaction: chats[chats.length - 1].transaction, avatars: element.messages.avatars })
                            }
                        }
                        if(emitterId == ids[0]){
                            if(chats[chats.length - 1].isActiveInterested == true){
                                chatsInbox.push({ lastMessage: chats[chats.length - 1], interactingIds: auxIds, viewsFalse: views, transaction: chats[chats.length - 1].transaction, avatars: element.messages.avatars })
                            }
                        }
                }}
            })

            setMessages(chatsInbox)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        const res = getMessages()

    }, [messages]);

    function goBack() {
        history.goBack();
    }


    const handleOnClickMessage = (propertyId, trans, intId, chatId) => {

        if (trans !== 'sell' && trans !== 'rent') {
            trans = 'tmpRent'
        }

        // eslint-disable-next-line
        history.push('contact/' + '?' + 'propertyId=' + propertyId + '?transaction=' + trans + '?intId=' + intId + '?chat=' + chatId) //ids necesarios

    }

    const handleOnClickMessageWeb = (propertyId, trans, intId, chatId) => {
        let urla;
        if (trans !== 'sell' && trans !== 'rent') {
            trans = 'tmpRent'
        }
        window.location.href.includes('contact/') ?
            // eslint-disable-next-line
            urla = '?' + 'propertyId=' + propertyId + '?transaction=' + trans + '?intId=' + intId + '?chat=' + chatId
            :
            // eslint-disable-next-line
            urla = 'contact/?' + 'propertyId=' + propertyId + '?transaction=' + trans + '?intId=' + intId + '?chat=' + chatId
        history.push(urla);
    }

    let screenSizeWidth = window.innerWidth;

    return (

        <Fragment>
            <div>
                {screenSizeWidth > 1030 ? (showNavBar && <TopAppBarWeb history={history} />) : (showNavBar && <TopAppBar history={history} />)}
            </div>
            {messages ? (
                <div className={showNavBar ? 'messagesWebContainerBox messagesWebContainerBox-withPadding' : 'messagesWebContainerBox'} >
                    <div className={screenSizeWidth < 1030 ? "topBar" : 'topBarWebMessages'} style={{ marginTop: screenSizeWidth > 1030 ? "65px" : "0px" }}>
                        {screenSizeWidth < 1030 && <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />}
                        {screenSizeWidth < 1030 ? <h3> Mensajes </h3> : <h3> Bandeja de entrada </h3>}
                    </div>

                    {messages.length === 0 ? <div className="container-empty-message">
                                    <div className="empty-card-message">
                                        <img className="empty-img-message" src={empty} alt={empty} />
                                        <p className="empty-text-message">Todavia no tenés <br /> mensajes</p>
                                    </div>
                                </div> :
                        <div>
                            {messages.map((message, index) => (

                                (message.viewsFalse.length > 0 && decoded.data.id !== message.lastMessage.emitter ?

                                    <div className='message-container-with-message' key={index}>
                                        <button
                                            onClick={() => 
                                                screenSizeWidth < 1030 ?
                                                    handleOnClickMessage(
                                                        message.interactingIds[2],
                                                        message.transaction,
                                                        message.interactingIds[1],
                                                        message.lastMessage.chatId) :
                                                    handleOnClickMessageWeb(
                                                        message.interactingIds[2],
                                                        message.transaction,
                                                        message.interactingIds[1],
                                                        message.lastMessage.chatId)
                                            }
                                            style={{ background: 'transparent', border: 'none', width: '100%' }}>
                                            <MessageContainer
                                                decoded={decoded}
                                                message={message} />
                                        </button>
                                    </div>

                                    :

                                    <div className='message-container-without-message' key={index}>
                                        <button
                                            onClick={() =>
                                                screenSizeWidth < 1030 ?
                                                    handleOnClickMessage(
                                                        message.interactingIds[2],
                                                        message.transaction,
                                                        message.interactingIds[1],
                                                        message.lastMessage.chatId) :
                                                    handleOnClickMessageWeb(
                                                        message.interactingIds[2],
                                                        message.transaction,
                                                        message.interactingIds[1],
                                                        message.lastMessage.chatId)
                                            }
                                            style={{ background: 'transparent', border: 'none', width: '100%' }}>
                                            <MessageContainer
                                                decoded={decoded}
                                                message={message} />
                                        </button>
                                    </div>
                                )
                            ))}
                        </div>
                    }
                </div>

            ) : (
                    <div className='messages-loading-screen'>
                        <CircularProgress />
                    </div>

                )}
        </Fragment>

    )
}

export default Messages;