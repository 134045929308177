import React, { useEffect, useState } from 'react';
import URL from '../../../config/urls';
import axios from 'axios';


const OrientationSelector = ({ stateContext, handleChange }) => {

  const [orientation, setOrientations] = useState([]);
  const [dictionary, setDictionary] = useState({});


  useEffect(() => {

    // /orientations is a private backend route, it requires the user to be authenticated
    const config = {
      headers: {
        'token': localStorage.getItem('token')
      }
    }

    let BASE_URL = URL + 'orientations';
    axios.get(BASE_URL, config)
      .then(res => {
        setOrientations(res.data.responseData);
      })

    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-')

    BASE_URL = URL + `dictionaries/${lang[0]}`
    axios.get(BASE_URL)
      .then(response => {
        setDictionary(response.data.responseData.dict.orientations)
      })

  }, [])

  return (
    <div>
      <select name="orientation" value={stateContext.orientation ? stateContext.orientation : ''} onChange={handleChange} >
        <option value="">Seleccione la orientación</option>
        {
          orientation.map((type, i) =>
            <option value={type} key={i} > {dictionary[type]} </option>
          )
        }
      </select>
    </div>
  )
}

export default OrientationSelector
