// const URL = 'http://192.168.0.79:7000/'

// const URL = 'https://us-central1-hipropi.cloudfunctions.net/api/'

const URL = 'https://us-central1-hipropi-prod.cloudfunctions.net/api/'

// const URL = 'http://192.168.100.163:7000/'

// const URL = 'http://10.127.127.1:7000/'

// const URL = 'http://localhost:5002/hipropi/us-centrall/api/';

export default URL;