import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import URL from '../../../../config/urls'
import axios from 'axios'
import ModalResult from '../Common/ModalResult/ModalResult'
import './Modal.css'

// Select
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Date picker
import DatePicker from "react-datepicker";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import CircularProgress from '@material-ui/core/CircularProgress';

const names = [
  "Encargar Cartel",
  "Cartel Encargado",
  "Cartel Entregado"
];

const useStyles = makeStyles((theme) => ({
  formControl: {

    minWidth: 110,
  },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
}));


const styles = (theme) => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}  >

        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} style={{marginTop: "10px", paddingTop: "20px"}}>
          <CloseIcon />
        </IconButton>
 
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({datos, func}) {

  const [open, setOpen] = React.useState(true);
  const [Fotografo, setFotografo] = React.useState([]);
  const [nombreFoto, setnombreFoto] = useState("")
  const [idFoto, setidFoto] = useState("")
  const [selectedDate, setSelectedDate] = React.useState(new Date('2020-08-18T21:11:54'));
  const [Data, setData] = React.useState([]);
  const [Carteleria, setCarteleria] = React.useState([]);
  const [checked, setChecked] = React.useState(true);
  const [loading, setloading] = useState(false)
  const [alertData, setalertData] = useState("")
  const [openAlert, setopenAlert] = useState(false)



  const classes = useStyles();
  
  const token = localStorage.getItem('tokenAdmin');


  useEffect(() => {
    setData([datos])

    if(datos.serviceCategory == "CARTELERIA"){
      setCarteleria(datos.serviceAction)
    }

    if(datos.serviceCategory == "FOTOGRAFO"){
      // setFotografo({
      //   photographerFullName: datos.photographer,
      //   photographerId: datos.photographerId
      // })
      setnombreFoto(datos.photographer)
      setidFoto(datos.photographerId)
    }

    setChecked(datos.icon)
  },[])


  useEffect(() => {

      if(Data){
        setOpen(true)
      } 
  
  },[])

  const handleChange = (event) => {
    setFotografo(event.target.value);
  };

  const handleChangeIcon = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeCartel = (event) => {
    setCarteleria(event.target.value);
  };

  const handleClose = () => {
    func(false)
    setOpen(false);
  };

  let BASE_URL = URL + "admin/dashboard/"


  const onUpdate = () => {


    if(datos.serviceCategory == "CARTELERIA"){

      let parametros = {
        serviceId: datos.serviceId,
        serviceStatus: datos.serviceStatus,
        serviceAction: Carteleria,
        icon: checked
      }

      try {
        setloading(true)

        const request = axios.put(BASE_URL + `servicesHired/poster/${datos.serviceId}`, parametros, { headers: { 'token': token } })
        request.then((res) => {
          setloading(false)
          setalertData("pass")
          setopenAlert(true)

        })
        request.catch((err) => {
          setloading(false)
          setopenAlert(true)
          setalertData("error")
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

    }


    if(datos.serviceCategory == "FOTOGRAFO"){

      if(Fotografo.length == 0 && nombreFoto == "Sin asignar"){
        setalertData("Foto")
       return setopenAlert(true)
      }

      let parametros;

      if(Fotografo.length == 0 && idFoto.length){

         parametros = {
          photographer: nombreFoto ,
          photographerId: idFoto ,
          icon: checked,
        }

      }else {

         parametros = {
          photographer: Fotografo.photographerFullName ,
          photographerId: Fotografo.photographerId ,
          icon: checked,
        }

      }

      try {
        setloading(true)
        const request = axios.put(BASE_URL + `servicesHired/photography/${datos.serviceId}`, parametros, { headers: { 'token': token } })
        request.then((res) => {
          setloading(false)
          setalertData("pass")
          setopenAlert(true)
          console.log(res)
  
        })
        request.catch((err) => {
          setloading(false)
          console.log('Hubo un error')
          setopenAlert(true)
          setalertData("error")
          console.log(err)
  
        })
        
      } catch (error) {
        
        console.log(error)

      }

    }
    
  }

  return (
    <div  >

      <Dialog style={{ marginTop: "20px" }} maxWidth="md" fullWidth={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

        <DialogContent >
         <Typography  style={{backgroundColor: "#5963cc", height: "5px", marginTop: "-10px" }}>
         </Typography>
        </DialogContent>

          <div className="Img-modal" >
            {
              datos.serviceCategory == "CARTELERIA" ? 
              <AspectRatioIcon className='imagen-foto-modal' style={{ fontSize: 70,color: "white", marginTop: "15px" }} /> :
               <PhotoCameraIcon  className='imagen-foto-modal' style={{ fontSize: 70,color: "white" }} />
            }
            
          </div>
  
        <DialogTitle onClose={handleClose} >

       </DialogTitle>
        <DialogContent className="div-modal-content" >
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px", marginLeft: "90px"}}>
            
            {
              datos.serviceCategory == "CARTELERIA" ? "Solicitud de carteleria" : "Solicitud de fotógrafo"
            }
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > {datos.userFullName} </h6>
          </Typography>
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>
  
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > { datos.phone } </h6>
          </Typography>
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>

            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} >{ datos.userEmail }</h6>
          </Typography>
        </DialogContent>
        <div className="hr-content">
            <hr  style={{borderColor: "#e6e6e6"}} />
        </div>


        <DialogContent className="div-modal-content" style={{marginTop: "20px"}} >
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px", marginLeft: "90px"}}>
            Fecha de ingreso de solicitud
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > { datos.orderDate } </h6>
          </Typography>
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>
             Fecha definida de servicio 
            <br/>
            <h6 className="data-modal-admin" style={{marginTop: "10px"}} > { datos.deliveryDate } </h6> 

          </Typography>
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>

            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} ></h6>
          </Typography>
        </DialogContent>
        <div className="hr-content">
            <hr />
        </div>

        
        <DialogContent className="div-modal-content" style={{marginTop: "20px"}} >
          <Typography className="titulo-modal-admin" style={{marginTop: "-20px", marginLeft: "90px"}}>
           Detalles de la entrega
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > {datos.adress}  </h6>
          </Typography>
         {
          datos.serviceCategory == "CARTELERIA" && <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>
           Texto de cartel
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > {datos.typeInfoPoster == "Telefono" ? datos.typeInfoPoster + " - " + datos.infoPoster : datos.typeInfoPoster + " - "  +  datos.propertyCode}  </h6>
          </Typography>
         } 

        </DialogContent>
        <div className="hr-content">
            <hr />
        </div>


  {

    datos.serviceCategory == "CARTELERIA" && 
    <DialogContent className="div-modal-content" style={{marginTop: "20px"}} >
    <Typography className="titulo-modal-admin" style={{marginTop: "-20px", marginLeft: "90px"}}>
     Accion 
      <br/> 
  <Typography className="titulo-modal-admin" style={{marginTop: "-20px"}}>

    <br/>
    <h6 className="data-modal-admin" style={{marginTop: "10px"}} > Activar icono       
    <Checkbox
        checked={checked}
        onChange={handleChangeIcon}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      /> </h6> 
    </Typography>
    </Typography>
      <FormControl className={classes.formControl} style={{marginRight: "50px"}} >
        <InputLabel id="demo-simple-select-label">Estado Cartel</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"

          value={Carteleria}
          onChange={handleChangeCartel}
          input={<Input />}
      
        >
          {names.map((name) => (
            <MenuItem key={name} value={name} >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


    </DialogContent>
  }

  {

datos.serviceCategory == "FOTOGRAFO" && 
<DialogContent className="div-modal-content" style={{marginTop: "20px"}} >
<Typography className="titulo-modal-admin" style={{marginTop: "-20px", marginLeft: "90px", marginRight: "40px"}}>
 Accion 
  <br/>
  <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{ nombreFoto }</InputLabel>
        <Select
          id="demo-simple-select"
          value={Fotografo}
          onChange={handleChange}
        >
            <MenuItem>
            </MenuItem>
          {datos.selectPhotographers.map((name) => (
            <MenuItem key={name} value={name}>
              {name.photographerFullName + " " + "(" + name.zone +")"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </Typography>

          <Typography className="titulo-modal-admin" style={{marginTop: "-20px", width: "300px", marginRight: "-40px"}}>
              Estado
             <br/>
           <h6 className="data-modal-admin" style={{marginTop: "10px"}} > { datos.serviceAction } </h6>
           </Typography>

          <Typography className="titulo-modal-admin" style={{marginTop: "-10px", width: "300px", marginRight: "-40px"}}>
             
            <br/>
          <h6 className="data-modal-admin" style={{marginTop: "10px"}} > Ver fotos cargadas </h6>
          </Typography>
          <Typography className="titulo-modal-admin" style={{marginTop: "-10px", width: "300px", marginRight: "0px"}}>

            <br/>
            <h6 className="data-modal-admin" style={{marginTop: "0px"}} > Activar icono       
       <Checkbox
        checked={checked}
        onChange={handleChangeIcon}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      /> </h6> 
          </Typography>
       
        </DialogContent>

  }

{
        loading && <div className='messages-loading-screen' style={{marginTop: "230px"}} >
        <CircularProgress />
        </div>
}

    {
     !loading && openAlert && <ModalResult open={openAlert} data={alertData} />
    } 

        <DialogActions>
          <Button autoFocus onClick={onUpdate} color="primary">
            Guardar cambios
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}