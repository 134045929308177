import 'date-fns';
import React from 'react';
import Select from '../Common/Select/Select'

import TablesProp from './TablesProp'
import RouterColumn from '../RouterColumn/RouterColumn'

 

export default function Usuarios() {


  let word = []

  
  return (
 <div>
        <RouterColumn word={"Propiedades"} />

        <div>
          <Select Word={word} palabra={"Propiedades"} />
        </div>
        <div>
          <TablesProp componente={"conectUsers"} palabra1={"PROPIEDADES"} palabra2={"TOTAL"} style={{marginLeft: "800px", marginRight: "50px"}} />
        </div>

    </div>
  );
}