import React, { useContext, useState } from 'react';
import { Context } from '../../../Context';
import './NewProperty.css';
import TopNewProperty from './TopNewProperty';
import TopStepper from './TopStepper';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import axios from 'axios';
import URL from '../../../config/urls';
import NewPropertyTip from './NewPropertyTip';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import CircularProgress from '@material-ui/core/CircularProgress';

const NewProperty5 = ({ history }) => {
    const { newProperty, setNewProperty } = useContext(Context);
    const [loaderNext, setLoaderNext] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewProperty({ ...newProperty, [name]: value });
    }


    const goNext = () => {

        let params =
        {
            mainData: {
                data: {
                    propertyDescription: newProperty.propertyDescription,
                }
            }
        }

        const token = localStorage.getItem('token');
        const BASE_URL = URL + 'properties/' + newProperty.propertyId;

        if (newProperty.propertyId) {
            setLoaderNext(true);
            axios.patch(BASE_URL, params, {
                headers: {
                    "token": token,
                }
            })
                .then(() => {
                    setLoaderNext(false);
                    history.push('/newproperty/final');
                });
        }
    }

    const goPrev = () => {
        history.push('/newproperty/4');
    }

    const tiptitle = 'Descripción';
    const tiptext = `Te sugerimos que en este punto describas a tu propiedad, como si estuvieses contando a un amigo sobre tu inmueble. 
    La terminación de la propiedad, qué comodidades cercanas tiene (tiene paradas de colectivo próximas, supermercados, hospitales, etc), si es ideal para un publico específico (por ej, ideal para adultos mayores) y cualquier otra información que consideres relevante.`;

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }
            <div className="new-property">
                <TopNewProperty step={5} action={newProperty.action} screenSizeWidth={screenSizeWidth} />
                <div className="properties-container-pc">
                    <TopStepper step={5} />
                    <div className="properties-inputs">
                        <div className="fields">
                            <h3>¡Ya casi terminás!</h3>
                            <p className="final-text">Necesitamos que escribas un pequeño texto descriptivo de tu inmueble. Sirve para colocar alguna característica única que no pudiste colocar en las opciones previas.</p>
                            <p className="final-text">Puede ser hasta <strong>500 caractereres.</strong></p>
                            <textarea placeholder="Escriba un texto breve" name="propertyDescription" maxLength="500" value={newProperty.propertyDescription ? newProperty.propertyDescription : ''} onChange={handleChange} />
                            <p className="final-text-smooth">{500 - newProperty.propertyDescription.length} caracteres restantes</p>
                        </div>
                    </div>
                    <NewPropertyTip title={tiptitle} text={tiptext} />
                </div>
                <div className="buttons">
                    {loaderNext ?
                        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
                            <CircularProgress size={30} />
                        </div> :
                        <>
                            <button className="prev" type="button" onClick={goPrev}>atrás</button>
                            <button className="next" type="submit" onClick={goNext}>siguiente</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewProperty5;