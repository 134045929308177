import React from 'react';
// import exclamationError from '../../../assets/images/exclamation.svg';
import './ErrorNotification.css';

const ErrorNotification = ({ text }) => {
    return (
        <div className='errorNotification'>
            <label className='errorLabel'> {text} </label>
        </div>
    )
}

export default ErrorNotification;