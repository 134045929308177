import React, { useState } from 'react';
import '../components/Services.css';
import Card from '../components/ServiceBigCard';
// import photographer from '../../../../assets/images/photographer.png';
import comercia from '../../../../assets/images/services/comercia.jpeg';
import TopAppBar from '../../../commons/BlueTopBar/BlueTopBar';
import TopAppBarWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb';
import Header from '../../../commons/HeaderWeb/HeaderWeb';
import Modal from '../../../commons/Modals/ModalWarning';

const Comercializacion = ({ history }) => {

    let token = localStorage.getItem('token')

    const [ openModal, setOpenModal ] = useState(false);

    function handleOptions() {

        localStorage.setItem("producto", "servicio-comercializacion");
        localStorage.setItem("productoPrice", "2000")

        if(token) {
            history.push('/pay/ways')
        } else {
            setOpenModal(true)
        }
    };

    function handleCloseOut() {
        setOpenModal(true)
    };

    function actionButton() {
        history.push('/welcome')
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            { screenSizeWidth < 1030 ?
                <TopAppBar 
                    title='Asesor Comercial'
                    link='/services'
                />
            :
                <TopAppBarWeb history={history} />
            }

            <Modal
            noButton={true}
            acept='Iniciar sesión'
            cancel='Cerrar'
            openModal={openModal}
            actionButton={actionButton}
            handleCloseOut={handleCloseOut}
            close='true'
            title='¡No estás logueado!'
            text='Para adquirir los diversos servicios que ofrece HiPropi, primero debes iniciar sesión.'
            />
    
            <div className='service-photographer-container'>
                <div className='service-photographer-content' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <div className='service-photographer-body-body'>
                        { screenSizeWidth > 1030 && <Header text='Servicios: Asesor Comercial' w='100%' /> }
                        <div className='service-photographer-page-01'>
                            <div className='serviceSignalContainer01'>
                                <Card 
                                    service={{
                                        id: 206,
                                        img: comercia,
                                        type: 'Servicio',
                                        title: 'Asesor comercial',
                                        description: 'Con Hi Propi podés contar con un asesor comercial preparado para asesorarte y acompañarte en la comercialización de tu inmueble.'
                                    }}
                                />
                            </div>
                            <div className='serviceSignalDescriptionContent'>
                                <label>
                                    Una vez que solicites este servicio un asesor comercial se pondrá en contacto
                                    por whatsapp para otorgarte una cita on-line. Este servicio exclusivo de Hi Propi te brindará 2 encuentros virtuales con asesores que te acompañarán en los procesos mas importantes para una correcta y segura comercialización de tu inmueble.
                                </label>
                                <div className='serviceSignalButtonContainer'>
                                    <button onClick={ () => handleOptions() }> ¡Lo quiero! </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Comercializacion;