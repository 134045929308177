import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import './Tables.css'



const useStyles = makeStyles({
  root: {
    width: '980px',
      marginTop: "110px",
      marginLeft: "230px",
  },
  container: {

  },
});

export default function StickyHeadTable({ rowsData, name }) {


  let columns

    columns = [
      // { id: 'chatId', label: 'Chat ID' },
      { id: 'propertyId', label: 'Property ID' },
      { id: 'fullNameInterested', label: 'Nombre Interesado' },
      { id: 'fullNameOwner', label: 'Nombre dueño' }
 
    ];
  


  

  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Paper className={classes.root}>
      <TableContainer className="table-container-admin">
        <Table aria-label="sticky table">
          <TableHead  >
            <TableRow  >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  
                >
                 <strong  className="head-table-admin-prop" > {column.label} </strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                  {columns.map((column) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                       <strong className="strong-table-admin-prop" style={{width: "400px", fontSize: "15px"}} > { value == 1 ? value = "Super Admin" : value == 2 ? value = "Administrador": value == 3 ? value = "Fotografo": value == 4 ? value = "Inversor" : value} </strong>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}