import React, { useEffect, useState } from 'react';
import URL from '../../../config/urls';
import axios from 'axios';

const PropertyTypes = ({ stateContext, handleChange }) => {

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {

    let BASE_URL = URL + 'propertyTypes';
    axios.get(BASE_URL)
      .then(res => {
        res.data.responseData.sort();
        setPropertyTypes(res.data.responseData);
      })

    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-')

    BASE_URL = URL + `dictionaries/${lang[0]}`
    axios.get(BASE_URL)
      .then(response => {
        setDictionary(response.data.responseData.dict.propertyTypes)
      })

  }, [])

  return (
    <div>
      <select id="propertyType" name="propertyType" value={stateContext.propertyType ? stateContext.propertyType : ''} onChange={handleChange} >
        <option value="">Tipo de propiedad</option>
        {
          propertyTypes.map((type, i) =>
            <option value={type} key={i} > {dictionary[type]} </option>
          )
        }
      </select>
    </div>
  )
}

export default PropertyTypes
