import React from 'react';
import './StepByStep.css';

const StepByStep = ( props => {

    const { steps, stepNumber } = props;

    let selectedOption = stepNumber;

    return (
        <div className='steps-section-container'>
            <div className='steps-section-content'>
                { steps.map((step, index) =>
                    (step.number === steps.length ) ?
                    <>
                        <div key={index} className='steps-section-first-step'>
                            <div className={ selectedOption === step.number ? 'steps-section-button' : 'steps-section-button-disabled' }>
                                <label> {step.number} </label>
                            </div>
                            <div className={ selectedOption === step.number ? 'steps-section-title' : 'steps-section-title-disabled' }>
                                <label> {step.name} </label>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div key={index} className='steps-section-first-step'>
                            <div className={ selectedOption === step.number ? 'steps-section-button' : 'steps-section-button-disabled' }>
                                <label> {step.number} </label>
                                <div className='steps-section-separate' />
                            </div>
                            <div className={ selectedOption === step.number ? 'steps-section-title' : 'steps-section-title-disabled' }>
                                <label> {step.name} </label>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
});

export default StepByStep;