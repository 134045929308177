import React, { useEffect, useState } from 'react';
import './TopAppBar.css'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import logo from '../../../assets/images/hipropiLogo.svg';

// import mailicon from '../../../assets/images/chatsLogo.svg';
import mailicon from '../../../assets/mobile/img3.svg';

import hipropiLogo from '../../../assets/mobile/img1.svg'; 

import menu from '../../../assets/mobile/menu.svg'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import menuhome from '../../../assets/images/menu-home.svg';
import menuiniciar from '../../../assets/images/menu-login.svg';
import menuregistrar from '../../../assets/images/menu-register.svg';
import menuvendedor from '../../../assets/images/menu-seller.svg';
import menutasador from '../../../assets/images/menu-appraiser.svg';
import menuservicios from '../../../assets/images/menu-services.svg';
import menufaq from '../../../assets/images/menu-faq.svg';
import menuperfil from '../../../assets/images/menu-profile.svg';
import menufavorito from '../../../assets/images/menu-favorite.svg';
import docu from '../../../assets/images/docu.png';

import { Link, Redirect } from 'react-router-dom';
import URL from '../../../config/urls';
import axios from 'axios';
import Modal from '../Modals/ModalWarning';
import SearchBar from './SearchBar'

import SearchIcon from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


let jwt = require('jsonwebtoken');

export default function TopAppBar({ history }) {
  return (
    <div>
      <SwipeableTemporaryDrawer history={history} />
    </div>
  );
}

const useStyles = makeStyles({
  list: {
    width: 230,
    height: "100%",
  },
  fullList: {
    width: 'auto',
  }
});

function SwipeableTemporaryDrawer(props) {

  const classes = useStyles();

  const { history } = props;

  const [state, setState] = useState({
    right: false,
  });

  const [openModal, setOpenModal] = useState(false);
  const [unviewed, setunviewed] = useState(0);
  const [selected, setSelected] = React.useState(false);


  // const handleAlignment = (event, newAlignment) => {
  //   setAlignment(event.target.value);
  // };

  function deleteToken() {
    localStorage.removeItem('token')
  }

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const goFacs = async () => {
    await <Redirect to='/preguntas' />
    // const goTo = document.getElementById('go-faqs').getBoundingClientRect().top + window.pageYOffset + -80;
    // window.scrollTo({ top: goTo, behavior: 'smooth' });
  }

  let online = localStorage.getItem('token');

  const getMessages = async () => {
    const response = await axios.get(URL + 'login/verifyToken', { headers: { 'token': online } })
    try {
      let tokenIsValid = response.data.tokenIsValid
      if (online && !tokenIsValid) {
        setOpenModal(true)
        deleteToken();
      } else {
        let decoded = jwt.decode(online).data.id;
        const messages = await axios.get(URL + 'messages/getById', { headers: { 'token': online } })

        let countUnviewed = 0;
        if (messages.data.responseData.length > 0) {
          messages.data.responseData.forEach((chat) => {
            chat.messages.chat.forEach((m) => {

              if (!m.viewed && m.receiver === decoded) {
                countUnviewed++;
              }
            })
          })
        };
        setunviewed(countUnviewed);

      }
    } catch (error) {

      console.log('No se pudo realizar la petición:', error)

    }



  }

  useEffect(() => {
    axios.post(URL + 'properties/sendMailToDate')
    .then((res) => {
        console.log(res)
    })
    .catch((err) => {
        console.log(err)
    })

},[])

  useEffect(() => {
    getMessages()
    // eslint-disable-next-line
  })

  let decoded = jwt.decode(online)

  let menuOffline = [
    {
      text: '',
      icon: logo,
      link: '/',
    },
    {
      text: 'Inicio',
      icon: menuhome,
      link: '/',
    },
    {
      text: 'Iniciar sesión',
      icon: menuiniciar,
      link: '/welcome',
    },
    {
      text: 'Registrarse',
      icon: menuregistrar,
      link: '/createaccount',
    },
    {
      text: 'Tasador Virtual',
      icon: menutasador,
      link: '/appraiser',
    },
    {
      text: 'Servicios',
      icon: menuservicios,
      link: '/services',
    },
    {
      text: 'FAQ de HiPropi',
      icon: menufaq,
      link: '/preguntas',
    },
  ];

  let menuOnline = [
    {
      text: `¡Hola, ${online && decoded !== null && decoded.data.firstName}!`,
      icon: online && decoded !== null && decoded.data.avatar,
      link: '/',
    },
    {
      text: 'Inicio',
      icon: menuhome,
      link: '/',
    },
    {
      text: 'Perfil',
      icon: menuperfil,
      link: online && decoded !== null && '/myprofile?userId=' + decoded.data.id,
    },
    {
      text: 'Mis favoritos',
      icon: menufavorito,
      link: '/favorites',
    },
    {
      text: 'Mis propiedades',
      icon: menuvendedor,
      link: '/myproperties',
    },
    {
      text: 'Tasador Virtual',
      icon: menutasador,
      link: '/appraiser',
    },
    {
      text: 'Servicios',
      icon: menuservicios,
      link: '/services',
    },
    {
      text: 'FAQ de HiPropi',
      icon: menufaq,
      link: '/preguntas',
    },
    // {
    //   text: 'Documentos',
    //   icon: docu,
    //   link: '/question/pack1',
    // }
  ];

  let listMenu = online !== null ? menuOnline : menuOffline;

  const sideList = side => (

    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
      style={{ display: 'fixed', height: '100%' }}
    >
      <List className="menu-list">
        <Link to={listMenu[0].link} className="menu-link" key={listMenu[0].text}>
          <ListItem
            className={online ? 'menu-online-item' : null}
            button
            style={{ borderRadius: 'none' }}
          >
            <ListItemIcon className={online ? 'menu-online-icon' : null}><img src={listMenu[0].icon} alt="Menu" /></ListItemIcon>
            <ListItemText className={online ? 'menu-online-text' : null} primary={listMenu[0].text} />
          </ListItem>
        </Link>
        {listMenu.map((menu, index) => (
          index > 0 ?
            <Link to={menu.link} onClick={(menu.text === 'FAQ de HiPropi') ? goFacs : null} className="menu-link" key={menu.text}>
              <ListItem className="menu-item"
                button
                style={{ width: '100%' }}
              >
                <div className='menu-item-content-styles'>
                  <ListItemIcon className="menu-item-icon"><img src={menu.icon} alt="Menu" /></ListItemIcon>
                  <ListItemText className="menu-item-text" primary={menu.text} />
                </div>
              </ListItem>
            </Link> : null
        ))}
        {online ?
          <Link to="/welcome" className="menu-link menu-link-close" key={"Cerrar sesión"} onClick={deleteToken} >
            <ListItem
              className="menu-item-last"
              button
            >
              <ListItemIcon className="menu-item-icon"><img src={menuiniciar} alt="Menu" /></ListItemIcon>
              <ListItemText className="menu-item-text" primary={"Cerrar sesión"} />
            </ListItem>
          </Link> : null
        }
      </List>
    </div>
  );

  const MyBadge = withStyles({
    colorPrimary: {
      backgroundColor: '#e32085',
    }
  })(Badge);

  function handleActionButton() {
    history.push('welcome')
  }

  return (
    <div>
      <Modal
        noButton={true}
        openModal={openModal}
        title='¡Tu sesión caducó!'
        text='Ha pasado mucho tiempo desde la última vez que iniciaste sesión. Ingresa nuevamente a tu cuenta para poder acceder a todas las secciones de HiPropi o continúa navegando offline.'
        acept='Iniciar sesión'
        cancel='cerrar'
        close={true}
        actionButton={handleActionButton}
      />
      <AppBar position="static" className="appBar" >
        <Toolbar className="toolBar" style={{backgroundColor: "#e32085"}} >

          <div className='logoAppContainer'>
            <Link to='/'><img src={hipropiLogo} alt="HiPropi" className="titleImg" /></Link>
          </div>


              <SearchBar funct={setSelected} />

          {online &&
            <div className='messagesIconContainer'>
              <Link to='/messages' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MyBadge badgeContent={unviewed == 0? null : unviewed } color={unviewed == 0 ? "" : "primary"} >
                  <img alt='message icon' src={mailicon}  />
                </MyBadge>
              </Link>
            </div>
          }

          <div className='menuIconContainer'>
            <IconButton
              edge="start"
              aria-label="open drawer"
              className="menu"
              onClick={toggleDrawer('right', true)}
            >
              <img src={menu} alt="Menu" className='menuImg' style={{marginLeft: "5px"}} />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      <SwipeableDrawer
       anchor={'right'}
        open={state.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {sideList('right')}
      </SwipeableDrawer>


     

    </div>
  );
}