import React, { useState } from 'react';
import { ReportComponent } from '../../../commons/ExpansionPanels/components/Complaint/ComplaintPage';
import arrowBack from '../../../../assets/images/arrowBack.svg';
import ModalWarning from '../../../commons/Modals/ModalWarning';
import reportUser from '../../../../assets/images/reportUser.svg';
import axios from 'axios';
import URL from '../../../../config/urls';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppBarWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'

const ReportUser = ({ history }) => {

    let ownerIdFromUrl = window.location.href.split('=')[1].split('?')[0];


    let token = localStorage.getItem('token');

    const useStyles = makeStyles(theme => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
    }));

    const classes = useStyles();

    const [ reportReason, setReportReason ] = useState({
        inappropriate: '',
        spam: '',
        other: '',
        txt: ''
    })


    const [ openSecondModal, setOpenSecondModal ] = useState(false);
    const [ openFirstModal, setOpenFirstModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    let reasons = [
        {
            r: 'inappropriate content',
            text: 'Contenido inapropiado.',
            state: reportReason.inappropriate
        },
        {
            r: 'spam',
            text: 'Spam.',
            state: reportReason.spam
        },
        {
            r: 'another reason',
            text: 'Otra razón.',
            state: reportReason.other
        }
    ];

    function handleSelectedOtherReason(value) {
        setReportReason({ ...reportReason, txt: value })
    }

    function handleSelectedReason(value) {
        switch(value) {
            case 'inappropriate content':
                setReportReason({ inappropriate: 'true', spam: '', other: '', txt: '' })
                break;
            
            case 'spam':
                setReportReason({ inappropriate: '', spam: 'true', other: '', txt: '' })
                break;

            case 'another reason':
                setReportReason({ inappropriate: '', spam: '', other: 'true', txt: '' })
                break;

            default:
                console.log('No se encontró valor.')
        }
    }

    let header = 'Gracias por ayudarnos denunciando aquellos usuarios que incumplen con las normas de HiPropi.'

    function handleActionButton() {
         history.goBack();
        // history.push('/messages')
    }

    function reportConfirm() {
        setOpenFirstModal(true)
    }

    function sendReport() {
        setOpenFirstModal(false);
        setLoading(true);
        let report = { 
                        inappropriate: reportReason.inappropriate,
                        spam: reportReason.spam,
                        other: reportReason.other,
                        txt: reportReason.txt
                    };
        if(report) {
            axios.post(URL + 'denounce/users/' + ownerIdFromUrl, report, { headers: { 'token': token } })
            .then( response => {
                setReportReason({ inappropriate: '', spam: '', other: '', txt: '' })
                setTimeout(() => setLoading(false), 500)
                setTimeout(() => setOpenSecondModal(true), 500)
                history.goBack();
            })
            .catch((e) => {
                history.goBack();
            })
        }
    }

    let params = 
        reportReason.inappropriate !== '' ||
        reportReason.spam !== '' ||
        reportReason.other !== '';

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            { openFirstModal &&
                <ModalWarning
                    title='¿Está seguro de denunciar este usuario?'
                    text=''
                    acept='Estoy seguro'
                    cancel='Cancelar'
                    image={reportUser}
                    noButton={true}
                    close={true}
                    openModal={openFirstModal}
                    actionButton={sendReport}
                />
            }
                <ModalWarning
                    title='¡Denuncia enviada!'
                    text='Gracias por ayudarnos para mantener HiPropi una mejor comunidad.'
                    acept='Entendido'
                    cancel='false'
                    image={reportUser}
                    noButton={true}
                    close={true}
                    openModal={openSecondModal}
                    actionButton={handleActionButton}
                />
                
                
                { screenSizeWidth > 1030 ?

                    <TopAppBarWeb history={history} />

                :

                    <div className="topBar" >
                        <Link to='/'><img src={arrowBack} alt={arrowBack} className="filterIcon" /></Link>
                        <h3> Denunciar usuario </h3>
                    </div>
                }

                <div className='report-property-container-body' >
                    { screenSizeWidth > 1030?
                    <div className='report-property-content-body' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                        <div className='report-title-container'>
                            <label> Denunciar usuario </label>
                        </div>

                        <ReportComponent
                        selectedOtherReason={handleSelectedOtherReason}
                        selectedReason={handleSelectedReason}
                        reportReason={reportReason}
                        handleSubmit={reportConfirm}
                        reasons={reasons}
                        header={header}
                        params={params}
                        />
                    </div>
                    :
                    <Fade right>
                        <ReportComponent
                        selectedOtherReason={handleSelectedOtherReason}
                        selectedReason={handleSelectedReason}
                        reportReason={reportReason}
                        handleSubmit={reportConfirm}
                        reasons={reasons}
                        header={header}
                        params={params}
                        />
                    </Fade>
                    }
                </div>

                { loading &&
                    <div>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> 
                }
                
        </div>
    )
}

export default ReportUser;