import React, {
    useEffect,
    useState
} from "react";
import { useHistory } from 'react-router-dom';
//import axios from 'axios';
import "./ViewList.css";
import axios from 'axios';
import URL from '../../../config/urls';

// components

//import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwipeableViewList from './component/SwipeableViewList';

// icons
import bed from './../../../assets/images/property/bed.svg';
import cube from './../../../assets/images/property/volume.svg';
import eye from "../../../assets/images/eye.svg";
import camera from '../../../assets/images/camera.svg';
import cameraFalse from '../../../assets/images/cameraFalse.svg'
import certificate from '../../../assets/images/certificate.svg';
import certificateFalse from '../../../assets/images/certificateFalse.svg';
import premium from '../../../assets/images/premium.svg';
import alertIcon from '../../../assets/images/favorites.svg';
import tooltipQuestion from '../../../assets/images/tooltip-question.svg';

import ModalWarning from '../../commons/Modals/ModalWarning';

// eslint-disable-next-line
let lang = navigator.language || navigator.userLanguage;
lang = lang.split('-')

let screenSizeWidth = window.innerWidth;


/* const useStyles = makeStyles(theme => ({
    price: {
        fontSize: 25
    }
})); */

// favorite component
const ItemFavorite = React.memo( props => {
    const { handleFavorite, isFavorite, propertyId, publishedFor } = props;
    return (
        <div className='item-favorite-content'>
            <input type='checkbox' id={propertyId + publishedFor} name={propertyId} className='item-favorite-input' defaultChecked={isFavorite} onChange={handleFavorite} />
            <label htmlFor={propertyId + publishedFor} className='item-favorite-label' />
        </div>
    )
});

// component containing items of the 'premium'
const Premium = React.memo( props => {
    const { card } = props;
    return (
        <div>
            {card === null ? null :
                <div>
                    { card.servicesHired.poster && card.servicesHired.photography ?
                    
                        <div className='premium'>
                            <img src={premium} alt={premium} />
                        </div>

                        : null
                    }
                </div>
            }
        </div>
    )
});

// component containing view items and favorite item

const MenuItems = React.memo( props => {
    const { handleFavorite, isFavorite, propertyId, publishedFor, visitsCount } = props;
    return (
        <div className='menu-item-card-content'>
            <img src={eye} alt={eye} className='eyes-style' />
            <span className='number-style'> {visitsCount} </span>
            <ItemFavorite handleFavorite={handleFavorite} isFavorite={isFavorite} propertyId={propertyId} publishedFor={publishedFor} />
        </div>

    )
});

// component containing premium component + menu item
export const Actions = React.memo( props => {
    const { card, handleFavorite, isFavorite, publishedFor } = props;
    return (
        <div className='actions-content'>
            <Premium card={card} />
            <MenuItems 
            visitsCount={card.visitsCount}
            handleFavorite={handleFavorite} 
            isFavorite={isFavorite} 
            propertyId={card.propertyId} 
            publishedFor={publishedFor} />
        </div>
    )
});

// component with items to premium
const ItemList = React.memo( props => {
    const { card, showModal } = props;
    return (
        <div className='item-list-container-cap'>
        {card === null ? null :
            <div className='item-list-container'>
            { !card.servicesHired.poster ?
                <div className='item-list' onClick={() => showModal('services')}>
                    <img className='item-list-img-certificate' src={certificateFalse} alt={certificateFalse} />
                    <span style={{ color: 'rgba(35, 47, 52, 0.5)' }}> Cartelería </span>
                </div>
            :
                <div className='item-list' onClick={() => showModal('services')}>
                    <img className='item-list-img-certificate' src={certificate} alt={certificate} />
                    <span> Cartelería </span>
                </div>
            }

            { !card.servicesHired.photography ?
                <div className='item-list' onClick={() => showModal('services')}>
                    <img className='item-list-img-camera' src={cameraFalse} alt={cameraFalse} />
                    <span style={{ color: 'rgba(35, 47, 52, 0.5)' }}> Fotógrafo Profesional </span>
                </div>
            :
                <div className='item-list' onClick={() => showModal('services')}>
                    <img className='item-list-img-camera' src={camera} alt={camera} />
                    <span> Fotógrafo Profesional </span>
                </div>
            }

            </div>
        }
        </div>
    )
});

const CustomCard = React.memo( props => {

    const { card, publishedFor, handleClick, isFavorite, showModal, size } = props;


    const [color, setColor] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState(null);

    useEffect(() => {

        switch(publishedFor) {
            case 'sell':
                setTransaction('Venta')
                setColor('Venta')
                setPrice(card.price.sellPrice)
                setCurrency(card.price.sellCurrency)
                break;

            case 'rent':
                setTransaction('Alquiler')
                setColor('Alquiler')
                setPrice(card.price.rentPrice)
                setCurrency(card.price.rentCurrency)
                break;

            case 'tmpRent':
                setTransaction('Temporario')
                setColor('Temporario')
                setPrice(card.price.tmpPrice)
                setCurrency(card.price.tmpCurrency)
                break;

            default:
                console.log('No se encuentran valores.')
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (card.propertyType === 'casaCountry') {
            card.propertyType = 'Casa Country'
        }

        if (card.propertyType === 'loteOTerreno') {
            card.propertyType = 'Lote / Terreno'
        }
    }, [card])

    const [isSafari, setisSafari] = useState(false)

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    useEffect(() => {
        //Check user mobile browser

        let navigatorIsSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;

        if (navigatorIsSafari) {
            setisSafari(true)
        }
    }, [userAgent, isSafari])

    const handleFavorite = e => {
        const token = localStorage.getItem('token');
        const headers = { headers: { "token": token } };
        let params =
        {
            propertyId: e.target.name,
            transaction: publishedFor
        }

        if (token) {
            if (e.target.checked) {
                const BASE_URL = URL + 'favourites/like';
                axios.post(BASE_URL, params, headers)
                    .then(res => {
                        const data = res.data;
                    })
            } else {
                const BASE_URL = URL + 'favourites/unlike';
                axios.post(BASE_URL, params, headers)
                    .then(res => {
                        const data = res.data;
                    })
            }
        } else {
            e.target.checked = false;
            showModal('favorites');
        }
    };


    return (
        <div style={{width: '100%', height: '100%'}}>
            <div className={ screenSizeWidth < 1030 ? 'card-view-list-complete-mobile' : size == 'small' ? 'card-view-list-complete-small' : 'card-view-list-complete-web' }>
                <button className="button-view-list" onClick={(e) => handleClick(card.propertyId, publishedFor)} />
                <SwipeableViewList size={size} images={card.images.medium} />
                <Actions card={card} handleFavorite={handleFavorite} isFavorite={isFavorite} publishedFor={publishedFor} />
                <div className='card-content-support'>
                    <div className='card-content-price'>
                    {
                        transaction === 'Venta' ?
                        <div className="price-line-through">
                            { 
                            `${currency} ${new Intl.NumberFormat("de-DE").format(Math.round(card.price.sellDiscount))}`
                            }
                        </div> :
                        <div className="price-not-line-through">
                            {
                                transaction === 'Alquiler' &&
                                    card.price.rentDiscount 
                                    // card.price.tmpDiscount
                            }
                        </div>
                    }
                        <div className='price'>
                            { `${currency} ${new Intl.NumberFormat("de-DE").format(Math.round(price))}` }
                        </div>
                    </div>
                    <div className='card-transaction-and-characters-description'>
                        <p className={color}>
                            {transaction}
                        </p>
                        {
                            // card.accommodations.hasBedroomsNumber &&
                            // <p className='characters-bed'>
                            //     | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
                             // </p>
                             card.accommodations.hasBedroomsNumber == -1 ?
                             <p className='characters-bed'>
                                 | <img src={bed} alt={bed} /> {"M"}
                             </p>
                             :  card.accommodations.hasBedroomsNumber ?
                             <p className='characters-bed'>
                             | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
                             </p>
                             : null
                         }
                         {
                            card.totalArea &&
                            <p className='characters-area'>
                                | <img src={cube} alt={cube} /> {card.edifiedArea}m<sup>2</sup>
                            </p>
                        }
                    </div>
                    <div className='address-property-card'>
                        <label> {card.propertyType && card.propertyType.charAt(0).toUpperCase() + card.propertyType.slice(1)} en <span>{card.address.city}</span>, {card.address.state}.</label>
                    </div>
                    <ItemList card={card} showModal={showModal}/>
                </div>
            </div>
        </div>
    )
});

const ViewList = React.memo( props => {


    const { property, publishedFor, handleClick, isFavorite, propTypeTranslation, size } = props;

    

    const [card, setCard] = useState(null);
    // const [openModal, setOpenModal] = useState(false);
    const [modal, setModal] = useState({});

    const showModal = (condition) => {
        switch(condition) {
            case 'favorites':
                setModal({
                    ...modal,
                    open: true,
                    title: 'Ups, no podés guardar favoritos',
                    text: 'Necesitas estar adentro de una cuenta para guardar propiedades favoritos.',
                    acept: 'Iniciar Sesión',
                    cancel: 'Ahora no',
                    image: alertIcon,
                    actionButton: goSignIn,
                });
            break;
            case 'services':
                setModal({
                    ...modal,
                    open: true,
                    title: 'Servicios de Contratación',
                    text: 'Estos íconos indican si una propiedad tiene contratados los servicios exclusivos de HiPropi para cartelería o fotógarfo profesional.',
                    acept: 'Entendido',
                    cancel: 'false',
                    image: tooltipQuestion,
                    actionButton: 'handleClose'
                });
            break;
            default:
                return
        }        
    }

    const handleCloseOut = () => {
        setModal({ ...modal, open: false });
    }

    // let propTypeTranslation;
    // for (let propType in dictionary) {
    //     if (property.propertyType === propType) {
    //         propTypeTranslation = dictionary[propType]
    //     }
    // }

    useEffect(() => {
        setCard(property);
        // eslint-disable-next-line
    }, []);

    const history = useHistory();
    const goSignIn = () => {
        history.push('/welcome');
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
        <ModalWarning
            title={modal.title}
            text={modal.text}
            acept={modal.acept}
            cancel={modal.cancel}
            image={modal.image}
            noButton={true}
            close={true}
            openModal={modal.open}
            actionButton={modal.actionButton}
            handleCloseOut={handleCloseOut}
        />
            {card === null ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                    <CircularProgress size={70} />
                </div> 
            :
                <CustomCard
                    propTypeTranslation={propTypeTranslation}
                    card={card}
                    handleClick={handleClick}
                    publishedFor={publishedFor}
                    isFavorite={isFavorite}
                    showModal={showModal}
                    size={size}
                />
            }
        </div>
    )
});

export default ViewList;