import React, { useState, useEffect } from 'react';
import './Security.css'
import axios from 'axios';
import URL from '../../../../../config/urls';
import ErrorNotification from '../../../../commons/ErrorNotification/ErrorNotification';
import showPassword from '../../../../../assets/images/seePassword.png';
import showPasswordError from '../../../../../assets/images/seePasswordError.png';
import Fade from 'react-reveal/Fade';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';
import Modal from '../../../../commons/Modals/ModalWarning';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const Security = ({ history }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
    }));

    const classes = useStyles();

    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];

    let BASE_URL; 
    let BASE_URL_ = URL + 'changePassword'

    if(token !== null) {
        BASE_URL = URL + 'users/' + userIdFromUrl
    }

    const [ user, setUser ] = useState({});
    const [ email, setEmail ] = useState('');
    const [ oldPassword, setOldPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordAgain, setNewPasswordAgain ] = useState('');

    const [ loading, setLoading ] = useState(false);

    const [ openAlert, setOpenAlert ] = useState({
        successful: false,
        error: false
    });

    useEffect(() => {  
        axios.get(BASE_URL, headers)
        .then(response => {
            const dataUser = response.data.responseData;
            setUser(dataUser)
            setEmail(dataUser.email)
        })
        .catch(e => {
            console.log(e);
            setOpenAlert({ ...openAlert, error: true })
        })
        // eslint-disable-next-line
       }, [])

       async function modifyUserPatch(modificatedPasswordObject) {
        setLoading(true);
        try {
            await axios.post(BASE_URL_, modificatedPasswordObject, { headers: { "token": token }
            })
            setTimeout(() => setLoading(false), 500)
            setTimeout(() => setOpenAlert({ ...openAlert, successful: true }), 500)
        } catch (e) {
            setTimeout(() => setLoading(false), 500);
            setTimeout(() => setOldPassNotMatch(true), 500);
            setTimeout(() => setOpenAlert({ ...openAlert, error: true }), 500);
        }
    }

    const modifyPassword = (modificatedPasswordObject) => {
        setUser({...user, modificatedPasswordObject})
        modifyUserPatch(modificatedPasswordObject)
    }

    // eslint-disable-next-line
    const [ oldPassNotMatch, setOldPassNotMatch ] = useState(false);

    const [ passErrors, setPassErrors ] = useState({ 
        oldPassInvalidFormat: false,
        oldPassNotMatch: false,
        newPassInvalidFormat: false,
        newPassConfirmInvalidFormat: false,
        passNotMatch: false
    })

    let params = 
        passErrors.oldPassInvalidFormat === false &&
        passErrors.newPassInvalidFormat === false &&
        passErrors.newPassConfirmInvalidFormat === false &&
        passErrors.passNotMatch === false &&
        oldPassword.length > 5 &&
        newPassword.length > 5 &&
        newPasswordAgain.length > 5;


    function isMatch(value) {
        if(newPassword === value) {
            setPassErrors({ ...passErrors, passNotMatch: false })
        } else {
            setPassErrors({ ...passErrors, passNotMatch: true })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        modifyPassword({ oldPassword, newPassword, token })
    }

    const [ seeOldPassword, setSeeOldPassword ] = useState(false);
    const [ seeNewPassword, setSeeNewPassword ] = useState(false);
    const [ seeNewPasswordAgain, setSeeNewPasswordAgain ] = useState(false);

    let screenSizeWidth = window.innerWidth;

    function handleShowPassword(type) {
        switch(type) {
            case 'oldPassword':
                setSeeOldPassword(!seeOldPassword);
                break;
            case 'newPassword':
                setSeeNewPassword(!seeNewPassword);
                break;
            case 'newPasswordAgain':
                setSeeNewPasswordAgain(!seeNewPasswordAgain)
                break;
            default:
                console.log('nada')
        }
    };

    function handleCloseError() {
        setOpenAlert({ ...openAlert, error: false })
        setOldPassword('');
        setNewPassword('');
        setNewPasswordAgain('');
    };

    function handleChangesConfirm() {
        setOpenAlert({ ...openAlert, successful: false })
        if (screenSizeWidth < 1030) {
            history.push('/myprofile/?userId=' + userIdFromUrl)
        }
    };

    return (
        <div>
            { user === null ? null : 
            <div>

            { loading && 
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            { screenSizeWidth < 1030 ?
                <BlueTopBar
                title='Privacidad'
                link={'/myprofile/?userId=' + userIdFromUrl}
                />
            :
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <label className='settings-profile-data' style={{ fontSize: 16 }}> Privacidad </label>
                    <label className='settings-profile-title' style={{ marginBottom: 10 }}> Cambiar mail y contraseña </label>
                </div>
            }


            <Fade right>
                <form onSubmit={handleSubmit} >

                    <div className='recover-password-my-profile-content'>
                        <div className='input-container-recover-pass'>
                            <label htmlFor='user'> Correo electrónico </label> 
                            <div className='change-password-container-input'>
                                <input 
                                className='input-disabled'
                                required 
                                defaultValue={email} name='username' type='text' />
                            </div>
                        </div>

                        <div className='input-container-recover-pass'>
                            <label htmlFor='current-password'> Contraseña actual </label> 
                            <div className='change-password-container-input'>
                                <input 
                                className={ passErrors.oldPassInvalidFormat && 'errorsInput' }
                                value={oldPassword} 
                                onChange={ (e) => { 
                                    setOldPassword(e.target.value);
                                    setPassErrors({ ...passErrors, oldPassInvalidFormat: false});
                                    setOldPassNotMatch(false);
                                }}
                                name='current-password' 
                                type={ seeOldPassword ? 'text' : 'password' } 
                                placeholder='Ingrese su contraseña actual' 
                                onBlur={ (e) => { oldPassword.length < 5 ? 
                                    setPassErrors({ ...passErrors, oldPassInvalidFormat: true }) :
                                    setPassErrors({ ...passErrors, oldPassInvalidFormat: false });
                                    setOldPassNotMatch(false); 
                                }} 
                                />
                                <button 
                                    onClick={ () => handleShowPassword('oldPassword')}
                                    className='showPassword'
                                    type='button'
                                >
                                    <img 
                                    alt='show password'
                                        className='eyeFromShowPasswordinSecurity'
                                        src={ passErrors.oldPassInvalidFormat ? showPasswordError : showPassword } 
                                    />
                                </button>
                            </div>

                            { passErrors.oldPassInvalidFormat && 
                            <ErrorNotification text='Debe tener min. 6 caracteres' /> }

                            { passErrors.oldPassNotMatch && 
                            <ErrorNotification text='La contraseña actual no coincide.' /> }
                        </div>

                        <div className='input-container-recover-pass'>
                            <label htmlFor='new-password'> Nueva contraseña </label> 
                            <div className='change-password-container-input'>
                                <input value={newPassword} 
                                name='new-password' 
                                onChange={ (e) => { 
                                    setNewPassword(e.target.value);
                                    e.target.value.length < 6 ?
                                    setPassErrors({ ...passErrors, newPassInvalidFormat: true }) :
                                    setPassErrors({ ...passErrors, newPassInvalidFormat: false })
                                }} 
                                type={ seeNewPassword ? 'text' : 'password'} 
                                placeholder='Ingrese su nueva contraseña'
                                className={ passErrors.newPassInvalidFormat && 'errorsInput' }
                                />
                                <button 
                                    onClick={ () => handleShowPassword('newPassword')}
                                    className='showPassword'
                                    type='button'
                                >
                                    <img 
                                    alt='show password'
                                        className='eyeFromShowPasswordinSecurity'
                                        src={ passErrors.newPassInvalidFormat ? showPasswordError : showPassword } 
                                    />
                                </button>
                            </div>

                            { passErrors.newPassInvalidFormat && 
                            <ErrorNotification text='Debe tener min. 6 caracteres'/> }
                        </div>

                        <div className='input-container-recover-pass'>
                            <label htmlFor='new-password-again'> Reingrese nueva contraseña </label> 
                            <div className='change-password-container-input'>
                                <input 
                                value={newPasswordAgain} 
                                name='new-password-again' 
                                onChange={ (e) => { 
                                    setNewPasswordAgain(e.target.value);
                                    isMatch(e.target.value);
                                }} 
                                type={ seeNewPasswordAgain ? 'text' : 'password' }
                                placeholder='Ingrese su nueva contraseña' 
                                onBlur={ (e) => {
                                    isMatch(e.target.value);
                                }}
                                className={ (passErrors.newPassConfirmInvalidFormat || 
                                    passErrors.passNotMatch) && 'errorsInput' }
                                />
                                <button 
                                    onClick={ () => handleShowPassword('newPasswordAgain')}
                                    className='showPassword'
                                    type='button'
                                >
                                    <img 
                                    alt='show password'
                                        className='eyeFromShowPasswordinSecurity'
                                        src={ passErrors.newPassConfirmInvalidFormat || 
                                            passErrors.passNotMatch
                                            ? showPasswordError : showPassword } 
                                    />
                                </button>
                            </div>

                            { passErrors.newPassConfirmInvalidFormat && 
                            <ErrorNotification text='Debe tener min. 6 caracteres'/> }

                            { passErrors.passNotMatch && 
                            <ErrorNotification text='Las contraseñas no coinciden'/> }
                        </div>

                    </div>

                    { openAlert.successful &&    
                        <Modal 
                        title='¡Tu contraseña se actualizó correctamente!'
                        text='A partir de ahora, ya puedes ingresar con tu nueva contraseña.'
                        noButton={true}
                        acept='Cerrar'
                        openModal={openAlert.successful}
                        actionButton={handleChangesConfirm}
                        cancel='false'
                        />
                    }

                    { openAlert.error && 
                        <Modal 
                        title='¡Ha ocurrido un error!'
                        text='La contraseña ingresada no coincide con la actual.'
                        noButton={true}
                        acept='Cerrar'
                        openModal={openAlert.error}
                        actionButton={handleCloseError}
                        cancel='false'
                        />
                    }

                    <div className='security-fixed'>
                        <button 
                        disabled={ params ? false : true }
                        className={ params ? 'security-button-fixed' : 'security-button-fixed-disabled' }> 
                            Guardar
                        </button>
                    </div>

                </form>
            </Fade>
            </div>
            }
        </div>
    )
};

export default Security;