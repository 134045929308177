import React from 'react'
import Select from '../../Common/Select/Select'
import DatePicker from '../../PickerTable/PickerTable'
import RouterColumn from '../../RouterColumn/RouterColumn'

export default function Contratos(){

    let word = [{ nombre: "Carteleria", link: "/admin/servicios"}, { nombre: "Fotografos", link: "/admin/fotografo"}, { nombre: "Asesor C.", link:"/admin/comercializacion" }]

    
  return (
    <div>
           <RouterColumn  />
   
           <div>
             <Select Word={word} palabra={"Contratos"} />
           </div>
           <div>
             <DatePicker componente={"servicesRequest/documents"} palabra1={"CONTRATOS"} palabra2={"SOLICITADOS"} style={{marginLeft: "800px", marginRight: "50px"}} />
           </div>
   
       </div>
  )

}