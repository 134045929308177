import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorNotification from "../CreatAccount/components/CustomNotification";
import TopBar from "../../commons/BlueTopBar/BlueTopBar";
import "../Consult/consult.css";
import TopAppBarWeb from "../../commons/TopAppBarWeb/TopAppBarWeb";
import axios from "axios";
import URL from "../../../config/urls";
import { Fragment } from "react";
import ModalWarning from "../../commons/Modals/ModalWarning";

export default function Contact({ history }) {
  const { register, handleSubmit, errors } = useForm();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const onSubmit = async (data) => {
    await axios.post(`${URL}consult`, data, config);
    setOpen(true);
  };

  const [open, setOpen] = useState(false);

  const [disabled, setDisabled] = useState(true);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const refreshPage = () => {
    window.location.reload(false);
  };
  
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
})

const onChangeInput = (e) => {
  setFormData({ ...formData, [
      e.target.name
    ]: e.target.value })
}

useEffect(() => {
  if(formData.name !== '' && formData.phone !== ''){
    setDisabled(false);
  }
  else{
    setDisabled(true);
  }        
}, [formData])

  let screenSizeWidth = window.innerWidth;

  return (
    <Fragment>
      {screenSizeWidth > 1030 ? (
        <TopAppBarWeb history={history} />
      ) : (
        <TopBar title="Contacto" link="/" />
      )}
      <div>
        <div className="services-web-container-card">
          <div className="services-web-content-card" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
            {screenSizeWidth > 1030 && (
              <div
                className="report-title-container"
                style={{ margin: "auto" }}
              >
                <label> Contacto </label>
              </div>
            )}

            <div className="servicesContainer">
              <div className="servicesContentText">
                <label> Enviar un correo eléctronico. </label>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="consult-form">
          <label className="label-form">Nombre</label>
          <input
            type="text"
            placeholder="Nombre"
            name="name"
            ref={register}
            required="true"
            className="input-form"
            onChange={e=> onChangeInput(e)}
          />
          {errors.name && <ErrorNotification errorText="Campo obligatorio" />}
          <label className="label-form">Correo Eléctronico</label>
          <input
            type="email"
            placeholder="Correo Eléctronico"
            name="email"
            ref={register}
            required="true"
            className="input-form"
            onChange={e=> onChangeInput(e)}
          />
          {errors.email && <ErrorNotification errorText="Campo obligatorio" />}
          <label className="label-form">Teléfono</label>
          <input
            type="phone"
            placeholder="Teléfono"
            name="phone"
            ref={register}
            required="true"
            className="input-form"
            onChange={e=> onChangeInput(e)}
          />
          {errors.phone && <ErrorNotification errorText="Campo obligatorio" />}
          <label className="label-form">Asunto</label>
          <input
            type="subject"
            placeholder="Asunto"
            name="subject"
            ref={register}
            required="true"
            className="input-form"
            onChange={e=> onChangeInput(e)}
          />
          {errors.subject && (
            <ErrorNotification errorText="Campo obligatorio" />
          )}
          <label className="label-form">Mensaje</label>
          <input
            type="message"
            placeholder="Mensaje"
            name="message"
            ref={register}
            required="true"
            className="input-form"
            onChange={e=> onChangeInput(e)}
          />
          {errors.message && (
            <ErrorNotification errorText="Campo obligatorio" />
          )}
          {!disabled ? 
                    <button type="submit" className="consult-button">
                    Enviar
                  </button>
                  
        :   <button  className="consult-button-disabled" disabled="true">
            Enviar
              </button> }

        
        </form>
        <div>
          <ModalWarning
            title="Tu mensaje ha sido enviado con éxito"
            text="Te responderemos a la brevedad"
            acept="Cerrar"
            cancel='false'
            noButton={true}
            openModal={open}
            actionButton={refreshPage}
          />
        </div>
      </div>
    </Fragment>
  );
}
