import React, { useContext, useState, useEffect, Fragment } from "react";
import "./Filter.css";
// import arrowBack from '../../../assets/images/arrowBack.svg';
import CloseIcon from "@material-ui/icons/Close";
import trash from "../../../assets/images/trash.svg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModalWarning from "../../commons/Modals/ModalWarning";
import { Context, goApplyFiltersWeb } from "../../../Context";
import URL from "../../../config/urls";
import axios from "axios";
import {
  accommodations,
  amenities,
  furnishedAttributes,
  furnishedBeds,
} from "../../../data/amenitesAccommodationsData.js";
import Location from "../../commons/Location/Location";
import PropertyTypesSelector from "../../commons/SelectorsData/PropertyTypesSelector";

let screenSizeWidth = window.innerWidth;

export const ExpansionPanels = ({ history }) => {
  const {
    searchFilters,
    setSearchFilters,
    setPage,
    applyFilters,
    setApplyFilters,
  } = useContext(Context);
  console.log(searchFilters.currencyPrice);

  const [err, setErr] = useState(false);

  // useEffect(() => {
  //     goApplyFiltersWeb();
  // }, [])

  // useEffect(() => {
  //     goApplyFiltersWeb();
  // }, [searchFilters])

  // console.log('zzzzzzzzzzzzzzzzzzzzzzzz', err)

  const removeFilter = (filterRemove) => {
    if (typeof filterRemove[1] === "boolean") {
      setSearchFilters({ ...searchFilters, [filterRemove[0]]: false });
    } else {
      setSearchFilters({ ...searchFilters, [filterRemove[0]]: "" });
    }
    goApplyFiltersWeb(setPage, setApplyFilters, applyFilters);
  };

  const goApplyFilters = () => {
    setPage({
      number: 1,
      total: 0,
      size: 21,
    });
    history.push("/properties");
  };

  const removeAllFilter = () => {
    // eslint-disable-next-line

    setPage({
      number: 1,
      total: 0,
      size: 21,
    });

    Object.entries(searchFilters).map((filter, index) => {
      typeof filter[1] === "boolean"
        ? setSearchFilters((prevState) => {
            return { ...prevState, [filter[0]]: false };
          })
        : setSearchFilters((prevState) => {
            return { ...prevState, [filter[0]]: "" };
          });
    });
    setSearchFilters((prevState) => {
      return {
        ...prevState,
        publishedFor: "sell",
        visual: "gallery",
      };
    });
    goApplyFiltersWeb(setPage, setApplyFilters, applyFilters);
  };

  const changePublishedFor = (e) => {
    setSearchFilters({ ...searchFilters, publishedFor: e.target.id });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setSearchFilters({ ...searchFilters, [name]: checked });
    } else if (type === "number") {
      setSearchFilters({ ...searchFilters, [name]: Math.abs(parseInt(value)) });
    } else if (type === "radio") {
      if (name === "visual") {
        setSearchFilters({ ...searchFilters, [name]: value });
      } else {
        setSearchFilters({ ...searchFilters, [name]: parseInt(value) });
      }
    } else {
      setSearchFilters({ ...searchFilters, [name]: value });
    }

    // console.log('Holaaaaaaaaaaaaaaa', searchFilters.currencyPrice)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let data = value

    if (data.length >= 12){
        data = data.slice(0,11); 
    } 

    var num = data.replace(/\./g, "");
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
 
      num = num.toString().split("").reverse().join("").replace(/^[\.]/, "");

      setSearchFilters({ ...searchFilters, [name]: num });
    }
  };

  useEffect(() => {
    if (searchFilters.currencyPrice.length > 0) {
      return setErr(false);
    }

    if (
      searchFilters.currencyPrice.length === 0 &&
      (searchFilters.priceLow.length > 0 || searchFilters.priceHigh.length > 0)
    ) {
      setErr(true);
    } else {
      setErr(false);
    }

    // console.log(format('122312312321321'))

    console.log(searchFilters);
  }, [searchFilters]);

  const handleUnCheck = (e) => {
    const { name, value } = e.target;
    // eslint-disable-next-line
    if (value == searchFilters[name]) {
      setSearchFilters({ ...searchFilters, [name]: "" });
    }
  };

  const [porpDic, setPorpDic] = useState({});
  const [acomDic, setAcomDic] = useState({});

  useEffect(() => {
    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split("-");
    const BASE_URL = URL + `dictionaries/${lang[0]}`;

    axios.get(BASE_URL).then((response) => {
      setPorpDic(response.data.responseData.dict.propertyTypes);
      setAcomDic(response.data.responseData.dict.accommodationsDict);
    });
  }, []);

  const [live, setLive] = useState(true);
  const [expensesLive, setExpensesLive] = useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    setLive(
      searchFilters.propertyType === "" ||
        searchFilters.propertyType === "casa" ||
        searchFilters.propertyType === "casaCountry" ||
        searchFilters.propertyType === "quinta" ||
        searchFilters.propertyType === "duplexPh" ||
        searchFilters.propertyType === "condoHousing" ||
        searchFilters.propertyType === "departamento"
    );
    setExpensesLive(
      searchFilters.propertyType === "" ||
        searchFilters.propertyType === "departamento" ||
        searchFilters.propertyType === "casaCountry" ||
        searchFilters.propertyType === "oficina" ||
        searchFilters.propertyType === "condoHousing" ||
        searchFilters.propertyType === "duplexPh" ||
        searchFilters.propertyType === "cochera"
    );
  });

  return (
    <div
      className="formFilterAllDetails"
      style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}
    >
      {err && (
        <ModalWarning
          title=""
          text="¡Para poder ingresar un rango de precio minimo y maximo primero debes seleccionar en que tipo de moneda queres visualizar las propiedas!"
          // acept='aceptar'
          cancel="ACEPTAR"
          // image={reportUser}
          noButton={true}
          close={true}
          openModal={err}
          // actionButton={sendReport}
        />
      )}
      <div className="filters-on">
        <h1 className="title-filters-on">Filtros aplicados</h1>
        <button
          className="apply-filters"
          onClick={
            screenSizeWidth < 1030
              ? goApplyFilters
              : () => goApplyFiltersWeb(setPage, setApplyFilters, applyFilters)
          }
        >
          {" "}
          Aplicar filtros
        </button>
        <button className="clear-all-filters" onClick={removeAllFilter}>
          <img className="trash-img" src={trash} alt={trash} />
          Limpiar filtros
        </button>
      </div>
      <div className="allFilterActive">
        {Object.entries(searchFilters).map(
          (filter, index) => (
            // eslint-disable-next-line
            (filter[0] === "furnished" || filter[0] === "country") &&
              console.log(filter),
            filter[0] !== "publishedFor" &&
              filter[0] !== "visual" &&
              (filter[1] || filter[1] === 0) && (
                <div className="filterActive" key={filter[0]}>
                  <div className="center">
                    {typeof filter[1] === "boolean" ? (
                      <span>{acomDic[filter[0]]}</span>
                    ) : typeof filter[1] === "number" ? (
                      filter[1] === -1 ? (
                        <span>Monoambiente</span>
                      ) : (
                        <span>
                          {acomDic[filter[0]]}: {filter[1]}
                        </span>
                      )
                    ) : filter[0] === "propertyType" ? (
                      <span>{porpDic[filter[1]]}</span>
                    ) : (
                      <span>{filter[1]}</span>
                    )}
                    <CloseIcon
                      className="delete-icon"
                      onClick={() => removeFilter(filter)}
                    />
                  </div>
                </div>
              )
          )
        )}
      </div>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Tipo de Operación</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className="all-tabs-filter">
            <div
              className={
                searchFilters.publishedFor === "rent"
                  ? "tab-filter active"
                  : "tab-filter"
              }
            >
              <label id="rent" onClick={changePublishedFor}>
                alquiler
              </label>
            </div>
            <div
              className={
                searchFilters.publishedFor === "sell"
                  ? "tab-filter active"
                  : "tab-filter"
              }
            >
              <label id="sell" onClick={changePublishedFor}>
                venta
              </label>
            </div>
            <div
              className={
                searchFilters.publishedFor === "tmpRent"
                  ? "tab-filter active"
                  : "tab-filter"
              }
            >
              <label id="tmpRent" onClick={changePublishedFor}>
                temporario
              </label>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr className="division-line"></hr>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Tipo de Propiedad</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className="container-living">
            <PropertyTypesSelector
              stateContext={searchFilters}
              handleChange={handleChange}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr className="division-line"></hr>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Ubicación</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className="container-living">
            <Location
              stateContext={searchFilters}
              setStateContext={setSearchFilters}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr className="division-line"></hr>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Tipo de Visualización</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className="container-living">
            <div className="container-enviroments">
              <label className="checks">
                <input
                  type="radio"
                  value="gallery"
                  checked={searchFilters.visual === "gallery"}
                  name="visual"
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
                <p className="label-check">Galería</p>
              </label>
              <label className="checks">
                <input
                  type="radio"
                  value="list"
                  checked={searchFilters.visual === "list"}
                  name="visual"
                  onChange={handleChange}
                />
                <span className="checkmark"></span>
                <p className="label-check">Lista</p>
              </label>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr className="division-line"></hr>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Tipo de Moneda Precio</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className={err ? "container-living-error" : "container-living"}>
            <select
              name="currencyPrice"
              value={searchFilters.currencyPrice}
              onChange={handleChange}
            >
              <option value="">Tipo de moneda</option>
              <option value="ARS">Pesos</option>
              <option value="USD">Dólares</option>
            </select>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <hr className="division-line"></hr>
      <ExpansionPanel className="expansion-panel" defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
          id="panel1a-header"
        >
          <span className="title-panel">Precio</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-info">
          <div className="container-price-between">
            <label className="price-between">Desde</label>
            <input
              className={
                err
                  ? "inputSearch input-error-filter"
                  : "inputSearch inputSearch-price-between"
              }
              type="text"
              name="priceLow"
              placeholder="1234"
              value={searchFilters.priceLow ? searchFilters.priceLow : ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="container-price-between">
            <label className="price-between">Hasta</label>
            <input
              className={
                err
                  ? "inputSearch input-error-filter"
                  : "inputSearch inputSearch-price-between"
              }
              type="text"
              name="priceHigh"
              placeholder="1234"
              value={searchFilters.priceHigh ? searchFilters.priceHigh : ""}
              onChange={handleInputChange}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {expensesLive && (
        <div>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Tipo de Moneda Expensas</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-living">
                <select
                  name="currencyExpenses"
                  value={searchFilters.currencyExpenses}
                  onChange={handleChange}
                >
                  <option value="">Tipo de moneda</option>
                  <option value="ARS">Pesos</option>
                  <option value="USD">Dólares</option>
                </select>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Expensas</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-price-between">
                <label className="price-between">Desde</label>
                <input
                  className="inputSearch inputSearch-price-between"
                  type="number"
                  name="expensesLow"
                  placeholder="1234"
                  value={
                    searchFilters.expensesLow ? searchFilters.expensesLow : ""
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="container-price-between">
                <label className="price-between">Hasta</label>
                <input
                  className="inputSearch inputSearch-price-between"
                  type="number"
                  name="expensesHigh"
                  placeholder="1234"
                  value={
                    searchFilters.expensesHigh ? searchFilters.expensesHigh : ""
                  }
                  onChange={handleChange}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      {live && (
        <div>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Dormitorios</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-room-bath">
                <label className="checks">
                  <input
                    type="radio"
                    value={-1}
                    checked={searchFilters.hasBedroomsNumber === -1}
                    name="hasBedroomsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">M</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={1}
                    checked={searchFilters.hasBedroomsNumber === 1}
                    name="hasBedroomsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">1+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={2}
                    checked={searchFilters.hasBedroomsNumber === 2}
                    name="hasBedroomsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">2+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={3}
                    checked={searchFilters.hasBedroomsNumber === 3}
                    name="hasBedroomsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">3+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={4}
                    checked={searchFilters.hasBedroomsNumber === 4}
                    name="hasBedroomsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">4+</p>
                </label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Baños</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-room-bath">
                <label className="checks">
                  <input
                    type="radio"
                    value={1}
                    checked={searchFilters.toiletsNumber === 1}
                    name="toiletsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">1+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={2}
                    checked={searchFilters.toiletsNumber === 2}
                    name="toiletsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">2+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={3}
                    checked={searchFilters.toiletsNumber === 3}
                    name="toiletsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">3+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={4}
                    checked={searchFilters.toiletsNumber === 4}
                    name="toiletsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">4+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={5}
                    checked={searchFilters.toiletsNumber === 5}
                    name="toiletsNumber"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">5+</p>
                </label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Cochera</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-room-bath">
                <label className="checks">
                  <input
                    type="radio"
                    value={1}
                    checked={searchFilters.parkingPlaces === 1}
                    name="parkingPlaces"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">1+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={2}
                    checked={searchFilters.parkingPlaces === 2}
                    name="parkingPlaces"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">2+</p>
                </label>
                <label className="checks">
                  <input
                    type="radio"
                    value={3}
                    checked={searchFilters.parkingPlaces === 3}
                    name="parkingPlaces"
                    onClick={handleUnCheck}
                    onChange={handleChange}
                  />
                  <p className="checkmark">3+</p>
                </label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      {searchFilters.propertyType !== "terreno" && (
        <div>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Superficie cubierta</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-area-between">
                <label className="area-between">Desde</label>
                <input
                  className="inputSearch inputSearch-area-between"
                  type="number"
                  name="edifiedAreaLow"
                  placeholder="número"
                  value={
                    searchFilters.edifiedAreaLow
                      ? searchFilters.edifiedAreaLow
                      : ""
                  }
                  onChange={handleChange}
                />
                <label className="area-between">
                  m<sup>2</sup>
                </label>
              </div>
              <div className="container-area-between">
                <label className="area-between">Hasta</label>
                <input
                  className="inputSearch inputSearch-area-between"
                  type="number"
                  name="edifiedAreaHigh"
                  placeholder="número"
                  value={
                    searchFilters.edifiedAreaHigh
                      ? searchFilters.edifiedAreaHigh
                      : ""
                  }
                  onChange={handleChange}
                />
                <label className="area-between">
                  m<sup>2</sup>
                </label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      <div>
        <hr className="division-line"></hr>
        <ExpansionPanel className="expansion-panel">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
            id="panel1a-header"
          >
            <span className="title-panel">Superficie total</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="expansion-info">
            <div className="container-area-between">
              <label className="area-between">Desde</label>
              <input
                className="inputSearch inputSearch-area-between"
                type="number"
                name="totalAreaLow"
                placeholder="número"
                value={
                  searchFilters.totalAreaLow ? searchFilters.totalAreaLow : ""
                }
                onChange={handleChange}
              />
              <label className="area-between">
                m<sup>2</sup>
              </label>
            </div>
            <div className="container-area-between">
              <label className="area-between">Hasta</label>
              <input
                className="inputSearch inputSearch-area-between"
                type="number"
                name="totalAreaHigh"
                placeholder="número"
                value={
                  searchFilters.totalAreaHigh ? searchFilters.totalAreaHigh : ""
                }
                onChange={handleChange}
              />
              <label className="area-between">
                m<sup>2</sup>
              </label>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      {searchFilters.propertyType !== "galpon" &&
        searchFilters.propertyType !== "terreno" &&
        searchFilters.propertyType !== "cochera" && (
          <div>
            <hr className="division-line"></hr>
            <ExpansionPanel className="expansion-panel">
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
                id="panel1a-header"
              >
                <span className="title-panel">Ambientes</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="expansion-info">
                <div className="container-enviroments">
                  {accommodations.map(
                    (item, index) =>
                      item.condition.search &&
                      item.condition.transaction.includes(
                        searchFilters.publishedFor === "tmpRent"
                          ? "tmp"
                          : searchFilters.publishedFor
                      ) &&
                      item.condition.propertyType.includes(
                        searchFilters.propertyType
                      ) && (
                        <label className="checks" key={index}>
                          <input
                            type="checkbox"
                            checked={searchFilters[item.attribute]}
                            name={item.attribute}
                            onChange={handleChange}
                          />
                          <span className="checkmark"></span>
                          <p className="label-check">
                            {acomDic[item.attribute]}
                          </p>
                        </label>
                      )
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
      {searchFilters.propertyType !== "galpon" && (
        <div>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Comodidades</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-enviroments">
                {amenities.map(
                  (item, index) =>
                    item.condition.search &&
                    item.condition.transaction.includes(
                      searchFilters.publishedFor === "tmpRent"
                        ? "tmp"
                        : searchFilters.publishedFor
                    ) &&
                    item.condition.propertyType.includes(
                      searchFilters.propertyType
                    ) && (
                      <label className="checks" key={index}>
                        <input
                          type="checkbox"
                          checked={searchFilters[item.attribute]}
                          name={item.attribute}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <p className="label-check">{acomDic[item.attribute]}</p>
                      </label>
                    )
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      {searchFilters.furnished && (
        <div>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Amoblado</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-enviroments">
                {furnishedAttributes.map(
                  (item, index) =>
                    item.condition.search && (
                      <label className="checks" key={index}>
                        <input
                          type="checkbox"
                          checked={searchFilters[item.attribute]}
                          name={item.attribute}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <p className="label-check">{acomDic[item.attribute]}</p>
                      </label>
                    )
                )}
              </div>
              {furnishedBeds.map(
                (item, index) =>
                  item.condition.search && (
                    <Fragment key={index}>
                      <span className="title-panel">
                        {acomDic[item.attribute]}
                      </span>
                      <div className="container-room-bath">
                        <label className="checks">
                          <input
                            type="radio"
                            value={1}
                            checked={searchFilters[item.attribute] === 1}
                            name={item.attribute}
                            onClick={handleUnCheck}
                            onChange={handleChange}
                          />
                          <p className="checkmark">1</p>
                        </label>
                        <label className="checks">
                          <input
                            type="radio"
                            value={2}
                            checked={searchFilters[item.attribute] === 2}
                            name={item.attribute}
                            onClick={handleUnCheck}
                            onChange={handleChange}
                          />
                          <p className="checkmark">2</p>
                        </label>
                        <label className="checks">
                          <input
                            type="radio"
                            value={3}
                            checked={searchFilters[item.attribute] === 3}
                            name={item.attribute}
                            onClick={handleUnCheck}
                            onChange={handleChange}
                          />
                          <p className="checkmark">3</p>
                        </label>
                        <label className="checks">
                          <input
                            type="radio"
                            value={4}
                            checked={searchFilters[item.attribute] === 4}
                            name={item.attribute}
                            onClick={handleUnCheck}
                            onChange={handleChange}
                          />
                          <p className="checkmark">4</p>
                        </label>
                        <label className="checks">
                          <input
                            type="radio"
                            value={5}
                            checked={searchFilters[item.attribute] === 5}
                            name={item.attribute}
                            onClick={handleUnCheck}
                            onChange={handleChange}
                          />
                          <p className="checkmark">5</p>
                        </label>
                      </div>
                    </Fragment>
                  )
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      {searchFilters.publishedFor === "sell" && (
        <div>
          <hr className="division-line"></hr>
          <ExpansionPanel className="expansion-panel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
              id="panel1a-header"
            >
              <span className="title-panel">Cormercialización</span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-info">
              <div className="container-enviroments">
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={searchFilters.creditSuitable}
                    name="creditSuitable"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">apto crédito</p>
                </label>
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={searchFilters.salesContract}
                    name="salesContract"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">boleto de compraventa</p>
                </label>
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={searchFilters.inmediateDelivery}
                    name="inmediateDelivery"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">entrega inmediata</p>
                </label>
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={searchFilters.hasDeed}
                    name="hasDeed"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">escriturado</p>
                </label>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      <div className="filters-on">
        <button
          className="apply-filters-bottom"
          onClick={
            screenSizeWidth < 1030
              ? goApplyFilters
              : () => goApplyFiltersWeb(setPage, setApplyFilters, applyFilters)
          }
        >
          {" "}
          Aplicar filtros
        </button>
      </div>
    </div>
  );
};

export default function FilterAllDetails({ history }) {
  /* function goProperties() {
        history.push('/properties')
    } */
  return (
    <div>
      {screenSizeWidth < 1030 ? (
        <div className="topBar">
          {/* <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goProperties} /> */}
          <h3 style={{ paddingLeft: "25px" }}>Filtros</h3>
        </div>
      ) : null}
      <ExpansionPanels history={history} />
    </div>
  );
}
