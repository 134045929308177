import React, { useState } from 'react';
import '../ViewList.css';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import LEFT from '../../../../assets/images/whiteRightArrow.png'
import RIGHT from '../../../../assets/images/leftArrow.png';

import test1 from '../../../../assets/images/test.png';
import test2 from '../../../../assets/images/test_one.jpg'
import test3 from '../../../../assets/images/test_two.jpg';

const RightArrow = () => {
    return <img alt='arrow' src={RIGHT} />
}

const LeftArrow = () => {
    return <img alt='arrow' src={LEFT} />
}

const SwipeableViewList = ({ images, size }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    !images.length && 
    (images = [
        {
            link: test1,
            name: 'img1'
        },
        {
            link: test2,
            name: 'img2'
        },
        {
            link: test3,
            name: 'img3'
        }
    ])

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    return (
        <div className='swipeableViewListContainer'>
            <button size="small" onClick={handleBack} disabled={activeStep === 0} className={activeStep === 0 ? 'disabledButtonSwipeable' : 'swippeableButtonLeftViewList'} >
                {theme.direction === 'rtl' ? <LeftArrow /> : <RightArrow />}
            </button>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                maxsteps={maxSteps}
            >

                {images.map((step, index) => (
                    <div key={index} className={ 'swipeableImgContainerViewList'}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img src={step.link} alt='imagen' className={step.name} />
                        ) : null}
                    </div>
                ))}

            </SwipeableViews>

            <button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={activeStep === maxSteps - 1 ? 'disabledButtonSwipeable' : 'swippeableButtonRightViewList'}>
                {theme.direction === 'rtl' ? <RightArrow /> : <LeftArrow />}
            </button>
        </div>
    );
}

export default SwipeableViewList;