import React from 'react';
import './Appraiser.css';
import CountUp from 'react-countup';

const ResultAppraiser = 
({ param }) => {


    return (
        <div className='AppraiserResultCard'>
            <div className='AppraiserTextContainer'>
                <label> El valor de tu inmueble es de: </label>
                <p> 
                    {param.currency + ' ' }
                    <CountUp
                    end={param.value}
                    start={0}
                    duration={0.5}
                    separator='.'
                    decimal=","
                    preserveValue={true}
                    /> 
                </p>
                <label> Usando HiPropi te ahorrarías: </label>
                { param.publishedFor === 'sell' ?
                    <p> {param.currency + ' '} { param.value * 0.06 } </p>
                    : 
                    <p> Comisión inmobiliaria </p>
                }
            </div>
            {/* <div className='AppraiserImgResult'>
                <img src={PC} />
            </div>
            <div className='AppraiserEquivalentResult'>
                <label> o podráis comprarte: </label>
                <h3> ¡Una computadora nueva! </h3>
            </div> */}
        </div>
    )
}

export default ResultAppraiser;