import React from 'react';

const CustomSelect = ({ options, handleChange, defValue, tabindex }) => {
    return (
        <div>
            <select onChange={(e) => handleChange(e.target.value)} tabindex={tabindex && tabindex} >
                {defValue && <option value=''> {defValue} </option>}
                {options.map((option, index) =>
                    <option
                        id={option.id}
                        value={option.value}
                        name={option.value}
                        key={index}>
                        {option.name}
                    </option>
                )}
            </select>
        </div>
    )
}

export default CustomSelect;