import React, { useState, useEffect } from 'react';
import './Verification.css';
import axios from 'axios';
import URL from '../../../../../config/urls';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from 'react-reveal/Fade';
import TopAppBarWeb from '../../../../commons/TopAppBarWeb/TopAppBarWeb';
import TopAppBar from '../../../../commons/TopBar/TopAppBar'

const EmailVerificationInProfile = ({ history }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
    }));

    const classes = useStyles();

    let token = localStorage.getItem('token')
    const headers = { headers: { "token": token } };

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];

    const [ checkEmail, setCheckEmail ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ disabled, setDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ circularProgress, setCircularProgress ] = useState(false);

    useEffect(() => {
        axios.get(URL + 'users/' + userIdFromUrl, headers)
        .then(response => {
          setEmail(response.data.responseData.email)
        })
        .catch(e => {
            console.log(e);
        })
        // eslint-disable-next-line
      }, [email])

    function handleCheckEmail(response) {
        if(response === 'yes') {
            setCheckEmail(true)
        } else {
            history.push('/myprofile/mydata?userId=' + userIdFromUrl)
        }
    }

    async function sendEmail() {
        setDisabled(true);
        setLoading(true);
        setCircularProgress(true);
        try {
            const response = await axios.post(URL + 'userVerifications/mail', 
                            { email: email }, { headers: { token } })
            if(response) {
                setTimeout(() => setCircularProgress(false), 500);
            }
        } catch(e) {
            setTimeout(() => setLoading(false), 500)
            setDisabled(false);
        }
    }


    let screenSizeWidth = window.innerWidth;
    
    return (
        <div>
            <div>
             {screenSizeWidth > 1030 ? <TopAppBarWeb history={history}/> : <TopAppBar history={history} />}
             </div>
             <div className='emailVerificationContainer'>
            <BlueTopBar 
            title='Verificación'
            link={ '/myprofile/verificated?userId=' + userIdFromUrl }
            />
            <Fade right>
            <div className='emailVerificationContent'>
                <div className='emailVerificationComponent' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <div className='emailVerificationTitle'>
                        <h1> Validá tu correo electrónico </h1>
                    </div>
                    <p className='p-email-validate-text'>
                    ¡Necesitamos que verifiques tu cuenta!
                    Esto te permitirá validar tu cuenta y poder contactarte con
                    aquellos propietarios que sólo acepten mensajes de usuarios validados.
                    </p>

                    { !checkEmail && 
                    <>
                        <div className='validate-input-container'>
                            <label className='p-email-validate-text' style={{ marginTop: 10, marginBottom: 10 }}>
                                ¿Esta es tu cuenta de correo electrónico?
                            </label>
                            <input disabled value={ email && email } />
                        </div>
                        <div className='buttonContainerEmailVerification'>
                            <button onClick={ (e) => handleCheckEmail('no')}>Modificar</button>
                            <button onClick={ (e) => handleCheckEmail('yes')}>Sí</button>
                        </div>
                    </>
                    }                        
                    { checkEmail &&
                    <>
                        <p className='p-email-validate-text'>
                        Haz click en el siguiente botón, y te enviaremos un mensaje a tu casilla de correo. 
                        Dentro del mismo habrá un link que te habilitará tu cuenta de HiPropi.
                        </p>
                        <div className='buttonSendEmailContainer'>
                            <button 
                            disabled={ disabled ? true : false }
                            onClick={sendEmail} 
                            className={ disabled ? 'sendEmailValidationTrue' : 'sendEmailValidation' }> 
                                Enviar mail 
                            </button>
                        </div>
                    </>
                    }
                    { loading &&
                        <Backdrop className={classes.backdrop} open={loading}>
                            { circularProgress ?
                            <CircularProgress color="inherit" />
                            :
                            <div className='verifyModalContainer'>
                                <div className='verifyModalContent'>
                                    <h3> ¡Hemos enviado un correo a tu casilla! </h3>
                                    <label> Revisa tu bandeja de entrada y sigue los pasos que se 
                                            detallan en él. 
                                    </label>
                                    <button onClick={ () => setLoading(false) } > Cerrar </button>
                                </div>
                            </div>
                            }
                        </Backdrop>
                    }
                </div>
            </div>
            </Fade>
        </div>
        </div>
    )
};

export default EmailVerificationInProfile;