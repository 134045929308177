import 'date-fns';
import React, {useState} from 'react';
import Select from '../Common/Select/Select'

import DatePicker from '../Common/DatePicker/DatePicker'
import URL from '../../../../config/urls'
import Chart from '../Graficos/Grafico'
import RouterColumn from '../RouterColumn/RouterColumn'

import './Usuario.css'  


export default function Usuarios() {


  let word = [{ nombre: "Nuevos", link: "/admin/nuevo"}]

  

  return (
 <div>
        <RouterColumn word={"Usuarios Conectados"} />
        {/* <Chart /> */}
    
        <div>
          <Select Word={word} palabra={"Conectados"} />
        </div>
        <div>
          <DatePicker componente={"conectUsers"} palabra1={"USUARIOS"} palabra2={"CONECTADOS"} style={{marginLeft: "800px", marginRight: "50px"}} />
        </div>

    </div>
  );
}