import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Context } from '../../../Context';
import './NewProperty.css';
import TopNewProperty from './TopNewProperty';
import TopStepper from './TopStepper';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import axios from 'axios';
import URL from '../../../config/urls';
import NewPropertyTip from './NewPropertyTip';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import { accommodations, amenities, furnishedAttributes, furnishedBeds } from '../../../data/amenitesAccommodationsData.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const NewProperty4 = ({ history }) => {
    const { newProperty, setNewProperty } = useContext(Context);
    const [loaderNext, setLoaderNext] = useState(false);
    const [acomDic, setAcomDic] = useState(null);

    let transactionName = '';

    //Condition inside amenities has to match with the transaction name
    if (newProperty.sell && newProperty.rent) {
        transactionName = 'sell&rent';
    } else if (newProperty.sell) {
        transactionName = 'sell';
    } else if (newProperty.rent) {
        transactionName = 'rent';
    } else if (newProperty.tmpRent) {
        transactionName = 'tmp';
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setNewProperty({ ...newProperty, [name]: checked })
        } else if (type === 'radio') {
            setNewProperty({ ...newProperty, [name]: parseInt(value) });
        } else {
            setNewProperty({ ...newProperty, [name]: value });
        }
    }



    useEffect(() => {
        let lang = navigator.language || navigator.userLanguage;
        lang = lang.split('-');
        //Lang ES
        const BASE_URL = URL + `dictionaries/${lang[0]}`;

        axios.get(BASE_URL)
            .then(response => {
                //Setting the names in spanish
                setAcomDic(response.data.responseData.dict.accommodationsDict);
            })
    }, []);

    const handleUnCheck = (e) => {
        const { name, value } = e.target;
        // eslint-disable-next-line
        if (value == newProperty[name]) {
            setNewProperty({ ...newProperty, [name]: '' })
        }
    }

    const goNext = () => {

        let params =
        {
            mainData: {
                data: {
                    accommodations: {},
                    amenities: {},
                    furnished: {}
                },
            }
        }

        accommodations.map((item) =>
            (item.condition.transaction.includes(transactionName) &&
                item.condition.propertyType.includes(newProperty.propertyType)) &&
            (params.mainData.data.accommodations[item.attribute] = newProperty[item.attribute])
        )

        amenities.map((item) =>
            (item.condition.transaction.includes(transactionName) &&
                item.condition.propertyType.includes(newProperty.propertyType)) &&
            (params.mainData.data.amenities[item.attribute] = newProperty[item.attribute])
        )

        furnishedAttributes.map((item) =>
            newProperty.furnished &&
            (params.mainData.data.furnished[item.attribute] = newProperty[item.attribute])
        )

        furnishedBeds.map((item) =>
            newProperty.furnished &&
            (params.mainData.data.furnished[item.attribute] = newProperty[item.attribute])
        )


        const token = localStorage.getItem('token');
        const BASE_URL = URL + 'properties/' + newProperty.propertyId;

        if (newProperty.propertyId) {
            setLoaderNext(true);
            axios.patch(BASE_URL, params, {
                headers: {
                    "token": token,
                }
            })
                .then(() => {
                    setLoaderNext(false);
                    history.push('/newproperty/5');
                });
        }
    }

    const goPrev = () => {
        history.push('/newproperty/3');
    }

    const tiptitle = 'Comodidades y amenities.';
    const tiptext = `Recordá que mientras más datos cargues, más fácil será para el que está buscando tener una referencia real de lo que estás ofreciendo.`;

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }
            <div className="new-property">
                <TopNewProperty step={4} action={newProperty.action} screenSizeWidth={screenSizeWidth} />
                <div className="properties-container-pc">
                    <TopStepper step={4} />
                    <div className="properties-inputs">
                        <div className="fields">
                            <h3 className="hide-mobile">Las características que destacan tu inmueble de los demás.</h3>
                            <p className="final-text hide-mobile">Mientras más datos incluyas, mayor alcance tendrá la publicación.</p>
                            {
                                (newProperty.propertyType !== 'galpon' &&
                                    newProperty.propertyType !== 'terreno' &&
                                    newProperty.propertyType !== 'cochera') &&
                                <div>
                                    <h3 className="small-title">Seleccione los ambientes que posee</h3>
                                    {acomDic !== null ? (
                                         <div className="container-enviroments">
                                         {accommodations.map((item, index) =>
                                             (item.condition.transaction.includes(transactionName) &&
                                                 item.condition.propertyType.includes(newProperty.propertyType)) &&
                                             <label className="checks" key={index} >
                                                 <input type="checkbox" checked={newProperty[item.attribute]} name={item.attribute} onChange={handleChange} />
                                                 <span className="checkmark"></span>
                                                 <p className="label-check">{acomDic[item.attribute]}</p>
                                             </label>
                                         )}
                                     </div>
                                    ) : (
                                         <div className="center-circular-progress">
                                        <CircularProgress/>
                                        </div>
                                    )}
                                   
                                </div>
                            }
                            {
                                newProperty.propertyType !== 'galpon' &&
                                <div>
                                    <h3 className="small-title">Seleccione las comodidades que posee</h3>
                                    {acomDic !== null ? (
                                         <div className="container-enviroments">
                                         {amenities.map((item, index) =>
                                             (item.condition.transaction.includes(transactionName) &&
                                                 item.condition.propertyType.includes(newProperty.propertyType)) &&
                                             <label className="checks" key={index}>
                                                 <input type="checkbox" checked={newProperty[item.attribute]} name={item.attribute} onChange={handleChange} />
                                                 <span className="checkmark"></span>
                                                 <p className="label-check">{acomDic[item.attribute]}</p>
                                             </label>
                                         )}
                                     </div>
                                    ) : (
                                         <div className="center-circular-progress">
                                        <CircularProgress/>
                                        </div>
                                    )}
                                   
                                </div>
                            }
                            {
                                newProperty.furnished &&
                                <div>
                                    <h3 className="small-title">Seleccione los atributos que posee el amoblado</h3>
                                    {acomDic !== null ? (
                                        <div className="container-enviroments">
                                        {furnishedAttributes.map((item, index) =>
                                            <label className="checks" key={index}>
                                                <input type="checkbox" checked={newProperty[item.attribute]} name={item.attribute} onChange={handleChange} />
                                                <span className="checkmark"></span>
                                                <p className="label-check">{acomDic[item.attribute]}</p>
                                            </label>
                                        )}
                                    </div>
                                    ) : (
                                         <div className="center-circular-progress">
                                        <CircularProgress/>
                                        </div>
                                    )}
                                    
                                    {acomDic !== null && 
                                    furnishedBeds.map((item, index) =>
                                        <Fragment key={index}>
                                            <p>{acomDic[item.attribute]}</p>
                                            <div className="container-radio-checks">
                                                <label className="checks">
                                                    <input type="radio" value={1} checked={newProperty[item.attribute] === 1} name={item.attribute} onClick={handleUnCheck} onChange={handleChange} />
                                                    <p className="checkmark">1</p>
                                                </label>
                                                <label className="checks">
                                                    <input type="radio" value={2} checked={newProperty[item.attribute] === 2} name={item.attribute} onClick={handleUnCheck} onChange={handleChange} />
                                                    <p className="checkmark">2</p>
                                                </label>
                                                <label className="checks">
                                                    <input type="radio" value={3} checked={newProperty[item.attribute] === 3} name={item.attribute} onClick={handleUnCheck} onChange={handleChange} />
                                                    <p className="checkmark">3</p>
                                                </label>
                                                <label className="checks">
                                                    <input type="radio" value={4} checked={newProperty[item.attribute] === 4} name={item.attribute} onClick={handleUnCheck} onChange={handleChange} />
                                                    <p className="checkmark">4</p>
                                                </label>
                                                <label className="checks">
                                                    <input type="radio" value={5} checked={newProperty[item.attribute] === 5} name={item.attribute} onClick={handleUnCheck} onChange={handleChange} />
                                                    <p className="checkmark">5+</p>
                                                </label>
                                            </div>
                                        </Fragment>
                                    )
                                    }
                                    
                                </div>
                            }
                        </div>
                    </div>
                    <NewPropertyTip title={tiptitle} text={tiptext} />
                </div>
                <div className="buttons">
                    {loaderNext ?
                        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
                            <CircularProgress size={30} />
                        </div> :
                        <>
                            <button className="prev" type="button" onClick={goPrev}>atrás</button>
                            <button className="next" type="submit" onClick={goNext}>siguiente</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewProperty4;
