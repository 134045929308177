import React from "react";
import TopAppBarWeb from "../../commons/TopAppBarWeb/TopAppBarWeb";
import TopAppBar from "../../commons/TopBar/TopAppBar";
import { MobilePayment } from "./MobilePayment";

import { WebPayment } from "./WebPayment";

export const ToWaysToPay = ({ history }) => {
  let screenSizeWidth = window.innerWidth;

  return (
    <div>
      {screenSizeWidth > 1030 ? (
        <TopAppBarWeb history={history} />
      ) : (
        <TopAppBar title="Preguntas Frecuentes" link="/" />
      )}
        {screenSizeWidth > 1030 ? (
         <WebPayment />
      ) : (
        <MobilePayment />
      )}
     
    </div>
  );
};
