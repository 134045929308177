import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function TransitionAlerts({open, data}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    
    {
        data == "pass" && <Collapse in={open}>
        <Alert variant="filled" severity="success">
        Servicio modificado exitosamente.
       </Alert>
        </Collapse>
    }

    {
        data == "error" &&  <Collapse in={open}>
<Alert variant="filled" severity="error">
  Hubo un error al modificar el servicio.
</Alert>
        </Collapse>
    } 
        {
        data == "Foto" &&  <Collapse in={open}>
<Alert variant="filled" severity="error">
  Debe seleccionar un fotografo.
</Alert>
        </Collapse>
    } 

    </div>
  );
}