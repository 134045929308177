import React from 'react';
import '../../pages/Appraiser/Appraiser.css';

const CustomCheckbox = ({ param, attribute, handleChange, visible, classNames }) => {
    return (
        <div className='customCheckbox-wrapper'>
            <input 
                id={attribute.id}
                name={attribute.name}
                type='checkbox'
                checked={param === attribute.value ? true : false}
                className='appraiserCustombox-input'
                onChange={() => handleChange(attribute.value)}
                />

            <label
                htmlFor={attribute.id}
                className='appraiserCustombox-label' 
            />

            {visible && <h5 className={`appraiserCustombox-h5 ${classNames}`}
                onClick={() => handleChange(attribute.value)}
            > {attribute.name} </h5>}
        </div>
    )
}

export default CustomCheckbox;