import React, { useState, useEffect, Fragment } from 'react';
import './BuySignal.css';
import TopAppBarMobile from '../../../../../commons/TopBar/TopAppBar';
import { Stepper } from '../../../../../commons/Stepper/Stepper';
import whiteRightArrow from '../../../../../../assets/images/whiteRightArrow.png';
import greenLeftArrow from '../../../../../../assets/images/greenLeftArrow.png';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';
import Header from '../../../../../commons/HeaderWeb/HeaderWeb';
import StepByStep from '../../../../../commons/StepByStep/StepByStep';
import axios from 'axios';
import ViewGrid from '../../../../../commons/ViewGrid/ViewGrid';
import URL from '../../../../../../config/urls';

const BuySignalConfirm = ({ history }) => {

    const BASE_URL = URL + 'properties/propertyId/'

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [hour, setHour] = useState('');
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [date, setDate] = useState('');
    const [ prop, setProp ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [code, setcode] = useState('')
    const [telefono, settelefono] = useState('')


    let datos = []
    const [show, setshow] = useState(false)
    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token, } };

    useEffect(() => {
        let dataFromLocalStorage = localStorage.getItem('dataPersistence');
        let dataPersistence = JSON.parse(dataFromLocalStorage);
        if (dataPersistence) {
            setCountry(dataPersistence.country)
            setState(dataPersistence.state)
            setCity(dataPersistence.city)
            setStreet(dataPersistence.street)
            setNumber(dataPersistence.number)
            setHour(dataPersistence.hour)
            setSelectedProperties(dataPersistence.selectedProperties)
            setDate(dataPersistence.date)
            setcode(dataPersistence.code)
            settelefono(dataPersistence.telefono)
        }

        var ok = [];

     
        for(let i = 0; i < dataPersistence.selectedProperties.length; i++) {
            axios.get(BASE_URL + dataPersistence.selectedProperties[i], headers).then(response => {
                const { responseData } = response.data
                // setProp([...prop,responseData])
                datos.push(responseData)
                ok.push(responseData)
                setProp(...prop,ok)
                setTimeout(() => {
                    setshow(true)
                }, 3000)
            })
            
        }




    }, [])


    

    function handleOptions(value) {
        if (value === 'nextStep') {
            let dataFromLocalStorage = localStorage.getItem('dataPersistence');
            let dataPersistence = JSON.parse(dataFromLocalStorage);
            axios.post(URL + 'services/cartel', dataPersistence, { headers: { 'token': token } })
            .then(response =>
            console.log('response:', response))
            setLoading(true)
            localStorage.removeItem('dataPersistence')
            
           return setTimeout(() => history.push('/services/signal/buy/confirm/successful'), 500)
        } else {
            
            history.push('/services/signal/buy')
              
        }
    };

    let screenSizeWidth = window.innerWidth;
    return (
        <div>
            {screenSizeWidth > 1030 ?
                <TopAppWeb history={history} />
                :
                <TopAppBarMobile history={history} />
            }
            {screenSizeWidth < 1030 &&
                <div className='buyServicesHeaderContainer'>
                    <label> Adquisición de servicios: </label>
                    <Stepper title='Cartelería' byStep='2/2' steps={5} activeStep={4} />
                </div>
            }

            <div className='buyServicesBodyContainer'>
                <div className='buy-services-photographer-body-content'>
                    <div className='buy-services-signal-width'>
                        {screenSizeWidth > 1030 && <Header text='Servicios: Cartelería' w='75%' />}
                        <div className='buy-services-signal-body01'>
                            {screenSizeWidth > 1030 &&
                                <div className='buy-services-steps'>
                                    <StepByStep
                                        steps={[
                                            {
                                                name: 'Datos de la entrega',
                                                number: 1
                                            },
                                            {
                                                name: 'Confirmación',
                                                number: 2
                                            }
                                        ]}
                                        stepNumber={2}
                                    />
                                </div>
                            }
                            <div className='buy-services-body-confirm'>
                                <h2> Confirmación de los datos </h2>

                                <label> País <strong>*</strong></label>
                                <input defaultValue={country} disabled />

                                <label> Provincia <strong>*</strong></label>
                                <input defaultValue={state} disabled />

                                <label> Ciudad <strong>*</strong></label>
                                <input defaultValue={city} disabled />

                                <label> Nombre de la calle <strong>*</strong></label>
                                <input defaultValue={street} disabled />

                                <label> Número <strong>*</strong></label>
                                <input defaultValue={number} disabled />

                                <h4> Horario seleccionado </h4>
                                <label> Hora <strong>*</strong></label>
                                <input defaultValue={hour} disabled />

                                <label>  Texto de cartel <strong>*</strong></label>
                                <input defaultValue={ code } disabled />

                               {
                                  code == 'Telefono' && <div>
                               <label>  Numero de contacto <strong>*</strong></label>
                                <input defaultValue={ telefono } disabled />
                                  </div> 
                               } 

                                <label> Fecha <strong>*</strong></label>
                                <input defaultValue={date} disabled />

                                {selectedProperties.length === 1 ?
                                    <h4> Propiedad </h4>
                                    :
                                    <h4> Propiedades </h4>
                                }

{
                                                    // We want to match each property with the id passed previously
                                                    show && prop.map((propy, id) => (

                                                        // (property.servicesRequested && property.propertyId === id && property.servicesRequested.photography === false) && (

                                                            <Fragment key={id}>

                                                                <p><strong style={{color: "black"}} >Codigo de propiedad:</strong> <strong> {propy.propertyCode[0]} </strong></p>
                                                                <ViewGrid handleClick={() => null} property={propy} myproperty={false}
                                                                    publishedFor={
                                                                        propy.publishedFor.sell ? 'sell' :
                                                                        propy.publishedFor.rent ? 'rent' :
                                                                                'tmpRent'
                                                                    } />

                                                            </Fragment>
                                                        
                                                    ))
                                                }


                                <div className='buySignalButtonsContainer'>
                                   
                                        <button className='buySignalBackButton' onClick={() => handleOptions('back')} >
                                            <img alt='go back' src={greenLeftArrow} />
                                            <label> Volver </label>
                                        </button>
                                    
                                    <button
                                        className='buySignalNextButton'
                                   onClick={ () => handleOptions('nextStep')} >
                                        <label> Confirmar </label>
                                        <img alt='confirm' src={whiteRightArrow} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading &&
                    <div>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                }
         { show == false  &&
                                    <div>
                                        <Backdrop className={classes.backdrop} open={true}>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    </div> 
            }

            </div>

        </div>
    )
};

export default BuySignalConfirm;