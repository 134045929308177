import React, { useState } from "react";
import Info from "./info.jsx";
import URL from '../../../../config/urls'
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import axios from 'axios'
import "./form.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: '20%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: "fixed",
    bottom: "3%",
    left: "3%",
    zIndex: 10000, 
  },
}));


export default function Formulario({setDataPrincipal, DataPrincipal, setparagrapth, postBody, setimgPrincipal, imgPrincipal, date}) {

  const [create, setcreate] = useState(false)

  const classes = useStyles();

  const onChange = (e) => {

    setDataPrincipal({
      ...DataPrincipal,
      [e.target.name]: e.target.value
    })

  }

  const token = localStorage.getItem('tokenAdmin');


  const handleSend = () => {
    

    let data = {
      DataPrincipal,
      postBody,
      imgPrincipal,
    }

    try {
      
      axios.post(URL + "blog/createNote", data ,{ headers: { 'token': token } })
      .then(() => {
        setDataPrincipal({
          postTitle: "",
          postSubtitle: "",
          author:""
        })
        setcreate(true)
        setimgPrincipal([])
        setparagrapth([])

      })
      .catch(() => {
        console.log('Error')
      })

    } catch (error) {
      console.log(error)
    }

  }



  return (
    <div style={{ width: "400px" }}>
      <h3>Titulo</h3>
      <input
        type="text"
        className="data-input"
        placeholder="Titulo"
        name="postTitle"
        value={DataPrincipal.postTitle}
        onChange={onChange}
      />
      <h3>Subtitulo</h3>
      <input
        type="text"
        className="data-input"
        name="postSubtitle"
        placeholder="Subtitulo"
        onChange={onChange}
        value={DataPrincipal.postSubtitle}
      />
      <h3>Autor</h3>
      <input
        type="text"
        className="data-input"
        name="author"
        placeholder="Autor"
        onChange={onChange}
        value={DataPrincipal.author}
      />
      <h3>Informacion de la nota</h3>
      <Info setparagrapth={setparagrapth} postBody={postBody}/>

      <button
        className="buttonSearch"
        style={{ width: "400px", marginTop: "30px" }}
        onClick={() => handleSend()}
      >
        
        CREAR
      </button>
      {
        create && <div className={classes.root}>

        <Alert onClose={() => setcreate(false)} variant="filled" severity="success">
          Creado exitosamente.
        </Alert>
      </div>
      }
    </div>
  );
}
