import React, { useState, useEffect } from 'react';
import './YourData.css'
//import { Link } from 'react-router-dom';
//import arrowBack from './../../../../../assets/images/arrowBack.svg';
import ErrorNotification from '../../../../commons/ErrorNotification/ErrorNotification';
import axios from 'axios';
import URL from './../../../../../config/urls';
import PhoneInput from 'react-phone-input-2';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../../../commons/Modals/ModalWarning';
import Fade from 'react-reveal/Fade';

const YourData = ({ history }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];
    let BASE_URL;

    if (token !== null) {
        BASE_URL = URL + 'users/' + userIdFromUrl
    }

    const [user, setUser] = useState({});
    const [file, setFile] = useState('');

    const [username, setUsername] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [avatar, setAvatar] = useState();
    const [dni, setDni] = useState();
    const [gender, setGender] = useState();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line 
        token = localStorage.getItem('token');
        axios.get(BASE_URL, headers)
            .then(response => {
                const dataUser = response.data.responseData;
                setUser(dataUser)
                setUsername(dataUser.username)
                setFirstName(dataUser.firstName)
                setLastName(dataUser.lastName)
                setEmail(dataUser.email)
                setPhone(dataUser.phone)
                setAvatar(dataUser.avatar)
                setDni(dataUser.dni)
                setGender(dataUser.gender)
            })
            .catch(e => {
                console.log(e);
            })
            // eslint-disable-next-line
    }, [])

    async function modifyUserPatch(modificatedUserObject) {
        setLoading(true);
        const formData = new FormData();
        for (let field in modificatedUserObject) {
            formData.append(`${field}`, modificatedUserObject[field]);
        };
        formData.append('file', file);
        try {
            token = localStorage.getItem('token');
            await axios.post(BASE_URL, formData, { headers: { 'Content-Type': 'multipart/form-data', 'token': token } })
            setTimeout(() => setLoading(false), 500)
            setTimeout(() => setOpenAlert({ ...openAlert, successful: true }), 500)
        } catch (e) {
            setTimeout(() => setLoading(false), 500)
            setTimeout(() => setOpenAlert({ ...openAlert, error: true }), 500);
        }
    }

    const modifyUser = (modificatedUserObject) => {
        setUser({ ...user, modificatedUserObject })
        modifyUserPatch(modificatedUserObject)
    }

    const [openAlert, setOpenAlert] = useState({
        successful: false,
        error: false
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        modifyUser({ username, firstName, lastName, email, phone, dni, gender });
    }

    const handleUpload = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(file);
        
        reader.onloadend = () => {
            setAvatar(reader.result);
        }
        reader.readAsDataURL(file)
        
    }

    const [errors, setErrors] = useState({
        usernameError: false,
        firstNameError: false,
        lastNameError: false,
        invalidMail: false,
        invalidPhone: false,
        invalidDni: false,
    })

    const isValidEmailAddress = (e) => {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e)) {
            setErrors({ ...errors, invalidMail: false });
        } else {
            setErrors({ ...errors, invalidMail: true });
        }
    }

    function isInvalidPhone(data) {
        if (data.length < 14) {
            setErrors({ ...errors, invalidPhone: true });
        } else {
            setErrors({ ...errors, invalidPhone: false });
        }
    }

    function showError(name, bool) {
        if (name === 'username') {
            setErrors({ ...errors, usernameError: bool });
        } else if (name === 'firstName') {
            setErrors({ ...errors, firstNameError: bool });
        } else if (name === 'lastName') {
            setErrors({ ...errors, lastNameError: bool });
        }
    }

    function dniIsShort(data) {
        if (data.length < 7 || data.length > 8) {
            setErrors({ ...errors, invalidDni: true })
        } else {
            setErrors({ ...errors, invalidDni: false })
        }
    }

    function ifHasErrors(data, inputName) {
        let cleanData = data.replace(/ /g, "");
        cleanData.trim();
        if (cleanData.length === 0) {
            showError(inputName, true);
        } else if (inputName === 'mail') {
            isValidEmailAddress(data);
        } else if (inputName === 'phone') {
            isInvalidPhone(data);
        } else {
            showError(inputName, false)
        }
    }

    let params =
        errors.usernameError === false &&
        errors.firstNameError === false &&
        errors.lastNameError === false &&
        errors.invalidMail === false &&
        errors.invalidPhone === false &&
        errors.invalidDni === false
        ;

    let screenSizeWidth = window.innerWidth;

    let genderData = [
        {
            value: 'female',
            name: 'Femenino'
        },
        {
            value: 'male',
            name: 'Masculino'
        }
    ]

    function handleCloseError() {
        setOpenAlert({ ...openAlert, error: false })
    };

    function handleChangesConfirm() {
        setOpenAlert({ ...openAlert, successful: false })
        if (screenSizeWidth < 1030) {
            history.push('/myprofile/?userId=' + userIdFromUrl)
        }
    };

    return (
        <div>
            {loading &&
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            

            <>

                {user === null ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                        <CircularProgress size={70} />
                    </div>

                    :

                    <Fade right>
                        <form onSubmit={handleSubmit} >
                            <div className='myData-content'>

                                {screenSizeWidth < 1030 ?
                                    <>
                                        {user.avatar === null ? <CircularProgress size={30} /> :
                                            <img alt='user avatar' src={avatar} className='img-user-avatar' />
                                        }
                                        <input type="file"
                                            onChange={(e) => handleUpload(e)}
                                            id='user-avatar'
                                            className='input-user-avatar'
                                        />
                                        <label htmlFor='user-avatar'
                                            className='label-user-avatar-yourdata'>
                                            CAMBIAR FOTO
                                </label>
                                    </>
                                    :
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label
                                                className='settings-profile-data'
                                                style={{ fontSize: 16, margin: 0 }}>
                                                Tus datos
                                    </label>
                                            <label
                                                className='settings-profile-title'
                                                style={{ marginBottom: 10, margin: 0 }}>
                                                Actualizar y modificar tu perfil
                                    </label>
                                        </div>

                                        <div className='img-avatar-upload-container'>
                                            {user.avatar === null ? <CircularProgress size={30} /> :
                                                <img  alt='user avatar' src={avatar} className='img-user-avatar' />
                                            }
                                            <input type="file"
                                                onChange={(e) => handleUpload(e)}
                                                id='user-avatar'
                                                className='input-user-avatar'
                                            />
                                            <label htmlFor='user-avatar'
                                                className='label-user-avatar-yourdata'>
                                                CAMBIAR FOTO
                                    </label>
                                        </div>
                                    </>
                                }

                                <div className='data-user-container-my-data'>
                                    <label
                                        htmlFor='user'
                                        style={{ marginTop: 15 }}
                                        className={errors.usernameError && 'errorsLabel'}>
                                        Usuario
                            </label>
                                    <input
                                        id='user'
                                        name='username'
                                        type='text'
                                        defaultValue={username}
                                        className={errors.usernameError && 'errorsInput'}
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                            ifHasErrors(e.target.value, e.target.name);
                                        }}
                                        onBlur={(e) => ifHasErrors(e.target.value, e.target.name)}
                                    />

                                    {errors.usernameError && <ErrorNotification text='El campo es obligatorio' />}

                                    <label
                                        htmlFor='firstName'
                                        className={errors.firstName && 'errorsLabel'}>
                                        Nombre
                            </label>
                                    <input id='firstName'
                                        name='firstName'
                                        type='text'
                                        defaultValue={firstName}
                                        className={errors.firstNameError && 'errorsInput'}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            ifHasErrors(e.target.value, e.target.name);
                                        }}
                                        onBlur={(e) => ifHasErrors(e.target.value, e.target.name)}
                                    />

                                    {errors.firstNameError && <ErrorNotification text='El campo es obligatorio' />}

                                    <label htmlFor='lastName'> Apellido </label>
                                    <input
                                        id='lastName'
                                        name='lastName'
                                        type='text'
                                        className={errors.lastNameError && 'errorsInput'}
                                        defaultValue={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            ifHasErrors(e.target.value, e.target.name);
                                        }}
                                        onBlur={(e) => ifHasErrors(e.target.value, e.target.name)}
                                    />

                                    {errors.lastNameError && <ErrorNotification text='El campo es obligatorio' />}

                                    <label htmlFor='dni'> DNI </label>
                                    <input
                                        id='dni'
                                        name='dni'
                                        type='number'
                                        className={errors.invalidDni && 'errorsInput'}
                                        defaultValue={dni}
                                        onChange={(e) => {
                                            setDni(e.target.value);
                                            dniIsShort(e.target.value);
                                        }}
                                        onBlur={(e) => dniIsShort(e.target.value)}
                                    />

                                    {errors.invalidDni && <ErrorNotification text='Dni inválido o incompleto' />}

                                    <label htmlFor='gender'> Género </label>
                                    <select value={gender} onChange={(e) => setGender(e.target.value)}>
                                        {genderData.map((g, index) =>
                                            <option
                                                key={index}
                                                value={g.value}>
                                                {g.name}
                                            </option>
                                        )}
                                    </select>

                                    <label htmlFor='mail'> Mail </label>
                                    <input
                                        id='mail'
                                        name='mail'
                                        type='email'
                                        defaultValue={email}
                                        className={errors.invalidMail && 'errorsInput'}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            ifHasErrors(e.target.value, e.target.name);
                                        }}
                                        onBlur={(e) => ifHasErrors(e.target.value, e.target.name)}
                                    />

                                    {errors.invalidMail && <ErrorNotification text='Mail incompleto o inválido' />}

                                    <label htmlFor='phone'> Teléfono </label>
                                    <PhoneInput
                                        country={'ar'}
                                        onlyCountries={['ar']}
                                        masks={{ar: '+.. ..........'}}
                                        value={phone}
                                        alwaysDefaultMask={false}
                                        containerStyle={{ width: '100%', marginTop: '5px' }}
                                        inputStyle={{ borderRadius: 20, height: 40, border: 'solid 1px #5963cc' }}
                                        buttonStyle={{ borderRadius: '20px 0 0 20px', border: 'solid 1px #5963cc' }}
                                        onChange={(e) => {
                                            setPhone(e);
                                            ifHasErrors(e, 'phone');
                                        }}
                                        id='phone'
                                        className={errors.invalidPhone && 'form-create-user-content-error input'}
                                        onBlur={(e) => ifHasErrors(e.target.value, 'phone')}
                                    />

                                    <div style={{ marginTop: 20 }}>
                                        {errors.invalidPhone &&
                                            <ErrorNotification text='Teléfono incompleto o inválido' />}
                                    </div>
                                </div>

                            </div>

                            {openAlert.error &&
                                <Modal
                                    noButton={true}
                                    title='¡Ha ocurrido un error!'
                                    text='No se han modificado tus datos correctamente. Intenta nuevamente.'
                                    acept='Cerrar'
                                    actionButton={() => handleCloseError}
                                    openModal={openAlert.error}
                                    cancel='false'
                                />
                            }

                            {openAlert.successful &&
                                <Modal
                                    noButton={true}
                                    title='¡Cambios realizados con éxito!'
                                    text='Se han modificado tus datos correctamente. Pronto podrás visualizarlos.'
                                    acept='Cerrar'
                                    actionButton={handleChangesConfirm}
                                    openModal={openAlert.successful}
                                    cancel='false'
                                />
                            }

                            <div className='your-data-container'>
                                <button
                                    disabled={params ? false : true}
                                    className={params ? 'security-button-fixed' : 'security-button-fixed-disabled'}
                                >
                                    Guardar
                        </button>
                            </div>

                        </form>
                    </Fade>
                }
            </>
        </div>
    )
};

export default YourData;