import React from 'react'
import Select from '../../Common/Select/Select'
import DatePicker from '../../PickerTable/PickerTable'
import RouterColumn from '../../RouterColumn/RouterColumn'

export default function Fotografo(){

    let word = [{ nombre: "Carteleria", link: "/admin/servicios"}, { nombre: "Contratos", link: "/admin/contratos"},  { nombre: "Asesor C.", link:"/admin/comercializacion" }]

    
  return (
    <div>
           <RouterColumn  />
   
           <div>
             <Select Word={word} palabra={"Fotografos"} />
           </div>
           <div>
             <DatePicker componente={"servicesRequest/photography"} palabra1={"FOTOGRAFOS"} palabra2={"SOLICITADOS"} style={{marginLeft: "800px", marginRight: "50px"}} />
           </div>
   
       </div>
  )

}