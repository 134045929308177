import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../Context'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import './Propiedades.css'
import Location from '../../../commons/Location/Location';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function DialogSelect({word, width}) {


  const { propSelect, setPropSelect } = useContext(Context);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { ciudad, provincia, condicion, tipo_prop } = propSelect

  const handleChange = (e) => {
    setPropSelect({
      ...propSelect,
      [e.target.name]: e.target.value
    })


  };


  const sendData = () => {
    // funct()
    setOpen(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(propSelect)
  return (
    <div>
      <button onClick={handleClickOpen} className="data-selected-admin" style={{border: 0, width:`${width}`, marginTop: "8px", backgroundColor: "transparent", outline: "none", cursor: "pointer"}} > <span style={{marginLeft: "15px"}} > { word } </span></button>
      
      {

      word == "Condición" && <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
      <DialogTitle className="data-selected-admin" >{word}</DialogTitle>
      <DialogContent>
        
        <form className={classes.container}>

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-dialog-select-label"> Condicion </InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              // id="demo-dialog-select"
              // value={condicion}
              name="condicion"
              onChange={handleChange}
              input={<Input />}
            >
              <MenuItem  value={"tmpRent"}>Alquiler temporario</MenuItem>
              <MenuItem  value={"rent"}>Alquiler</MenuItem>
              <MenuItem  value={"sell"}>Venta</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={sendData} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

      }

{

word == "Tipo de propiedad" && <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
<DialogTitle className="data-selected-admin" >{word}</DialogTitle>
<DialogContent>
  
  <form className={classes.container}>

    <FormControl className={classes.formControl}>
      <InputLabel id="demo-dialog-select-label"> Tipo </InputLabel>
      <Select
        labelId="demo-dialog-select-label"
        // id="demo-dialog-select"
        // value={tipo_prop}
        name="tipo_prop"
        onChange={handleChange}
        input={<Input />}
      >
        <MenuItem value={"casa"}>Casa</MenuItem>
        <MenuItem value={"casaCountry"}>Casa country</MenuItem>
        <MenuItem value={"cochera"}>Cochera</MenuItem>
        <MenuItem value={"condoHousing"}>Condominio</MenuItem>
        <MenuItem value={"departamento"}>Departamento</MenuItem>
        <MenuItem value={"duplexPh"}>Duplex-Ph</MenuItem>
        <MenuItem value={"galpon"}>Galpón</MenuItem>
        <MenuItem value={"inversion"}>Inversión</MenuItem>
        <MenuItem value={"localComercial"}>Local comercial</MenuItem>
        <MenuItem value={"loteOTerreno"}>Lote o terreno</MenuItem>
        <MenuItem value={"oficina"}>Oficina</MenuItem>
        <MenuItem value={"quinta"}>Quinta</MenuItem>
      </Select>
    </FormControl>
  </form>
</DialogContent>
<DialogActions>
  <Button onClick={handleClose} color="primary">
    Cancel
  </Button>
  <Button onClick={sendData} color="primary">
    Ok
  </Button>
</DialogActions>
</Dialog>

}

{
  word == "Ubicación" && <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
  <DialogTitle className="data-selected-admin" >{word}</DialogTitle>
  <DialogContent style={{width: "300px"}} >

  <Location
    stateContext={propSelect}
    setStateContext={setPropSelect}
    Selected={true}
  />
   
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="primary">
      Cancel
    </Button>
    <Button onClick={sendData} color="primary">
      Ok
    </Button>
  </DialogActions>
</Dialog>
}

      
    </div>
  );
}