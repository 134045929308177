import React from 'react';
import TopBar from '../../commons/BlueTopBar/BlueTopBar';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';

const QuestionDocs = ({ history }) => {

    const QuestionsAnswers = [
        {
            que: '1. Que es el pack documentos',
            ans: `Es un servicio pago en el cual podrás adquirir la documentación que necesites para vender y comprar una propiedad, alquiler o alquiler temporario.  También podrás consultar con un personal jurídico (abogado/corredor inmobiliario) cualquier inquietud sobre la comercialización de un inmueble (hasta 20 preguntas). 
            `
        },
        {
            que: '2. Que tipos de documentos puedo solicitar?',

        },
        { new: "Para compra o venta de un inmueble:"},
        { ora: "a. Seña"},
        { ora: "b. Boleto compraventa "},
         {ora: "c. Informes personales "},
         {ora: " d. Informes comerciales de las personas "},
         {ora: "e. Informe registral del inmueble "},
         {new: "Para alquiler:  "},
         {ora: "a. Seña de alquiler "},
         {ora: "b. Contrato de alquiler "},
         { ora: "c. Informe comerciales de inquilinos y garantes "},
         {ora: "d. Informe registral de los inmuebles como garantías"},
         {new: "Para alquiler temporario"},
        { ora: "a. Seña de reserva"},
        { ora: "b. Contrato de alquiler temporario"},
        {
            que: '3. Quienes pueden solicitar el servicio de documentacion?',
            ans: `Cualquier persona interesada, tanto vendedor como comprador.`
        },
        {
            que: '4. Es necesario que la propiedad a comercializar esté cargada en Hi Propi.com?',
            ans: `Si, es condición indispensable. `
        },
        {
            que: '5. Como adquiero mi pack documentos?',
            ans: `Lo confirmas aqui, en donde podras abonar tu servicio. Una vez recibido el pago, un asesor legal se pondrá en contacto con vos para brindarte la información necesaria. `
        },
        {
            que: '6. Como es la consulta jurídica online?',
            ans: `Es un servicio pago mediante el cual podrás disponer de un abogado especializado en el rubro. Allí podrás consultar y evacuar todas tus dudas, respecto a los aspectos legales de la comercialización de tu inmueble. Tendrás hasta 20 preguntas, y en menos de 48hs hábiles nos pondremos en contacto con vos para validar tu disponibilidad para coordinar la reunión. `
        },
        {
            que: '7. Que valor tienen los servicios de documentación?',
            
        },
        { new: "Para alquiler:"},
        { ora: "Seña $2000"},
        { ora: "Contrato alquiler $4000"},
         {prom: "Contratando el pack Alquiler completo $5000 (ahorrás $1000)"},

         {new: "Para venta:"},
         {ora: "Seña $2000"},
         {ora: "Boleto compra/venta $4000"},
        { prom: "Contratando el pack Venta completo $5000 (ahorrás $1000)"},
         {new: "Para alquiler temporario:"},
        { ora: "Seña $2000"},
        { ora: "Contrato alquiler $4000"},
        { prom: "Contratando el pack Alquiler completo $5000 (ahorrás $1000)"},
        { ora: "Informe personal (por persona) $1500"},
        { ora: "Informe comercial (por persona) $1500"},
        { ora: "Informe inmueble (por inmueble) $2500"},
        {
            que: '8. Es obligatorio contratar servicios para publicar propiedades en Hi Propi?',
            ans: `No, es opcional. Podés publicar sin contratar estos servicios. `
        },
        {
            que: '9. Cuál es el objetivo del pack documentos de Hi Propi?',
            ans: `El objetivo es ayudarte a comercializar tu inmueble de la manera mas transparente, segura y simple posible, a un costo muy bajo.`
        }
    ]

    let screenSizeWidth = window.innerWidth;

    return (
        <div  >
            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
            :
                <TopBar
                    title='Consulta Juridica'
                    link='/'
                />
            }
            <div className='services-web-container-card'  >
                <div className='services-web-content-card'>


                    <div className='servicesContainer'>
                    { screenSizeWidth > 1030 &&
                        <div className='report-title-container' style={{ margin: 'auto' }} style={{ marginTop: screenSizeWidth > 1030 ? "35px" : "0px", marginBottom: "30PX"}}>
                            <label> Consulta Juridica </label>
                        </div>
                    }
                        {/* <div className='servicesContentText'>
                            <label> Aquí encontrarás los servicios exclusivos de HiPropi que te ayudarán a comercializar tus inmuebles de manera directa y simple. </label>
                        </div> */}
                        <div className="text-preguntas-questions" >
                        {
                            QuestionsAnswers.map((e,index) => {
                                console.log(e)
                                return (
                                    <div>
                                    
                                        <strong className={ e.que ? "title-text-color-question" : ""}>{ e.que}</strong>
                                        <label className= { e.ans ? "label-question" : ""}>{e.ans}</label>
                                        <div style={{marginTop: "10px"}} >
                                        <strong  > {e.new} </strong>
                                        </div>
                                        <label className={ e.ora ? "ora-question" : ""} >{e.ora}</label>
                                        
                                        <strong className={e.prom ? "prom-questions" : ""} > {e.prom} </strong>
                                    </div>
                                    
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default QuestionDocs;