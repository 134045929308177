import React from 'react';
import './DeleteAccount.css'
import { Link } from 'react-router-dom';
import arrowBack from './../../../../../assets/images/arrowBack.svg';
import axios from 'axios';
import URL from '../../../../../config/urls';
import ModalWarning from '../../../../commons/Modals/ModalWarning';
import Fade from 'react-reveal/Fade';

const DeleteAccount = () => {

  let token = localStorage.getItem('token');
  const headers = { headers: { "token": token } };

  let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];
  let BASE_URL;

  if(token !== null) {
      BASE_URL = URL + 'users/' + userIdFromUrl
  }

  const handleSubmit = (e) => {
        e.preventDefault();
        axios.delete(BASE_URL, headers)
          .then(res => {
            localStorage.removeItem('token')
            setTimeout(window.location.href='/welcome', 2000);
          })
      }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
          { screenSizeWidth < 1030 && 
             <div className="topBar">
                <Link to={'/myprofile/?userId=' + userIdFromUrl}>
                  <img src={arrowBack} alt={arrowBack} className="filterIcon" />
                </Link>
                <h3> Borrar cuenta </h3>
            </div>
          }
           
          { screenSizeWidth > 1030 && 
            <div style={{ display: 'flex', flexDirection: 'column '}}>
              <label className='settings-profile-data' style={{ fontSize: 16 }}> Borrar cuenta </label>
              <label className='settings-profile-title' style={{ marginBottom: 10 }}> Eliminar tu cuenta de HiPropi </label>
            </div>
          }
          <div>
            <Fade right>
              <div className='delete-account-body-container'>

                  <label className='delete-account-title'> Estás a punto de borrar tu cuenta. </label>
                  <label className='delete-account-subtitle'> Lamentamos que te vayas, y esperamos que tu experiencia en Hi Propi haya sido satisfactoria. Y cuando desees volver, estaremos esperando con ganas de recibirte nuevamente para ayudarte a comercializa tus inmuebles o buscar tu próxima propiedad. 
                  </label>
                  <label className='delete-account-subtitle'>Gracias por todo, Equipo de Hi Propi</label>

                    <ModalWarning
                    styleButton='delete-account-button'
                    callToAction='Borrar'
                    title='¿Estás seguro de eliminar tu cuenta?'
                    text='Si eliminas tu cuenta, se perderán todas tus conversaciones,
                    propiedades favoritas y ya no podrás realizar ni recibir consultas.'
                    actionButton={handleSubmit}
                    acept='Sí, eliminar'
                    cancel='cancelar'
                    close={true}
                    /> 
            
              </div>
            </Fade>
          </div>
        </div>
    )
};

export default DeleteAccount; 