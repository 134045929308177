import React from 'react';
import './Swipeable.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import ARROW from '../../../../../assets/images/rightArrow.png';


const RightArrow = () => {
  return <img alt='arrow-right' src={ARROW} />
}

const LeftArrow = () => {
  return <img alt='arrow-left' src={ARROW} style={{transform: 'rotate(180deg)'}}/>
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Swipeable = ({ images }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <button size="small" onClick={handleBack} disabled={activeStep === 0} className='swippeableButtonLeft'>
        {theme.direction === 'rtl' ? <LeftArrow /> : <RightArrow /> }
      </button>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
          {images.map((step, index) => (
            <div key={index} className='imgContainerSwippeable'>
              {Math.abs(activeStep - index) <= 2 ? (
                <img className='img-swipeable' src={step} alt='imagen' />
              ) : null}
            </div>
          ))}

      </SwipeableViews>
      <button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className='swippeableButtonRight'>
        {theme.direction === 'rtl' ? <RightArrow /> : <LeftArrow /> }
      </button>
    </div>
  );
}

export default Swipeable;