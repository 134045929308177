import React, { useContext, useEffect, useState, Fragment, useCallback } from 'react';
import { Context } from '../../../Context';
import './NewProperty.css';
import TopNewProperty from './TopNewProperty';
import TopStepper from './TopStepper';
import CancelIcon from '@material-ui/icons/Cancel';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import Services from '../../commons/Services/Services';
import icon from '../../../assets/images/camera-service.svg';
import axios from 'axios';
import URL from '../../../config/urls';
import exclamation from '../../../assets/images/exclamation.svg';
import NewPropertyTip from './NewPropertyTip';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import CircularProgress from '@material-ui/core/CircularProgress';
import imageCompression from 'browser-image-compression';
import arrayMove from "array-move";
import { Modal } from '@material-ui/core';
import ModalWarning from '../../commons/Modals/ModalWarning'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from './ImgDialog'
import getCroppedImg from './cropper'
import { styles } from './styles'

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


const PrincipalImage = (props) => {

    // const BASE_URL_IMAGES = URL + 'properties/';

    return (
        <div className="principal-image">
            <h5>Tu foto de portada actual</h5>
            <img className='image-thumbnail' alt="imagen de la propiedad" src={props.image.link} />
            {/* <div className="description-image">
                <p className="name-image">{props.image.name}</p>
            </div> */}
            <CancelIcon className="deleteIcon" onClick={() => props.deleteImg(props.image.name)} />
        </div>
    )
}


const SecondaryImage = ({image, deleteImg,setdataImg, setimgName, seteditPhoto, setmodal}) => {

    const editImg = (name, edit, nameData) => {
        setdataImg(nameData)
        setimgName(name)
        seteditPhoto(edit)
        window.scrollTo(0, 400);
    }

    let screenSizeWidth = window.innerWidth;

    // const BASE_URL_IMAGES = URL + 'properties/';
    return (
        <div style={{display: "flex"}} >
        <div className="cutimage">
            
            <img className='image-thumbnail' alt="imagen de la propiedad" src={image.link} />
            
        </div>

        <div className='botones-newproperty' >
        <button className="deleteButtonNew" onClick={() => deleteImg(image.name)}  > { 'Eliminar' }</button>
        <button className="editButton" onClick={() => (editImg(image.link, true, image.name), setmodal(true))}>{  'Editar'  }</button>
        </div>

        </div> 
    )
}


const NewProperty2 = ({ history }) => {
    const { newProperty, setNewProperty } = useContext(Context);
    const [loaderNew, setLoaderNew] = useState(false);
    const [propertyImagesUploadingNumber, setpropertyImagesUploadingNumber] = useState()
    const [currentImageUploadingNumber, setcurrentImageUploadingNumber] = useState()
    const [uploadingFiles, setUploadingFiles] = useState(false)
    const [errorWhileUploadingPhoto, setErrorWhileUploadingPhoto] = useState(false)
    const [imagesList, setImagesList] = useState([])
    const [editPhoto, seteditPhoto] = useState(false)
    const [imgName, setimgName] = useState("")
    const [dataImg, setdataImg] = useState('')
    const [modal, setmodal] = useState(false)
    const [ViewFile, setsetViewFile] = useState('')

    const handleCloseOut = () => {
        setmodal(false)
    }

    const title = "Fotógrafo Validado";
    const text = "El servicio de fotógrafo validado es un servicio exclusivo de HiPropi que tiene como objetivo capturar las mejores imágenes para ayudarte a vender tu propiedad de manera exitosa y rápida.";
    const link = "/services/photographer";

    const token = localStorage.getItem('token');

    const headers = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
            "Access-Control-Allow-Origin": ['https://hipropi.com/', 'https://hipropi.firebaseapp.com/', 'http://localhost:3000/'],
            "Token": token,
        }
    }

    const actualImages = (nameDeleted) => {


        const BASE_URL = URL + 'properties/propertyId/' + newProperty.propertyId;
        axios.get(BASE_URL)
        .then(res => {
            let data = res.data.responseData;
            setTimeout(() => {
                setNewProperty({
                    ...newProperty,
                    low: data.images.low,
                    medium: data.images.medium,
                    high: data.images.high,
                });
            });
            setImagesList(data.images.high)
            if (nameDeleted) {
                // eslint-disable-next-line
                data.images.high.map(image => {
                    (nameDeleted === image.name) && actualImages();
                })
            }
            setLoaderNew(false);
        })
    }

    const [isMobile, setIsMobile] = useState(null)
    const [isSafari, setisSafari] = useState(false)

    useEffect(() => {
        actualImages();
        // eslint-disable-next-line
    }, [])

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    useEffect(() => {
        //Check user mobile browser

        let navigatorIsSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;

        if (navigatorIsSafari) {
            setisSafari(true)
        }

        //Android
        if (/android/i.test(userAgent)) {
            setIsMobile(true)
        }

        //iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true)
        }

        //Windows Phone
        if (/windows phone/i.test(userAgent)) {
            setIsMobile(true)
        }


    }, [userAgent, isSafari])

    const addImage = async (e) => {
        let files = e.target.files;
        setUploadingFiles(true)


        if (newProperty.propertyId) {

            const BASE_URL = URL + 'properties/images/' + newProperty.propertyId;


            for (let i = 0; i < files.length; i++) {
                let formData = new FormData();

                setpropertyImagesUploadingNumber(files.length)
                setcurrentImageUploadingNumber(i + 1)

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                try {
                    const compressedFile = await imageCompression(files[i], options);

                    // Since compressed file is an instance of Blob the backend will throw a type error. Thus, we have to add properties to this object so it can be sent correctly to our backend
                    var readyForBackendFile = new File([compressedFile], [files[i].name]);

                    formData.append(`img.${i}`, readyForBackendFile);

                    setLoaderNew(true);
                    setTimeout(() => {
                        setErrorWhileUploadingPhoto(true)
                        i = 16
                    }, 120000);
                    const res = await axios.post(BASE_URL, formData, headers)


                    if (res.data.responseData.rejectedForNoSpace) {
                        let element;
                        let iconError;
                        iconError = document.getElementById("icon-error");
                        iconError && iconError.remove();
                        document.getElementById('fields').scrollIntoView();
                        element = document.getElementById("img-length-max");
                        element.classList.add("error-img");
                        element.insertAdjacentHTML('afterbegin', `<img src=${exclamation} id="icon-error" class="icon-error" alt="Error" /> `);
                    }

                }

                catch (error) {
                    console.log(error);
                    setErrorWhileUploadingPhoto(true)
                    i = 16
                }
            } //For loop end

            actualImages();
            setUploadingFiles(false)

        }


    }


    const addImageCrop = async(files) => {
        
        // let files = new File([data], "myfile.jpg");

        if (newProperty.propertyId) {

            const BASE_URL = URL + 'properties/images/' + newProperty.propertyId;


            for (let i = 0; i < files.length; i++) {
                let formData = new FormData();

                setpropertyImagesUploadingNumber(files.length)
                setcurrentImageUploadingNumber(i + 1)

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                try {
                    const compressedFile = await imageCompression(files[i], options);

                    // Since compressed file is an instance of Blob the backend will throw a type error. Thus, we have to add properties to this object so it can be sent correctly to our backend
                    var readyForBackendFile = new File([compressedFile], [files[i].name]);
                    
                    
          
                    formData.append(`img.${i}`, readyForBackendFile);

                    setLoaderNew(true);
                    setTimeout(() => {
                        setErrorWhileUploadingPhoto(true)
                        i = 16
                    }, 120000);

      
                    const res = await axios.post(BASE_URL, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Acecpt": "*/*",
                            "Cache-Control": "no-cache",
                            "Access-Control-Allow-Origin": "*",
                            "Token": token,
                        }
                    })



                    if (res.data.responseData.rejectedForNoSpace) {
                        let element;
                        let iconError;
                        iconError = document.getElementById("icon-error");
                        iconError && iconError.remove();
                        document.getElementById('fields').scrollIntoView();
                        element = document.getElementById("img-length-max");
                        element.classList.add("error-img");
                        element.insertAdjacentHTML('afterbegin', `<img src=${exclamation} id="icon-error" class="icon-error" alt="Error" /> `);
                    }

                }

                catch (error) {
                    console.log(error);
                    setErrorWhileUploadingPhoto(true)
                    i = 16
                }
            } //For loop end

            actualImages();
            setUploadingFiles(false)

        }
    }



    const deleteImg = name => {


        const BASE_URL = URL + 'img/' + name;

        axios.delete(BASE_URL, {
            headers: {
                "Token": token,
            }
        })
            .then(res => {
                actualImages(name);
            })
    }

    const SortableImage = SortableElement(({image, deleteImg}) => {
        return (
            <SecondaryImage image={image} deleteImg={deleteImg}  seteditPhoto={seteditPhoto} setimgName={setimgName} setdataImg={setdataImg}  setmodal={ setmodal}/>
        )
    })


    const SortableImagesList = SortableContainer(({ images }) => {
        return (
          <ul>
            {images.map((image, i) => (
              <SortableImage image={image} index={i} key={i} deleteImg={deleteImg}/> 
            ))}
          </ul>
        )
    })

    const SortableImages = () => {
            const onSortEnd = ({ oldIndex, newIndex }) => {
                setImagesList(arrayMove(imagesList, oldIndex, newIndex))
            }
            return (<SortableImagesList images={imagesList} onSortEnd={onSortEnd} />)
    }


    const setPrincipalImage = async id => {
        // eslint-disable-next-line 
        const BASE_URL = URL + 'properties/images/' + newProperty.propertyId;

        let index = id;
        let imagesCopy = [...newProperty.low]
        imagesCopy = arrayMove(imagesCopy, index, 0);
        setNewProperty({
            ...newProperty,
            low: imagesCopy
        })

        // falta funcion de actualizar back con el nuevo array de imagenes
        /**if(newProperty.propertyId) {
            await axios.put(BASE_URL, images, {
                headers: {
                    "Token": token,
                }
            })
        }**/
    }

    const goNext = async () => {
        const BASE_URL = URL + 'properties/images/' + newProperty.propertyId;

        newProperty.propertyId && history.push('/newproperty/3');
        newProperty.propertyId && await axios.put(BASE_URL, imagesList, {
            headers: {
                "Token": token,
            }
        })
    }

    const goPrev = () => {
        history.push('/newproperty/1');
    }

    const checkErrorNext = () => {

        if (newProperty.low.length < 3) {
            let element;
            let iconError;
            iconError = document.getElementById("icon-error2");
            iconError && iconError.remove();
            document.getElementById('fields').scrollIntoView();
            element = document.getElementById("img-length-min")
            element.classList.add("error-img");
            element.insertAdjacentHTML('afterbegin', `<img src=${exclamation} id="icon-error2" class="icon-error" alt="Error" /> `);
        } else {
            goNext();
        }
    }

    const tiptitle = 'Carga de imágenes.';
    const tiptext = `Una buena sesión de fotos incrementa cerca de un 20% tus posibilidades de concretar tu venta o alquiler, por lo que te sugerimos que adquieras el servicio de Fotógrafo Profesional. 
    Pero si querés sacar vos tus fotos, lo mejor es que lo hagas de día, con todas las ventas abiertas, y luces prendidas. No ocultes detalles negativos (como humedades o roturas), ya que es mejor filtrar en esta instancia a tus visitantes, a que te visiten y sientan que han perdido su tiempo. 
    Y si tu propiedad está amoblada, no olvides acomodar y limpiar todo antes de las fotos.`;

    let screenSizeWidth = window.innerWidth;

    const Demo = ({ classes }) => {
        const [crop, setCrop] = useState({ x: 0, y: 0 })
        const [rotation, setRotation] = useState(0)
        const [zoom, setZoom] = useState(1)
        const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
        const [croppedImage, setCroppedImage] = useState(null)
      
        const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
          setCroppedAreaPixels(croppedAreaPixels)
        }, [])
      


        const showCroppedImage = useCallback(async () => {

            deleteImg(dataImg)

          try {
            const croppedImage = await getCroppedImg(
              imgName,
              dataImg,
              croppedAreaPixels,
              rotation
            )
            seteditPhoto(false)
            setCroppedImage(croppedImage)
            addImageCrop([croppedImage])

          } catch (e) {
            console.error(e)
          }
        }, [croppedAreaPixels, rotation])
      
        const onClose = useCallback(() => {
          setCroppedImage(null)
        }, [])


        return (
          <div>
            <div className={classes.cropContainer}>
              <Cropper
                image={imgName}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={10 / 10}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
     
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <br/>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Rotación
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ root: classes.slider }}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </div>
              <button
                onClick={showCroppedImage}
                variant="contained"
                color="primary"
                className='cutButton'
                style={{marginTop: "20px"}}
              >
                Recortar
              </button>
            </div>
            <ImgDialog img={croppedImage} onClose={onClose} />
          </div>
        )
      }
      
      const StyledDemo = withStyles(styles)(Demo)


    return (
        <div>
            {errorWhileUploadingPhoto && (
                <div className="error-modal-backdrop">
                    <div className="error-modal-container">
                        <h3>Ups! Hubo un error al subir la imagen {currentImageUploadingNumber && currentImageUploadingNumber} de {propertyImagesUploadingNumber && propertyImagesUploadingNumber}</h3>
                        <p>No te preocupes, las imagenes anteriores se cargaron correctamente</p>
                        <button onClick={()=> window.location.reload()} className="services-button">Intentar nuevamente</button>
                    </div>
                </div>

            )}
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }

            {

                modal &&

                <ModalWarning
                // title='¿Está seguro de denunciar este usuario?'
                text='Nueva función de edición de imágenes!

                Ahora podés editar tus imágenes dentro de Hi Propi! Podrás recortar, hacer zoom y girar todas tus fotos!
                Tené en cuenta que una vez que finalices de editar tus fotos, al presionar "finalizar edición" la imagen original se borrará, dejando únicamente la nueva imagen. '
                cancel='Continuar'
                noButton={true}
                close={true}
                openModal={modal}
                handleCloseOut={handleCloseOut}
                />

            }
            <div className="new-property"  >
                <TopNewProperty step={2} action={newProperty.action} screenSizeWidth={screenSizeWidth} />
                <div className="properties-container-pc" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <TopStepper step={2} />
                    <div className="properties-inputs"  >
                        <div className="fields" id="fields">
                            <h3 className="hide-mobile">Una foto vale mil palabras</h3>
                            <h3 className="small-title">Elegir las fotografías para representar tu propiedad <span className="asterisk">*</span></h3>
                            {
                                isMobile && isSafari && (
                                    <Fragment>
                                        <p className='error-img'>Detectamos que estás usando el navegador Safari. Si es así es muy probable que, al tomar una foto, la aplicación no funcione correctamente. Te recomendamos subir fotos de tu galería o usar otro navegador si necesitas tomar una foto en el momento.</p>
                                    </Fragment>
                                )
                            }
                            <input type="file" className="add-image-file" id="add-image-file" name="add-image-file" accept="image/x-png,image/jpg,image/jpeg" onChange={(e) => addImage(e)} multiple />
                            <label htmlFor="add-image-file" className="add-image">Seleccioná las imágenes</label>

                            <div className="requirements">
                                <p>Requisitos:</p>
                                <p id="img-length-min">La cantidad mínima son 3 fotografías.</p>
                                <p id="img-length-max">La cantidad máxima son 15 fotografías.</p>
                                <p>Debe ser en formato png, jpg o jpeg.</p>
                            </div>
                            <Services icon={icon} title={title} text={text} link={link} />
                            {
                                editPhoto &&  <StyledDemo/>
                            }                
                            
                            {loaderNew ?
                                <Fragment>
                                    <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
                                        <CircularProgress size={30} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {uploadingFiles && (
                                            <p style={{ marginLeft: '-15px' }}>Subiendo imagen {currentImageUploadingNumber && currentImageUploadingNumber} de {propertyImagesUploadingNumber && propertyImagesUploadingNumber}</p>
                                        )}
                                    </div>

                                </Fragment>
                                :
                                <div className="all-images">
                                    {
                                        newProperty.low.length > 0 ? <SortableImages /> : <h2>No hay imagenes cargadas.</h2>
                                    }

                                </div>
                            }
                        </div>
                    </div>
                    <NewPropertyTip title={tiptitle} text={tiptext} />
                </div>
                <div className="buttons">
                    {!loaderNew &&
                        <>
                            <button className="prev" type="button" onClick={goPrev}>atrás</button>
                            <button className="next" type="submit" onClick={checkErrorNext}>siguiente</button>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default NewProperty2;