import React, { useState, useEffect, Fragment } from 'react';
import TopAppBarMobile from '../../../../../commons/TopBar/TopAppBar';
import { Stepper } from '../../../../../commons/Stepper/Stepper';
import '../BuySignalService/BuySignal.css';
import './BuyPhotographer.css';
import axios from 'axios';
import URL from '../../../../../../config/urls';
import Checkbox from '../../../../../commons/Checkbox/Checkbox';
import { Link } from 'react-router-dom';
import whiteRightArrow from '../../../../../../assets/images/whiteRightArrow.png';
import greenLeftArrow from '../../../../../../assets/images/greenLeftArrow.png';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppBarWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';
import StepByStep from '../../../../../commons/StepByStep/StepByStep';
import Head from '../../../../../commons/HeaderWeb/HeaderWeb';
import ViewGrid from '../../../../../commons/ViewGrid/ViewGrid';
import PhoneInput from 'react-phone-input-2';
import { useForm } from "react-hook-form";

const BuyPhotographer = ({ history }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token)

    // attributes
    const [phone, setPhone] = useState(' ');
    // const [phoneComplete, setPhoneComplete] = useState(' ');
    const [tooMuchProperties, setTooMuchProperties] = useState(false);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [properties, setProperties] = useState([]);
    const [termsAndContidionsAcepted, setTermsAndContidionsAcepted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        phone: ''
    })
    const { register, handleSubmit, errors } = useForm();
    // properties from request API
    const [propertiesIdFromApi, setPropertiesIdFromApi] = useState([]);


    const headers = { headers: { "token": token, } };

    useEffect(() => {

        let dataFromLocalStorage = localStorage.getItem('dataPersistence');
        let dataPersistence = JSON.parse(dataFromLocalStorage)

        if (dataPersistence) {
            setPhone(phone)
        }

        axios.get(URL + 'users/' + decoded.data.id, headers)
            .then(response =>
                setPropertiesIdFromApi(response.data.responseData.myProperties));
        // eslint-disable-next-line
    }, [])

    // Get user properties
    useEffect(() => {
        const BASE_URL = URL + 'myProperties';
        axios.get(BASE_URL, headers).then(response => {
            const { responseData } = response.data
            setProperties(...properties, responseData)
        })
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function removeItemFromArr(arr, value) {
        let newArray = arr.filter(element => element !== value)
        setSelectedProperties(newArray)
    }

    function searchElement(value) {
        return selectedProperties.includes(value)
    }

    function handleProperties(value) {
        let ifExists = searchElement(value);
        if (ifExists) {
            if (ifExists && selectedProperties.length > 4) {
                removeItemFromArr(selectedProperties, value);
                setTooMuchProperties(true)
            } else {
                removeItemFromArr(selectedProperties, value)
                setTooMuchProperties(false)
            }
        } else {
            if (selectedProperties.length < 3) {
                setSelectedProperties([...selectedProperties, value])
                setTooMuchProperties(false)
            } else {
                setSelectedProperties([...selectedProperties, value])
                setTooMuchProperties(true)
            }
        }
    }

    function handleOptions(value) {
        if (value === 'nextStep') {
            history.push('/services/signal/buy/confirm')
        } else {
            history.push('/services/signal')
        }
    }

    function sendOrder() {
        let order = { userPhone: phone, selectedProperties }
        if (order) {
            let dataPersistence = JSON.stringify(order)

            localStorage.setItem('dataPersistence', dataPersistence)
            history.push('/services/photographer/buy/confirm')
            // .catch(e =>
            //     history.push('/services/signal/buy/confirm')
            //     //contemplar errores
        }
    }

    function handleSubmitOrder() {
        setTimeout(() => sendOrder(), 1000)
    }

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    let params =
        phone.length < 14 ||
        selectedProperties.length < 1 ||
        termsAndContidionsAcepted === false ||
        tooMuchProperties === true;
    let screenSizeWidth = window.innerWidth;

    return (
        <div className='buy-photographer-container'>
            <div className='buy-photographer-content'  >
                {screenSizeWidth > 1030 ?
                    <TopAppBarWeb history={history} />
                    : <TopAppBarMobile history={history} />
                }
                {screenSizeWidth < 1030 &&
                    <div className='buyServicesHeaderContainer'>
                        <label> Adquisición de servicios: </label>
                        <Stepper title='Fotógrafo Profesional' byStep='1/2' steps={5} activeStep={2} />
                    </div>
                }
                <div className='buyServicesBodyContainer'>
                    <div className='buy-services-body-content-photographer'  style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                        <div className='buy-services-photographer-body'>
                            {screenSizeWidth > 1030 && <Head text='Servicio: Fotógrafo Profesional' w='80%' />}
                            <div className='buy-services-content-body-0101'>
                                {screenSizeWidth > 1030 &&
                                    <div className='buy-services-photographer-steps'>
                                        <StepByStep
                                            steps={[
                                                {
                                                    number: 1,
                                                    name: 'Datos de contacto'
                                                },
                                                {
                                                    number: 2,
                                                    name: 'Confirmación'
                                                }
                                            ]}
                                            stepNumber={1}
                                        />
                                    </div>
                                }

                                <div className='buy-services-photographer-data'>
                                    <h2> Datos de contacto </h2>
                                    <h3> Solo necesitamos un teléfono y la propiedad guardada.
                                    Nos pondremos en contacto con vos para coordinar fecha y hora para la sesión. </h3>
                                    <h3> Todos los campos con <strong>*</strong> son obligatorios. </h3>

                                    <h4> Teléfono de contacto </h4>
                                    <form onSubmit={handleSubmit()}>
                                    <label className="label-form">Número de teléfono</label>
                                        <PhoneInput
                                                inputProps={{ autoComplete: "no" }}
                                                country={'ar'}
                                                onlyCountries={['ar']}
                                                masks={{ ar: '+.. ..........' }}
                                                value={phone}
                                                containerStyle={{ width: '100%', marginTop: 20 }}
                                                inputStyle={{
                                                    borderRadius: 20, height: 40, border: 'solid 1px #5963cc',
                                                    background: 'rgba(89, 99, 204, 0.05)'
                                                }}
                                                buttonStyle={{ borderRadius: '20px 0 0 20px', border: 'solid 1px #5963cc' }}
                                                onChange={e => { setPhone(e);}}
                                                id='phone'
                                            />
                                    </form>

                                    <h4> Seleccione la propiedad </h4>
                                    {tooMuchProperties && <h6 className='tooMuchPropError'> ¡Máximo tres propiedades! </h6>}
                                    <div>

                                        {propertiesIdFromApi.length > 0 && propertiesIdFromApi.map(id =>
                                            <div className='property-item'>

                                                {
                                                    // We want to match each property with the id passed previously
                                                    properties.map(property => (

                                                        (property.servicesRequested && property.propertyId === id && property.servicesRequested.photography === false) && (

                                                            <Fragment key={id}>
                                                                <Checkbox
                                                                    attribute={{
                                                                        id: id,
                                                                        value: id,
                                                                    }}
                                                                    param={searchElement(id) ? id : ''}
                                                                    key={id}
                                                                    handleChange={() => handleProperties(id)}
                                                                    classNames='photographer-service-property-checkbox'
                                                                />
                                                                <p>{property.id}</p>
                                                                <ViewGrid handleClick={() => null} property={property} myproperty={true}
                                                                    publishedFor={
                                                                        property.publishedFor.sell ? 'sell' :
                                                                            property.publishedFor.rent ? 'rent' :
                                                                                'tmpRent'
                                                                    } />

                                                            </Fragment>
                                                        )
                                                    ))
                                                }


                                            </div>
                                        )}
                                    </div>

                                    <h4> Permisos del servicio <strong>*</strong></h4>
                                    <div className='termsAndConditionsContainer'>
                                        <div className='termsAndCondCheckbox'>
                                            <Checkbox
                                                attribute={{
                                                    id: 'termsAndCond',
                                                    value: true
                                                }}
                                                param={termsAndContidionsAcepted}
                                                handleChange={() => setTermsAndContidionsAcepted(!termsAndContidionsAcepted)}
                                            />
                                        </div>
                                        <div className='termsAndCondLabel'>
                                            <label> Acepto los <Link style={{ textDecoration: 'none', fontWeight: 'bold', color: '#232f34' }} to='/terms'> términos y condiciones</Link>.</label>
                                        </div>
                                    </div>
                                    <div className='buySignalButtonsContainer'>
                                        {screenSizeWidth < 1030 &&
                                            <button className='buySignalBackButton' onClick={() => handleOptions('back')}>
                                                <img alt='arrow' src={greenLeftArrow} />
                                                <label> Atrás </label>
                                            </button>
                                        }
                                        <button
                                            disabled={params ? true : false}
                                            className={params ? 'buySignalNextButtonDisabled' : 'buySignalNextButton'}
                                            onClick={handleSubmitOrder}>
                                            <label> Siguiente </label>
                                            {!params && <img alt='arrow' src={whiteRightArrow} />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading &&
                        <div>
                            <Backdrop className={classes.backdrop} open={loading}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
};

export default BuyPhotographer;
