import React from 'react';

const CustomInput = ({ attribute, handleChange, text, type, w, h, description, errorStyle, value }) => {

    return (
        <div>
            { attribute.text && <h6 style={{ paddingBottom: 10 }}> {attribute.text} </h6> }
            <div style={{ display: 'flex'}}>
                <input 
                style={{ width: w, height: h }}
                id={attribute.id}
                name={attribute.name}
                placeholder={description}
                value={ value && value }
                type={type}
                className={ errorStyle && 'errorInput' }
                onChange={ (e) => handleChange(e.target.name, e.target.value) } 
                />
                { text && <label className='appraiserAquareMeters'> {text} </label> }
            </div>
        </div>
    )
}

export default CustomInput;