import React from 'react';
import emailIsVerified from '../../../../../assets/images/emailIsVerifiedTrue.svg';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';
import { useEffect } from 'react';
import URL from '../../../../../config/urls';
import axios from 'axios';

const VerifyEmailConfirm = ({ history }) => {

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token)

    function handleNextOptions(value) {
        if (value === 'verifyPhone') {
            history.push('/myprofile/verificated/verifyPhone?userId=' + decoded.data.id)
        } else {
            history.push('/')
        }
    }

    const verifyMail = async () => {
        let mailTokenFromUrl = window.location.href.split('confirm/')[1]
        const BASE_URL = `${URL}userVerifications/sentEmailVerification`

        await axios.post(BASE_URL, {
            mailToken: mailTokenFromUrl
        })
    }

    useEffect(() => {
        verifyMail()
    }, [])

    return (
        <div className='verifyEmailConfirmContainer' >
            <BlueTopBar
                title='Verificación'
                link={
                    decoded && decoded.data.id
                        ? '/myprofile/verificated?userId=' + decoded.data.id
                        : '/'}
            />

            <div className='verifyEmailConfirmContent'>
                <img src={emailIsVerified} alt='verfied email icon' />
                <h3> ¡Tu mail ya está validado! </h3>
                <label>
                    Ahora, estás un paso más cerca de ser un usuario verificado.
                </label>
                <div>
                    {/* <button onClick={() => handleNextOptions('verifyPhone')} > Validar teléfono </button> */}
                    <button onClick={() => handleNextOptions('goHome')} > Volver al inicio </button>
                </div>
            </div>

        </div>
    )
};

export default VerifyEmailConfirm;