import React, { useState, useEffect } from 'react';
import axios from 'axios';
import URL from '../../../../../config/urls';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';
import Fade from 'react-reveal/Fade';

const Verification = ({ history }) => {

    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];
    let BASE_URL;

    if (token !== null) {
        BASE_URL = URL + 'users/' + userIdFromUrl
    }

    const [user, setUser] = useState(null);

    useEffect(() => {
        axios.get(BASE_URL, headers)
            .then(response => {
                const dataUser = response.data.responseData;
                setUser(dataUser)
            })
            .catch(e => {
                console.log(e);
            })
        // eslint-disable-next-line
    }, [])

    let screenSizeWidth = window.innerWidth;

    function handleGoNextStep(value) {
        switch (value) {
            case 'verifyEmail':
                history.push('/myprofile/verificated/verifyEmail?userId=' + userIdFromUrl)
                break;
            case 'verifyPhone':
                history.push('/myprofile/verificated/verifyPhone?userId=' + userIdFromUrl)
                break;
            case 'verifyDni':
                history.push('/myprofile/verificated/verifyDni?userId=' + userIdFromUrl)
                break;
            default:
                console.log('No se encontraron valores')
        }
    }

    return (
        <div>
            <div>
                {screenSizeWidth < 1030 ?

                    <BlueTopBar
                        title='Verificación'
                        link={'/myprofile?userId=' + userIdFromUrl}
                    />

                    :

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='settings-profile-data' style={{ fontSize: 16 }}> Verificación </label>
                        <label className='settings-profile-title' style={{ marginBottom: 10 }}> Configuración en la validación </label>
                    </div>

                }

                <div className='verificatedContainer-main'>

                    {user === null ? null :

                        <Fade right>
                            <>

                                <div className='optionVerificatedContainer'>
                                    <div className='optionVerificatedContent'>
                                        <h3> Verificación del correo electrónico </h3>
                                        <label> Descripción, a realizarse. </label>
                                        {user.emailIsVerified === undefined ?
                                            null
                                            : user.emailIsVerified === false ?
                                                <button
                                                    className='buttonIsVerifiedIsFalse'
                                                    onClick={() => handleGoNextStep('verifyEmail')} >
                                                    Activar
                                        </button>
                                                : <button
                                                    className='buttonIsVerifiedOk'
                                                    disabled
                                                >
                                                    Ya estás validado
                                        </button>
                                        }
                                    </div>
                                </div>

                                {/* THE FOLLOWING CODE BLOCK SHOULD BE UNCOMMENTED ONCE ==PHONE VERIFICATION FEATURE== HAS BEEN IMPLEMENTED */}

                                <div className='optionVerificatedContainer'>
                            <div className='optionVerificatedContent'>
                                <h3> Verificación del teléfono </h3>
                                <label> Descripción, a realizarse. </label>
                                { user.phoneIsVerified === undefined ?
                                    null 
                                    : user.phoneIsVerified === false ?
                                        <button 
                                        className='buttonIsVerifiedIsFalse'
                                        onClick={ () => handleGoNextStep('verifyPhone') }> 
                                            Activar 
                                        </button>
                                    :   <button
                                        className='buttonIsVerifiedOk'  
                                        disabled > 
                                            Ya estás validado 
                                        </button>
                                }
                            </div>
                        </div> 

                         {/* THE FOLLOWING CODE BLOCK SHOULD BE UNCOMMENTED ONCE ==DNI VERIFICATION FEATURE== HAS BEEN IMPLEMENTED */}

                                {/* <div className='optionVerificatedContainer'>
                            <div className='optionVerificatedContent'>
                                <h3> Verificación del DNI </h3>
                                <label> Descripción, a realizarse. </label>
                                <button
                                className='buttonIsVerifiedIsFalse'
                                > 
                                    Activar 
                                </button>
                            </div>
                        </div> */}

                            </>
                        </Fade>
                    }
                </div>
            </div>
        </div>
    )
};

export default Verification;