import React, {useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { fade, makeStyles } from '@material-ui/core/styles';
import URL from './../../../config/urls'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { Context } from '../../../Context'
import { useHistory } from 'react-router-dom'
import icono from '../../../assets/mobile/img2.svg'
import axios from 'axios'


const useStyles = makeStyles((theme) => ({


search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    '&:hover': {
      backgroundColor: "white",
    },
    borderRadius: "100px",

    // marginRight: theme.spacing(20),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  
    display: "flex"

  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: '100%',
    paddingTop: "5px",
    // marginLeft: "200px",
    // position: 'absolute',
    cursor: "pointer",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    color: "black",
    // paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: `calc(1em + ${theme.spacing(0.3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '120ch',
    },
    '&:hover': {
        color: "black",
      },
  },
}));

export default function TransitionsModal({ checked, funct }) {

  const { 
    setdataSearch,
    setloading,
    dataSearch,
    setword
   } = useContext(Context);
  
  let history = useHistory();
  
  const classes = useStyles();

  const token = localStorage.getItem('token');

  // const headers = { headers: { "token": token, } };



  const handleChangeWord = (e) => {
    setword(e.target.value)
  }

  const Adress = () => {

    setdataSearch(dataSearch + 1)
    history.push('/search/locate')

  }


  // const [open, setOpen] = React.useState(true);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   funct(false)
  // };

return (

        <div className={classes.search} style={{border: "0px"}} >

             <InputBase
              onChange={handleChangeWord}
              placeholder="Buscá por ciudad o barrio "
              classes={{
                input: classes.inputInput,
              }}
              style={{color: "black", fontSize: "12px"}}

            />
            <button
              style={{border: "0px", borderRadius: "100px", backgroundColor: "white", outline:"none", cursor: "pointer"}} 
              onClick={ () => 
                Adress()
              }
              ><img style={{height: "13px"}} src={icono} /> </button> 
          </div>


  );
}

