import React from 'react';
import emailIsVerified from '../../../../../assets/images/emailIsVerifiedTrue.svg';
import BlueTopBar from '../../../../commons/BlueTopBar/BlueTopBar';

const VerifyPhoneConfirm = ({ history }) => {

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token)

    return (
        <div className='verifyEmailConfirmContainer' >
            <BlueTopBar 
            title='Verificación'
            link= { 
                decoded.data.id
                ? '/myprofile/verificated?userId=' + decoded.data.id
                : '/' } 
            />

            <div className='verifyEmailConfirmContent'>
                <img src={emailIsVerified} alt='' />
                <h3> ¡Tu teléfono ya está validado! </h3>
                <label> 
                    Ya sos un usuario verificado. 
                    Podrás contactarte con todos los dueños de las propiedades y cargar tus propiedades.
                </label>
                <div style={{ width: '100%' }}>
                    <button 
                    className='verifyPhoneButtonConfirm'
                    onClick={ () => history.push('/') } > 
                        Buscar propiedades 
                    </button>
                </div>
            </div>
        </div>
    )
};

export default VerifyPhoneConfirm;