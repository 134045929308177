import React, { useEffect } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import {API} from '../../../config/API'

export default function Checkout({dataId}) {
    const mercadopago = useMercadopago.v2(API, {
        locale: 'es-AR'
    });

    console.log(dataId)

    useEffect(() => {
        if(dataId){
            if (mercadopago) {
                mercadopago.checkout({
                    preference: {
                        id: dataId
                    },
                    autoOpen: true,
                })
            }
        }
    }, [mercadopago, dataId])
    return (
        // jsx
        <div class="cho-container"></div>
    )
}