import React, { useState, useEffect } from 'react';
import './GenderSelect.css';
import axios from 'axios';
import URL from '../../../config/urls';

const GenderSelect = ({ handleSelectGender }) => {

    const [ gender, setGender ] = useState("");

    useEffect(() => {
        axios.get(URL + 'genders')
        .then(response => {
            // setGender(response.data.responseData)
            console.log(response)
        })
    }, []);

    return (
        <div className='input-select-gender-container'>
            <select className='input-select-gender' onChange={(e) => handleSelectGender(e.target.value)} >
                <option value='' > Seleccione un género </option>
                <option value='male' > Masculino </option>
                <option value='female' > Femenino </option>

                {/* { gender && gender.map(( gn, index) =>
                    <option value={gn} key={index} > {gn === 'male' ? 'Masculino' : 'Femenino' } </option>
                )} */}
            </select>
        </div>
    )
};

export default React.memo(GenderSelect);