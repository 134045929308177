import profile from '../assets/images/profile.svg';
import verified from '../assets/images/verified.svg';
import security from '../assets/images/security.svg';
import trash from '../assets/images/trash-profile.svg';

export let settingsData = [
    {  
        id: 'yourData',
        icon: profile,
        title: 'Tus datos',
        subtitle: 'Actualizar o modificar tus datos'
    },
    {
        id: 'security',
        icon: security,
        title: 'Privacidad',
        subtitle: 'Cambiar contraseña'
    },
    {
        id: 'verification',
        icon: verified,
        title: 'Verificación',
        subtitle: 'Configuración en la validación'
    },
    {
        id: 'deleteAccount',
        icon: trash,
        title: 'Borrar cuenta',
        subtitle: 'Eliminar tu cuenta de HiPropi'
    }
]