import React from 'react';
import './Favorites.css';
import arrowBack from '../../../assets/images/arrowBack.svg';
import empty from '../../../assets/images/empty.png';
import { useState, useEffect } from 'react';
import ViewList from '../../commons/ViewList/ViewList';
import axios from 'axios';
import URL from '../../../config/urls';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';

export default function Favorites({ history }) {

    const [myFavorites, setMyFavorites] = useState([]);
    const [searchDonde, setSearchDone] = useState(false);


    function goBack() {
        history.push('/')
    }

    function goSearch() {
        history.push('/')
    }

    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    useEffect(() => {

        const token = localStorage.getItem('token');
        const headers = { headers: { "token": token, } };

        const BASE_URL = URL + 'favourites/getFavourites';

        axios.get(BASE_URL, headers)
            .then(res => {
                const data = res.data;
                setMyFavorites(myFavorites => [...myFavorites, data.responseData]);
                setSearchDone(true);
            })

    }, []);

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 &&
                <NavBarWeb history={history} />
            }
            <div className="favorites-separation-pc">
                <div className="topBar" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                    <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />
                    <h3>Mis favoritos</h3>
                </div>
                <div className="container-favorites">
                    {
                        !searchDonde ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '75vh' }}>
                                <CircularProgress size={70} />
                            </div> :
                            myFavorites[0].length === 0 || myFavorites[0][0] === false ?
                                <div className="container-empty">
                                    <div className="empty-card">
                                        <img className="empty-img" src={empty} alt={empty} />
                                        <p className="empty-text">Todavía no tenés ningún favorito</p>
                                        <button className="go-search" onClick={goSearch}>Ir a buscador</button>
                                    </div>
                                </div> :
                                <div className="container-full">
                                    {myFavorites[0].map((property, i) => (
                                        property && <ViewList property={property} handleClick={handleClick} publishedFor={property.likedtransaction} key={i} isFavorite={true} />
                                    ))}
                                </div>
                    }
                </div>
            </div>
        </div>
    );
}