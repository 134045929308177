import React, {useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { fade, makeStyles } from '@material-ui/core/styles';
import URL from './../../../config/urls'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { Context } from '../../../Context'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import './TopAppBarWeb.css'

const useStyles = makeStyles((theme) => ({


search: {
 
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.15),
    },
    // marginRight: theme.spacing(20),
    marginLeft: 0,
 
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  
    display: "flex"

  },
  searchIcon: {
    height: '100%',
    paddingTop: "5px",
    //  marginLeft: "-480px",
    // position: 'absolute',
    cursor: "pointer",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    color: "black",
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    width: "430px",

  },
}));

export default function TransitionsModal() {

  const { 
    setdataSearch,
    setloading,
    dataSearch,
    setword,
    page, 
    setPage
   } = useContext(Context);
  
  let history = useHistory();
  
  const classes = useStyles();

  const token = localStorage.getItem('token');

  const headers = { headers: { "token": token, } };

  const handleChangeWord = (e) => {
    setword(e.target.value)
  }
 

  const Adress = () => {
    // setdataSearch([])
    setdataSearch(dataSearch + 1)
    setPage({ ...page, number: 0 });
    history.push('/search/locate')

  }


return (
    <div>


        <div className="search" style={{width: "500px", backgroundColor: "white", borderRadius: "25px", color: "black", height: "40px"}} >

             <input
              onChange={handleChangeWord}
              placeholder="Buscá por ciudad o barrio tu propiedad"
              // classes={{
              //   input: classes.inputInput,
              // }}
              // onFocus={{color: "black"}}
              className="searchbar-web-buscador"
              style={{color: "black",  width: "450px"}}
            
            />
            {/* <div className={classes.searchIcon} > */}
              <button 
               style={{border: "0px", backgroundColor: "transparent", outline:"none", cursor: "pointer"}} 
                  onClick={ () => 
                    
                    Adress()
                  }
               > <SearchIcon  /> </button> 
             {/* </div> */}
          </div>



    </div>
  );
}

