import React, { useState, useEffect } from "react";
import "./Verification.css";
import BlueTopBar from "../../../../commons/BlueTopBar/BlueTopBar";
import { makeStyles } from "@material-ui/core/styles";
//import { Link } from 'react-router-dom';
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import URL from "../../../../../config/urls";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmationModal from "../../../../commons/ConfirmationModal/ConfirmationModal";
import whiteRightArrow from "../../../../../assets/images/whiteRightArrow.png";


/* let jwt = require('jsonwebtoken');
let token = localStorage.getItem('token');
let decoded = jwt.decode(token); */

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StepTwo = ({ history }) => {
  const classes = useStyles();

  let userIdFromUrl = window.location.href.split("=")[1].split("?")[0];

  const BASE_URL = URL + "userVerifications/phone/recieveCode";

  const [one, setOne] = useState();
  const [two, setTwo] = useState();
  const [three, setThree] = useState();
  const [four, setFour] = useState();
  const [five, setFive] = useState();
  const [six, setSix] = useState();

  const [errorCode, setErrorCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const oneRef = React.createRef();
  /*   const twoRef = React.createRef();
  const threeRef = React.createRef();
  const fourRef = React.createRef();
  const fiveRef = React.createRef();
  const sixRef = React.createRef(); */

  const [phone, setPhone] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");
    let headers = { token: token };
    axios
      .get(URL + "users/" + userIdFromUrl, headers)
      .then((response) => {
        setPhone(response.data.responseData.phone);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, [phone]);

  let container = [];
  container.push(one, two, three, four, five, six);
  let confirmCode = container.join("");

  let token = localStorage.getItem("token");
  const headers = { headers: { token: token } };

  function isSuccessful() {
    history.push("/myprofile/verificated/verifyPhone/code/confirm");
  }

  async function codeEntered(codeObject) {
    try {
      const response = await axios.post(BASE_URL, codeObject, headers);
      if (response) {
        isSuccessful();
      }
    } catch (e) {
      setTimeout(() => setLoading(false), 500);
      setTimeout(() => setErrorCode(true), 500);
    }
  }

  const handleConfirmCode = (e) => {
    e.preventDefault();
    codeEntered({ code: confirmCode });
    setLoading(true);
  };

  const deleteData = (e) => {
    e.preventDefault();
    setErrorCode(false);
    setOne("");
    setTwo("");
    setThree("");
    setFour("");
    setFive("");
    setSix("");
    confirmCode = "";
    oneRef.current.focus();
  };


  return (
    <div>
      <BlueTopBar
        title="Verificación"
        link={"/myprofile/verificated/verifyPhone?userId=" + userIdFromUrl}
      />
      <div className="verifyPhoneCodeContainerApp">
        <div className="emailVerificationTitle code">
          <h1> Validá tu teléfono </h1>
        </div>
        <div className="verifyPhoneCodeContainer">
          <div className="verifyPhoneCodeContent">
            <label className="label-step-four">
              {" "}
              Te hemos enviado un código al siguiente número de teléfono:{" "}
            </label>
            <p className="label-step-four">
              <strong style={{ color: "#232f34", fontSize: "16px" }}>
                {" "}
                {phone && phone}{" "}
              </strong>
            </p>
            <label className="label-step-four"> Ingresa el código: </label>
            <div className="input-step-four">
              <input
                className="input-step-four-input"
                required
                autoFocus
                type="number"
                ref={oneRef}
                value={one}
                onChange={(e) => {
                  setOne(e.target.value);
                  oneRef.current.focus();
                  setErrorCode(false);
                }}
              />
              <CancelIcon
                style={{
                  color: "rgba(35, 47, 52, 0.4)",
                  position: "absolute",
                  right: "9%",
                  top: 273,
                }}
                className="delete-icon"
                onClick={deleteData}
              />
            </div>
          </div>

          {errorCode && (
            <div style={{ marginTop: 20 }}>
              <ConfirmationModal
                title="¡Algo no salió bien!"
                description="El código ingresado es incorrecto."
                modalType="error"
              />
            </div>
          )}

          <div className="button-fullvalidate-container-app">
            <button
              type="submit"
              disabled={confirmCode.length === 6 ? false : true}
              onClick={handleConfirmCode}
              className={
                confirmCode.length === 6
                  ? "verifyPhoneCodeButtonTrue"
                  : "verifyPhoneCodeButtonFalse"
              }
            >
              Siguiente{" "}
              {confirmCode.length === 6 && (
                <img style={{ paddingLeft: 10 }} src={whiteRightArrow} alt="" />
              )}
            </button>
          </div>

          {loading && (
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
