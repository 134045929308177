import React, {
    useEffect,
    useState,
    Fragment
} from "react";
import URL from '../../../../../../config/urls';
import ReactWhatsapp from 'react-whatsapp';
import axios from 'axios';
import WspImage from '../../../../../../assets/images/whatsapp-logo.png'
import "./InternalCard.css";
// components
import Modal from '../../../../../commons/Modals/ModalWarning';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CarouselWeb from '../../../../Carousel/CarouselWeb';
import ExpansionPanels from '../../../../../commons/ExpansionPanels/ExpansionPanels';
import SwipeableViewList from '../../../../../commons/ViewList/component/SwipeableViewList';
import Backdrop from '@material-ui/core/Backdrop';

// icons
import bed from '../../../../../../assets/images/property/bed.svg'
import cube from '../../../../../../assets/images/property/volume.svg'
//import eyes from "../../../../../../assets/images/eyes.png";
import blackEyes from '../../../../../../assets/images/black-eyes.PNG'
import camera from '../../../../../../assets/images/camera.svg';
import cameraFalse from '../../../../../../assets/images/cameraFalse.svg'
import certificate from '../../../../../../assets/images/certificate.svg';
import certificateFalse from '../../../../../../assets/images/certificateFalse.svg';
/* import money from '../../../../../../assets/images/money.svg';
import moneyFalse from '../../../../../../assets/images/moneyFalse.svg'; */
import premium from '../../../../../../assets/images/premium.svg'
import expand from '../../../../../../assets/images/expand.png';
/* import { slideNext } from "react-alice-carousel";
import { Redirect } from "react-router-dom"; */

let lang = navigator.language || navigator.userLanguage;
lang = lang.split('-')

const BASE_URL = URL + `dictionaries/${lang[0]}`

const useStyles = makeStyles(theme => ({
    price: {
        fontSize: 25
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    }
}));

// // component favorite
// const ItemFavorite = () => {
//     return (
//         <div>
//             <input type='checkbox' id='check' className='item-favorite-input-internal-card' />
//             <label htmlFor='check' className='item-favorite-label-internal-card' />
//         </div>
//     )
// }

// component containing items of the 'premium'
const Premium = (props => {

    const { card } = props;

    return (
        <div>
            {card === null ? null :
                <div>
                    {!card.servicesHired.photography || !card.servicesHired.poster ? null
                        :
                        <div className='premium'>
                            <img src={premium} alt={premium} />
                        </div>
                    }
                </div>
            }
        </div>
    )
});

// component containing view items and favorite item
const MenuItems = (props => {

    const { visitsCount } = props;

    return (
        <div className='menu-item-internal-card'>
            <img src={blackEyes} alt={blackEyes} className='eyes-style-internal-card' />
            <span className='number-style-internal-card'> {visitsCount} </span>
            {/* <ItemFavorite /> */}
        </div>

    )
});

// component containing premium component + menu item
const Actions = (props => {

    const { card, setOpenBackdrop, openBackdrop } = props;

    function handleOpenBackdrop() {
        setOpenBackdrop(true)
    }

    return (
        <div className='actions-content-internal-card'>
            <Premium card={card} />
            <div onClick={() => handleOpenBackdrop()} >
                <img src={openBackdrop ? null : expand} alt='' className='expandImg' />
            </div>
        </div>
    )
});

// component with items to premium
const ItemList = (props => {

    const { card } = props;

    return (
        <>
            {card === null ? null :
                <div className='item-list-container item-list-container-internal'>
                    {!card.servicesHired.poster ?
                        <div className='item-list'>
                            <img className='item-list-img-certificate' src={certificateFalse} alt={certificateFalse} />
                            <span style={{ color: 'rgba(35, 47, 52, 0.5)' }}> Cartelería </span>
                        </div>
                        :
                        <div className='item-list'>
                            <img className='item-list-img-certificate' src={certificate} alt={certificate} />
                            <span> Cartelería </span>
                        </div>
                    }

                    {!card.servicesHired.photography ?
                        <div className='item-list'>
                            <img className='item-list-img-camera' src={cameraFalse} alt={cameraFalse} />
                            <span style={{ color: 'rgba(35, 47, 52, 0.5)' }}> Fotógrafo Profesional </span>
                        </div>
                        :
                        <div className='item-list'>
                            <img className='item-list-img-camera' src={camera} alt={camera} />
                            <span> Fotógrafo Profesional </span>
                        </div>
                    }

                </div>
            }
        </>
    )
});

const CustomCard = (props => {
    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    let decoded = jwt.decode(token);
    const classes = useStyles();
    const { card, publishedFor, dataFooter,
        propTypeTranslation, expansionPanelsData,
        map, setOpenBackdrop, openBackdrop } = props;

    const [color, setColor] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [modalOn, setModalOn] = useState(false);
    const [urlToMessages, setUrl] = useState('');
    useEffect(() => {

        // if(card.isActive == false && card.isPublished == false){
        //     props.history.push('/')
        // }

        console.log(card)
        if (publishedFor === 'sell') {
            setTransaction('Venta')
            setColor('Venta')
            setPrice(card.price.sellPrice)
            setCurrency(card.price.sellCurrency)
        }
        if (publishedFor === 'rent') {
            setTransaction('Alquiler')
            setColor('Alquiler')
            setPrice(card.price.rentPrice)
            setCurrency(card.price.rentCurrency)
        }
        if (publishedFor === 'tmpRent') {
            setTransaction('Temporario')
            setColor('Temporario')
            setPrice(card.price.tmpPrice)
            setCurrency(card.price.tmpCurrency)
        }

        dataFooter(publishedFor, card.price);
        if (decoded) {
        }
        
        const headers = { headers: { "token": token, 'Content-Type': 'application/json' } };
        axios.get(URL + `users/${card.contact.userId} `, headers)
            .then((user) => {
                setUrl(`/contact/?propertyId=${card.propertyId}?transaction=${publishedFor}?intId=${decoded.data.id}?chat=${decoded.data.id}«»${user.data.id}«»${card.propertyId}`)
                return
            })
            .catch((er, req, res, next) => {
                console.log(er, req, res);
            })
        // eslint-disable-next-line 
    }, []);
    let screenSizeWidth = window.innerWidth;

    function handleCloseBackdrop() {
        setOpenBackdrop(!modalOn)
    }

    function handleCloseError() {
        setModalOn(!modalOn);
        //  props.history.push('/error-page');
    };
    const getAccessToMessages = (onlyValidatedUsers) => {
        //usuario no logueado
        if (!decoded) {
            setModalOn(!modalOn)
            return
        }
        // user es dueño de la propiedad
        if (decoded && decoded.data.email === card.contact.email) {
            (setModalOn(true))
            return
        }
        if (!onlyValidatedUsers) {
           
            props.history.push(urlToMessages);
        } else {
            //user no tiene el mail verificado
            if (!decoded.data.emailIsVerified) {
                setModalOn(!modalOn);
            } else {
                //redirect to messages
                props.history.push(urlToMessages);
            }
        }
    }

    return (

        <div style={{ width: '100%' }} className='internalPropertyContainerWeb' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>

            <div className='internalPropertyCarouselContainer'>
                {screenSizeWidth > 1030 && <CarouselWeb images={card.images.medium} />}
            </div>

            <div className='internalPropertyCardContainer'>
                <div className={screenSizeWidth < 1030 ? 'internal-card-mobile' : 'internal-card-web'} >
                    <div className='SwipeableContainerMobileVersion'>
                        {screenSizeWidth < 1030 && <SwipeableViewList images={card.images.medium} />}
                    </div>

                    {openBackdrop &&
                        <div>
                            <Backdrop className={classes.backdrop} open={openBackdrop}>
                                <img alt='close' src={expand} onClick={() => handleCloseBackdrop()} className='expandImgOnTrue' />
                                <SwipeableViewList images={card.images.medium} />
                            </Backdrop>
                        </div>
                    }

                    <Actions card={card} openBackdrop={openBackdrop} setOpenBackdrop={setOpenBackdrop} />
                    <div className='card-content-support-internal-card'>
                        <div>
                            <div style={{ display: 'flex' }}>
                                {
                                    transaction === 'Venta' ?
                                        <div className="price-line-through-internal-card">
                                            {
                                                `${currency} ${new Intl.NumberFormat("de-DE").format(Math.round(card.price.sellDiscount))}`
                                            }
                                        </div> :
                                        <div className="price-not-line-through-internal-card">
                                            {
                                                transaction === 'Alquiler' ?
                                                    card.price.rentDiscount :
                                                    card.price.tmpDiscount
                                            }
                                        </div>
                                }
                                <MenuItems visitsCount={card.visitsCount} />
                            </div>
                            <div className='price'>
                                {
                                    `${currency} ${new Intl.NumberFormat("de-DE").format(Math.round(price))}`
                                }
                            </div>
                        </div>
                        <div className='card-transaction-and-characters-description'>
                            <p className={color}>
                                {transaction}
                            </p>
                            {
                             card.accommodations.hasBedroomsNumber == -1 ?
                             <p className='characters-bed'>
                                 | <img src={bed} alt={bed} /> {"M"}
                             </p>
                             :  card.accommodations.hasBedroomsNumber ?
                             <p className='characters-bed'>
                             | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
                             </p>
                             : null
                            }
                            {/* {
                                card.accommodations.hasBedroomsNumber &&
                                <p className='characters-bed'>
                                    | <img src={bed} alt={bed} /> {card.accommodations.hasBedroomsNumber}
                                </p>
                            } */}
                            {
                                card.edifiedArea &&
                                <p className='characters-area'>
                                    | <img src={cube} alt={cube} /> {card.edifiedArea}m<sup>2</sup>
                                </p>
                            }
                        </div>
                        <div className='address-property-card'>
                            <label> {propTypeTranslation && propTypeTranslation.charAt(0).toUpperCase() + propTypeTranslation.slice(1)} en <span>{card.address.street} </span> al  <span>{card.address.number ? Math.round(card.address.number / 100) * 100 : null}</span>, {card.address.city}, {card.address.state}.</label>
                        </div>
                        <ItemList card={card} />

                       
                        {(screenSizeWidth > 1030) &&

                            <div className='buttonWebContainer'>


                                <button
                                    className='button-contact-content'
                                    onClick={() => getAccessToMessages(card.onlyValidatedUsers)}
                                // propertyId, trans, intId,chatId1
                                //   onClick = {() => handleOnClickMessageWeb('7GYQ4lS6kIE_ERKOa7LD','sell','EBMYZgd1AE7yOH4DmnNgzJT4o-c','Ew5DPOKSeoikEXRoVwO2DvOSPaQ%C2%AB%C2%BBEBMYZgd1AE7yOH4DmnNgzJT4o-c%C2%AB%C2%BB7GYQ4lS6kIE_ERKOa7LD')}
                                //  to={`/contact/?propertyId=${card.propertyId}?transaction=${publishedFor}?intId=${decoded.data.id}?chat=${decoded.data.id}«»${card.contact.}`}
                                > Contacto </button>
                                {decoded ? (
                                    <Fragment>
                                        {decoded.data.email === card.contact.email ? (
                                            <Modal
                                                noButton={true}
                                                title='Esta es tu propiedad'
                                                text='Eres el dueño de este inmueble, por lo tanto no puedes establecer contacto contigo mismo'
                                                acept={false}
                                                openModal={modalOn}
                                                actionButton={() => handleCloseError()}
                                                //onClick = {() => handleCloseError()}
                                                handleCloseOut={() => handleCloseError()}
                                                cancel='Ok'
                                                close={true}
                                            />
                                        ) : (
                                                <Modal
                                                    noButton={true}
                                                    title='Debes validar tu email para contactar'
                                                    text='El dueño de la propiedad ha decidido no ser contactado por usuarios que no tengan su email validado'
                                                    acept='Validar correo electrónico'
                                                    customLink={`/myprofile/verificated/verifyEmail?userId=${decoded.data.id}`}
                                                    openModal={modalOn}
                                                    actionButton={() => handleCloseError()}
                                                    //onClick = {() => handleCloseError()}
                                                    handleCloseOut={() => handleCloseError()}
                                                    cancel='Cancelar'
                                                    close={true}
                                                />
                                            )}

                                    </Fragment>
                                ) : (<Modal
                                    noButton={true}
                                    title='Debes iniciar sesion para contactar'
                                    text='Para poder comunicarte con el dueño de la propiedad debes iniciar sesión. Si no tienes cuenta aún puedes crear una completamente gratis en unos minutos!'
                                    acept='Ingresar'
                                    customLink={`/welcome`}
                                    openModal={modalOn}
                                    actionButton={() => handleCloseError()}
                                    //onClick = {() => handleCloseError()}
                                    handleCloseOut={() => handleCloseError()}
                                    cancel='cancelar'
                                    close={true}
                                />)}

                            </div>
                        }
                    </div>
                </div>
                

                <div className='expansionPanelContainerWebVersion'>
                    {screenSizeWidth > 1030 &&
                        expansionPanelsData &&
                        expansionPanelsData.map((data, index) =>
                            <ExpansionPanels key={index} item={data} property={card} />)}
                            
                </div>
                
                {screenSizeWidth > 1030 && <div> {map} </div>}
            </div>
            

        </div>

    )
});

const InternalCard = (props => {

    const { property, publishedFor, dataFooter, expansionPanelsData, map } = props;

    const [card, setCard] = useState(null);
    const [dictionary, setDictionary] = useState({});

    const [openBackdrop, setOpenBackdrop] = useState(false);

    useEffect(() => {

        axios.get(BASE_URL)
            .then(response => {
                const data = response.data.responseData.dict.propertyTypes
                setDictionary(data)
            })
            
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })

        function getMeProperty() {
            setCard(property);
        }
        getMeProperty();

        // eslint-disable-next-line 
    }, []);

    let propTypeTranslation;
    for (let propType in dictionary) {
        if (property.propertyType === propType) {

            dictionary[propType] === 'Casa country' ? propTypeTranslation = 'Casa Country' : propTypeTranslation = dictionary[propType]
            dictionary[propType] === 'Lote o terreno' ? propTypeTranslation = 'Lote / Terreno' : propTypeTranslation = dictionary[propType]
        }
    }

  

    return (
        <div style={{ width: '100%' }}>
            {card === null ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                    <CircularProgress size={70} />
                </div> :
                <>
                    <CustomCard
                        history={props.history}
                        propTypeTranslation={propTypeTranslation}
                        card={card}
                        publishedFor={publishedFor}
                        dataFooter={dataFooter}
                        expansionPanelsData={expansionPanelsData}
                        map={map}
                        openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop}
                    />
                </>
            }
        </div>
    )
});

export default InternalCard;
