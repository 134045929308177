import React from "react";
import Select from "../Common/Select/Select";
import RouterColumn from "../RouterColumn/RouterColumn";
import Update from './updateForm'

export default function Usuarios() {



  let word = [{ nombre: "Crear nota", link: "/admin/blog"}];

  return (
    <div>
      <RouterColumn word={"Usuarios"} />

      <div>
        <Select Word={word} palabra={"Ver nota"} />
      </div>
    <Update  />
    </div>
  );
}
