import React from 'react'
import { Line } from 'react-chartjs-2'
import './Grafico.css'

export default function Grafico({words, datos}){


    let info = Object.values(datos)
 
    const data = {
        labels: words,
        datasets:[
            {
            label:"Cargados por mes",
            fill: false,
            pointStyle: 'rectRounded',
            // borderDash: [5, 5],
            lineTension: 0,
            backgroundColor: '#5963cc',
            borderColor:'#e32085',
            pointBorderColor:'#5963cc',
            pointBorderWidth:6,
            pointHoverRadius:5,
            pointHoverBackgroundColor:'#5963cc',
            pointHoverBorderColor:'#5963cc',
            pointRadius: 2,
            pointHitRadius: 10,
            data: info
            }
        ]
    }

    return (
        <div className="conteiner-chart">
            <Line data={data}  width={100} height={20} />
        </div>
    )

}