import 'date-fns';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../Context'
import DatePicker from "react-datepicker";
import axios from 'axios'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../config/urls'
// import './Propiedades.css'
import TotalCount from '../Common/TotalCount/TotalCount'
// import Selected from './Selected'
import Tables from './Tablas'

export default function MaterialUIPickers({componente, palabra1, palabra2}) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date('2020-08-18T21:11:54'));
  const [selectedDateTwo, setSelectedDateTwo] = React.useState(new Date('2021-08-18T21:11:54'));
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [dataprop, setdataprop] = useState(0)

  const { propSelect, setPropSelect } = useContext(Context);

  const { district, state, condicion, tipo_prop } = propSelect

  const token = localStorage.getItem('tokenAdmin');

  // const handleChange = (req, res) => {

  //   setPropSelect({
  //     ...propSelect,
  //     [e.target.name]: e.target.value
  //   })

  // }

  const handleGetProp = () => {

    try {
      
      const request = axios.get(URL + `admin/dashboard/allProperties`, { headers: { 'token': token } })
      .then((res) => {

        setdataprop(res.data.responseData[0].numberOfProperties)

        // setdataprop(res.data.responseData[0])

      })
      .catch((err) => {
        console.log(err)

      })

    } catch (error) {
      
      console.log(error)

    }

  }


  const handleSend = () => {
  

      try {
        setloading(true)
        const request = axios.get(URL + `admin/dashboard/appraiser`, { headers: { 'token': token } })
        .then((res) => {
          setdata(res.data.responseData[0])
          setloading(false)
        })
        .catch((err) => {
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

  }

  useEffect(() => {

    handleSend()
    handleGetProp()
  },[selectedDateTwo, selectedDate])



  return (
      <div>
        <div style={{marginLeft: "960px", marginRight: "50px", marginTop: "-70px",}} >
          <TotalCount palabra1={"TOTAL"} palabra2={"PROPIEDADES"} total={data.length} />
        </div>

  
   {
     !loading && <Tables name={componente} rowsData={data} />
   } 

    {
        loading && <div className='messages-loading-screen'>
        <CircularProgress />
        </div>
    }
    </div>
  );
}