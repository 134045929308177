import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Carousel.css';
//import URL from '../../../config/urls';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

import Swippeable from '../InternalProperty/components/Swipeable/Swipeable';

const CarouselWeb = ({ images }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    let imageLinks = []
    images.map(image => imageLinks.push(image.link))

    const [lightbox, setLightbox] = useState('');
    const [openLightbox, setOpenLightbox] = useState(false)

    function handleLightbox(value) {
        if (value === 'close') {
            setOpenLightbox(false)
        } else {
            setOpenLightbox(true)
            setLightbox(value)
        }
    };


    return (
        <div className='carouselWeb'>
            <Carousel
                showArrows={true}
                showThumbs={true}
                infiniteLoop={true}
                dynamicHeight={false}
                showStatus={false}
                showIndicators={true}
            >

                {imageLinks.map((image, index) => (
                    <div id={index} onClick={() => handleLightbox(image)} >
                        <img alt='carousel item' src={image} />
                    </div>
                ))}

            </Carousel>

            {lightbox.length > 1 && openLightbox &&
                <Backdrop className={classes.backdrop} open={openLightbox}>
                    <div className='lightboxContentWeb' >
                        <div className='escapeButton' onClick={() => handleLightbox('close')} > x </div>
                        {/* <img src={lightbox} alt='' className='imgLightbox' /> */}
                        < Swippeable images={imageLinks} />
                    </div>
                </Backdrop>
            }

        </div>
    );
};

export default CarouselWeb;