import money from '../assets/images/moneyCard.svg';
import certificated from '../assets/images/certificateCard.svg';
import camera from '../assets/images/cameraCard.svg';
// import signal from '../assets/images/signal.png';
import signal from '../assets/images/services/cartel.png';
// import photographer from '../assets/images/photographer.png';
import photographer from '../assets/images/services/fotografo.png';
import documents from '../assets/images/services/documento.png';
import comercia from '../assets/images/services/comercia.jpeg';

export let servicesData = [
    {
        id: 200,
        icon: money,
        title: 'Precio validado',
        description: 'Semper pharetra vehicula dui id porta congue gravida.'
    },
    {
        id: 201,
        icon: certificated,
        title: 'Título validado',
        description: 'Semper pharetra vehicula dui id porta congue gravida.'
    },
    {
        id: 202,
        icon: camera,
        title: 'Fotógrafo validado',
        description: 'Semper pharetra vehicula dui id porta congue gravida.'
    }
];

export let servicesDataBigCard = [
    {
        path: 'services/signal',
        id: 203,
        img: signal,
        type: 'Servicio',
        title: 'Cartelería',
        description: 'Sabemos que la gente que busca comprar o alquilar, también lo hace de manera activa cuando va a su trabajo o camina por la ciudad. Con nuestro servicio único de cartel a domicilio podrás incrementar tus posibilidades de comercializar tu propiedad.',
        action: 'Adquirir',
    },
    {
        path: 'services/photographer',
        id: 204,
        img: photographer,
        type: 'Servicio',
        title: 'Fotógrafo profesional',
        description: 'En un mundo cada vez más digital, las imágenes hablan por si solas. Por eso, aumentá tus posibilidades de comercializar tu propiedad con imágenes tomadas por un fotógrafo profesional, quien luego las subirá a nuestra plataforma para que vos no tengas que ocuparte de las fotos.',
        action: 'Adquirir',
    },
    {
        path: 'question/pack1',
        id: 205, 
        img: documents,
        type: 'Servicio',
        title: 'Pack contratos',
        description: 'En Hi Propi contamos con un equipo legal y jurídico preparado para acompañarte en el proceso de comercialización de tu propiedad, gestionando señas, boletos de compra o alquiler, informes personales o del inmueble. Los contratos son redactados y revisados por un abogado matriculado en el Colegio de Abogados de la provincia donde se encuentra tu propiedad.',
        action: 'Adquirir',
    },
    {
        path: 'services/comercializacion',
        id: 206, 
        img: comercia,
        type: 'Servicio',
        title: 'Asesor Comercial',
        description: 'Con Hi Propi podés contar con un asesor comercial preparado para asesorarte y acompañarte en la comercialización de tu inmueble.',
        action: 'Adquirir',
    }
];