import 'date-fns';
import React, {useEffect, useState} from 'react';
import Select from '../Common/Select/Select'
import axios from 'axios'
import DatePicker from '../Common/DatePicker/DatePicker'
import URL from '../../../../config/urls'
import RouterColumn from '../RouterColumn/RouterColumn'
import Tables from './Tables'


export default function Usuarios() {


  let word = []

  return (
 <div>
        <RouterColumn word={"Usuarios"} />

        <div>
          <Select Word={word} palabra={"Chats"} />
        </div>     

        <DatePicker componente={"chats"} palabra1={"TOTAL"} palabra2={"CHATS"} style={{marginLeft: "800px", marginRight: "50px"}} />
 

 
    </div>
  );
}