import React from 'react';
import TopBar from '../../commons/BlueTopBar/BlueTopBar';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import './Document.css'

const DocQuestion = ({ history }) => {

    const QuestionsAnswers = [
        {
            title: "VENTAS:",
            subtitle: "Hi Propi te ofrece los contratos necesarios para concretar la compra-venta de tu próximo inmueble, o la seña del mismo. Los mismos están redactados según las leyes vigentes de tu país, y los usos y costumbres propios de la región, y los realizan abogados matriculados de tu localidad.",
            que: 'Seña del inmueble:',
            ans: `Este pre acuerdo consiste en el pago de un adelanto del precio del inmueble, y es confirmatorio de la compra-venta. Como tal, te permite pre acordar montos, forma de pago, y condiciones del inmueble. Las partes no pueden arrepentirse de concretar la operación, en tal caso, quien entregó la seña y se arrepiente la pierde el monto abonado; y si se arrepiente quien la recibió, debe pagar el doble del valor. Hi propi te asistirá para que tengas la seña de tu inmueble a medida de la próxima operación, prefijando el precio del inmueble y los plazos para llevar adelante la venta, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Boleto compraventa:',
            ans: `Es el contrato que permite concretar la compra-venta de tu inmueble. Una vez que firmes este acuerdo, se generan obligaciones para cada una de las partes: El vendedor deberá otorgar de la escritura del inmueble (ante un Escribano competente) y la entrega de la posesión de lo vendido; para el comprador, la entrega de la suma de dinero pactada como precio. Hi propi te asistirá para que tengas el contrato a tu medida, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Informe personal:',
            ans: `Este informe, emitido por una base de datos pública de acceso privado, te permitirá conocer el perfil público crediticio de la persona con quién estás por contratar, para saber, entre otras cosas, si respecto de esa persona existen registradas o no inhibiciones o interdicciones que le impidan disponer de sus bienes, si tiene juicios en contra, o deudas ante entidades financieras. Esta información es brindada por bases de datos que no forman parte de Hi Propi. No implican certeza sobre el cumplimiento o no de las obligaciones que asuma la otra parte, pero te ayudan a adoptar una decisión correcta a la hora de celebrar una seña o un contrato de compra-venta.`
        },
        {
            que: 'Informe del inmueble',
            ans: `Este informe es emitido por el Registro Público de la jurisdicción donde se encuentra el bien. Te permite saber quién es el dueño del bien, cuales son los datos de superficie y de tipo catastral, y conocer si sobre ese bien hay embargos, hipotecas o cualquier otro tipo de gravamen. Recomendamos solicitar un informe del inmueble previo a suscribir un boleto de compraventa, que te permita conocer jurídicamente el estado del bien que estás por vender o comprar.`
        },
        {
            que: 'Consulta Juridica',
            ans: `Hi Propi te asiste y pone a tu disposición un cuerpo de abogados matriculados de tu jurisdicción, a efectos de que te asesoren sobre la operación que quieres celebrar, cuales son los recaudos que debes tener a la hora de contratar, y toda otra información indispensable para llevar adelante la misma. Te guiamos paso a paso en la celebración de los contratos, y si persisten las dudas, concretamos una cita con un abogado, vía streaming, para que evacúe las mismas.`
        },
        

        {
            title: "ALQUILERES:",
            subtitle: "Hi Propi te ofrece los contratos necesarios para concretar el alquiler de tu próximo inmueble, o la seña del mismo. Los mismos están redactados según las leyes vigentes de tu país, y los usos y costumbres propios de la región, y los realizan abogados matriculados de tu localidad.",
            que: 'Seña del alquiler del inmueble:',
            ans: `Este pre acuerdo consiste en el pago de un adelanto de futuros alquileres, y es confirmatorio del contrato de alquiler a celebrar. Como tal, te permite pre acordar montos, forma de pago, y condiciones del inmueble. Las partes no pueden arrepentirse de concretar la operación, en tal caso, quien entregó la seña y se arrepiente la pierde el monto abonado; y si se arrepiente quien la recibió, debe pagar el doble del valor. Hi propi te asistirá para que tengas la seña del alquiler de tu inmueble a medida de la próxima operación, prefijando costos de alquiler, condiciones de entrega del inmueble y los plazos para llevar adelante la operación, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Contrato de alquiler',
            ans: `Es el contrato que permite concretar el alquiler de tu inmueble. Una vez que firmes este acuerdo, se generan obligaciones para cada una de las partes: El locador (dueño de la cosa) se obliga a dar el uso y goce del inmueble por un determinado tiempo al locatario, y este último a cambio del uso y goce del inmueble entregará a cambio el pago de un precio en dinero. Hi propi te asistirá para que tengas el contrato a tu medida, con todos los detalles que otorguen a las partes a mayor seguridad, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Informe personal:',
            ans: `Este informe, emitido por una base de datos pública de acceso privado, te permitirá conocer el perfil público crediticio de la persona con quién estás por contratar, para saber, entre otras cosas, si respecto de esa persona existen registradas o no inhibiciones o interdicciones que le impidan disponer de sus bienes, si tiene juicios en contra, o deudas ante entidades financieras. Esta información es brindada por bases de datos que no forman parte de Hi Propi. No implican certeza sobre el cumplimiento o no de las obligaciones que asuma la otra parte, pero te ayudan a adoptar una decisión correcta a la hora de celebrar una seña o un contrato de compra-venta.`
        },
        {
            que: 'Informe del inmueble',
            ans: `Este informe es emitido por el Registro Público de la jurisdicción donde se encuentra el bien. Te permite saber quién es el dueño del bien, cuales son los datos de superficie y de tipo catastral, y conocer si sobre ese bien hay embargos, hipotecas o cualquier otro tipo de gravamen. Recomendamos solicitar un informe del inmueble que sea dado en garantía de cumplimiento de un contrato, que te permita conocer jurídicamente el estado del mismo.`
        },
        {
            que: 'Consulta Juridica',
            ans: `Hi Propi te asiste y pone a tu disposición un cuerpo de abogados matriculados de tu jurisdicción, a efectos de que te asesoren sobre la operación que quieres celebrar, cuales son los recaudos que debes tener a la hora de contratar, y toda otra información indispensable para llevar adelante la misma. Te guiamos paso a paso en la celebración de los contratos, y si persisten las dudas, concretamos una cita con un abogado, vía streaming, para que evacúe las mismas.`
        },


        
        {
            title: "ALQUILER TEMPORARIO:",
            subtitle: "Hi Propi te ofrece los contratos necesarios para concretar el alquiler temporario del inmueble que te interesa, o la seña del mismo. Los mismos están redactados según las leyes vigentes de tu país, y los usos y costumbres propios de la región, y los realizan abogados matriculados de tu localidad.",
            que: 'Seña del alquiler temporario del inmueble:',
            ans: `Este pre acuerdo consiste en el pago de un adelanto de futuros alquileres, y es confirmatorio del contrato de alquiler temporario a celebrar. Como tal, te permite pre acordar montos, forma de pago, y condiciones del inmueble. Las partes no pueden arrepentirse de concretar la operación, en tal caso, quien entregó la seña y se arrepiente la pierde el monto abonado; y si se arrepiente quien la recibió, debe pagar el doble del valor. Hi propi te asistirá para que tengas la seña del alquiler temporario de tu inmueble a medida de la próxima operación, prefijando costos de alquiler, condiciones de entrega del inmueble y los plazos para llevar adelante la operación, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Contrato de alquiler temporario',
            ans: `Es el contrato que permite concretar el alquiler de tu inmueble. Una vez que firmes este acuerdo, se generan obligaciones para cada una de las partes: El locador (dueño de la cosa) se obliga a dar el uso y goce del inmueble por un determinado tiempo al locatario, y este último a cambio del uso y goce del inmueble entregará a cambio el pago de un precio en dinero. Hi propi te asistirá para que tengas el contrato a tu medida, con todos los detalles que otorguen a las partes a mayor seguridad, a un solo click, y revisado por profesionales de las ciencias jurídicas idóneos para tal acto.`
        },
        {
            que: 'Informe personal:',
            ans: `Este informe, emitido por una base de datos pública de acceso privado, te permitirá conocer el perfil público crediticio de la persona con quién estás por contratar, para saber, entre otras cosas, si respecto de esa persona existen registradas o no inhibiciones o interdicciones que le impidan disponer de sus bienes, si tiene juicios en contra, o deudas ante entidades financieras. Esta información es brindada por bases de datos que no forman parte de Hi Propi. No implican certeza sobre el cumplimiento o no de las obligaciones que asuma la otra parte, pero te ayudan a adoptar una decisión correcta a la hora de celebrar una seña o un contrato de compra-venta.`
        },
        // {
        //     que: 'Informe del inmueble',
        //     ans: `Este informe es emitido por el Registro Público de la jurisdicción donde se encuentra el bien. Te permite saber quién es el dueño del bien, cuales son los datos de superficie y de tipo catastral, y conocer si sobre ese bien hay embargos, hipotecas o cualquier otro tipo de gravamen. Recomendamos solicitar un informe del inmueble que sea dado en garantía de cumplimiento de un contrato, que te permita conocer jurídicamente el estado del mismo.`
        // },
        {
            que: 'Consulta Juridica',
            ans: `Hi Propi te asiste y pone a tu disposición un cuerpo de abogados matriculados de tu jurisdicción, a efectos de que te asesoren sobre la operación que quieres celebrar, cuales son los recaudos que debes tener a la hora de contratar, y toda otra información indispensable para llevar adelante la misma. Te guiamos paso a paso en la celebración de los contratos, y si persisten las dudas, concretamos una cita con un abogado, vía streaming, para que evacúe las mismas.`
        },
    ]

    let screenSizeWidth = window.innerWidth;

    return (
        <div  >
            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
            :
                <TopBar
                    title='Consulta Juridica'
                    link='/'
                />
            }
            <div className='services-web-container-card'  >
                <div className='services-web-content-card'>


                    <div className='servicesContainer'>
                    { screenSizeWidth > 1030 &&
                        <div className='report-title-container' style={{ margin: 'auto' }} style={{ marginTop: screenSizeWidth > 1030 ? "35px" : "0px", marginBottom: "30PX"}}>
                            <label>Consulta Juridica</label>
                        </div>
                    }
                        <div className='servicesContentText'>
                        </div>
                        <div className="text-preguntas" >
                        {
                            QuestionsAnswers.map((e,index) => {
                                console.log(e)
                                return (
                                    <>
                                        <div style={{marginBottom: "20px"}} >
                                        <h3 className="title-text-color ">  {e.title} </h3>
                                        <label style={{marginBottom: "20px"}} >{e.subtitle}</label>
                                        <strong className="title-text-color ">{e.que}</strong>
                                        <br/>
                                        <label  >{e.ans}</label>
                                        </div>
                                    </>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DocQuestion;