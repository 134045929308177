import React from 'react'
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import mobile from './imgs/mobile.svg'
import './Repair.css'

const ProximamenteDocumentos = ({history}) => {

    let screenSizeWidth = window.innerWidth;

    return (

            <div>
     {screenSizeWidth > 1030 ? (
            <TopAppBarWeb history={history} />
          ) : (
            <TopAppBar />
          )}
          {
              screenSizeWidth > 1030 ? 
              (
                  <div >
                      <div className="contenedor-success-check" >
                          <img src={mobile} className="check-success" />

                          <strong className="pago-confirmado p-proximamente"> Toda tu documentación,<br/>
                          directa y simple con Hi Propi</strong>
                          <p className="div-proximamente-data">
                          Estamos trabajando para ofrecerte todos los servicios que necesitas para comercializar de manera directa tu inmueble.
                      </p>
                      <div className='how-much-your-properties-container'>
                                    <button style={{width: "300px"}} onClick={() => history.push('/services')} > volver</button>
                        </div>
                      </div>

                  </div>
              ) : (
                  <div> 
                      <div className="contenedor-success-check" >
                          <img src={mobile} className="check-success" />
                          <strong className="pago-confirmado  p-proximamente" style={{fontSize: "20px"}}>Toda tu documentación,<br/>
                          directa y simple con Hi Propi</strong>
                          <p className="div-proximamente-data">
                          Estamos trabajando para ofrecerte todos los servicios que necesitas para comercializar de manera directa tu inmueble.
                          </p>
                          <div className='serviceSignalButtonContainer'>
                                    <button onClick={() => history.push('/services')}> volver</button>
                         </div>
                      </div>

                  </div>
              )
          }
            </div>
        
    )
}

export default ProximamenteDocumentos
