import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RouterColumn from './components/RouterColumn/RouterColumn'

const Admin = () => {

    return(
   <div>
   <RouterColumn />
   </div>
    )

}

export default Admin