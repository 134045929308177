import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMemoryHistory } from "history";
import GoogleAnalytics from './components/commons/Analytics/Analytics';
import ScrollPage from './components/commons/ScrollPage/ScrollPage';

// Search properties flow
import PropertiesList from './components/pages/PropertiesList/PropertiesList';
import FullInternalProperty from './components/pages/Map/Map';
import FilterAllDetails from './components/pages/Filter/FilterAllDetails';

import NavBarSearch from './components/pages/SearchNavbar/SearchNavbar'

// User flow
import CreateUser from './components/pages/CreatAccount/CreatAccount';
import Login from './components/pages/Welcome/Welcome';
import RecoverPassword from './components/pages/Welcome/RecoverPass/RecoverPass';
import EmailValidate from './components/pages/CreatAccount/EmailValidate/EmailValidate';
import ErrorPage from './components/commons/ErrorPage/ErrorPage';

// ABM properties
import Favorites from './components/pages/Favorites/Favorites';
import MyProperties from './components/pages/MyProperties/MyProperties';
import NewPropertyStepOne from './components/pages/NewProperty/NewProperty1';
import NewPropertyStepTwo from './components/pages/NewProperty/NewProperty2';
import NewPropertyStepThree from './components/pages/NewProperty/NewProperty3';
import NewPropertyStepFour from './components/pages/NewProperty/NewProperty4';
import NewPropertyStepFive from './components/pages/NewProperty/NewProperty5';
import NewPropertyLastStep from './components/pages/NewProperty/NewPropertyFinal';
import PropertyCreatedConfirm from './components/pages/NewProperty/PropertyCreated';

// My profile page and components
import MyProfile from './components/pages/MyProfile/MyProfile';
import Verification from './components/pages/MyProfile/components/Verification/Verification';
import VerifyEmail from './components/pages/MyProfile/components/Verification/VerifyEmail';
import VerifyEmailConfirm from './components/pages/MyProfile/components/Verification/VerifyEmailConfirm';
import VerifyPhone from './components/pages/MyProfile/components/Verification/VerifyPhone';
import VerifyPhoneCode from './components/pages/MyProfile/components/Verification/VerifyPhoneCode';
import VerifyPhoneConfirm from './components/pages/MyProfile/components/Verification/VerifyPhoneConfirm';
import DeleteAccount from './components/pages/MyProfile/components/DeleteAccount/DeleteAccount';
import YourData from './components/pages/MyProfile/components/YourData/YourData';
import Security from './components/pages/MyProfile/components/Security/Security';

// Messages flow
import Messages from './components/pages/Messages/Messages';
import Notifications from './components/pages/Notifications/Notifications';
import Contact from './components/pages/Contact/Contact';

// Virtual appraiser
import Appraiser from './components/pages/Appraiser/Appraiser';

// Reports
import ReportProperty from './components/commons/ExpansionPanels/components/Complaint/ComplaintPage';
import ReportUser from './components/pages/Contact/components/ReportUser';

// Services
import Services from './components/pages/Services/Services';
import SignalPage from './components/pages/Services/ServicesPages/SignalPage';
import BuySignal from './components/pages/Services/ServicesPages/BuyServices/BuySignalService/BuySignal';
import BuySignalConfirm from './components/pages/Services/ServicesPages/BuyServices/BuySignalService/BuySignalConfirm';
import BuySignalSuccessful from './components/pages/Services/ServicesPages/BuyServices/BuySignalService/BuySignalSuccessful';
import PhotographerPage from './components/pages/Services/ServicesPages/PhotographerPage';
import BuyPhotographer from './components/pages/Services/ServicesPages/BuyServices/BuyServicePhotographer/BuyPhotographer';
import BuyPhotographerConfirm from './components/pages/Services/ServicesPages/BuyServices/BuyServicePhotographer/BuyPhotographerConfirm';
import BuyPhotographerSuccessful from './components/pages/Services/ServicesPages/BuyServices/BuyServicePhotographer/BuyPhotographerSuccessful';

// Blog
import InternalBlog from './components/pages/Blog/InternalBlog';

// Terms
import Terms from './components/pages/Terms/Terms';

// Consult
import Consult from './components/pages/Consult/Consult';
import PreguntasFrecuentes from './components/pages/Preguntas/PreguntasFrecuentes';

// Admin
import Admin from './components/admin/admin'
import LoginAdmin from './components/admin/components/Login/Login'
import NavbarAdmin from './components/admin/components/NavbarAdmin/Navbar'
import Usuarios from './components/admin/components/Usuarios/Usuarios'
import UsuarioCreado from './components/admin/components/UsuarioCreado/UsuarioCreado'
import Carteleria from './components/admin/components/Servicios/Carteleria/Carteleria'
import Fotografo from './components/admin/components/Servicios/Fotografo/fotografo'
import Propiedades from './components/admin/components/Propiedades/Propiedades'
import UserDenied from './components/admin/components/Denuncias/User/User'
import PropDenied from './components/admin/components/Denuncias/Propiedades/Prop'
import Tasador from './components/admin/components/Tasador/Tasador'
import Rol from './components/admin/components/Rol/Rol'
import Chat from './components/admin/components/Chat/Chat'
import Blog from  './components/admin/components/Blog/Blog'
import UpdateBlog from './components/admin/components/UpdateBlog/UpdateBlog'
import UpdateOne from './components/admin/components/UpdateBlog/UpdateOne/UpdateOneBlog'
import Emprendimiento from './components/admin/components/Empredimientos/EmprendimientosWeb'
import Contratos from './components/admin/components/Servicios/Contratos/Contrato';


// Repair
import RepairWeb from './components/pages/Repair/RepairWeb'


// Preguntas documentos
import QuestionDocs from './components/pages/QuestionDocs/QuestionDoc';


//Pack documentos
import DocPackQues from './components/pages/DocumentPack/DocPackQues';
import DocQuestion from './components/pages/DocumentPack/DocQuestion';
import { loadingPage } from './components/pages/LoadingPage/loadingPage';
import FormUserData from './components/pages/FormUserData/FormUserData';
import SuccessPay from './components/pages/SuccessPay/SuccessPay';
import ProximamenteDocumentos from './components/pages/Repair/proximamente';
import { ToWaysToPay } from './components/pages/TwoWaysToPay/ToWaysToPay';
import { TarjetaMobile } from './components/pages/TwoWaysToPay/mobile/TarjetaMobile';
import { TransferenciaMobile } from './components/pages/TwoWaysToPay/mobile/TransferenciaMobile';
import FormUserDataTransf from './components/pages/FormUserData/FormUserDataTranf';
import Comercializacion from './components/pages/Services/ServicesPages/Comercializacion';
import ComercializacionAdmin from './components/admin/components/Servicios/Comercializacion/Comercializacion';
import Aranceles from './components/admin/components/Aranceles/Aranceles';

// Principal page with principal search of properties
const Landing = lazy(() => import('./components/pages/Search/PrincipalSearch'));




// import IsValidated = lazy(() => import( './components/pages/CreatAccount/IsValidated/IsValidated';
// import IsFullValidation = lazy(() => import( './components/pages/FullAccountValidation/IsFullValidation';
const ChangePass = lazy(() => import( './components/pages/Welcome/RecoverPass/ChangePass'));
// import SuccessfulRecovery = lazy(() => import( './components/pages/Welcome/RecoverPass/SuccessfulRecovery';

// Used for urls data
export const history = createMemoryHistory();

history.listen((location, action) => {
    // location is an object like window.location
    console.log(action, location.pathname, location.state);
});

const Routes = () => {

    let urlData = [
        {
            path: '/',
            component: Landing
        },
        {
            path: '/properties',
            component: PropertiesList
        },
        {
            path: '/internalproperty',
            component: FullInternalProperty
        },
        {
            path: '/filterdetails',
            component: FilterAllDetails
        },
        {
            path: '/createaccount',
            component: CreateUser
        },
        {
            path: '/recoverpass',
            component: RecoverPassword
        },
        {
            path: '/emailvalidate',
            component: EmailValidate
        },
        {
            path: '/favorites',
            component: Favorites
        },
        {
            path: '/myproperties',
            component: MyProperties
        },
        {
            path: '/newproperty/1',
            component: NewPropertyStepOne
        },
        {
            path: '/newproperty/2',
            component: NewPropertyStepTwo
        },
        {
            path: '/newproperty/3',
            component: NewPropertyStepThree
        },
        {
            path: '/newproperty/4',
            component: NewPropertyStepFour
        },
        {
            path: '/newproperty/5',
            component: NewPropertyStepFive
        },
        {
            path: '/newproperty/final',
            component: NewPropertyLastStep
        },
        {
            path: '/newproperty/created',
            component: PropertyCreatedConfirm
        },
        {
            path: '/myprofile',
            component: MyProfile
        },
        {
            path: '/myprofile/verificated',
            component: Verification
        },
        {
            path: '/myprofile/verificated/verifyEmail',
            component: VerifyEmail
        },
        {
            path: '/myprofile/verificated/verifyEmail/confirm/:mailToken',
            component: VerifyEmailConfirm
        },
        {
            path: '/myprofile/verificated/verifyPhone',
            component: VerifyPhone
        },
        {
            path: '/myprofile/verificated/verifyPhone/code',
            component: VerifyPhoneCode
        },
        {
            path: '/myprofile/verificated/verifyPhone/code/confirm',
            component: VerifyPhoneConfirm
        },
        {
            path: '/myprofile/deleteaccount',
            component: DeleteAccount
        },
        {
            path: '/myprofile/security',
            component: Security
        },
        {
            path: '/myprofile/mydata',
            component: YourData
        },
        {
            path: '/messages',
            component: Messages
        },
        {
            path: '/contact',
            component: Contact
        },
        {
            path: '/notifications',
            component: Notifications
        },
        {
            path: '/appraiser',
            component: Appraiser
        },
        {
            path: '/reportProperty',
            component: ReportProperty
        },
        {
            path: '/reportUser',
            component: ReportUser
        },
        {
            path: '/services',
            component: Services
        },
        {
            path: "/services/comercializacion",
            component: Comercializacion
        },
        {
            path: '/services/signal',
            component: SignalPage
        },
        {
            path: '/services/signal/buy',
            component: BuySignal
        },
        {
            path: '/services/signal/buy/confirm',
            component: BuySignalConfirm
        },
        {
            path: '/services/signal/buy/confirm/successful',
            component: BuySignalSuccessful
        },
        {
            path: '/services/photographer',
            component: PhotographerPage
        },
        {
            path: '/services/photographer/buy',
            component: BuyPhotographer
        },
        {
            path: '/services/photographer/buy/confirm',
            component: BuyPhotographerConfirm
        },
        {
            path: '/services/photographer/buy/confirm/successful',
            component: BuyPhotographerSuccessful
        },
        {
            path: '/post',
            component: InternalBlog
        },
        {
            path: '/terms',
            component: Terms
        },
        {
            path: '/error-page',
            component: ErrorPage
        },
        {
            path: '/consult',
            component: Consult
        },
        {
            path: '/preguntas',
            component: PreguntasFrecuentes
        },
        {
            path: '/question/pack1',
            component: DocPackQues
        },
        {
            path: '/question/pack2',
            component: DocQuestion
        },
        {
            path: '/userVerifications/reset/password/:mailToken',
            component: ChangePass
        },
        {
            path: '/login/dashboard',
            component: LoginAdmin
        },

        {
            path: '/admin',
            component: Admin
        },
        {
            path: '/admin/users',
            component: Usuarios
        },
        {
            path: '/admin/nuevo',
            component: UsuarioCreado
        },
        {
            path: '/admin/servicios',
            component: Carteleria
        },
        {
            path: "/admin/fotografo",
            component: Fotografo
        },
        {
            path: "/admin/comercializacion",
            component: ComercializacionAdmin
        },
        {
            path: "/admin/contratos",
            component: Contratos
        },
        {
            path: "/admin/properties",
            component: Propiedades
        },
        {
            path: "/admin/denuncias",
            component: UserDenied
        },
        {
            path: "/admin/propdenounced",
            component: PropDenied
        },
        {
            path: "/admin/tasador",
            component: Tasador
        },
        {
            path: "/admin/administradores",
            component: Rol
        },
        {
            path: "/admin/chat",
            component: Chat
        },
        {
            path: "/search/locate",
            component: NavBarSearch
        },
        {
            path: "/admin/blog",
            component: Blog
        },
        {
            path: "/admin/aranceles",
            component: Aranceles
        },
        {
            path: "/admin/UpdateBlog",
            component: UpdateBlog
        },
        {
            path: "/admin/UpdateBlog/:id",
            component: UpdateOne
        },
        {
            path: "/admin/emprendimientos",
            component: Emprendimiento
        },
        {
            path: "/doc/questions",
            component: QuestionDocs
        },
        {
            path: "/data/user",
            component: FormUserData
        },
        {
            path: "/data/user/transf",
            component: FormUserDataTransf
        },
        {
            path: "/pay/successful",
            component: SuccessPay
        },
        {
            path: "/pay/proximamente",
            component: ProximamenteDocumentos
        },
        {
            path: "/pay/ways",
            component: ToWaysToPay
        },
        {
            path: "/pay/ways/tarjeta",
            component: TarjetaMobile
        },
        {
            path: "/pay/ways/tran",
            component: TransferenciaMobile
        }
    ];
    

    return (
        <>
            <Router>

                <ScrollPage>
                    <Suspense fallback={''}>
                        <Switch history={history}>
                                <Route 
                                    path="/welcome"
                                    component={Login}
                                />

                            {urlData.map((exactPath, id) =>
                                <Route 
                                exact 
                                key={id} path={exactPath.path} component={exactPath.component} />
                            )}


                        </Switch>
                    </Suspense>
                </ScrollPage>
                <GoogleAnalytics/>
            </Router>
        </>
    )
};

export default Routes;

