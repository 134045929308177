import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ModalProp from './ModalProp'
import { Context } from '../../../../Context'
// import './Tables.css'



const useStyles = makeStyles({
  root: {
    width: '980px',
    marginTop: "110px",
    marginLeft: "230px",
  },
  container: {

  },
});

export default function StickyHeadTable({ rowsData, name, handle }) {


  const { modal } = useContext(Context);


  let columns

    columns = [
      { id: 'propertyCode', label: 'Alias/Codigo' },
      { id: 'propertyType', label: 'Tipo' },
      { id: 'address', label: 'Ubicación'},
      { id: 'publishedFor', label: 'Condición'},
      { id: 'dateCreate', label: 'Fecha'}
    ];
    

  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [dataRow, setdataRow] = useState([])

  const [openModal, setopenModal] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const DataOfModal = (data) => {

    setdataRow(data)
    setopenModal(true)

  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div>
      {
        modal == true && openModal && <ModalProp datos={dataRow} funct={setopenModal} handle={handle} />
      }
    <Paper className={classes.root}>
      <TableContainer className="table-container-admin">
        <Table aria-label="sticky table">
          <TableHead  >
            <TableRow  >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  
                >
                 <strong  className="head-table-admin-prop" > {column.label} </strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}  onClick={ modal == true ? () => DataOfModal(row)  :  () => console.log("  ")} >
                       <strong className="strong-table-admin-prop" style={{width: "400px"}} > {column.format && typeof value === 'number' ? column.format(value) : value} </strong>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}