import React from 'react';
import isVerified from './../../../assets/images/isVerified.svg';
//import userImage from './../../../assets/images/user-image.jpg';

const MessageContainer = ({ message, decoded }) => {
    let userImage = (() => {
        let url;
        for (let a in message.avatars) {
            if (a !== decoded.data.id) {
                url = message.avatars[a];
            }
        } 
        return url;
    })();

    //     if (lastMessageEmitterId === decoded.id) {
    //         userImage = decoded.data.avatar;
    //     } else {
    //         userImage = message.lastMessage.avOwn;
    //     }

    let msg = message.lastMessage.txt
    let MSG = msg.substring(0, 50) + '(...)'
    let time_ = new Date(message.lastMessage.createdAt * 1000);
    let time = time_.toLocaleTimeString().slice(time_.length, -3);
    if (time.length > 5) { time = time.slice(0, -3) };
    return (

        <div style={{ display: 'flex', alignItems: 'center', width: '83%', margin: 'auto', minHeight: 55, maxHeight: 55 }} >
            <div className='message-container-avatar'>
                <img alt='user avatar' src={typeof userImage == 'object' ? userImage.link : userImage } />
            </div>
            <div className='message-container-user-information'>
                <div className='message-container-user-name-and-validation'>
                    <div style={{ display: 'flex' }}>
                        <h1> {decoded.data.id === message.lastMessage.emitter ? message.lastMessage.receiverFirstName : message.lastMessage.emmiterFirstName} </h1>
                        <img alt='is verified' src={isVerified} />
                    </div>
                    <label> {msg.length > 50 ? MSG : msg} </label>
                </div>
                <div className='message-container-message'>
                    <h2> {time} </h2>
                    {decoded.data.id === message.lastMessage.emitter ? null : <div className={ message.viewsFalse.length == 0 ? "" : "message-container-notification"}> {message.viewsFalse.length > 0 ? message.viewsFalse.length: null  } </div>}
                </div>
            </div>
        </div>
    )
}

export default MessageContainer;