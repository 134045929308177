import React, { useState } from "react";
import imageCompression from 'browser-image-compression';
import CircularProgress from '@material-ui/core/CircularProgress';

import URL from '../../../../../config/urls'
import axios from 'axios'

import "../../Blog/Blog.css";

const defaultState = {
  title: "",
  itemTitle: "",
  paragraph: "",
  paragraphImg: "",
};

const token = localStorage.getItem('tokenAdmin');


function Row({ onChange, onRemove, itemTitle, title, paragraph, handleOnAdd, index }) {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <input
          value={itemTitle}
          className="data-input-2"
          onChange={(e) => onChange("itemTitle", e.target.value)}
          placeholder="Titulo"
        />
        <input
          value={title}
          className="data-input-2"
          onChange={(e) => onChange("title", e.target.value)}
          placeholder="title"
        />
        <textarea
          placeholder="Parrafo"
          className="textarea-dash"
          value={paragraph}
          onChange={(e) => onChange("paragraph", e.target.value)}
        />
        <input
              type="file"
              className={"data-input-2 image-file"}
              id="paragraphImg"
              name="paragraphImg"
              accept="image/x-png,image/jpg,image/jpeg"
              data={index}
              onChange={handleOnAdd}
        />
            <label htmlFor="paragraphImg" className="data-input-2">
              Seleccioná las imágenes
            </label>
      </div>

      <button className="deleteButton" onClick={onRemove}>
        X
      </button>
    </div>
  );
}

export default function Info({ setparagrapth, postBody}) {
  const [rows, setRows] = useState([defaultState]);
  const [loading, setloading] = useState(false)



const handleOnChangeImg = async(index, e) => {
  setloading(true)
  let files = e.target.files;


  for (let i = 0; i < files.length; i++) {
      let formData = new FormData();


      const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
      }

      try {
        const compressedFile = await imageCompression(files[i], options);

        // Since compressed file is an instance of Blob the backend will throw a type error. Thus, we have to add properties to this object so it can be sent correctly to our backend
        var readyForBackendFile = new File([compressedFile], [files[i].name]);

        formData.append(`img.${i}`, readyForBackendFile);

        const res = await axios.post(
          URL + "blog/uploadImagesStorage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Acecpt: "*/*",
              "Cache-Control": "no-cache",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          }
        );



          // [e.target.name] = readyForBackendFile
          const copyRows = [...postBody];
          copyRows[index] = {
            ...copyRows[index],
            ["paragraphImg"]: res.data.responseData[0]
          };



          setparagrapth(copyRows)


      }

      catch (error) {
          console.log(error);
          i = 16
      }
  }

  setloading(false)

};


const deleteImgParrafo = (index) => {
  setloading(true)

  const copyRows = [...postBody];
  copyRows[index] = {
    ...copyRows[index],
    ["paragraphImg"]: ""
  }
  setparagrapth(copyRows)


  setTimeout(() => {
    setloading(false)

  }, 1000)

}

  const handleOnChange = (index, e) => {

    const { name, value } = e.target
    const copyRows = [...postBody];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setparagrapth(copyRows);
  };

  const handleOnAdd = () => {
    setparagrapth(postBody.concat(defaultState));
  };

  const handleOnRemove = (index) => {
    const copyRows = [...postBody];
    copyRows.splice(index, 1);
    setparagrapth(copyRows);
  };

  return (
    <div className="App">
      {postBody &&  postBody.map((row, index) => {
        return (
          <div>
            <h3>Parrafo numero {index + 1}</h3>
            {/* <Row
              {...row}
              onChange={(name, value) => handleOnChange(index, name, value)}
              onRemove={() => handleOnRemove(index)}
              handleOnAdd={(e) => handleOnChangeImg(index, e, setRows)}
              key={index}
              index={index}
            /> */}

<div style={{ display: "flex" }}>
      <div>

      <input
          value={row.title}
          className="data-input-2"
          name="title"
          onChange={(e) => handleOnChange(index, e)}
          placeholder="Titulo"
        />
        <input
          value={row.itemTitle}
          className="data-input-2"
          name="itemTitle"
          onChange={(e) => handleOnChange(index, e)}
          placeholder="Titulo item"
        />
        <textarea
          value={row.paragraph}
          placeholder="Parrafo"
          className="textarea-dash"
          name="paragraph"
          
          onChange={(e) => handleOnChange(index, e)}
        />
        <input
              type="file"
              className={"data-input-2 image-file"}
              id={"paragraphImg" + " " + index}
              name="paragraphImg"

              accept="image/x-png,image/jpg,image/jpeg"
              onChange={(e) => handleOnChangeImg(index, e)}
        />
              
              <label htmlFor={"paragraphImg" + " " + index} className="data-input-2">
             Seleccioná las imágenes
              </label>
        
        {
           loading && <CircularProgress style={{marginLeft: "130px", marginTop: "60px"}}/> 
        }
        
       {
        (row.paragraphImg && row.paragraphImg.linkStorage.length > 0 && !loading) && <div style={{ display: "flex", marginTop: "30px" }} >
        <div className="cutimage">
          <img
            className="image-thumbnail"
            alt="paragraphImg de la propiedad"
            src={ row.paragraphImg.linkStorage.replace(/&amp;/g, '&') }
          />
        </div>

          <button
          // style={{marginLeft:"-50px"}}
            style={{width: "40px"}}
            className="deleteButton"
            onClick={() => deleteImgParrafo(index)}
          >
            X
          </button>
      </div>
       } 

      </div>

      <button className="deleteButton" onClick={handleOnRemove}>
        X
      </button>
    </div>
          </div>
        );
      })}
      <button className="editButtonDash" onClick={handleOnAdd}>
        agregar
      </button>
    </div>
  );
}
