import React, { useState, useEffect } from 'react';
import './Post.css';
import BlogCard from '../../commons/Blog/BlogCard';


const Post = ({ post, history }) => {


    let idFromUrl = window.location.href.split('=')[1].split('?')[0];

    const id = post.findIndex( input => input.id === idFromUrl );


    let screenSizeWidth = window.innerWidth;

    return (
        <div className='post-container-post'>
            <div className='post-container'>
                <div className='post-content-body' style={{marginTop: "65px"}} >
                    <div className='post-content'>
                        <div className='post-img-container'>
                            <img src={post[id].principalImg[0].img.linkStorage.replace(/&amp;/g, '&')} alt={post[id].principalImg[0].img.imageName} />
                        </div>
                        <div className='post-body-container'>
                            <h1> {post[id].principalData.postTitle} </h1> 
                            <label> {post[id].date}  </label> <label> Escrito por: <strong> {post[id].principalData.author} </strong></label>
                            <h3> {post[id].principalData.subtitle} </h3>

                            { post[id].postBody.map((p, index) => 
                                <div key={index} className='post-body-paragraph'>
                                    <strong> {p.title} </strong>
                                    <div className='post-body-content-paragraph'>
                                        <strong> {p.itemTitle} </strong>
                                        <label> {p.paragraph} </label>
                                    </div>
                                    { p.paragraphImg.linkStorage &&
                                        <div className='post-body-paragraph-img'>
                                            <img src={p.paragraphImg.linkStorage.replace(/&amp;/g, '&')} alt={p.paragraphImg.imageName} />
                                        </div>
                                    }
                                </div>
                            )}
                        </div> 
                    </div>
                    { screenSizeWidth > 1030 &&
                    <div className='post-container-similar-posts'>
                        <h4> Más artículos </h4>
                        { post.map(( p, index ) =>
                            p.id === idFromUrl ? null :
                            <BlogCard publication={p} key={index} history={history} />
                        )}
                    </div>
                    }
                </div>
            </div>
            { screenSizeWidth < 1030 &&
            <div className='blog-section-inputs-container-post'>
                <h4> Más artículos </h4>
                <div className='blog-section-inputs-content-post'>
                    { post.map(( p, index ) =>
                        p.id == idFromUrl ? null :
                        <div key={index} className='blog-section-cards-carousel-post'>
                            <BlogCard publication={p} key={index} history={history} />
                        </div>
                    )}
                </div>
            </div>
            }
        </div>
    )
};

export default Post;