import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import URL from '../../../../config/urls'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://hipropi.com/">
        Hi Propi
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {

  let history = useHistory();

  const BASE_URL_ = URL + 'admin/dashboard/login'

  const [error, seterror] = useState("")
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState({
    email: "",
    password: ""
  })

  let token = localStorage.getItem('tokenAdmin');

  useEffect(() => {
    if(token){
      history.push('/admin')
    }
  },[token])

  const { email, password } = data

  const OnChange = (e) => {

    setdata({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  let header = new Headers();

  const handleLogin = async () => {

    let params = {
      email,
      password
    }

    try {
      setloading(true)
      const request = axios.post(BASE_URL_, params, header)
      .then((res) => {
        const name = res.data.responseData.userName
        const token = res.data.responseData.token;
        const rol = res.data.responseData.rol;

        localStorage.setItem('tokenAdmin', token);
        localStorage.setItem('nombre', name);
        localStorage.setItem('rol', rol)
        setloading(false)
        // history.push('/dashboard')
      })
      .catch(() => {
        seterror('Usuario o contraseña incorrectos')
      })
      
    } catch (error) {
      
      console.log('Hubo un error')

    }

  }

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            onChange={OnChange}
            type='email'
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={OnChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Sign In
          </Button>

          {
           error && <p style={{color: '#e84141', fontSize: '16px', fontWeight: '600', margin: 0, padding: 0}}>{error}</p>
          }

        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}