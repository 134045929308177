import React from 'react';
import "./ExpansionPanels.css";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '85%',
        margin: 'auto',
        borderBottom: '1px solid #BCC0C1',
    },
    rooter: {
        boxShadow: 'none',
        borderRadius: 0
    },
    expansionSummary: {
        margin: 0,
        padding: '0 20px 0 0',
    },
    expansionDetails: {
        margin: 0,
        padding: '0 20px 20px 0',
    },
    rootWeb: {
        width: '100%',
        borderBottom: '1px solid #BCC0C1'
    }
}));

let screenSizeWidth = window.innerWidth;

const ExpansionPanels = ({ item, property, button, func }) => {
    const classes = useStyles();

    return (
        <div className={ screenSizeWidth < 1030 ? classes.root : classes.rootWeb }>
            <ExpansionPanel className={classes.rooter}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon color='inherit' />}
                    className={classes.expansionSummary}
                >
                    <div className='expansion-panels-title'> {item.title} </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionDetails}>
                    <div className='expansion-panels-description'>
                        {item.description}
                        <p> {item.assets} </p>
                        {button && <strong style={{color: "#5963cc", display: "flex", 
                        // justifyContent: "center", 
                        // alignItems: "center", 
                        marginBottom: "20px",
                        fontSize: "20px"}} >$ {item.price}</strong>}
                        {
                            button && <button onClick={func} className="edit-button width-edit-btn " >Lo quiero!</button>
                        }
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
};

export default ExpansionPanels;