import React, { useContext, useState } from 'react';
import { Context } from '../../../Context';
import './NewProperty.css';
import TopNewProperty from './TopNewProperty';
import TopStepper from './TopStepper';
import TopAppBar from '../../commons/TopBar/TopAppBar';
//import Services from '../../commons/Services/Services';
//import icon from '../../../assets/images/certificate-service.svg';
import axios from 'axios';
import URL from '../../../config/urls';
import NewPropertyTip from './NewPropertyTip';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import DispositionSelector from '../../commons/SelectorsData/DispositionSelector';
import OrientationSelector from '../../commons/SelectorsData/OrientationSelector';
import CircularProgress from '@material-ui/core/CircularProgress';

const NewProperty3 = ({ history }) => {

    const { newProperty, setNewProperty } = useContext(Context);
    const [loaderNext, setLoaderNext] = useState(false);

    /* const title = "Título Validado";
    const text = "Semper pharetra vehicula dui id porta congue gravida penatibus, odio commodo tempus ridiculus"; */

    const handleChange = (e, max) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            if (name === "salesContract") {
                setNewProperty({ ...newProperty, [name]: checked, hasDeed: false });
            } else if (name === "hasDeed") {
                setNewProperty({ ...newProperty, [name]: checked, salesContract: false });
            } else {
                setNewProperty({ ...newProperty, [name]: checked });
            }
        } else if (type === 'number') {
            if (max) {
                if (max >= value.length) {
                    setNewProperty({ ...newProperty, [name]: Math.abs(parseInt(value)) });
                }
            } else {
                setNewProperty({ ...newProperty, [name]: Math.abs(parseInt(value)) });
            }
        } else if (type === 'radio') {
            setNewProperty({ ...newProperty, [name]: parseInt(value) });
        } else {
            setNewProperty({ ...newProperty, [name]: value })
        }
    }

    const handleUnCheck = (e) => {
        const { name, value } = e.target;
        // eslint-disable-next-line
        if (value == newProperty[name]) {
            setNewProperty({ ...newProperty, [name]: '' })
        }
    }

    const goNext = () => {

        let params =
        {
            mainData: {
                data: {
                    totalArea: newProperty.totalArea,
                    edifiedArea: newProperty.edifiedArea,
                    accommodationsNumber: newProperty.accommodationsNumber,
                    accommodations: {
                        hasBedroomsNumber: newProperty.hasBedroomsNumber,
                        toiletsNumber: newProperty.toiletsNumber,
                        parkingPlaces: newProperty.parkingPlaces,
                        hasConstructionLevels: newProperty.hasConstructionLevels,
                    },
                    creditSuitable: newProperty.creditSuitable,
                    salesContract: newProperty.salesContract,
                    inmediateDelivery: newProperty.inmediateDelivery,
                    hasDeed: newProperty.hasDeed,
                    disposition: newProperty.disposition,
                    orientation: newProperty.orientation,
                    buildingYear: newProperty.buildingYear,
                }
            }
        }

        const token = localStorage.getItem('token');
        const BASE_URL = URL + 'properties/' + newProperty.propertyId;

        if (newProperty.propertyId) {
            setLoaderNext(true);
            axios.patch(BASE_URL, params, {
                headers: {
                    "token": token,
                }
            })
                .then(() => {
                    setLoaderNext(false);
                    history.push('/newproperty/4');
                })
        }
    }

    const goPrev = () => {
        history.push('/newproperty/2');
    }

    const inhabitable = newProperty.propertyType === "" || newProperty.propertyType === "casa" || newProperty.propertyType === "casaCountry"
        || newProperty.propertyType === "quinta" || newProperty.propertyType === "duplexPh" || newProperty.propertyType === "condoHousing"
        || newProperty.propertyType === "departamento" || newProperty.propertyType === "oficina" || newProperty.propertyType === "localComercial";
    const live = newProperty.propertyType === "" || newProperty.propertyType === "casa" || newProperty.propertyType === "casaCountry"
        || newProperty.propertyType === "quinta" || newProperty.propertyType === "duplexPh" || newProperty.propertyType === "condoHousing"
        || newProperty.propertyType === "departamento";

    const tiptitle = 'Características del inmueble.';
    const tiptext = `Recordá que mientras más datos cargues, más fácil será para el que está buscando tener una referencia real de lo que estás ofreciendo.`;
    
    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {
                screenSizeWidth > 1030 ?
                    <NavBarWeb history={history} /> :
                    <TopAppBar />
            }
            <div className="new-property">
                <TopNewProperty step={3} action={newProperty.action} screenSizeWidth={screenSizeWidth} />
                <div className="properties-container-pc" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                    <TopStepper step={3} />
                    <div className="properties-inputs">
                        <div className="fields">
                            <h3>Describa su propiedad</h3>
                            <p className="final-text hide-mobile">Mientras más datos incluyas, mayor alcance tendrá la publicación.</p>
                            {/* <Services icon={icon} title={title} text={text} /> */}
                            <div className="medium-large-pc">
                                <p>Superficie del terreno</p>
                                <div className="container-short-input">
                                    <input className="short-input" type="number" name="totalArea" placeholder="1234" value={newProperty.totalArea ? newProperty.totalArea : ''} onChange={e => handleChange(e, 6)} />
                                    <p>m<sup>2</sup></p>
                                </div>
                            </div>
                            {
                                newProperty.propertyType !== "terreno" &&
                                <div className="medium-large-pc">
                                    <p>Superficie cubierta</p>
                                    <div className="container-short-input">
                                        <input className="short-input" type="number" name="edifiedArea" placeholder="1234" value={newProperty.edifiedArea ? newProperty.edifiedArea : ''} onChange={e => handleChange(e, 6)} />
                                        <p>m<sup>2</sup></p>
                                    </div>
                                </div>
                            }
                            {
                                newProperty.propertyType !== "terreno" &&
                                <div>
                                    <p>Cantidad de ambientes</p>
                                    <div className="container-radio-checks">
                                        <label className="checks">
                                            <input type="radio" value={1} checked={newProperty.accommodationsNumber === 1} name="accommodationsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">1</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={2} checked={newProperty.accommodationsNumber === 2} name="accommodationsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">2</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={3} checked={newProperty.accommodationsNumber === 3} name="accommodationsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">3</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={4} checked={newProperty.accommodationsNumber === 4} name="accommodationsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">4</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={5} checked={newProperty.accommodationsNumber === 5} name="accommodationsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">5+</p>
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                live &&
                                <div>
                                    <p>Cantidad de dormitorios</p>
                                    <div className="container-radio-checks">
                                        <label className="checks">
                                            <input type="radio" value={-1} checked={newProperty.hasBedroomsNumber === -1} name="hasBedroomsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">M</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={1} checked={newProperty.hasBedroomsNumber === 1} name="hasBedroomsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">1</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={2} checked={newProperty.hasBedroomsNumber === 2} name="hasBedroomsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">2</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={3} checked={newProperty.hasBedroomsNumber === 3} name="hasBedroomsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">3</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={4} checked={newProperty.hasBedroomsNumber === 4} name="hasBedroomsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">4+</p>
                                        </label>
                                    </div>
                                    <p>Cantidad de baños</p>
                                    <div className="container-radio-checks">
                                        <label className="checks">
                                            <input type="radio" value={1} checked={newProperty.toiletsNumber === 1} name="toiletsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">1</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={2} checked={newProperty.toiletsNumber === 2} name="toiletsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">2</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={3} checked={newProperty.toiletsNumber === 3} name="toiletsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">3</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={4} checked={newProperty.toiletsNumber === 4} name="toiletsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">4</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={5} checked={newProperty.toiletsNumber === 5} name="toiletsNumber" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">5+</p>
                                        </label>
                                    </div>
                                    <p>Cantidad de cocheras</p>
                                    <div className="container-radio-checks">
                                        <label className="checks">
                                            <input type="radio" value={0} checked={newProperty.parkingPlaces === 0} name="parkingPlaces" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">0</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={1} checked={newProperty.parkingPlaces === 1} name="parkingPlaces" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">1</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={2} checked={newProperty.parkingPlaces === 2} name="parkingPlaces" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">2</p>
                                        </label>
                                        <label className="checks">
                                            <input type="radio" value={3} checked={newProperty.parkingPlaces === 3} name="parkingPlaces" onClick={handleUnCheck} onChange={handleChange} />
                                            <p className="checkmark">3+</p>
                                        </label>
                                    </div>
                                </div>
                            }

                            {
                                newProperty.sell &&
                                <div>
                                    <p>Condiciones de comercialización</p>
                                    <div className="container-enviroments">
                                        <label className="checks">
                                            <input type="checkbox" checked={newProperty.creditSuitable} name="creditSuitable" onChange={handleChange} />
                                            <span className="checkmark"></span>
                                            <p className="label-check">Apto crédito</p>
                                        </label>
                                        <label className="checks">
                                            <input type="checkbox" checked={newProperty.salesContract} name="salesContract" onChange={handleChange} />
                                            <span className="checkmark"></span>
                                            <p className="label-check">Boleto de compraventa</p>
                                        </label>
                                        <label className="checks">
                                            <input type="checkbox" checked={newProperty.inmediateDelivery} name="inmediateDelivery" onChange={handleChange} />
                                            <span className="checkmark"></span>
                                            <p className="label-check">Entrega inmediata</p>
                                        </label>
                                        <label className="checks">
                                            <input type="checkbox" checked={newProperty.hasDeed} name="hasDeed" onChange={handleChange} />
                                            <span className="checkmark"></span>
                                            <p className="label-check">Escriturado</p>
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                (newProperty.propertyType === "" || newProperty.propertyType === "casa" || newProperty.propertyType === "casaCountry") &&
                                <div className="medium-large-pc">
                                    <p>Niveles de construcción</p>
                                    <input className="short-input" type="number" maxLength="5" name="hasConstructionLevels" placeholder="2" value={newProperty.hasConstructionLevels ? newProperty.hasConstructionLevels : ''} onChange={e => handleChange(e, 2)} />
                                </div>
                            }
                            {
                                inhabitable &&
                                <div className="medium-large-pc">
                                    <p>Disposición</p>
                                    <DispositionSelector stateContext={newProperty} handleChange={handleChange} />
                                </div>
                            }
                            {
                                inhabitable &&
                                <div className="medium-large-pc">
                                    <p>Orientación</p>
                                    <OrientationSelector stateContext={newProperty} handleChange={handleChange} />
                                </div>
                            }
                            {
                                newProperty.propertyType !== "terreno" &&
                                <div className="medium-large-pc">
                                    <p>Antigüedad</p>
                                    <input type="number" name="buildingYear" placeholder="Año de terminación" value={newProperty.buildingYear ? newProperty.buildingYear : ''} onChange={e => handleChange(e, 4)} />
                                </div>
                            }
                        </div>
                    </div>
                    <NewPropertyTip title={tiptitle} text={tiptext} />
                </div>
                <div className="buttons">
                    {loaderNext ?
                        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
                            <CircularProgress size={30} />
                        </div> :
                        <>
                            <button className="prev" type="button" onClick={goPrev}>atrás</button>
                            <button className="next" type="submit" onClick={goNext}>siguiente</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewProperty3;