import React from 'react';
import './ErrorPage.css';
import errorBuilding from './../../../assets/images/errorBuilding.svg';

const ErrorPage = ({ history, status, title, text }) => {

    function handleClickBackButton() {
        history.goBack();
    }

    function handleClickGoHome() {
        history.push('/welcome')
    }
 
    return (
        <div className='error-page-component'>
            <div className='error-page-container'>
                <div className='error-page-content'>
                    <img alt='error' src={errorBuilding} />
                    <h2> error </h2>
                    <h1> {status} </h1>
                    <h3> {title} </h3>
                    <label> {text} </label>
                    <button onClick={handleClickBackButton} className='error-page-back-button'> Volver atrás </button>
                    <button onClick={handleClickGoHome} className='error-page-go-to-welcome-button'> Ir al inicio </button>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;