import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '250px',
    marginTop: "30px",
    marginLeft: "240px"
 },
  rooter: {
    boxShadow: 'none',
    borderRadius: 0
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: "-250px"
  },
}));

export default function SimpleAccordion({Word, palabra}) {
  const classes = useStyles();


  return (
    <div className={classes.root}  >
      <ExpansionPanel className={classes.rooter} style={{backgroundColor: "#FAFAFA"}} >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><span className='word-Router' style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "25px"}} >{palabra}</span></Typography>
        </ExpansionPanelSummary>

        {
           Word[0] && Word.map((e, i) => {
             return (
              <ExpansionPanelDetails  >
             <a href={e.link} style={{textDecoration: "none"}} > <Typography className={classes.heading}><span className='word-Router'  style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "25px"}} >{e.nombre}</span></Typography></a>
             
              </ExpansionPanelDetails>
             )
           })
         }
        


  
      </ExpansionPanel>

    </div>
  );
}