import React, { useState, useEffect, Fragment } from 'react';
import './Appraiser.css';
import VirtualAppraiserSlider from './components/VirtualAppraiserSlider/VirtualAppraiserSlider';
import {
    quantityBedrooms,
    quantityBathrooms,
    quantityGarages,
    typeTransaction,
    quantityEdified,
    poolData,
    isFurnished,
    antiquityOfProperty,
    constructionCond,
    isCreditSuitable,
    whatKindDeed,
    forSelectPropertyType,
    forSelectState,
    hasAmenities,
    neighborhoodType,
    constructionType
} from '../../../data/appraiserData';
import axios from 'axios';
import RoundCheckbox from '../../commons/RoundCheckbox/RoundCheckbox';
import CustomCheckbox from '../../commons/Checkbox/Checkbox';
import CustomSelect from '../../commons/Select/Select';
import CustomInput from '../../commons/Input/Input';
import URL from '../../../config/urls';
import ResultAppraiser from './ResultAppraiser';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const VirtualAppraiser = () => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [countriesFromApi, setcountriesFromApi] = useState([])
    const [statesFromApi, setStatesFromApi] = useState([])

    //location > ex: 'country: argentina'
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');

    // result modal
    const [modal, setModal] = useState({
        open: false,
        value: '',
        currency: ''
    });

    // address
    const [streetName, setStreetName] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [neighborhoodName, setNeighborhoodName] = useState('');

    //property type > ex: 'house'
    const [propertyType, setPropertyType] = useState('');

    // edified
    const [totalArea, setTotalArea] = useState('');
    const [edifiedArea, setEdifiedArea] = useState('');
    const [semiEdifiedArea, setSemiEdifiedArea] = useState('');

    // transactions
    const [publishedFor, setPublishedFor] = useState('');

    //attributes
    const [garage, setGarage] = useState('');
    const [pool, setPool] = useState('1');
    const [furnished, setFurnished] = useState('');
    const [creditSuitable, setCreditSuitable] = useState('');
    const [amenities, setAmenities] = useState('1');

    //sliders > 1 to 9
    const [neighborhood, setNeighborhood] = useState(1);
    const [constructionQuality, setConstructionQuality] = useState(1);

    // > 1 to 5
    const [constructionCondition, setConstructionCondition] = useState('');

    // attributes quantity > 1 to 5
    const [bedroomsNumber, setBedroomsNumber] = useState('');
    const [bathroomsNumber, setBathroomsNumber] = useState('');

    // ageProperty > 1 to 4  
    const [ageOfProperty, setAgeOfProperty] = useState('');

    // deed type
    const [hasDeed, setHasDeed] = useState('');

    useEffect(() => {
        let selectedOptions = localStorage.getItem('selectedOptions');
        if (selectedOptions) {
            let options = JSON.parse(selectedOptions)
            handlePublishedFor(options.transaction)
            handleSelectPropertyType(options.propertyType)
        }
    }, [])

    const getCountries = async () => {
        let countries = []
        const { data: { responseData } } = await axios.get(`${URL}locations/countries`)
        for (let i = 0; i < responseData.countries.length; i++) {
            const country = responseData.countries[i];
            countries.push({
                id: country.name,
                value: country.name,
                name: country.name
            })
        }
        setcountriesFromApi(countries)
        setLoading(false)
    }

    const getCountryStates = async (countrySelected) => {
        setLoading(true)
        let countryStates = []
        const { data: { responseData } } = await axios.get(`${URL}locations/countryStates/${countrySelected}`)
        for (let i = 0; i < responseData.states.length; i++) {
            const state = responseData.states[i];
            countryStates.push({
                id: state.name,
                value: state.name,
                name: state.name
            })
        }
        setStatesFromApi(countryStates)
        setLoading(false)
    }

    useEffect(() => {
        getCountries()
    }, [])

    // FUNCTIONS:

    function ratingConstruction(value) {
        return setConstructionQuality(value)
    }

    function ratingNeighborhood(value) {
        return setNeighborhood(value)
    }

    function handleBedroomsNumber(value) {
        return setBedroomsNumber(value)
    }

    function handleBathroomsNumber(value) {
        return setBathroomsNumber(value)
    }

    function handleGarage(value) {
        return setGarage(value)
    }

    function handlePublishedFor(value) {
        return setPublishedFor(value)
    }

    function handleEdifiedArea(name, value) {
        if (name === 'totalArea') {
            setTotalArea(value)
        } else if (name === 'edifiedArea') {
            setEdifiedArea(value)
        } else if (name === 'semiEdifiedArea') {
            setSemiEdifiedArea(value)
        }
    }

    function handleHasPool(value) {
        return setPool(value)
    }

    function handleIsFurnished(value) {
        return setFurnished(value)
    }

    function handleAgeOfProperty(value) {
        return setAgeOfProperty(value)
    }

    function handleConstructionCondition(value) {
        return setConstructionCondition(value)
    }

    function handleIsCreditSuitable(value) {
        return setCreditSuitable(value)
    }

    function handleHasDeed(value) {
        return setHasDeed(value)
    }

    function handleSelectPropertyType(value) {
        return setPropertyType(value)
    }

    function handleSelectCountry(value) {
        getCountryStates(value)
        return setCountry(value)
    }

    function handleSelectState(value) {
        return setState(value)
    }

    function handleStreet(value) {
        return setStreetName(value)
    }

    function handleNumber(value) {
        return setStreetNumber(value)
    }

    function handleNeighborhood(value) {
        return setNeighborhoodName(value)
    }

    function handleHasAmenities(value) {
        return setAmenities(value)
    }

    let BASE_URL = URL + 'appraise'

    async function sendAppriser(value) {
        try {
            const response = await axios.post(BASE_URL, value)
            if (response) {
                setLoading(false)
                localStorage.removeItem('selectedOptions')
                setModal({ open: true, value: response.data.value, currency: response.data.currency, publishedFor: response.data.publishedFor })
            }
        } catch (e) {
            console.log('Ocurrió un error.', e)
        }
    }

    async function handleSubmit() {
        setLoading(true);
        let appraiser = {
            country, state, neighborhoodName, streetName, streetNumber,
            propertyType, publishedFor, totalArea, neighborhood, edifiedArea,
            semiEdifiedArea, bedroomsNumber, bathroomsNumber, garage, pool,
            constructionQuality, ageOfProperty, constructionCondition, furnished,
            hasDeed, creditSuitable, amenities
        }
        if (appraiser) {
            sendAppriser(appraiser)
        }
    };

    let params =
        country.length > 0 &&
        state.length > 0 &&
        neighborhoodName.length > 0 &&
        streetName.length > 0 &&
        streetNumber.length > 0 &&
        propertyType.length > 0 &&
        publishedFor.length > 0 &&
        totalArea.length > 0 &&
        neighborhood > 0 &&
        edifiedArea.length > 0 &&
        semiEdifiedArea.length > 0 &&
        bedroomsNumber.length > 0 &&
        bathroomsNumber.length > 0 &&
        garage.length > 0 &&
        pool.length > 0 &&
        constructionQuality > 0 &&
        ageOfProperty.length > 0 &&
        constructionCondition.length > 0 &&
        furnished.length > 0 &&
        hasDeed.length > 0 &&
        creditSuitable.length > 0 &&
        amenities.length > 0
        ;

    //let screenSizeWidth = window.innerWidth;

    return (
        <div className='appraiser-container' >
            <h1 style={{ marginTop: "75px" }}> Tasador virtual </h1 >
            <h6> Completa los datos de tu propiedad así te damos un valor aproximado.
            Sólo dura 5 minutos.
            </h6>

            <p> País: </p>
            {/* <CustomSelect
                defValue='Seleccione su país'
                options={!loading ? (countriesFromApi) : (forSelectCountry)}
                handleChange={handleSelectCountry}
            /> */}

            <select className='filter-select' name="order" onChange={(e) => handleSelectCountry(e.target.value)} required>
                <option selected disabled>Seleccione su país</option>
                {countriesFromApi.map(country => (
                    <option value={country.name} id={country.id} key={country.id}>{country.name}</option>
                ))}
            </select>

            {
                country && (
                    <Fragment>
                        <p> Provincia: </p>
                        <CustomSelect
                            defValue='Seleccione su provincia'
                            options={!loading ? (statesFromApi) : (forSelectState)}
                            handleChange={handleSelectState}
                        />

                        <p style={{ marginTop: 15 }}> Barrio: </p>
                        <input
                            value={neighborhoodName}
                            onChange={(e) => handleNeighborhood(e.target.value)}
                        />

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-5px' }}>
                            <div style={{ width: '65%' }}>
                                <p style={{ marginTop: 0, marginBottom: 10 }}> Calle: </p>
                                <input
                                    value={streetName}
                                    onChange={(e) => handleStreet(e.target.value)}
                                />
                            </div>
                            <div style={{ width: '30%' }}>
                                <p style={{ margin: 0, marginBottom: 10 }}> Número: </p>
                                <input
                                    value={streetNumber}
                                    type='number'
                                    onChange={(e) => handleNumber(e.target.value)}
                                />
                            </div>
                        </div>

                    </Fragment>
                )
            }



            <p> ¿Qué tipo de inmueble es? </p>
            <select value={propertyType === '' ? '' : propertyType} onChange={(e) => handleSelectPropertyType(e.target.value)}>
                <option value=''> Seleccione el tipo de inmueble </option>
                {forSelectPropertyType.map((option, index) =>
                    <option key={index} value={option.value}> {option.name} </option>
                )}
            </select>

            <p style={{ marginTop: 20 }}> ¿Qué operación estás buscando hacer? </p>
            <div className="appraiserPublishedForContainer">
                {typeTransaction.map((transaction, index) =>
                    <CustomCheckbox
                        visible={true}
                        key={index}
                        param={publishedFor}
                        attribute={transaction}
                        handleChange={handlePublishedFor}
                    />
                )}
            </div>

            <p style={{ marginTop: 25 }}> ¿Cuál es la superficie? </p>
            {quantityEdified.map((edif, index) =>
                <CustomInput
                    key={index}
                    param={edifiedArea}
                    attribute={edif}
                    handleChange={handleEdifiedArea}
                    text='m2'
                    number={true}
                    w={93}
                    h={41}
                />
            )}

            <p style={{ marginTop: 5, marginBottom: 10 }}> ¿Cuántos dormitorios tiene? </p>
            <div style={{ display: 'flex' }}>
                {quantityBedrooms.map((bedroom, index) =>
                    <RoundCheckbox
                        key={index}
                        param={bedroomsNumber}
                        attribute={bedroom}
                        handleChange={handleBedroomsNumber}
                    />
                )}
            </div>

            <p style={{ marginTop: 70 }}> ¿Cuántos baños tiene? </p>
            <div style={{ display: 'flex' }}>
                {quantityBathrooms.map((bathroom, index) =>
                    <RoundCheckbox
                        key={index}
                        param={bathroomsNumber}
                        attribute={bathroom}
                        handleChange={handleBathroomsNumber}
                    />
                )}
            </div>

            <p style={{ marginTop: 70 }}> ¿Tiene cochera? </p>
            <div style={{ display: 'flex' }}>
                {quantityGarages.map((gar, index) =>
                    <RoundCheckbox
                        key={index}
                        param={garage}
                        attribute={gar}
                        handleChange={handleGarage}
                    />
                )}
            </div>

            {propertyType === '' ? null :

                propertyType !== 'deptoCondo' ?

                    <div>
                        <p style={{ marginTop: 70 }}> ¿Tiene piscina? </p>
                        <div>
                            {poolData.map((hasPool, index) =>
                                <CustomCheckbox
                                    visible={true}
                                    key={index}
                                    param={pool}
                                    attribute={hasPool}
                                    handleChange={handleHasPool}
                                />
                            )}
                        </div>
                    </div>

                    :

                    <div>
                        <p style={{ marginTop: 70 }}> ¿Tiene amenities? </p>
                        <div>
                            {hasAmenities.map((amenity, index) =>
                                <CustomCheckbox
                                    visible={true}
                                    key={index}
                                    param={amenities}
                                    attribute={amenity}
                                    handleChange={handleHasAmenities}
                                />
                            )}
                        </div>
                    </div>

            }

            <p className={propertyType !== '' ? 'withoutMargin' : 'withMargin'} > ¿Está amoblada? </p>
            <div className="appraiserFurnishedContainer">
                {isFurnished.map((furniture, index) =>
                    <CustomCheckbox
                        visible={true}
                        key={index}
                        param={furnished}
                        attribute={furniture}
                        handleChange={handleIsFurnished}
                    />
                )}
            </div>

            <p style={{ marginTop: 25 }}> ¿Con qué antigüedad cuenta? </p>
            <div className='appraiserAntiquityContainer' >
                {antiquityOfProperty.map((antiquity, index) =>
                    <CustomCheckbox
                        visible={true}
                        key={index}
                        param={ageOfProperty}
                        attribute={antiquity}
                        handleChange={handleAgeOfProperty}
                    />
                )}
            </div>

            <p style={{ marginTop: 35 }}> ¿Cuál es el estado de construcción? </p>
            <div className='appraiserStateConstructionContainer'>
                {constructionCond.map((condition, index) =>
                    <CustomCheckbox
                        visible={true}
                        key={index}
                        param={constructionCondition}
                        attribute={condition}
                        handleChange={handleConstructionCondition}
                    />
                )}
            </div>

            <p style={{ marginTop: 35 }}> ¿Cuál es el tipo de construcción? </p>
            <VirtualAppraiserSlider ratingParams={ratingConstruction} images={constructionType} />

            <p style={{ marginTop: 25 }}> ¿Cuál es el tipo de barrio? </p>
            <VirtualAppraiserSlider ratingParams={ratingNeighborhood} images={neighborhoodType} />

            <p style={{ marginTop: 25 }}> ¿Qué título posee? </p>
            <div>
                {whatKindDeed.map((deed, index) =>
                    <CustomCheckbox
                        visible={true}
                        key={index}
                        param={hasDeed}
                        attribute={deed}
                        handleChange={handleHasDeed}
                    />
                )}
            </div>

            <p style={{ marginTop: 20 }}> ¿Es apto crédito? </p>
            <div>
                {isCreditSuitable.map((option, index) =>
                    <CustomCheckbox
                    visible={true}
                        key={index}
                        param={creditSuitable}
                        attribute={option}
                        handleChange={handleIsCreditSuitable}
                    />
                )}
            </div>

            {modal.open && <ResultAppraiser param={modal} />}

            <button
                disabled={params ? false : true}
                className={params ? 'appraiserButton' : 'appraiserButton-disabled'}
                onClick={handleSubmit}>
                calcular
            </button>

            {loading &&
                <div>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            }

        </div>
    )
}

export default VirtualAppraiser;