import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({setlink, link, nameLink, handleUpdate}) {
//   const [link, setlink] = React.useState(true);

  const [data, setdata] = useState("")
  
  useEffect(() => {
    setdata(nameLink.linkEmprendimiento)
  },[])

  const handleClickOpen = () => {
    setlink(true);
  };

  const handleClose = () => {
    setlink(false);
  };

  const handleSet = () => {
    
    // if(nameLink.linkEmprendimiento.length < 0){
      nameLink.linkEmprendimiento = data
    handleUpdate && handleUpdate()
    setlink(false);
  };



  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={link} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Link de emprendimiento</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={ data }
            id="name"
            onChange={(e) => setdata(e.target.value)}
            label="Link"
            type="email"
            fullWidth
            maxWidth="md"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSet} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}