import 'date-fns';
import React, {useState} from 'react';
import Select from '../Common/Select/Select'

import DatePicker from '../Common/DatePicker/DatePicker'
import RouterColumn from '../RouterColumn/RouterColumn'


export default function Usuarios() {


  let word = [{ nombre: "Conectados", link: "/admin/users"}]

  

  return (
 <div>
        <RouterColumn word={"Usuarios"} />

        <div>
          <Select Word={word} palabra={"Nuevos"} />
        </div>
        <div>
          <DatePicker componente={"newUsers"} palabra1={"USUARIOS"} palabra2={"NUEVOS"} style={{marginLeft: "800px", marginRight: "50px"}} />
        </div>

    </div>
  );
}