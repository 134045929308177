import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Modal from './modal'
import ModalWarning from '../../../commons/Modals/ModalWarning'
import URL from '../../../../config/urls'
import { Context } from '../../../../Context'
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import './update.css'
import axios from 'axios'

const UpdateForm = () => {
    
    let history = useHistory();
    const { dataBlog, 
        setdataBlog } = useContext(Context);

    const [blogData, setblogData] = useState([])
    const [published, setpublished] = useState([])
    const [nopublished, setnopublished] = useState([])
    const [modal, setmodal] = useState(false)
    const [modalUpload, setmodalUpload] = useState(false)

    const token = localStorage.getItem('tokenAdmin');

    // const published =  blogData.find((e) => e.isPublished == true )

    const publishedBlog = () => {

        try {
            axios.post(URL + `blog/publishedNotes/${blogData.id}`, { headers: { 'token': token } }) 
            .then((data) => {
                history.go(0)
            })

        } catch (error) {
            console.log(error)
        }

    }


    const getData = () => {
        
        let publica = []
        let noPublic = []

        try {
            axios.get(URL + 'blog/getAllNotes', { headers: { 'token': token } }) 
            .then((data) => {
                // setblogData(data.data.responseData[0])
                data.data.responseData[0].map((e) => {

                    if(e.isPublished){
                        publica.push(e)
                        
                    }
                    if(!e.isPublished){
                        noPublic.push(e)
                    }

                })

                setpublished(publica)
                setnopublished(noPublic)
            })

        } catch (error) {
            console.log(error)
        }



    }

    const handleDelete = (data) => {
        setblogData(data)
        setmodal(true)

    }

    const handleUpload = (data) => {
        setblogData(data)
        setmodalUpload(true)

    }



    const handleYes = () => {
        
        try {
            axios.delete(URL + `blog/deleteNotes/${blogData.id}`, { headers: { 'token': token } }) 
            .then((data) => {
                history.go(0)
            })

        } catch (error) {
            console.log(error)
        }

        setmodal(false)

    }
    
    const handleNot = () => {
        setmodal(false)

    }

    const handleOnClick = (id, publication) => {
        setdataBlog(publication)
        history.push('/admin/UpdateBlog/' + id)
    }


    useEffect(() => {
        getData()
    },[])




    return (
        <div className="div-blog-update">
                <Modal
                title="Estas seguro que queres despublicar esta nota?"
                // text="Podes publicarlo mas adelante si queres!"
                acept="Si"
                cancel="No"
                // image={alertIcon}
                noButton={true}
                close={false}
                open={modal}
                openModal={setmodal}
                actionButton={handleYes}
                handleCloseOut={handleNot}
                
              /> 


            <Modal
                title="Estas seguro que queres publicar esta nota?"
                // text="Podes publicarlo mas adelante si queres!"
                acept="Si"
                cancel="No"
                // image={alertIcon}
                noButton={true}
                close={false}
                open={modalUpload}
                openModal={setmodalUpload}
                actionButton={publishedBlog}
                handleCloseOut={handleNot}
                
              /> 


            <h3 className="h3-edit-note" >Publicadas</h3>
            
        {    published.map((publication) => (
            <div className='blog-mini-card-container-admin'>
                <div className='blog-mini-card-content'>
                    <div className='blog-mini-card-img'>
                        <img src={publication.principalImg[0] && publication.principalImg[0].img.linkStorage.replace(/&amp;/g, '&')} alt={publication.principalImg[0] && publication.principalImg[0].img.imageName} />
                    </div>
                    <div className='blog-mini-card-text'>
                        <label onClick={() => handleOnClick(publication.id, publication)} > {publication.principalData.postTitle} </label>
                        <label> {publication.date} </label>
                    </div>
                    <div>
                       <button onClick={()=> handleDelete(publication)} className="deleteButton-update">X</button> 
                    </div>
                </div>
            </div>
        ))    
        }

    <h3 className="h3-edit-note" >No publicadas</h3>
            
            {    nopublished.map((publication) => (
                <div className='blog-mini-card-container-admin' >
                    <div className='blog-mini-card-content'>
                        <div className='blog-mini-card-img'>
                            <img src={publication.principalImg[0] && publication.principalImg[0].img.linkStorage.replace(/&amp;/g, '&')} alt={publication.principalImg[0] && publication.principalImg[0].img.imageName} />
                        </div>
                        <div className='blog-mini-card-text'>
                            <label onClick={() => handleOnClick(publication.id, publication)} > {publication.principalData.postTitle} </label>
                            <label> {publication.date} </label>
                        </div>
                        <div>
                           {/* <button className="deleteButton-update">X</button>  */}
                               <IconButton className="deleteButton-update-2" onClick={()=> handleUpload(publication)}>
                                 <PublishIcon style={{color: "green"}} />
                               </IconButton>
                        </div>
                    </div>
                </div>
            ))    
            }
    </div>
    )

}

export default UpdateForm