import React from 'react';
//import Stepper from './Stepper';
//import StepperPc from './StepperPc';

export default function TopNewProperty(props) {

    let myAction;

    if (props.action === 'upload') {
        myAction = 'Carga';
    } else if (props.action === 'edit') {
        myAction = 'Edita';
    }

    return (
        <div className="top-new-property">
            <div className="title-property">
                <p>{myAction} tu propiedad</p>
                {props.screenSizeWidth < 1030 && <p>{props.step}/6</p>}
            </div>
        </div>
    )
}
