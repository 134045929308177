import Axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../../config/urls";
import TopBar from "../../commons/BlueTopBar/BlueTopBar";
import TopAppBarWeb from "../../commons/TopAppBarWeb/TopAppBarWeb";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkout from './useScript'
import './form-data-user.css'
import ModalData from "./ModalData";


const FormUserData = ({ history }) => {
  let screenSizeWidth = window.innerWidth;

  const [telefono, settelefono] = useState("");
  const [alter, setalter] = useState("");
  const [email, setemail] = useState("");
  const [openModal, setOpenModal] = useState(true)
  const [loading, setLoading] = useState(false)
  const [id, setid] = useState("")
  const [error, setError] = useState(false)

  const token = localStorage.getItem('token')

  const handleOnCLick = async() => {
    if(telefono.trim().length == 0 || email.trim().length == 0){
      setError(true)
      return
    } else {

      setError(false)
      setLoading(true)
      const dataName = localStorage.getItem('producto')
      const dataPrice = localStorage.getItem('productoPrice')
      console.log(dataName)
  
      const params = {
        itemName: dataName,
        itemPrice: dataPrice,
        itemQuantity: 1,
        telefono,
        alter,
        email,
        typePay:"MercadoPago"
      }
  
      try {
  
        const {data} = await Axios.post(URL + "mercadoPago/pay", params, { headers: {token} })
        setid(data.responseData[0].preferenceId)
        setLoading(false)
        localStorage.setItem('serviceBuy', 'MECA')
  
      } catch (error) {
        console.log(error)
      }

    }

  }



  return (
    <div>
      {
        <ModalData 
          noButton={true}
          openModal={openModal}
          title='¡Al concretar tu compra asegurate apretar el boton de volver al sitio para poder finalizar tu transacción de forma correcta!'
          text='Ha pasado mucho tiempo desde la última vez que iniciaste sesión. Ingresa nuevamente a tu cuenta para poder acceder a todas las secciones de HiPropi o continúa navegando offline.'
          acept='Iniciar sesión'
          cancel='cerrar'
          image={true}
          close={true}
         
          onClose={setOpenModal}

        />
      }
      {screenSizeWidth > 1030 ? (
        <TopAppBarWeb history={history} />
      ) : (
        <TopBar title="Datos de usuario" link="/" />
      )}
      <div className="services-web-container-card">
        <div className="services-web-content-card">
          <div className="servicesContainer">
            {screenSizeWidth > 1030 && (
              <div
                className="report-title-container"
                style={{ margin: "auto" }}
                style={{
                  marginTop: screenSizeWidth > 1030 ? "35px" : "0px",
                  marginBottom: "30PX",
                }}
              >
                <label> Datos de usuario </label>
              </div>
            )}

            <div className="text-preguntas data-container-flex">
             { error &&
            <div className="data-container-contain" >
            <div className='classColorModalError'>
                        <div>
                            <h3 style={{display: "flex", justifyContent: "center"}} > ¡Hubo un error! </h3>

                        </div>
                        <label style={{textAlign: "center"}}  > Debes completar los campos obligatorios con tus datos para procesar tu compra. </label>
                    </div>
                    </div>
             } 
              <div className="data-container-contain" >
              <span className="user-info-data" >Telefono *</span>
              <input
                type="number"
                className="data-input data-form-user"
                
                placeholder="Telefono"
                onChange={(username) => settelefono(username.target.value)}
              />
              </div>
              <div className="data-container-contain" >
              <span  className="user-info-data">Telefono alternativo</span>
              <input
                type="number"
                className="data-input data-form-user"
                placeholder="Telefono alternativo"
                onChange={(email) => setalter(email.target.value)}
              />
              </div>
              <div className="data-container-contain" >
              <span  className="user-info-data">Email *</span>
              <input
                type="email"
                className="data-input data-form-user"
                placeholder="Email"
                onChange={(pass) => setemail(pass.target.value)}
              />
             {
               loading ?
                  <div className='serviceBigCardButtonContainer'>
                    <CircularProgress color="inherit" />
                  </div> : 
                  <button className="buttonSearch button-send-data" id="checkout-btn"  style={{width: "300px", marginTop: "40px"}} onClick={handleOnCLick}  > Enviar </button>
             } 
            </div>
                
            </div>
            <Checkout dataId={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormUserData;
