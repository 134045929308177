import 'date-fns';
import React, {useEffect, useState} from 'react';
import Select from '../Common/Select/Select'
import axios from 'axios'
import DatePicker from '../Common/DatePicker/DatePicker'
import URL from '../../../../config/urls'
import RouterColumn from '../RouterColumn/RouterColumn'
import Formulario from './Formulario.js'
import Tables from './tables'
import { useHistory } from 'react-router-dom'


export default function Usuarios() {

  const [data, setdata] = React.useState([])
  let history = useHistory();
  let rol = localStorage.getItem('rol');  

  useEffect(() => {

    if(rol == 2){

      history.push('/admin')

    }

  },[])


  const token = localStorage.getItem('tokenAdmin');

  let word = []

  useEffect(() => {

    try {
      
      const request = axios.get(URL + `admin/dashboard/allAdmin`, { headers: { 'token': token } })
      .then((res) => {

        setdata(res.data.responseData[0] )

      })
      .catch((err) => {
        console.log('Hubo un error')
        console.log(err)

      })

    } catch (error) {
      
      console.log(error)

    }


  },[data])

  

  return (
 <div>
        <RouterColumn word={"Usuarios"} />

        <div>
          <Select Word={word} palabra={"Crear roles"} />
        </div>

        <div style={{display: "flex"}} >
          
        <div style={{marginLeft: "255px" }} >
            <Formulario />
        </div>

        <div>
          <Tables rowsData={data} />
        </div>
        </div>

    </div>
  );
}