import React, { useState, useEffect } from 'react';
import './MyProfile.css'
import arrowBack from '../../../assets/images/arrowBack.svg';
import URL from '../../../config/urls';
import axios from 'axios';
import isVerified from './../../../assets/images/isVerified.svg'
import arrow from './../../../assets/images/arrow-link.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import YourData from './components/YourData/YourData';
import Verification from './components/Verification/Verification';
import Security from './components/Security/Security';
import DeleteAccount from './components/DeleteAccount/DeleteAccount';
import { settingsData } from '../../../data/settingsData';

const UserAvatar = ({ avatar }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <img src={avatar} alt='user______' className='user-avatar-myprofile' />
        </div>
    )
}

const ItemsData = ({ number, title }) => {
    return (
        <div className='item-data-profile'>
            <label className='item-data-profile-number'> {number} </label>
            <label className='item-data-profile-title'> {title} </label>
        </div>
    )
}

const SettingItem = ({ setting, handleClick }) => {
    return (
        <div className='SettingsButtons' onClick={() => handleClick(setting.id)}>
            <div style={{ width: 50 }}>
                <img alt='setting icon' src={setting.icon} />
            </div>
            <div
                style={{ display: 'flex', width: '70%', flexDirection: 'column' }} >
                <label className='settings-profile-data'> {setting.title} </label>
                <label className='settings-profile-title'> {setting.subtitle} </label>
            </div>
            <img alt='arrow' src={arrow} />
        </div>
    )
}

const SettingsProfile = ({ userIdFromUrl, history, screenSizeWidth, directToSettingsItem }) => {

    function redirectTo(id) {
        if (id === 'yourData') {
            history.push('/myprofile/mydata?id=' + userIdFromUrl)
        } else if (id === 'security') {
            history.push('/myprofile/security?id=' + userIdFromUrl)
        } else if (id === 'verification') {
            history.push('/myprofile/verificated?id=' + userIdFromUrl)
        } else if (id === 'deleteAccount') {
            history.push('/myprofile/deleteaccount?id=' + userIdFromUrl)
        }
    }


    return (
        <>
            { screenSizeWidth < 1030 ?
                <>
                    { settingsData.map((setting, index) =>
                        <SettingItem
                            key={index}
                            setting={setting}
                            handleClick={redirectTo}
                        />
                    )}
                </>
                :
                <>
                    { settingsData.map((setting, index) =>
                        <SettingItem
                            key={index}
                            setting={setting}
                            handleClick={directToSettingsItem}
                        />
                    )}
                </>
            }
        </>
    )
}

const MyProfile = ({ history }) => {

    let userIdFromUrl = window.location.href.split('=')[1].split('?')[0];

    let screenSizeWidth = window.innerWidth;

    const [user, setUser] = useState(null);

    let jwt, token, decoded, GET_USER_ENDPOINT
    token = localStorage.getItem('token');
    const headers = { headers: { "token": token, } };
    /* eslint-disable */
    useEffect(() => {
        jwt = require('jsonwebtoken');
        token = localStorage.getItem('token');
        
        decoded = jwt.decode(token);
        GET_USER_ENDPOINT = decoded ? URL + 'users/' + decoded.data.id : null
    }, [])
    /* eslint-enable */

    const getUserData = async () => {
        if (decoded) {
            const res = await axios.get(GET_USER_ENDPOINT, headers)
            const {data: {responseData}} = res

            if (responseData.id !== userIdFromUrl) {
                history.push(`/myprofile?userId=${decoded.data.id}`)
                window.location.reload()
            } 

        } else {
            history.push(`/welcome`)
        }

    }

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line
    }, [decoded])

    let BASE_URL = URL + 'users/' + userIdFromUrl


    useEffect(() => {
        axios.get(BASE_URL, headers)
            .then(response => {
                const dataUser = response.data.responseData;
                setUser(dataUser)
            })
            .catch(e => {
                console.log(e);
            })
        // eslint-disable-next-line
    }, [])

    function goBack() {
        history.push('/');
    }

    const [openSettings, setOpenSettings] = useState({
        yourDataOpen: false,
        verificationOpen: false,
        securityOpen: false,
        deleteAccountOpen: false
    })

    function directToSettingsItem(params) {
        if (params === 'yourData') {
            setOpenSettings({
                yourDataOpen: true,
                verificationOpen: false,
                securityOpen: false,
                deleteAccountOpen: false
            })
        } else if (params === 'verification') {
            setOpenSettings({
                verificationOpen: true,
                yourDataOpen: false,
                securityOpen: false,
                deleteAccountOpen: false
            })
        } else if (params === 'security') {
            setOpenSettings({
                securityOpen: true,
                yourDataOpen: false,
                verificationOpen: false,
                deleteAccountOpen: false
            })
        } else if (params === 'deleteAccount') {
            setOpenSettings({
                deleteAccountOpen: true,
                yourDataOpen: false,
                verificationOpen: false,
                securityOpen: false
            })
        }
    }

    return (
        <div>
            <div>
                {screenSizeWidth < 1030 ?
                    <div className="topBar" style={{ position: 'fixed', width: '100%', top: 0 }}>
                        <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />
                        <h3> Perfil </h3>
                    </div>
                    : <NavBarWeb history={history} />
                }
            </div>
            <div>
                {user === null ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                        <CircularProgress size={70} />
                    </div>
                    :
                    <div className='myProfileWebVersion'>
                        <div className='myProfileWebVersionContainer' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                            {screenSizeWidth > 1030 &&
                                <div className='report-title-container'>
                                    <label> Mi perfil </label>
                                </div>
                            }
                            <div className='myProfileWebVersionBody'>
                                <div className='my-profile-web-version-container'>
                                    <div className='myProfileWebVersionContent'>
                                        <UserAvatar avatar={user.avatar} />
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <label className='label-user-avatar-profile'> {user.firstName} </label>
                                            {user.phoneIsVerified === true && user.emailIsVerified === true ?
                                                <img alt='is verified' src={isVerified} style={{ marginTop: '10px', paddingLeft: '10px' }} />
                                                : null}
                                        </div>
                                        <div style={{ display: 'flex', width: '85%', margin: 'auto', marginTop: '20px' }}>
                                            <ItemsData number={user.myProperties.length} title={'propiedades publicadas'} />
                                            <ItemsData title={'contactos aprobados'} number={'0'} />
                                            <ItemsData title={'servicios adquiridos'} number={'0'} />
                                        </div>
                                        <div className='settings-buttons-container'>
                                            <label className='label-user-profile-settings'> Ajustes del perfil </label>
                                            <SettingsProfile
                                                history={history}
                                                screenSizeWidth={screenSizeWidth}
                                                directToSettingsItem={directToSettingsItem}
                                                userIdFromUrl={userIdFromUrl}
                                            />
                                        </div>
                                    </div>
                                    {screenSizeWidth > 1030 &&
                                        <div className='myProfileWebVersionComponentDetails'>
                                            {openSettings.yourDataOpen ?
                                                <YourData />
                                                :
                                                openSettings.verificationOpen ?
                                                    <Verification history={history} />
                                                    :
                                                    openSettings.securityOpen ?
                                                        <Security />
                                                        :
                                                        openSettings.deleteAccountOpen ?
                                                            <DeleteAccount />
                                                            :
                                                            null
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default MyProfile;