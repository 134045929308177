import React from 'react'
import Select from '../../Common/Select/Select'
import DatePicker from '../../PickerTable/PickerTable'
import RouterColumn from '../../RouterColumn/RouterColumn'

export default function ComercializacionAdmin(){

    let word = [{ nombre: "Carteleria", link: "/admin/servicios"}, { nombre: "Fotografos", link: "/admin/fotografo"}, { nombre: "Contratos", link: "/admin/contratos"}]

    
  return (
    <div>
           <RouterColumn  />
   
           <div>
             <Select Word={word} palabra={"Asesor C."} />
           </div>
           <div>
             <DatePicker componente={"servicesRequest/commercialization"} palabra1={"COMERCIALIZACION"} palabra2={"SOLICITADAS"} style={{marginLeft: "800px", marginRight: "50px"}} />
           </div>
   
       </div>
  )

}