import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ModalResult from '../../Common/ModalResult/ModalResult'
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../../config/urls'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({datos, funct, handle}) {
    const [Data, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [alertData, setalertData] = React.useState("")
    const [openAlert, setopenAlert] = React.useState(false)
    const [loading, setloading] = useState(false)

    useEffect(() => {

        setData([datos])

        if(Data){
          setOpen(true)
          if(datos.userBan){
            setChecked(datos.userBan)            
          }
          
          if(datos.propertyId){
            setChecked(datos.propertyBan)
          }

        } 
    
    },[])


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    funct(false)
    setOpen(false);
    handle()
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const token = localStorage.getItem('tokenAdmin');


  return (
    <div>

      <Dialog onClose={handleClose} maxWidth="xl" aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          <strong> Chats </strong>
        </DialogTitle>
     <DialogContent dividers style={{ height: "150%" }} >
          <Typography gutterBottom>
           <strong >Chat ID</strong>: {datos.chatId}
          </Typography>
          <Typography gutterBottom>
          <strong >Nombre interesado</strong>: { datos.fullNameInterested }
          </Typography>
          <Typography gutterBottom>
          <strong >Nombre dueño</strong>: { datos.fullNameOwner }
          </Typography>
          <Typography gutterBottom>
          <strong >ID propiedad</strong>: { datos.propertyId }
          </Typography>

          {/* propertyId */}

        </DialogContent>


      </Dialog>
    </div>
  );
}