import React, { useEffect, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import hipropiLogo from "../../../../assets/images/hipropiLogo.svg";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { Context } from "../../../../Context";

import { useHistory } from "react-router-dom";
import URL from "../../../../config/urls";
import axios from "axios";
import Modal from "../../../commons/Modals/ModalWarning";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Iconos
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BuildIcon from "@material-ui/icons/Build";
import BookIcon from "@material-ui/icons/Book";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import HouseIcon from "@material-ui/icons/House";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ChatIcon from "@material-ui/icons/Chat";
import WorkIcon from '@material-ui/icons/Work';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import "./RouterColumn.css";

const drawerWidth = 240;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  search: {
    // position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginRight: "15%",
    marginLeft: "100%",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "17%",
      marginTop: "20px",
      width: "400px",
      borderRadius: "25px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(-1)}px)`,
    transition: theme.transitions.create("width"),
    // width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
  // necessary for content to be below app bar
}));

function ResponsiveDrawer(prop) {
  const {
    buscador,
    setBuscador,
    setdataBuscador,
    dataBuscador,
    setmodal,
  } = useContext(Context);

  let token = localStorage.getItem("tokenAdmin");
  const handleSend = () => {
    if (prop.word == "Usuarios") {
      let params = {
        email: buscador,
      };

      try {
        const request = axios
          .post(URL + `admin/dashboard/getNewUserEmail`, params, {
            headers: { token: token },
          })
          .then((res) => {
            // setdata(res.data.responseData[0])
            setOpenSucess(true);
            setdataBuscador(res.data.responseData[0]);
            setmodal(true);
          })
          .catch((err) => {
            setopenError(true);
            console.log(err);
          });
      } catch (error) {
        setopenError(true);
      }
    }
    if (prop.word == "Usuarios Conectados") {
      let params = {
        email: buscador,
      };

      try {
        const request = axios
          .post(URL + `admin/dashboard/getConectUserEmail`, params, {
            headers: { token: token },
          })
          .then((res) => {
            setOpenSucess(true);
            setdataBuscador(res.data.responseData[0]);
            setmodal(true);
            // setdata(res.data.responseData[0])
          })
          .catch((err) => {
            setopenError(true);
            console.log(err);
          });
      } catch (error) {
        setopenError(true);
      }
    }
    if (prop.word == "Propiedades") {
      let params = {
        propertyCode: buscador,
      };

      try {
        const request = axios
          .post(URL + `admin/dashboard/getPropertiesCode`, params, {
            headers: { token: token },
          })
          .then((res) => {
            setOpenSucess(true);
            setdataBuscador(res.data.responseData[0]);
            setmodal(true);
            // setdata(res.data.responseData[0])
          })
          .catch((err) => {
            setopenError(true);
            console.log(err);
          });
      } catch (error) {
        setopenError(true);
      }
    }
  };
  const handleOnChange = (e) => {
    setBuscador(e.target.value);
  };

  let history = useHistory();

  let nombre = localStorage.getItem("nombre");
  let rol = localStorage.getItem("rol");

  //   const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSucess, setOpenSucess] = React.useState(false);
  const [openError, setopenError] = React.useState(false);

  const handleClick = () => {
    setOpenSucess(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenError(false);
    setOpenSucess(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const deleteToken = () => {
    localStorage.removeItem("tokenAdmin");

    history.push("/login/dashboard");
    history.go(0);
  };

  useEffect(() => {
    if (!token) {
      history.push("/login/dashboard");
      history.go(0);
    }

    if (token) {
      axios
        .get(URL + "admin/dashboard/verifyToken", { headers: { token: token } })
        .then((e) => {
          if (!e.data.tokenIsValid) {
            setTimeout(() => {
              setOpenModal(false);
              deleteToken();
            }, 0);
          }
        })

        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  function handleActionButton() {
    setOpenModal(false);
    deleteToken();
    history.push("/login/dashboard");
    history.go(0);
  }

  const drawer = (
    <div className="div-drawer-app">
      <a href="/admin">
        <div style={{ marginLeft: "30px", paddingTop: "10px" }}>
          <img
            src={hipropiLogo}
            alt=""
            style={{ width: "80px", height: "80px" }}
          />
        </div>
      </a>
      <List className="list-component">
        {(rol == 1 || rol == 2) && (
          <a href="/admin/users" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <PeopleIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                Usuarios
              </span>
            </ListItem>
          </a>
        )}

        {(rol == 1 || rol == 2) && (
          <a href="/admin/servicios" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <AssignmentIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Servicios
              </span>
            </ListItem>
          </a>
        )}


      {(rol == 1 || rol == 2) && (
          <a href="/admin/aranceles" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <MonetizationOnIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Aranceles
              </span>
            </ListItem>
          </a>
        )}



        {(rol == 1 || rol == 2) && (
          <a href="/admin/denuncias" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <InfoIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Denuncias
              </span>
            </ListItem>
          </a>
        )}

        {rol == 1 && (
          <a href="/admin/administradores" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <BuildIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Roles
              </span>
            </ListItem>
          </a>
        )}

   
          <a href="/admin/UpdateBlog" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <BookIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Blog
              </span>
            </ListItem>
          </a>


          {/* WorkIcon */}

        {/* {false && ( */}
          <a href="/admin/emprendimientos" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <FilterNoneIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                Emprendimientos
              </span>
            </ListItem>
          </a>
        {/* )} */}

        {(rol == 1 || rol == 2) && (
          <a href="/admin/properties" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <HouseIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Propiedades
              </span>
            </ListItem>
          </a>
        )}

        {(rol == 1 || rol == 2) && (
          <a href="/admin/tasador" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <ReceiptIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Tasador
              </span>
            </ListItem>
          </a>
        )}

        {(rol == 1 || rol == 2) && (
          <a href="/admin/chat" style={{ textDecoration: "none" }}>
            <ListItem button className="paddingRouter">
              <ListItemIcon>
                {" "}
                <ChatIcon className="iconButton" />{" "}
              </ListItemIcon>
              <span className="textIcon" primary={"Cerrar sesión"}>
                {" "}
                Chats
              </span>
            </ListItem>
          </a>
        )}

        <div className="cerrarSesionButton">
          <ListItem button className=" paddingRouter">
            <ListItemIcon>
              {" "}
              <ExitToAppIcon className="iconButton" />{" "}
            </ListItemIcon>
            <span
              className="textIcon"
              onClick={deleteToken}
              primary={"Cerrar sesión"}
            >
              {" "}
              Cerrar sesión
            </span>
          </ListItem>
        </div>
      </List>
    </div>
  );

  //   const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <Modal
        noButton={true}
        openModal={openModal}
        title="¡Tu sesión caducó!"
        text="Ha pasado mucho tiempo desde la última vez que iniciaste sesión. Ingresa nuevamente a tu cuenta para poder acceder a todas las secciones de HiPropi o continúa navegando offline."
        acept="Iniciar sesión"
        cancel="cerrar"
        close={true}
        actionButton={handleActionButton}
      />
      <div className={classes.search}>
        <InputBase
          placeholder="Buscar"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          style={{ color: "black" }}
          name="buscador"
          onChange={handleOnChange}
          style={{ width: "350px" }}
        />
        <SearchIcon
          style={{ color: "black", cursor: "pointer" }}
          onClick={handleSend}
        />
      </div>

      <div>
        <h1 className="wordNavbar" style={{ position: "absolute" }}>
          {nombre}
        </h1>
      </div>

      <CssBaseline />

      <hr className="line-navbar" />

      <div className={classes.root}>
        <Snackbar
          open={openSucess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Usuario encontrado exitosamente.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            Usuario no encontrado.
          </Alert>
        </Snackbar>
      </div>

      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
