import React from 'react';
import TopBar from '../../commons/BlueTopBar/BlueTopBar';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import './Preguntas.css'

const PreguntasFrecuentes = ({ history }) => {

    const QuestionsAnswers = [
        {
            que: '¿HiPropi tiene algún costo en la comercialización de mi inmueble?',
            ans: `No. HiPropi es totalmente gratis, por lo que no cobra ni cobrará ningún tipo de comisión o arancel entre las personas que se vinculen para comercializar un inmueble.
            De hecho, tampoco te cobraremos por publicar en nuestro portal.`
        },
        {
            que: '¿Puedo obtener los servicios gratuitos de Fotógrafo y Cartel para todas mis propiedades?',
            ans: `Por el momento dispondremos de manera gratuita de nuestros servicios de Fotografo y Cartel únicamente para una propiedad por usuario.`
        },
        {
            que: '¿Hay algún limite de propiedades?',
            ans: `Podrás publicar todas las propiedades que quieras, sin limites ni restricciones.
            Solo necesitas registrarte y publicar de manera detallada cada una de tus propiedades.`
        },
        {
            que: '¿Puedo publicar si soy inmobiliaria?',
            ans: `No. Nuestra plataforma está pensada para vincular de manera directa a particulares, sin comisiones ni intermediarios.`
        },
        {
            que: '¿El tasador es 100% efectivo?',
            ans: `Si bien nuestra formula está desarrollada por profesionales del rubro, no podemos garantizarte la efectividad total del tasador,
            aunque si podemos decirte que con HiPropi obtendrás la tasación digital más efectiva de Argentina.`
        },
        {
            que: '¿Que sucede si cargo mi propiedad y soy de una inmobiliaria?',
            ans: `Todos los usuarios tienen la opción de denunciar si una propiedad pertenece a una inmobiliaria.
            Una vez que se denuncia, el usuario y todas las propiedades vinculadas a ese usuario serán eliminadas.`
        },
        {
            que: '¿Qué son los estilos de vida?',
            ans: `Los Estilos de HiPropi son un conjunto de filtros basados en las preferencias o usos de nuestros usuarios.
            De esta manera entonces si sos estudiante y buscas un departamento cerca de la zona universitaria,
            o si queres mudarte a una casa mas grande porque tu familia se agrandó, solo con aplicar estos filtro te mostraremos las mejores propiedades para vos.`
        },
        {
            que: '¿Cómo obtiene sus ingresos Hi Propi?',
            ans: `Hi Propi tiene actualmente dos servicios que están pensados para ayudarte a comercializar tus inmuebles: servicio de Cartelería y servicio de Fotografo.
            Por el momento estos servicios son gratuitos, pero a futuro estos servicios serán la manera de rentabilizar nuestra plataforma.`
        }
    ]

    let screenSizeWidth = window.innerWidth;

    return (
        <div  >
            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
            :
                <TopBar
                    title='Preguntas Frecuentes'
                    link='/'
                />
            }
            <div className='services-web-container-card'  >
                <div className='services-web-content-card'>


                    <div className='servicesContainer'>
                    { screenSizeWidth > 1030 &&
                        <div className='report-title-container' style={{ margin: 'auto' }} style={{ marginTop: screenSizeWidth > 1030 ? "35px" : "0px", marginBottom: "30PX"}}>
                            <label> Preguntas Frecuentes </label>
                        </div>
                    }
                        {/* <div className='servicesContentText'>
                            <label> Aquí encontrarás los servicios exclusivos de HiPropi que te ayudarán a comercializar tus inmuebles de manera directa y simple. </label>
                        </div> */}
                        <div className="text-preguntas" >
                        {
                            QuestionsAnswers.map((e,index) => {
                                console.log(e)
                                return (
                                    <>
                                        <strong className="title-text-color">{'0' + (index + 1) + '. '+ e.que}</strong>
                                        <br/>
                                        <label>{e.ans}</label>
                                        <br  />
                                        <br  />
                                    </>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PreguntasFrecuentes;