import React, { useState, useEffect, useContext } from 'react';
import './Filter.css';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ViewList from '../../commons/ViewList/ViewList';
import ViewGrid from '../../commons/ViewGrid/ViewGrid';
import TuneIcon from '@material-ui/icons/Tune';
import axios from 'axios';
import URL from '../../../config/urls';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context } from '../../../Context';
import MapsAll from '../../commons/Maps/MapsAll';
import credentials from '../../../data/credentials';
import noproperties from '../../../assets/images/no-properties.svg';
import favoriteIcon from '../../../assets/images/favorites.svg';
import ModalWarning from '../../commons/Modals/ModalWarning';
import Pagination from '@material-ui/lab/Pagination';
import { accommodations, amenities, furnishedAttributes, furnishedBeds } from '../../../data/amenitesAccommodationsData.js';

const mapURL = 'https://maps.googleapis.com/maps/api/js?key=' + credentials.mapKey;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={0}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const FilterGroup = ({ history, order, changeOrder, searchResults, totalProperties, filterOpening }) => {

    function goFilterDetails() {
        history.push('/filterdetails')
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div className="filterContainer">
            <div className="filterButtons">
                {screenSizeWidth > 1030 ?
                    null
                    :
                    <button className="buttonFilter" onClick={goFilterDetails}> <TuneIcon className="filterIcon" /><span className="filterText">Filtros</span></button>
                }

                <select className='filter-select' name="order" value={order} onChange={changeOrder} required>
                    <option value="relevants">Relevantes</option>
                    <option value="lowerPrice">Menor precio</option>
                    <option value="higherPirce">Mayor precio</option>
                    <option value="lastModifyDate">Recientes</option>
                </select>
            </div>
            <div className='SearchResultsWeb'>
                {
                    searchResults &&
                    (
                        searchResults.length !== 0 ?
                            <p className="results">{totalProperties} resultados</p> :
                            <p className="results">No hay resultados</p>
                    )
                }
            </div>
        </div>
    )
}

const FilterAll = ({ history, applyFilters }) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [searchResults, setSearchResults] = useState(null);
    const [favorites, setFavorites] = useState([]);
    let { searchFilters, setSearchFilters, page, setPage } = useContext(Context);



    const [order, setOrder] = useState('relevants');
    const [totalProperties, setTotalProperties] = useState(0);
    const [noPropertiesWithinDistrict, setNoPropertiesWithinDistrict] = useState(false)
    const [noPropertiesWithSpecificFilters, setnoPropertiesWithSpecificFilters] = useState(false)
    // const [page, setPage] = useState({
    //     number: 1,
    //     total: 0,
    //     size: 21
    // });
    const [dictionary, setDictionary] = useState({});
    const [modal, setModal] = useState({});

    const handleChangePage = (event, value) => {
        setPage({ ...page, number: value });
    };


    //We remove the PropertiesNear component whenever the user changes filters
    useEffect(() => {
        setNoPropertiesWithinDistrict(false)
        setnoPropertiesWithSpecificFilters(false)
    }, [searchFilters])

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setPage({ ...page, size: 20 });
        } else if (newValue === 1) {
            setPage({ ...page, size: 999999 });
        }
    };

    /*  const handleChangeIndex = index => {
         setValue(index);
     }; */

    const validateComparisons = () => {
        searchFilters.totalAreaLow > searchFilters.totalAreaHigh && (searchFilters.totalAreaLow = [searchFilters.totalAreaHigh, searchFilters.totalAreaHigh = searchFilters.totalAreaLow][0]);
        searchFilters.edifiedAreaLow > searchFilters.edifiedAreaHigh && (searchFilters.edifiedAreaLow = [searchFilters.edifiedAreaHigh, searchFilters.edifiedAreaHigh = searchFilters.edifiedAreaLow][0]);
        /* searchFilters.priceLow > searchFilters.priceHigh && (searchFilters.priceLow = [searchFilters.priceHigh, searchFilters.priceHigh = searchFilters.priceLow][0]); */
        searchFilters.expensesLow > searchFilters.expensesHigh && (searchFilters.expensesLow = [searchFilters.expensesHigh, searchFilters.expensesHigh = searchFilters.expensesLow][0]);
    }

    const validateStyles = () => {
        let added = 0;
        let allLifeStyles = 'Acabas de seleccionar:\n';
        const addLifeStyle = (lifeStyle) => {
            allLifeStyles += `${lifeStyle}. `;
            added++;
        }
        let paramsApplied = '\nEsto significa que te mostraremos propiedades con estas características:\n';
        const addParams = (...params) => {
            for (var i = 0; i < params.length; i++) {
                if (paramsApplied.indexOf(params[i]) < 0) {
                    paramsApplied += `${params[i]}.\n`;
                }
            }
            added++;
        }
        if (searchFilters.publishedFor !== 'tmpRent') {
            if (searchFilters.student) {
                addLifeStyle('Soy estudiante');
                addParams('Con monoambiente, 1 dormitorio o más', 'Con acceso a transporte público');
                setSearchFilters(searchFilters => { return { ...searchFilters, student: false, hasBedroomsNumber: -1, accessToPublicTransportation: true } });
            }
            if (searchFilters.idealpets) {
                addLifeStyle('Ideal mascotas');
                addParams('Con balcón', 'Con patio', 'Que admite mascota');
                setSearchFilters(searchFilters => { return { ...searchFilters, idealpets: false, isPetFriendly: true, hasBalcony: true, hasBackyard: true } });
            }
            if (searchFilters.security) {
                addLifeStyle('Con seguridad');
                addParams('En barrio cerrado', 'Con cámara de seguridad', 'En complejo cerrado', 'Con guardia', 'Con portero eléctrico');
                setSearchFilters(searchFilters => { return { ...searchFilters, security: false, secCamera: true, secGuard: true, secClosedNeighborhood: true, secComplexBuilding: true, secDoorbellIntercom: true } });
            }
            if (searchFilters.familylife) {
                addLifeStyle('Vida familiar');
                addParams('Con 2 o más dormitorios', 'Con 1 o más baños');
                setSearchFilters(searchFilters => { return { ...searchFilters, familylife: false, hasBedroomsNumber: 2, parkingPlaces: 1 } });
            }
            if (searchFilters.openspace) {
                addLifeStyle('Espacio abierto');
                addParams('Con balcón', 'Con patio');
                setSearchFilters(searchFilters => { return { ...searchFilters, openspace: false, hasBalcony: true, hasBackyard: true } });
            }
            if (searchFilters.single) {
                addLifeStyle('Soltero');
                addParams('Con monoambiente, 1 dormitorio o más');
                setSearchFilters(searchFilters => { return { ...searchFilters, single: false, hasBedroomsNumber: -1 } });
            }
        } else {
            if (searchFilters.familytmp) {
                addLifeStyle('Vida familiar');
                addParams('Con 2 o más dormitorios', 'Con 1 o más estacionamientos', 'Amoblado', 'Con cuna');
                setSearchFilters(searchFilters => { return { ...searchFilters, familytmp: false, parkingPlaces: 1, hasBedroomsNumber: 2, furnished: true, cradle: true } });
            }
            if (searchFilters.alone) {
                addLifeStyle('Viajo sol@');
                addParams('Con monoambiente, 1 dormitorio o más');
                setSearchFilters(searchFilters => { return { ...searchFilters, alone: false, hasBedroomsNumber: -1 } });
            }
            if (searchFilters.romantic) {
                addLifeStyle('Escapada romántica');
                addParams('Con 1 o más dormitorios');
                setSearchFilters(searchFilters => { return { ...searchFilters, romantic: false, hasBedroomsNumber: 1 } });
            }
            if (searchFilters.work) {
                addLifeStyle('Viaje laboral');
                addParams('Con 1 o más estacionamientos', 'Con acceso a transporte público', 'Con wifi');
                setSearchFilters(searchFilters => { return { ...searchFilters, work: false, wifi: true, parkingPlaces: 1, accessToPublicTransportation: true } });
            }
            if (searchFilters.group) {
                addLifeStyle('Viaje grupal');
                addParams('Con 2 o más dormitorios', 'Con 2 o más baños', 'Con televisión por cable', 'Con wifi');
                setSearchFilters(searchFilters => { return { ...searchFilters, group: false, wifi: true, cableTV: true, hasBedroomsNumber: 2, toiletsNumber: 2 } });
            }
            if (searchFilters.event) {
                addLifeStyle('Voy por un evento');
                addParams('Con acceso a transporte público');
                setSearchFilters(searchFilters => { return { ...searchFilters, event: false, accessToPublicTransportation: true } });
            }
        }
        let textModal = allLifeStyles + paramsApplied;
        let newTextModal = textModal.split('\n').map((item, i) => {
            return <p key={i} style={{ margin: 0 }}>{item}</p>;
        });
        if (added > 0 && !localStorage.getItem('hideModalLifeStyles')) {
            setModal({
                ...modal,
                open: true,
                title: `Hola! Los estilos de vida te ayudarán a encontar mejor tu propiedad. 
                A continuación te explicamos como funcionan tus estilos seleccionados.`,
                text: newTextModal,
                acept: 'Ok',
                cancel: 'false',
                action: 'handleClose',
            });
            // localStorage.setItem('hideModalLifeStyles', true);
        }
    }

    const validateParameters = (test) => {

        validateComparisons();

        validateStyles();

        let orderBy;
        let asc_desc;

        const checkPriceType = () => {
            if (searchFilters.publishedFor === 'sell') {
                orderBy = 'price.sellNormPrice';
            } else if (searchFilters.publishedFor === 'rent') {
                orderBy = 'price.rentNormPrice';
            } else if (searchFilters.publishedFor === 'tmpRent') {
                orderBy = 'price.tmpNormPrice';
            }
        }

        switch (order) {
            case 'relevants':
                orderBy = order;
                asc_desc = 'desc';
                break;
            case 'lowerPrice':
                checkPriceType();
                asc_desc = 'asc';
                break;
            case 'higherPirce':
                checkPriceType();
                asc_desc = 'desc';
                break;
            case 'lastModifyDate':
                orderBy = order;
                asc_desc = 'desc';
                break;
            default:
                orderBy = 'relevants';
                asc_desc = 'desc';
        }

        let params = {
            transaction: searchFilters.publishedFor,
            orderBy,
            asc_desc,
            pagination: {
                pageSize: page.size,
                gotoPage: page.number
            },
            totalArea: {
                totalAreaLow: searchFilters.totalAreaLow,
                totalAreaHigh: searchFilters.totalAreaHigh,
            },
            edifiedArea: {
                edifiedAreaLow: searchFilters.edifiedAreaLow,
                edifiedAreaHigh: searchFilters.edifiedAreaHigh,
            },
            price: {
                priceLow: searchFilters.priceLow,
                priceHigh: searchFilters.priceHigh,
                currency: searchFilters.currencyPrice,
            },
            expenses: {
                expensesLow: searchFilters.expensesLow,
                expensesHigh: searchFilters.expensesHigh,
                currency: searchFilters.currencyExpenses,
            },
            accommodationsNumber: searchFilters.accommodationsNumber,
            toiletsNumber: searchFilters.toiletsNumber,
            parkingPlaces: searchFilters.parkingPlaces,
            hasBedroomsNumber: searchFilters.hasBedroomsNumber,
            hasConstructionLevels: searchFilters.hasConstructionLevels,
            filters: {
                publishedFor: {
                    sell: searchFilters.publishedFor === 'sell' && true,
                    rent: searchFilters.publishedFor === 'rent' && true,
                    tmpRent: searchFilters.publishedFor === 'tmpRent' && true,
                },
                propertyType: searchFilters.propertyType,
                address: {
                    street: searchFilters.street,
                    neighborhood: searchFilters.neighborhood,
                    district: searchFilters.district,
                    state: searchFilters.state,
                    country: searchFilters.country,
                },
                accommodations: {},
                amenities: {},
                furnished: {}
            }
        }

        accommodations.map((item) =>
            (item.condition.search &&
                item.condition.transaction.includes(searchFilters.publishedFor) &&
                item.condition.propertyType.includes(searchFilters.propertyType)) &&
            (params.filters.accommodations[item.attribute] = searchFilters[item.attribute])
        )

        amenities.map((item) =>
            (item.condition.search &&
                item.condition.transaction.includes(searchFilters.publishedFor) &&
                item.condition.propertyType.includes(searchFilters.propertyType)) &&
            (params.filters.amenities[item.attribute] = searchFilters[item.attribute])
        )

        furnishedAttributes.map((item) =>
            (item.condition.search &&
                searchFilters.furnished) &&
            (params.filters.furnished[item.attribute] = searchFilters[item.attribute])
        )

        furnishedBeds.map((item) =>
            (item.condition.search &&
                searchFilters.furnished) &&
            (params.filters.furnished[item.attribute] = searchFilters[item.attribute])
        )

        // eslint-disable-next-line
        Object.entries(params).map(param => {
            if (typeof param[1] === 'object') {
                // eslint-disable-next-line
                Object.entries(param[1]).map(subparam => {
                    if (typeof subparam[1] === 'object') {
                        // eslint-disable-next-line
                        Object.entries(subparam[1]).map(subsubparam => {
                            !subsubparam[1] && delete params[param[0]][subparam[0]][subsubparam[0]]
                        })
                    } else {
                        !subparam[1] && delete params[param[0]][subparam[0]]
                    }
                })
            } else {
                !param[1] && delete params[param[0]]
            }
        })


        return params;
    }
    let token = localStorage.getItem('token');
    let headers = { headers: { "token": token, } };

    useEffect(() => {
        // eslint-disable-next-line
        token = localStorage.getItem('token');
        // eslint-disable-next-line
        headers = { headers: { "token": token, } };
    }, [])

    let BASE_URL;

    const getProperties = async () => {
        BASE_URL = URL + 'properties/filters';

        const res = await axios.post(BASE_URL, validateParameters(order))
        localStorage.getItem('searchFilters')
        const data = res.data;

        //If no properties are not found in a specific city we change this state to indicate the user that similar properties are getting returned
        if (data.propertiesWithRemovedDistrict) {
            setNoPropertiesWithinDistrict(true)
        } else {
            setNoPropertiesWithinDistrict(false)
        }

        //If no properties match with the filters applied by the user we change this state to indicate the user that similar properties are getting returned
        if (data.propertiesWithoutFilters) {
            setnoPropertiesWithSpecificFilters(true)
        } else {
            setnoPropertiesWithSpecificFilters(false)
        }

        setPage({ ...page, total: data.totalPages });
        data.responseData[0] ? setSearchResults(data.responseData[0]) :
            setSearchResults([]);

        if (data.totalRows === 0) {
            setTotalProperties(0)
            //Disabled for backend handling
            //getSuggestedProperties()
        } else {
            setTotalProperties(data.totalRows)
        }

    }

    // If getProperties() does not return any property due to the filters applied, this function will take care of removing filters until we get some properties
    // eslint-disable-next-line

    //DEPRECATED: Now the backend sends specific responses to handle this
    const getSuggestedProperties = async () => {


        const localSearchFilters = await JSON.parse(localStorage.getItem('searchFilters'))

        //If we cannot find properties within certain district we remove the district filter
        if (localSearchFilters.district !== '') {
            setNoPropertiesWithinDistrict(localSearchFilters.district)
            localSearchFilters.district = ''
            localStorage.setItem('searchFilters', JSON.stringify(localSearchFilters))
            searchFilters = localSearchFilters

            //We call the function that gets properties with the new search filters applied
            getProperties()
            return
        } else if (localSearchFilters.district === '') {
            //If there are no properties on certain state we don't what to show the "PropertiesNear" component
            setNoPropertiesWithinDistrict(false)
        }

        //If we cannot find certain type of properties...
        if (localSearchFilters.propertyType !== '') {
            setnoPropertiesWithSpecificFilters(true)
            localSearchFilters.propertyType = ''
            localStorage.setItem('searchFilters', JSON.stringify(localSearchFilters))
            searchFilters = localSearchFilters

            //We call the function that gets properties with the new search filters applied
            getProperties()
            return
        }


    }

    const NoProperties = () => {
        return (
            <div className="container-no-properties">
                <img src={noproperties} alt='Ninguna propiedad' />
                <p className="no-properties-title">Ups, no hay propiedades con tu búsqueda</p>
                <p className="no-properties-subtitle">Lamentablemente, no pudimos encontrar propiedades. Recomendamos sacar algunos de los filtros colocados y volver a intentar.</p>
            </div>
        )
    }

    const PropertiesNear = ({ city }) => {
        return (
            <div className="container-similar-properties">
                <img src={noproperties} alt='Ninguna propiedad' />
                <p className="no-properties-title">No hemos encontrado propiedades en {city}</p>
                <p className="no-properties-subtitle">...pero podemos mostrarte algunas propiedades cercanas que pueden interesarte!</p>
            </div>
        )
    }

    const SimilarPropertiesNear = ({ state }) => {
        return (
            <div className="container-similar-properties">
                <img src={noproperties} alt='Ninguna propiedad' />
                <p className="no-properties-title">No hemos encontrado propiedades {state ? `en ${state}` : ''}  con los filtros que seleccionaste</p>
                <p className="no-properties-subtitle">...pero podemos mostrarte algunas propiedades similares que pueden interesarte!</p>
            </div>
        )
    }


    let lang = navigator.language || navigator.userLanguage;
    lang = lang.split('-')

    const BASE_URL_DICT = URL + `dictionaries/${lang[0]}`

    useEffect(() => {
        axios.get(BASE_URL_DICT)
            .then(response => {
                const data = response.data.responseData.dict
                setDictionary(data)
            })
            .catch(e => {
                console.log('La petición no se ha realizado con éxito')
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setSearchResults(null);
        // eslint-disable-next-line
        BASE_URL = URL + 'favourites/getFavourites';

        if (token) {
            axios.get(BASE_URL, headers)
                .then(res => {
                    const data = res.data.responseData;
                    // eslint-disable-next-line
                    data.map(favorite => {
                        if (favorite.likedtransaction === searchFilters.publishedFor) {
                            setFavorites(favorites => {
                                return [
                                    ...favorites,
                                    favorite.propertyId
                                ]
                            })
                        }
                    });
                    getProperties();
                })
        } else {
            getProperties();
        }

    }, [order, page.number, value, applyFilters]);

    const changeOrder = e => {
        setOrder(e.target.value);
    }

    const MyTabs = withStyles({
        root: {
            borderRadius: "100px !important"
        },
        indicator: {
            backgroundColor: 'transparent',
        },
    })(Tabs);

    let labels = ['LISTADO', 'MAPA'];

    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    const showModal = () => {
        setModal({
            ...modal,
            open: true,
            title: 'Ups, no podés guardar favoritos',
            text: 'Necesitas iniciar sesión con tu cuenta para guardar tus propiedades favoritas.',
            acept: 'Iniciar Sesión',
            cancel: 'Ahora no',
            image: favoriteIcon,
            actionButton: history.push("/welcome")
        });
    }

    const [filtersModalOpen, setFiltersModalOpen] = useState(false);
    function filterOpening() {
        setFiltersModalOpen(!filtersModalOpen)
    }

    let screenSizeWidth = window.innerWidth;

    const ifMobileVersionContainer = {
        height: '70vh',
        width: '100%',
        margin: 'auto',
        padding: '20px'
    }

    const ifWebVersionContainer = {
        height: '95vh',
        width: '95%',
    }

    const goSignIn = () => {
        history.push('/welcome');
    }

    return (
        <div className="container-filterall">



            <ModalWarning
                title={modal.title}
                text={modal.text}
                acept={modal.acept}
                cancel={modal.cancel}
                image={modal.image}
                noButton={true}
                close={true}
                openModal={modal.open}
                actionButton={modal.action}
            />
            <div className='filtersGroupForWeb' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                <div className="container-maplist"  >
                    <AppBar position="static" color="default" style={{ width: '85%', margin: 'auto' }} >
                        <MyTabs
                            value={value}
                            onChange={handleChangeTab}
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            {labels.map(label => <Tab className="tabColor" label={label} key={label} style={{ borderRadius: '100px' }} />)}
                        </MyTabs>
                    </AppBar>
                </div>
                <FilterGroup
                    history={history}
                    order={order}
                    changeOrder={changeOrder}
                    searchResults={searchResults}
                    totalProperties={totalProperties}
                    filterOpening={filterOpening} />
            </div>
            <TabPanel value={value} index={0} dir={theme.direction} className='tab-panel-style' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                <div className='view-list-container-web'>
                    <div className='view-list-containt-content'>
                        <div className='propertiesListWebContainer'>
                            {noPropertiesWithinDistrict && !noPropertiesWithSpecificFilters && <PropertiesNear city={searchFilters.district} />}
                            {noPropertiesWithSpecificFilters && totalProperties > 0 && <SimilarPropertiesNear state={searchFilters.state ? searchFilters.state : null} />}
                            <div className='propertiesListWebContent'>

                                {
                                    searchResults === null ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                                            <CircularProgress size={70} />
                                        </div> :
                                        searchResults.length === 0 ?
                                            <NoProperties /> :
                                            <div className='PropertiesViewListWeb'>

                                                {(
                                                    searchFilters.visual === 'gallery' ?
                                                        searchResults.map((property, i) => <ViewList property={property} handleClick={handleClick} publishedFor={searchFilters.publishedFor} key={i} isFavorite={favorites.includes(property.propertyId)} showModal={showModal} dictionary={dictionary.propertyTypes} />) :
                                                        searchResults.map((property, i) => <ViewGrid property={property} handleClick={handleClick} publishedFor={searchFilters.publishedFor} key={i} isFavorite={favorites.includes(property.propertyId)} showModal={showModal} dictionary={dictionary.propertyTypes} />)
                                                )}
                                            </div>
                                }
                            </div>
                            {page.total > 1 && <Pagination classes={{ root: 'my-pagination', ul: 'my-pagination-items' }} count={page.total} page={page.number} onChange={handleChangePage} />}
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction} className='tab-panel-style' >
                <div className='view-list-container-web'>
                    <div className='view-list-containt-content'>
                        <div className='propertiesListWebContainer'>
                            <div className='propertiesListWebContent' style={{ marginTop: screenSizeWidth > 1030 ? "45px" : "0px" }}>
                                {
                                    searchResults === null ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                                            <CircularProgress size={70} />
                                        </div> :
                                        searchResults.length === 0 ?
                                            <div style={{ textAlign: 'center' }}><NoProperties /></div> :
                                            (
                                                <MapsAll

                                                    history={history}
                                                    searchResults={searchResults}
                                                    favorites={favorites}
                                                    publishedFor={searchFilters.publishedFor}
                                                    googleMapURL={mapURL}
                                                    containerElement={<div style={screenSizeWidth < 1030 ? ifMobileVersionContainer : ifWebVersionContainer} />}
                                                    mapElement={<div style={{ height: '100%', borderRadius: '10px', marginTop: '10px' }} />}
                                                    loadingElement={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                                                        <CircularProgress size={40} />
                                                    </div>}
                                                    isMarkerShow
                                                />
                                            )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
        </div>
    );
}

export default FilterAll;