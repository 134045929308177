import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import '../TotalCount/TotalCount.css'

const useStyles = makeStyles({
  root: {
    width: "250.8px",
    height: "142.3px"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({palabra1, palabra2, total }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={palabra1 == "PROPIEDADES" ? "total-div-admin-min" : "total-div-admin"} style={{backgroundColor: "#5963cc", borderRadius: "15px"}} >
      <CardContent>
        
        <Typography className='palabra-icon' variant="body2" component="p">
        { palabra1 }
        <br/>
        { palabra2 }
        </Typography>
        <div className='hr-admin-total' >
            <hr/>
        </div>
        
        <h1 className="numero-total-icon" >{ total }</h1>

        {
          palabra1 != "PROPIEDADES" &&   <PersonIcon className={ palabra1 == "PROPIEDADES" ? 'icon-style-admin-min' : 'icon-style-admin'} style={{ height: "240px", width:"100px", marginTop: "-100px"}} />
        }
        
       
      </CardContent>
    </Card>
  );
}