import React from 'react'
import TopAppBar from "../../../commons/TopBar/TopAppBar";
import hands from "../img/hands.svg";
import surface from "../img/surface1.svg";
import './mobilePay.css'

export const TransferenciaMobile = () => {
    return (
        <div>
             <TopAppBar  />
             <div className="contenedor-tarejta-pay-mobiledata" >
                <div className="img-data-tarj-mobi" >
                    <img src={ hands }/>
                </div>

                <div className="text-tarj-mobi" >
                    <strong className="blue-strong-pay" > Transferencia bancaria </strong>
                </div>
                <div className="text-data-tarj-mobile" >
                    <p className="p-data-pay-mobi" >
                    También podés abonar realizando una transferencia o depósito bancario.
Una vez realizado el pago, contactate con nosotros al
351 239-9518 y envianos tu comprobante. 
                    </p>
                </div>
                <div className="button-payment-data">
                  <button className="buttonSearch" style={{ width: "250px", fontSize: "14px", height: "50px"}}>ABONAR CON TRANSFERENCIA</button>
                </div>
             </div>
        </div>
    )
}
