import React from "react";
import Logo from './img/logoHiColor.svg'
import "./loading.css";

const LoadingPage = ({visible}) => {

  console.log(visible)

  var isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);



  if (!isChrome) {
    document.getElementsByClassName("infinityChrome")[0].style.display = "none";
    document.getElementsByClassName("infinity")[0].style.display = "block";



  }



  return (
    <div>
     {
      visible == "no" && <div></div>
     }  
    {
      visible == "yes" &&  <div style={{marginTop: "-100px"}} className="container-loading-pricipal ">
      <div >
        <div class="infinityChrome">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div class="infinity">
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          style={{ display: "none" }}
        >
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="6"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                result="goo"
              />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
      </div>

    <div id="testLoading">
    <img  className="logo-loading-hiPropi loadingLogo" src={Logo} />
    </div>
    </div>
    }
   </div>
  );
};

export default LoadingPage