import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import './VirtualAppraiserSlider.css';

const ViewAppraiser = ({ option }) => {
    return (
        <div>
            <div className='viewAppraiserContentImg'>
                <img alt='option' src={option.img} />
            </div>
            <div className='viewAppraiserDescription'>
                <strong> Puntuación: {option.ratingValue} </strong>
                <div className='view-appraiser-description-content-complete'>
                    { option.text.map((txt, index) =>
                        <div key={index} className='view-appraiser-description-label-01'>
                            <strong> {txt.title} </strong>
                            <label> {txt.description} </label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    }
}))

const VirtualAppraiserSlider = ({ ratingParams, images }) => {

    const classes = useStyles();

    const [ rating, setRating ] = useState(1)
   
    const marks = [
        { 
            value: 10
        },
        {
            value: 20,
        },
        {
            value: 30,
        },
        {
            value: 40,
        },
        {
            value: 50,
        },
        {
            value: 60,
        },
        {
            value: 70,
        },
        {
            value: 80,
        },
        {
            value: 90,
        }
    ];

    function valuetext(value) {
        if(value === 10) {
            setRating(1);
        } else if(value === 20) {
            setRating(2);
        } else if(value === 30) {
            setRating(3)
        } else if (value === 40) {
            setRating(4)
        } else if (value === 50) {
            setRating(5)
        } else if (value === 60) {
            setRating(6)
        } else if (value === 70) {
            setRating(7)
        } else if (value === 80) {
            setRating(8)
        } else if (value === 90) {
            setRating(9)
        }
    }

    function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
    }

    return (
        <div>
            <div className='viewAppraiserContainer'>

                { images && images.map(( option, index) => 
                    rating === option.ratingValue && 
                        <ViewAppraiser
                        key={index}
                        option={option} 
                        />
                )}

                {/* { rating === 1 && <ViewAppraiser 
                    img={test} 
                    title='Title 1' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 2 && <ViewAppraiser 
                    img={test} 
                    title='Title 2' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'
                    /> 
                }
                { rating === 3 && <ViewAppraiser 
                    img={test} 
                    title='Title 3' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 4 && <ViewAppraiser 
                    img={test} 
                    title='Title 4' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 5 && <ViewAppraiser 
                    img={test} 
                    title='Title 5' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 6 && <ViewAppraiser 
                    img={test} 
                    title='Title 6'
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 7 && <ViewAppraiser 
                    img={test} 
                    title='Title 7'
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 8 && <ViewAppraiser 
                    img={test} 
                    title='Title 8'
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                }
                { rating === 9 && <ViewAppraiser 
                    img={test} 
                    title='Title 9' 
                    description='Esto es una descripcion breve que hace referencia al titulo + img'/> 
                } */}
                
            </div>
            <div className={classes.root} onClick={ratingParams(rating)} >
                <Slider
                defaultValue={50}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                marks={marks}
                valueLabelDisplay="off"
                />
            </div>
        </div>
    )
}

export default VirtualAppraiserSlider;