import React, { useContext, useState, useEffect } from 'react';
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Circle,
    InfoWindow,
    Marker
} from 'react-google-maps';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Context } from '../../../Context';
import './Maps.css';
import ViewList from '../../commons/ViewList/ViewList';
import ViewGrid from '../../commons/ViewGrid/ViewGrid';


const MapsAll = ({ history, favorites, searchResults, publishedFor }) => {


    const { searchFilters } = useContext(Context);
    const [coordinates, setCoordinates] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 2.5
    });

    const [propertyShownCard, setPropertyShownCard] = useState('');
    const [prop, setprop] = useState('')
    const [change, setchange] = useState(false)

    let screenSizeWidth = window.innerWidth;

    const showInternal = property => {
        setTimeout(function () {
            setPropertyShownCard('');

                setPropertyShownCard(
                    
                        screenSizeWidth > 1050 ? <div style={{width: "200px", height: "360px"}} >
                        <ViewList
                            property={property}
                            handleClick={handleClick}
                            publishedFor={searchFilters.publishedFor}
                            isFavorite={favorites.includes(property.propertyId)}
                            size='small'
                        />
                        </div>: <div className='view-grid-map' >
                        <ViewGrid
                            property={property}
                            handleClick={handleClick}
                            publishedFor={searchFilters.publishedFor}
                            isFavorite={favorites.includes(property.propertyId)}
                        />
                        </div>
                    

                );
            

        //  window.scrollTo(0, 300);
        });


            setCoordinates({
                latitude: property.address.latitude,
                longitude: property.address.longitude
            });

    };

    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    const defaultCoordinates = results => {
        let latitudes = [];
        let longitudes = [];

        results.map(
            (property) => (
                // eslint-disable-next-line
                latitudes.push(parseFloat(property.address.latitude)),
                longitudes.push(parseFloat(property.address.longitude))
            )
        );

        let middleLatitude = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
        let middleLongitude =
            (Math.min(...longitudes) + Math.max(...longitudes)) / 2;

        let difLatitude = Math.max(...latitudes) - Math.min(...latitudes);
        let difLongitude = Math.max(...longitudes) - Math.min(...longitudes);

        let difUsed;

        difLatitude > difLongitude ? difUsed = difLatitude : difUsed = difLongitude;

        let newZoom = Math.floor(Math.log(256 / difUsed) / Math.log(2));


        setCoordinates({
            latitude: middleLatitude,
            longitude: middleLongitude,
            zoom: 2 
            // newZoom
        });
    };

    useEffect(() => {
        defaultCoordinates(searchResults);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {(searchResults === null || coordinates.zoom === 0) ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '50vh'
                    }}
                >
                    {/* <CircularProgress size={70} /> */}
                </div>
            ) : (
                    <GoogleMap
                        ref={React.createRef()}
                        center={{
                            lat: coordinates.latitude,
                            lng: coordinates.longitude
                        }}
                        defaultZoom={coordinates.zoom}
                        options={{
                            streetViewControl: false,
                            mapTypeControl: false,
                            gestureHandling:  ( change && screenSizeWidth < 1050) && 'none'
                        }}
                        onClick={() => setchange(false)}
                    >
     
                        {searchResults.map((property, i) => {
                            
                           return ( <Marker
                                position={{
                                    lat: parseFloat(property.address.latitude),
                                    lng: parseFloat(property.address.longitude)
                                }}
                                visible={false}
                                key={i}
                                
                            >
                     
                                <InfoWindow
                                    position={{
                                        lat: parseFloat(property.address.latitude),
                                        lng: parseFloat(property.address.longitude)
                                    }}
                                    draggable={false}
                                
                                >
                                    <div  >
                                    <button
                                        className={(property.propertyId == prop && change) ? 'maps-button-select' :'maps-my-button' }
                                        onClick={() => (showInternal(property), setprop(property.propertyId), setchange(!change))}
                                    >
                                        {
                                            (property.propertyId == prop && screenSizeWidth > 1050) ? null : <div className={property.propertyId == prop && change ? 'maps-button-select' :'maps-price' } >
                                            {publishedFor === 'sell' ? (
                                                <div>
                                                    {`${property.price.sellCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(property.price.sellPrice))}`}
                                                </div>
                                            ) : publishedFor === 'rent' ? (
                                                <div>
                                                    {`${property.price.rentCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(property.price.rentPrice))}`}
                                                </div>
                                            ) : (
                                                        <div>
                                                            {`${property.price.tmpCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(property.price.tmpPrice))}`}
                                                        </div>
                                                    )}
                                            <Circle
                                                ref={React.createRef()}
                                                center={{
                                                    lat: parseFloat(property.address.latitude),
                                                    lng: parseFloat(property.address.longitude)
                                                }}
                                                radius={200}
                                                options={{
                                                    fillColor: '#e32085',
                                                    strokeColor: 'transparent'
                                                }}
                                            ></Circle>
                                              
                                        </div>
                                        }
                                        
                                    </button>
                                    <div>
                                    {screenSizeWidth > 1050 && property.propertyId == prop ? propertyShownCard : null}
                                    </div>
                                    
                                    </div>
                                </InfoWindow>
                                                             
                            </Marker>
                        )})} 
                    {screenSizeWidth < 1050 && change &&  propertyShownCard }   
                    </GoogleMap>
                )}

        </div>
    );
};

export default withScriptjs(withGoogleMap(MapsAll));
