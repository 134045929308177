import React from 'react';
import './EmailValidate.css'
// import { Link } from 'react-router-dom';
import TopAppBar from '../../../commons/TopBar/TopAppBar';
import NavBarWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb'

//============= INITIAL APP CREATE ACCOUNT =============
const CreatYourAccount = ({ text }) => {
    return (
        <div className='services-web-container-card'>
                <div className='services-web-content-card'>
                        <div className='report-title-container' style={{ margin: 'auto' }}>
                            <label> { text } </label>
                        </div>
                </div>
        </div>

    )
};

const ButtonSearchProperties = ({ goSearch }) => {
    return (
        <div className='button-validate-container'>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 20 }}>
                <button onClick={goSearch} style={{ width: '83%', marginTop: 20 }} type='button' className='button-validate'> En otro momento </button>
            </div> */}
            {/* <div className='button-validate-text'>
                <label> Necesito ayuda, <strong> no me llegó ningún mail.</strong></label>
            </div> */}
        </div>
    )
};

const EmailValidateText = ({ goSearch }) => {
    return (
        <div className='email-validate-container-card'>
            <p className='p-email-validate-text'>
            Ahora, necesitamos que verifiques tu correo electrónico. 
            Esto te permitirá validar tu cuenta y poder contactarte con aquellos propietarios que sólo acepten mensajes de usuarios validados.
            </p>
            <p className='p-email-validate-text'> 
            Te hemos enviado un mensaje a tu casilla de correo. 
            Dentro del mismo habrá un link que te habilitará tu cuenta de HiPropi.
            </p>
            <p className='p-email-validate-text'> 
            <strong>El e-mail puede demorar unos minutos en llegar. Por favor revisa tu casilla de SPAM o correo no deseado</strong>, te recomendamos que lo verifiques para tener habilitadas todas las funcionalidades de HiPropi y 
            puedas comenzar a cargar propiedades!
            </p>
            { goSearch && <ButtonSearchProperties goSearch={goSearch} /> }
        </div>
    )
}

const EmailValidate = ({ history }) => {

    let screenSize = window.innerWidth;

    function goSearch() {
        history.push('/')
    }

    return (
        <div>
            {screenSize > 1030 ?
                <NavBarWeb history={history} />
                :
                <TopAppBar history={history} />
            }
            <div>
                <CreatYourAccount text='Necesitamos que valides tu mail' />
                <EmailValidateText goSearch={goSearch} />
            </div>
        </div>
    )
}

export default EmailValidate;