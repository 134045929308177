import React from 'react';
import tipicon from '../../../assets/images/tip-alert.svg';
import { useHistory } from 'react-router-dom';


const NewPropertyTip = ({ title, text, link }) => {
  const history = useHistory();

  return (
    <div className="tip-container">
      <div className="tip-card">
        <img src={tipicon} alt="tool-tip" />
        <h3 className="tip-text">{title}</h3>
        <p className={`tip-text ${link ? "tip-link" : ""}`} onClick={() => link && history.push(link)}>{text}</p>
      </div>
    </div>
  )
}

export default NewPropertyTip
