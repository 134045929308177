import React, { useState } from 'react';
import '../components/Services.css';
import Card from '../components/ServiceBigCard';
// import photographer from '../../../../assets/images/photographer.png';
import photographer from '../../../../assets/images/services/fotografo.png';
import TopAppBar from '../../../commons/BlueTopBar/BlueTopBar';
import TopAppBarWeb from '../../../commons/TopAppBarWeb/TopAppBarWeb';
import Header from '../../../commons/HeaderWeb/HeaderWeb';
import Modal from '../../../commons/Modals/ModalWarning';

const PhotographerPage = ({ history }) => {

    let token = localStorage.getItem('token')

    const [ openModal, setOpenModal ] = useState(false);

    function handleOptions() {
        if(token) {
            history.push('/services/photographer/buy')
        } else {
            setOpenModal(true)
        }
    };

    function handleCloseOut() {
        setOpenModal(true)
    };

    function actionButton() {
        history.push('/welcome')
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            { screenSizeWidth < 1030 ?
                <TopAppBar 
                    title='Fotógrafo'
                    link='/services'
                />
            :
                <TopAppBarWeb history={history} />
            }

            <Modal
            noButton={true}
            acept='Iniciar sesión'
            cancel='Cerrar'
            openModal={openModal}
            actionButton={actionButton}
            handleCloseOut={handleCloseOut}
            close='true'
            title='¡No estás logueado!'
            text='Para adquirir los diversos servicios que ofrece HiPropi, primero debes iniciar sesión.'
            />
    
            <div className='service-photographer-container'>
                <div className='service-photographer-content' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <div className='service-photographer-body-body'>
                        { screenSizeWidth > 1030 && <Header text='Servicios: Fotógrafo Profesional' w='100%' /> }
                        <div className='service-photographer-page-01'>
                            <div className='serviceSignalContainer01'>
                                <Card 
                                    service={{
                                        id: 203,
                                        img: photographer,
                                        type: 'Servicio',
                                        title: 'Fotógrafo',
                                        description: 'En un mundo cada vez más digital, las imágenes hablan por si solas. Por eso, aumentá tus posibilidades de comercializar tu propiedad con imágenes tomadas por un fotógrafo profesional, quien luego las subirá a nuestra plataforma para que vos no tengas que ocuparte de las fotos.'
                                    }}
                                />
                            </div>
                            <div className='serviceSignalDescriptionContent'>
                                <label>
                                    Una vez que solicitas tu servicio de Fotografo, nos pondremos en contacto 
                                    con vos para coordinar fecha y hora para la sesión. Te recomendamos que para 
                                    la fecha de la sesión tengas tu propiedad ordenada y limpia para poder resaltar 
                                    las virutes del inmueble. Luego de la sesión, nuestro fotografo seleccionará las 
                                    mejores imágenes y las cargaremos automáticamente a tu propiedad, para que vos 
                                    solo tengas que validarlas. 
                                </label>
                                <div className='serviceSignalButtonContainer'>
                                    <button onClick={ () => handleOptions() }> ¡Lo quiero! </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PhotographerPage;