import React, { useEffect, useState } from 'react';
import TopAppBar from '../../commons/TopBar/TopAppBar';
import Post from './Post';
// import { blogData } from '../../../data/blogData';
import TopNavWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import URL from '../../../config/urls'
import axios from 'axios'

const InternalBlog = ({ history }) => {

    let screenSizeWidth = window.innerWidth;


    const [blogData, setblogData] = useState([])
    const [published, setpublished] = useState([])
    const token = localStorage.getItem('token');



    const getData = () => {
        let publica = []
        
        try {
            axios.get(URL + 'blog/getAllNotes', { headers: { 'token': token } })
            .then((data) => {

                data.data.responseData[0].map((e) => {

                    if(e.isPublished){
                        publica.push(e)
                        
                    }

                })

                setblogData(publica)

            })

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getData()
    },[])




    return (
        <div>
            { screenSizeWidth < 1030 ? <TopAppBar /> : <TopNavWeb history={history} /> } 
            {
                blogData[0] && <Post post={blogData} history={history} />
            } 
        </div>
    )
};

export default InternalBlog;