import React, { useState, useEffect, Fragment } from 'react';
import './FooterContact.css'
//import { Link } from 'react-router-dom';
import axios from 'axios';
import URL from '../../../config/urls';
import Modal from '../Modals/ModalWarning';
import ReactWhatsapp from 'react-whatsapp';
import WspImage from '../../../assets/images/whatsapp-logo.png'
// whatsapp-logo.png
//import ModalWarning from '../../commons/Modals/ModalWarning';
//import alert from '../../../assets/images/alert.svg';

// const useStyles = makeStyles(theme => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     width: '83%',
//     minHeight: 374,
//     background: '#ffffff',
//     border: 'solid 1px #707070',
//     borderRadius: 5,
//     padding: '20px 25px 25px 25px',
//     outline: 'none',
//     display: 'inline-block',
//     textAlign: 'center'
//   },
//   p: {
//     fontFamily: 'Nunito Sans',
//     fontSize: '16px',
//     fontWeight: 800,
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: 1.38,
//     letterSpacing: 'normal',
//     color: '#232f34',
//     margin: '10px 0 10px 0'
//   },
//   label: {
//     fontFamily: 'Nunito Sans',
//     fontSize: '14px',
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: 1.36,
//     letterSpacing: 'normal',
//     color: '#232f34'
//   },
//   yes: {
//     height: 50,
//     borderRadius: 25,
//     boxShadrow: '3px 3px 15px 0 rgba(0, 0, 0, 0.16)',
//     background: '#e32085',
//     width: '83%',
//     border: 'none',
//     fontFamily: 'Nunito Sans',
//     fontSize: '16px',
//     fontWeight: 800,
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: 1.38,
//     letterSpacing: 'normal',
//     color: '#ffffff',
//     textTransform: 'uppercase',
//     marginTop: 20,
//     outline: 'none'
//   },
//   no: {
//     height: 40,
//     borderRadius: 25,
//     boxShadrow: '3px 3px 15px 0 rgba(0, 0, 0, 0.15)',
//     background: 'rgba(89, 99, 204, 0.05)',
//     width: '83%',
//     border: 'solid 1px #5963cc',
//     fontFamily: 'Nunito Sans',
//     fontSize: '16px',
//     fontWeight: 800,
//     fontStretch: 'normal',
//     fontStyle: 'normal',
//     lineHeight: 1.38,
//     letterSpacing: 'normal',
//     color: '#5963cc',
//     textTransform: 'uppercase',
//     marginTop: 15,
//     outline: 'none'
//   }
// }));

//   const TransitionsModal = ( { history }) => {
//     const classes = useStyles();
//     const [open, setOpen] = useState(false);

//     function goBack() {
//       history.goBack();
//     }

//     const handleOpen = () => {
//       setOpen(true);
//     };

//     const handleClose = () => {
//       setOpen(false);
//     };

//     return (
//       <div>
//         <button type="button" className='button-contact-content' onClick={handleOpen}>
//           Contacto
//         </button>
//         <Modal
//           aria-labelledby="transition-modal-title"
//           aria-describedby="transition-modal-description"
//           className={classes.modal}
//           open={open}
//           onClose={handleClose}
//           closeAfterTransition
//           disableEnforceFocus
//           BackdropComponent={Backdrop}
//           BackdropProps={{
//             timeout: 500,
//           }}

//         >
//           <Fade in={open}>
//             <div className={classes.paper}>
//               <img src={alert} />
//               <p className={classes.p}> Primero necesitamos que valides tu cuenta </p>
//               <label className={classes.label} > Por cuestión de seguridad necesitamos que hagas lorem ipsum ipsum, sólo dura 4 minutos en completar y no tendrás que volverlo a hacer. </label>
//               <div>
//                 <Link to='/myprofile/verificated'><button className={classes.yes} > validar </button></Link>
//               </div>
//               <div>
//                 <button onClick={goBack} className={classes.no}> ahora no </button>
//               </div>
//             </div>
//           </Fade>
//         </Modal>
//       </div>
//     );
//   }

const FooterContact = ({ history, publishedFor, price, property }) => {

    let jwt = require('jsonwebtoken');
    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token } };
    let decoded = jwt.decode(token)
    let BASE_URL;
    let contactId = property ? property.contact.userId : null;
    let propertyIdFromUrl;
    let transactionFromUrl;

    // eslint-disable-next-line
    let contactUrl;

    if (token !== null) {
        propertyIdFromUrl = window.location.href.split('=')[1].split('?')[0];
        transactionFromUrl = window.location.href.split('=')[2];
        BASE_URL = URL + 'users/' + decoded.data.id
        contactUrl = '/contact?propertyId=' + propertyIdFromUrl + '?transaction=' + transactionFromUrl + '?intId=' + decoded.data.id
    }

    const [user, setUser] = useState(null);
    const [modalOn, setModalOn] = useState(false);
    const [errorSesion, seterrorSesion] = useState(false)

    useEffect(() => {
        axios.get(BASE_URL, headers)
            .then(response => {
                const dataUser = response.data.responseData;
                setUser(dataUser)
            })
            .catch(e => {
                console.log(e);
            })
        // eslint-disable-next-line
    }, [])

    function handleAlert() {
        seterrorSesion(true)
        setModalOn(true)
        console.log('integrar notificación')
    }
    function handleCloseError() {
        setModalOn(!modalOn);
        //  props.history.push('/error-page');
    };
    const getAccessToMessages = (property) => {
        const urlToMessages = `/contact/?propertyId=${property.propertyId}?transaction=${property.publishedFor}?intId=${decoded.data.id}?chat=${decoded.data.id}«»${property.contact.userId}«»${property.propertyId}`
        //usuario no logueado
        if (!decoded) {
            setModalOn(!modalOn)
            return
        }
        // user es dueño de la propiedad
        if (decoded.data.id === property.contact.userId) {
            (setModalOn(true))
            return
        }
        if (!property.onlyValidatedUsers) {
            history.push(urlToMessages);
        } else {
            //user no tiene el mail verificado
            if (!decoded.data.emailIsVerified) {
                setModalOn(!modalOn);
            } else {
                //redirect to messages
                history.push(urlToMessages);
            }
        }
    }

    console.log(user)

    return (
        <div>
            <div className='footer-contact-content'>
                {/* {
                    price &&
                    <>
                        {
                            publishedFor === 'sell' ?
                                <div>
                                    <div className='footer-contact-desc-line'>
                                        {price.sellCurrency ? (`${price.sellCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(price.sellDiscount))}`) : ''}
                                    </div>
                                    <div className='footer-contact-price'>
                                        {price.sellCurrency ? (`${price.sellCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(price.sellPrice))}`) : 'Cargando...'}
                                    </div>
                                </div> :
                                publishedFor === 'rent' ?
                                    <div>
                                        <div className='footer-contact-desc'>
                                            {price.rentDiscount ? price.rentDiscount : ''}
                                        </div>
                                        <div className='footer-contact-price'>
                                            {price.rentCurrency ? `${price.rentCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(price.rentPrice))}` : 'Cargando...'}
                                        </div>
                                    </div> :
                                    <div>
                                        <div className='footer-contact-desc'>
                                            {price.tmpDiscount ? price.tmpDiscount : ''}
                                        </div>
                                        <div className='footer-contact-price'>
                                            {price.tmpCurrency ? `${price.tmpCurrency} ${new Intl.NumberFormat("de-DE").format(Math.round(price.tmpPrice))}` : 'Cargando...'}
                                        </div>
                                    </div>
                        }
                    </>
                } */}
                {/* { user.phoneIsVerified && user.emailIsVerified ?  */}


                {user ?
                    <div style={{display: "flex" }} >
                        <button className='button-contact-content' onClick={() => getAccessToMessages(property)}> Contacto </button>
                         {
                             property &&  <ReactWhatsapp style={{border: "0px", backgroundColor: "transparent" }} element="button" number={ property ? property.contact.phone : null } message="Hola! me interesa la propiedad que publicaste en la pagina de Hi Propi!" >
                             <img style={{height: "50px", width: "50px" }} src={WspImage} />
                             </ReactWhatsapp>
                         }

                        
                    
                        {decoded ? (
                            <Fragment>
                                {decoded.data.id === contactId ? (
                                    <Modal
                                        noButton={true}
                                        title='Esta es tu propiedad'
                                        text='Eres el dueño de este inmueble, por lo tanto no puedes establecer contacto contigo mismo'
                                        acept={false}
                                        openModal={modalOn}
                                        actionButton={() => handleCloseError()}
                                        //onClick = {() => handleCloseError()}
                                        handleCloseOut={() => handleCloseError()}
                                        cancel='Ok'
                                        close={true}
                                    />
                                ) : (
                                        <Modal
                                            noButton={true}
                                            title='Debes validar tu email para contactar'
                                            text='El dueño de la propiedad ha decidido no ser contactado por usuarios que no tengan su email validado'
                                            acept='Validar correo electrónico'
                                            customLink={`/myprofile/verificated/verifyEmail?userId=${decoded.data.id}`}
                                            openModal={modalOn}
                                            actionButton={() => handleCloseError()}
                                            //onClick = {() => handleCloseError()}
                                            handleCloseOut={() => handleCloseError()}
                                            cancel='Cancelar'
                                            close={true}
                                        />
                                    )}

                            </Fragment>
                        ) : (<Modal
                            noButton={true}
                            title='Debes iniciar sesion para contactar'
                            text='Para poder comunicarte con el dueño de la propiedad debes iniciar sesión. Si no tienes cuenta aún puedes crear una completamente gratis en unos minutos!'
                            acept='Ingresar'
                            customLink={`/welcome`}
                            openModal={modalOn}
                            actionButton={() => handleCloseError()}
                            //onClick = {() => handleCloseError()}
                            handleCloseOut={() => handleCloseError()}
                            cancel='cancelar'
                            close={true}
                        />)}

                    </div>
                    :
                 
                    <div>
                                        {
                        errorSesion ? <Modal
                        noButton={true}
                        title='Debes iniciar sesion para contactar'
                        text='Para poder comunicarte con el dueño de la propiedad debes iniciar sesión. Si no tienes cuenta aún puedes crear una completamente gratis en unos minutos!'
                        acept='Ingresar'
                        customLink={`/welcome`}
                        openModal={modalOn}
                        actionButton={() => handleCloseError()}
                        //onClick = {() => handleCloseError()}
                        handleCloseOut={() => handleCloseError()}
                        cancel='cancelar'
                        close={true}
                    /> : null
                    }
                    <button onClick={handleAlert} className='button-contact-content'> Contacto </button>
                    </div>

                    }
                {/* : */}

                {/* <ModalWarning
                history={history}
                title='No podés contactarte con el dueño'
                text='Tenés que ingresar a tu cuenta o registrarse para poder comunicarte con el dueño.'
                acept='Aceptar'
                cancel='Ahora no'
                image={alert}
                callToAction='Contacto'
                styleButton='button-contact-content' /> */}

                {/* <ModalWarning
                history={history}
                title='No podés contactarte con el dueño'
                text='Tenés que verificar tu mail para poder contactarte con el dueño.'
                acept='Verificar mail'
                cancel='Ahora no'
                image={alert}
                callToAction='Contacto'
                styleButton='button-contact-content'
                  /> */}

                {/* <ModalWarning
                history={history}
                title='Este dueño únicamente recibe mensajes de usuarios verificados'
                text='Sólo dura 4 minutos en completar, vas a poder comunicarte con más dueños
                y no tendrás que volverlo a hacer.'
                acept='validar'
                cancel='Ahora no'
                image={alert}
                callToAction='Contacto'
                styleButton='button-contact-content'
                /> */}

                {/* <ModalWarning
                history={history}
                title='¿Estas seguro que querés borrar esta propiedad?'
                text='Se borrará y ya no podrás recuperarla.'
                acept='Sí, borrar'
                cancel='No borrar'
                /> */}

                {/* <ModalWarning
                history={history}
                title='¿Estás seguro que queres dar de baja esta propiedad?'
                text='Ya no se podrá ver en las búsquedas pero quedará guardada en "Mis propiedades".'
                acept='Sí, dar de baja'
                cancel='No dar de baja'
                /> */}

                {/* <ModalWarning
                history={history}
                title='¿Estás seguro que querés publicar esta propiedad?'
                text='Se podrá ver en las búsquedas y podrás modificarla en cualquier momento.'
                acept='Sí, publicar'
                cancel='No publicar' /> */}

                {/* <ModalWarning
                history={history}
                title='No podés publicar tu propiedad'
                text='Tenés que ir al botón editar y completar los datos obligatorios que faltan.'
                acept='Entendido'
                cancel='false' /> */}

                {/* <ModalWarning
                history={history}
                title='¡Tu denuncia fue exitosa!'
                text='Gracias por ayudarnos para mantener HiPropi una mejor comunidad.'
                acept='Entendido'
                cancel='false'
                /> */}

                {/* } */}
            </div>
        </div>
    )
}

export default FooterContact;