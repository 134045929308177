import React, { useState, useEffect,  Fragment} from 'react';
import '../BuySignalService/BuySignal.css';
import TopAppBarMobile from '../../../../../commons/TopBar/TopAppBar';
import { Stepper } from '../../../../../commons/Stepper/Stepper';
import whiteRightArrow from '../../../../../../assets/images/whiteRightArrow.png';
import greenLeftArrow from '../../../../../../assets/images/greenLeftArrow.png';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TopAppBarWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';
import Head from '../../../../../commons/HeaderWeb/HeaderWeb';
import StepByStep from '../../../../../commons/StepByStep/StepByStep';
import URL from '../../../../../../config/urls';
import axios from 'axios';
import ViewGrid from '../../../../../commons/ViewGrid/ViewGrid';

const BuyPhotographerConfirm = ({ history }) => {

    const BASE_URL = URL + 'properties/propertyId/'

    const useStyles = makeStyles(theme => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
    }));
    let token = localStorage.getItem('token');
    const headers = { headers: { "token": token, } };
    const classes = useStyles();

    const [ phone, setPhone ] = useState('');
    const [ properties, setProperties ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ prop, setProp ] = useState([]);
    const [propi, setPropi] = useState([])

    const [show, setshow] = useState(false)

    let datos = [];

    useEffect(() => {

        let dataFromLocalStorage = localStorage.getItem('dataPersistence');
        let dataPersistence = JSON.parse(dataFromLocalStorage);

        // We store the properties from localStorage in this array. Then we'll use it to set the properties on our state.
        let propertiesArray = []
        if(dataPersistence) {
            setPhone(dataPersistence.userPhone)

            //We map through the properties from localStorage and push them into the array
            dataPersistence.selectedProperties.map(property => propertiesArray.push(property))
        }   
        setProperties(propertiesArray)
        
        var ok = [];

        for(let i = 0; i < properties.length; i++) {
            axios.get(BASE_URL + properties[i], headers).then(response => {
                const { responseData } = response.data
                // setProp([...prop,responseData])
                datos.push(responseData)
                ok.push(responseData)
                setProp(...prop,ok)
                setTimeout(() => {
                    setshow(true)
                }, 3000)
            })
            
        }
        
        setPropi(...propi, prop)
    }, [phone])

    function handleOptions(value) {
        if(value === 'nextStep') {
            let order = JSON.parse(localStorage.getItem('dataPersistence'));
            setLoading(true)
            axios.post(URL + 'services/photo', order, { headers: { 'token': token } })
                .then(response =>
                    console.log('response:', response))
            localStorage.removeItem('dataPersistence')
            setTimeout(() => history.push('/services/photographer/buy/confirm/successful'), 500)
        } else {
            history.push('/services/photographer/buy')
        }
    };

    let screenSizeWidth = window.innerWidth;

    // useEffect(() => {
    //     setTimeout(() => {
    //     })
    //         setPropi(...propi, prop)

    // },[properties])

    return (
        <>

            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
                :
                <>
                    <TopAppBarMobile history={history} />
                    <div className='buyServicesHeaderContainer'  style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                        <label> Adquisición de servicios: </label>
                        <Stepper title='Fotógrafo Profesional' byStep='2/2' steps={5} activeStep={4} />
                    </div>
                </>
            }
            <div className='buyServicesBodyContainer'>

                <div className='buy-services-photographer-confirm-content'>
                    <div className='buy-services-photographer-confirm-body'>
                    { screenSizeWidth > 1030 &&
                        <div className='buy-services-photographer-confirm-header'>
                            <Head text='Servicio: Fotógrafo Profesional' w='80%' />
                        </div>
                    }
                        <div className='buy-services-photographer-confirm-body0101'>
                            { screenSizeWidth > 1030 &&
                            <div className='buy-services-photographer-confirm-steps'>
                                <StepByStep
                                steps={[
                                    {
                                        number: 1,
                                        name: 'Datos de contacto'
                                    },
                                    {
                                        number: 2,
                                        name: 'Confirmación'
                                    }
                                ]}
                                stepNumber={2}
                                />
                            </div>
                            }
                            <div className='buy-services-photographer-confirm-data'>
                                <h2> Confirmación de los datos </h2>

                                <label> Teléfono de contacto <strong>*</strong></label>
                                <input defaultValue={phone} disabled />

                                { properties && properties.length === 1 ?
                                    <h4> Propiedad </h4>
                                :
                                    <h4> Propiedades </h4> 
                                }
                                
                                {/* {properties && properties.map((property, index) => <label key={index}> {property} </label> )} */}

                                {
                                                    // We want to match each property with the id passed previously
                                                    show && prop.map((propy, id) => (

                                                        // (property.servicesRequested && property.propertyId === id && property.servicesRequested.photography === false) && (

                                                            <Fragment key={id}>

                                                                {/* <p>{propy.id}</p> */}
                                                                <p><strong style={{color: "black"}} >Codigo de propiedad:</strong> <strong> {propy.propertyCode[0]} </strong></p>
                                                                <ViewGrid handleClick={() => null} property={propy} myproperty={false}
                                                                    publishedFor={
                                                                        propy.publishedFor.sell ? 'sell' :
                                                                        propy.publishedFor.rent ? 'rent' :
                                                                                'tmpRent'
                                                                    } />

                                                            </Fragment>
                                                        
                                                    ))
                                                }



                                <div className='buySignalButtonsContainer'>
                                    { screenSizeWidth < 1030 &&
                                    <button className='buySignalBackButton' onClick={ () => handleOptions('back')} >
                                        <img alt='go back' src={greenLeftArrow} />
                                        <label> Atrás </label>
                                    </button>
                                    }
                                    <button 
                                    className='buySignalNextButton' 
                                    onClick={ () => handleOptions('nextStep')}>
                                        <label> Confirmar </label>
                                        <img alt='confirm' src={whiteRightArrow} />
                                    </button>
                                </div>

                                { loading  &&
                                    <div>
                                        <Backdrop className={classes.backdrop} open={loading}>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    </div> 
                                }
                                  { show == false  &&
                                    <div>
                                        <Backdrop className={classes.backdrop} open={true}>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    </div> 
            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BuyPhotographerConfirm;