import React, { useState} from "react";
import Info from "./info.jsx";
import URL from '../../../../../config/urls'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';

import { Alert } from '@material-ui/lab';
import "../../Blog/form.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: '20%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: "fixed",
    bottom: "3%",
    left: "3%",
    zIndex: 10000, 
  },
}));

export default function Formulario({id,setDataPrincipal, DataPrincipal, setparagrapth, postBody, imgPrincipal, date}) {

  const classes = useStyles();

  const [create, setcreate] = useState(false)
  const [error, seterror] = useState(false)

  const onChange = (e) => {

    setDataPrincipal({
      ...DataPrincipal,
      [e.target.name]: e.target.value
    })

  }

  const token = localStorage.getItem('tokenAdmin');


  const handleSend = () => {
    
    let data = {
      DataPrincipal,
      postBody,
      imgPrincipal
    }

    try {
      
      axios.put(URL + `blog/updateNotes/${id}`  , data ,{ headers: { 'token': token } })
      .then(() => {
        setcreate(true)
      })
      .catch((err) => {
        seterror(true)

      })

    } catch (error) {
      seterror(true)
    }

  }



  return (
    <div style={{ width: "400px" }}>
      <h3>Titulo</h3>
      <input
        type="text"
        className="data-input"
        placeholder="Titulo"
        name="postTitle"
        value={DataPrincipal && DataPrincipal.postTitle}
        onChange={onChange}
      />
      <h3>Subtitulo</h3>
      <input
        type="text"
        className="data-input"
        name="postSubtitle"
        placeholder="Subtitulo"
        value={DataPrincipal && DataPrincipal.postSubtitle}
        onChange={onChange}
      />
      <h3>Autor</h3>
      <input
        type="text"
        className="data-input"
        name="author"
        placeholder="Autor"
        value={DataPrincipal &&  DataPrincipal.author}
        onChange={onChange}
      />
      <h3>Informacion de la nota</h3>
      <Info setparagrapth={setparagrapth} postBody={postBody}/>

      <button
        className="buttonSearch"
        style={{ width: "400px", marginTop: "30px" }}
        onClick={() => handleSend()}
      >
        
        GUARDAR CAMBIOS
      </button>
      {
        create && <div className={classes.root}>

        <Alert onClose={() => setcreate(false)} variant="filled" severity="success">
          Guardado exitosamente
        </Alert>
      </div>
      }
      {
        error && <div className={classes.root}>

        <Alert onClose={() => seterror(false)} variant="filled" severity="error">
          Hubo un error
        </Alert>
      </div>
      }
    </div>
  );
}
