import React, {useContext} from 'react';
import TopBar from '../../../../../commons/TopBar/TopAppBar';
import '../BuySignalService/BuySignal.css';
import smarthphone from '../../../../../../assets/images/smarthphone.svg';
import TopAppWeb from '../../../../../commons/TopAppBarWeb/TopAppBarWeb';
import { Context } from '../../../../../../Context'

const BuyPhotographerSuccessful = ({ history }) => {

    const { newProperty, setNewProperty } = useContext(Context);

    function handleRedirection(value) {
        if( value === 'anotherService') {
            history.push('/services')
        } else if (value === 'backProperty') {
            history.push('/newproperty/2');
        }
         else {
            history.push('/')
        }
    };

    let screenSizeWidth = window.innerWidth;


    return (
        <>
            {
                screenSizeWidth > 1030 ?
                    <TopAppWeb history={history} />
                :
                    <TopBar />
            }

            <div className='buy-signal-successful-container'>
                <div className='buy-signal-successful-content'  style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                    <img src={smarthphone} alt='smarthphone-img' />
                    <h3> ¡Un fotógrafo se va a contactar con vos! </h3>
                    <label> Has solicitado un fotografo a domicilio! Pronto nos pondremos en contacto con vos para validar la fecha y hora de la sesión. Te sugerimos que ese dia dejes tu propiedad limpia y ordenada para que las fotos luzcan increíbles. </label>
                    <div className='buy-signal-successful-button-container'>
                        {
                            newProperty.route !== '' && <button onClick={() => handleRedirection('backProperty')} > Volver a mi propiedad </button> 
                        }
                        <button onClick={() => handleRedirection('anotherService')} > Pedir otro servicio </button>
                        <button onClick={ () => handleRedirection('goHome')} > Volver al Inicio </button>
                    </div>
                </div>
            </div>
        </> 
    )
};

export default BuyPhotographerSuccessful;