import React, {useState, useEffect, Fragment, useContext} from 'react';
import TopBar from '../../commons/BlueTopBar/BlueTopBar';
// import Card from '../../pages/Services/components/ServiceCard';
import Card from '../../pages/Services/components/ServiceBigCard';
import { makeStyles } from '@material-ui/core/styles';
import empty from '../../../assets/images/empty.png';
import { Context } from '../../../Context'
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import URL from '../../../../../frontend/src/config/urls';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewGrid from '../../commons/ViewGrid/ViewGrid';
import ViewList from '../../commons/ViewList/ViewList';
import Pagination from '@material-ui/lab/Pagination';
import './SearchNavbar.css'
import axios from 'axios';

const Services = ({ history }) => {


    const [myPropertiesUpload, setMyPropertiesUpload] = useState([]);
    const [myPropertiesSave, setMyPropertiesSave] = useState([]);
    const [searchDonde, setSearchDone] = useState(false);
    const [modal, setModal] = useState({});
    const [userHasVerifiedEmail, setUserHasVerifiedEmail] = useState()

    const [isLoading, setIsLoading] = useState(true)



    let jwt = require('jsonwebtoken');
    const token = localStorage.getItem('token');
    const headers = { headers: { "token": token, } };

    const { 
        setdataSearch,
        dataSearch,
        loading,
        word,
        page, 
        setPage
        
    } = useContext(Context);

    

    const handleSubmit = () => {
      
        history.push('/properties')
      }

    const handleChangePage = (event, value) => {

        setPage({ ...page, number: value });
    };


    const handleClick = (id, publishedFor) => {
        // eslint-disable-next-line
        history.push('/internalproperty' + '?propertyId=' + id + '?transaction=' + publishedFor);
    }

    useEffect(() => {
    setSearchDone(false)
    setIsLoading(true)
    setMyPropertiesUpload([])


    let BASE_URL = URL + "properties/searchPropertiesFast"

    let params = {
      propertyType: "casa",
      location: word,
      pagination: {
        pageSize: page.size,
        gotoPage: page.number
      }
  }
    axios.post(BASE_URL, params, headers )
    .then((res) => {
    
     setMyPropertiesUpload(res.data.responseData[0])
     setPage({ ...page, total: res.data.totalPages });
     setIsLoading(false)
     setSearchDone(true)
   
    })
    .catch((err) => {
        setSearchDone(true)
        setIsLoading(false)
        console.log(err)


    })

 
    }, [dataSearch, page.number]);




    let screenSizeWidth = window.innerWidth;



    return (
        <div>
            { screenSizeWidth > 1030 ?
                <TopAppBarWeb history={history} />
            :
                <TopBar 
                    title='Resultados de busqueda' 
                    link='/'
                />
            }
            <div className='services-web-container-card'  >
                <div className='services-web-content-card'>
                    { screenSizeWidth > 1030 &&
                        <div  className='report-title-container' style={{ margin: 'auto' }} style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "10px", marginLeft: "40px"}}>
                            <label> Resultados de tu busqueda </label>
                        </div>
                    }
                    <div className="container-myproperties"  style={{ marginTop: screenSizeWidth > 1030 ? "0px" : "100px" }}>

                    {
                        !searchDonde ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '180px' }}>
                                <CircularProgress size={70} />
                            </div> :
                            myPropertiesUpload.length === 0 ?
                                <div className="container-empty">
                                    <div className="empty-card">
                                        <img className="empty-img" src={empty} alt={empty} />
                                        <p className="empty-text">No hay ninguna <br /> propiedad publicada en esa zona.</p>
                                    </div>
                                </div> :
                                <div className="container-fullP"   >
                                    {
                                         isLoading  ?
                                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '180px', marginLeft: "380px" }}>
                                             <CircularProgress size={70} />
                                         </div> : 
                                         
                                         
                                         myPropertiesUpload[0] ? myPropertiesUpload.map((property, i) =>
                                            property.publishedFor.sell && property.publishedFor.rent ?
                                                <Fragment key={i}>
                                                    <div>
                                                        <ViewList property={property} handleClick={handleClick}  publishedFor={'sell'}  />
                                                        <div className="edit-save-delete-group">
                                                            
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ViewList property={property} handleClick={handleClick}  publishedFor={'rent'}  />
                                                        <div className="edit-save-delete-group">
                                                           
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <div key={i}  >
                                                    <ViewList property={property} handleClick={handleClick} 
                                                        publishedFor={
                                                            property.publishedFor.sell ? 'sell' :
                                                                property.publishedFor.rent ? 'rent' :
                                                                    'tmpRent'
                                                        } />
                                                    <div className="edit-save-delete-group">
                                                      
                                                    </div>
                                                </div>
                                        ) : myPropertiesUpload[0] &&  myPropertiesUpload.map((property, i) =>
                                        property.publishedFor.sell && property.publishedFor.rent ?
                                            <Fragment key={i}>
                                                <div>
                                                    <ViewList property={property} handleClick={handleClick}  publishedFor={'sell'}  />
                                                    <div className="edit-save-delete-group">
                                                      
                                                    </div>
                                                </div>
                                                <div>
                                                    <ViewList property={property} handleClick={handleClick}  publishedFor={'rent'}  />
                                                    <div className="edit-save-delete-group">
                                                       
                                                    </div>
                                                </div>
                                            </Fragment> : 
                                            <div key={i}  >
                                                <ViewList property={property} handleClick={handleClick}  
                                                    publishedFor={
                                                        property.publishedFor.sell ? 'sell' :
                                                            property.publishedFor.rent ? 'rent' :
                                                                'tmpRent'
                                                    } />
                                                <div className="edit-save-delete-group">
                                                   
                                                </div>
                                            </div>
                                        )}
                                </div>
                    }
                    

                    </div>

                </div>
                {myPropertiesUpload.length > 0 &&  page.total > 1 && <Pagination style={{marginBottom: "20px"}} classes={{ root: 'my-pagination', ul: 'my-pagination-items' }}  count={page.total} page={page.number} onChange={handleChangePage} />}
               { isLoading == false && <div className='btn-vermas' style={{width: "40%", marginLeft: "auto", marginRight: "auto", marginBottom: "40px"}} >
                   <button onClick={() => handleSubmit()}> Ver mas </button>
                 </div> }
            </div>
        </div>
    )
};

export default Services;