import React, { createContext, useState, useEffect } from 'react';
import { accommodations, amenities, furnishedAttributes, furnishedBeds } from './data/amenitesAccommodationsData.js';

export const Context = createContext();



const ContextProvider = (props) => {

    const [buscador, setBuscador] = useState('')
    const [dataBuscador, setdataBuscador] = useState([])
    const [dataBlog, setdataBlog] = useState([])
    const [dataSearch, setdataSearch] = useState(0)
    const [loadingService, setloadingService] = useState(false)
    const [button, setbutton] = useState(false)
    const [word, setword] = useState("")
    const [loading, setloading] = useState(false)
    const [modal, setmodal] = useState(false)
    const [page, setPage] = useState({
        number: 1,
        total: 0,
        size: 20
    });
    
    const [applyFilters, setApplyFilters] = useState(false);

    const [propSelect, setPropSelect] = useState({
        tipo_prop: '',
        condicion: '',
        district: '',
        state: '',
        dateOne: '',
        dateTwo: ''
    })

    const [searchFilters, setSearchFilters] = useState(
        JSON.parse(localStorage.getItem('searchFilters')) ?
            JSON.parse(localStorage.getItem('searchFilters')) :
            {
                publishedFor: 'sell',
                visual: 'gallery',
                propertyType: '',
                district: '',
                state: '',
                currencyPrice: '',
                priceLow: 0,
                priceHigh: 0,
                currencyExpenses: '',
                expensesLow: '',
                expensesHigh: '',
                hasBedroomsNumber: '',
                toiletsNumber: '',
                parkingPlaces: '',
                edifiedAreaLow: '',
                edifiedAreaHigh: '',
                totalAreaLow: '',
                totalAreaHigh: '',
                creditSuitable: false,
                salesContract: false,
                inmediateDelivery: false,
                hasDeed: false,
                student: false,
                idealpets: false,
                security: false,
                familylife: false,
                openspace: false,
                single: false,
                familytmp: false,
                alone: false,
                romantic: false,
                work: false,
                group: false,
                event: false
            }
    );

    const [newProperty, setNewProperty] = useState(
        JSON.parse(localStorage.getItem('newProperty')) ?
            JSON.parse(localStorage.getItem('newProperty')) :
            {
                onlyValidatedUsers: false,
                sell: false,
                rent: false,
                tmpRent: false,
                sellPrice: '',
                rentPrice: '',
                tmpPrice: '',
                sellCurrency: '',
                rentCurrency: '',
                tmpCurrency: '',
                value: '',
                description: '',
                propertyType: '',
                street: '',
                number: '',
                neighborhood: '',
                district: '',
                state: '',
                postalCode: '',
                country: '',
                low: [],
                medium: [],
                high: [],
                totalArea: '',
                edifiedArea: '',
                accommodationsNumber: '',
                hasBedroomsNumber: '',
                toiletsNumber: '',
                parkingPlaces: '',
                creditSuitable: false,
                salesContract: false,
                inmediateDelivery: false,
                hasDeed: false,
                hasConstructionLevels: '',
                disposition: '',
                orientation: '',
                buildingYear: '',
                propertyDescription: '',
                propertyId: '',
                action: 'upload',
                route: ''
            }
    );


    const [dataLocation, setDataLocation] = useState(
        JSON.parse(localStorage.getItem('dataLocation')) ?
            JSON.parse(localStorage.getItem('dataLocation')) :
            {
                district: '',
                state: '',
                country: ''
            }
    );



    const [login, setLogin] = useState(false);

    useEffect(() => {
        if(!JSON.parse(localStorage.getItem('searchFilters'))) {
            accommodations.map((item) =>
            setSearchFilters(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            amenities.map((item) =>
                setSearchFilters(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            furnishedAttributes.map((item) =>
                setSearchFilters(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            furnishedBeds.map((item) =>
                setSearchFilters(prevState => { return { ...prevState, [item.attribute]: '' } })
            )
        }
        if(!JSON.parse(localStorage.getItem('newProperty'))) {
            accommodations.map((item) =>
            setNewProperty(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            amenities.map((item) =>
                setNewProperty(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            furnishedAttributes.map((item) =>
                setNewProperty(prevState => { return { ...prevState, [item.attribute]: false } })
            )
            furnishedBeds.map((item) =>
                setNewProperty(prevState => { return { ...prevState, [item.attribute]: '' } })
            )
        }       
        
    }, [])

    useEffect(() => {
        localStorage.setItem('searchFilters', JSON.stringify(searchFilters));
        localStorage.setItem('newProperty', JSON.stringify(newProperty));
        localStorage.setItem('dataLocation', JSON.stringify(dataLocation))
    })

    return (
        <div>
            <Context.Provider value={{
                searchFilters,
                setSearchFilters,
                login,
                setLogin,
                newProperty,
                setNewProperty,
                propSelect, 
                setPropSelect,
                dataLocation,
                setDataLocation,
                setBuscador,
                buscador,
                setdataBuscador,
                dataBuscador,
                modal, 
                setmodal,
                dataSearch, 
                setdataSearch,
                loading, 
                setloading,
                word, 
                setword,
                page, 
                setPage,
                dataBlog, 
                setdataBlog,
                loadingService, 
                setloadingService,
                button, 
                setbutton,
                applyFilters, 
                setApplyFilters
            }}>
                {props.children}
            </Context.Provider>
        </div>
    );
}

export default ContextProvider;

export const goApplyFiltersWeb = (setPage,setApplyFilters, applyFilters) => {
    setPage({
        number: 1,
        total: 0,
        size: 21
    })

    setApplyFilters(!applyFilters);
}
