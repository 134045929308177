import React, {
    Fragment,
    useState
} from 'react';
import { Redirect } from 'react-router-dom';
import './CreatAccount.css'
import TopAppBar from '../../commons/TopBar/TopAppBar';
import axios from 'axios';
import URL from '../../../config/urls';
import NavBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
// import CustomSelect from '../../commons/Select/Select';
// import { forGenderSelect } from '../../../data/createAccountData';
import CustomCheckbox from '../../commons/Checkbox/Checkbox';
import showPassword from '../../../assets/images/seePassword.png';
import showPasswordError from '../../../assets/images/seePasswordError.png';
import InputComponent from './components/CustomInput';
import ErrorNotification from './components/CustomNotification';
import GenderSelect from './../../commons/GenderSelect/GenderSelect';
import AVATAR from '../../../assets/images/user-avatar-default.jpg'
import Terms from '../Terms/Terms';

//let AVATAR = require('../../../assets/images/user-avatar-default')

const FormCreatAccount = ({ startCreateAccount, created, errorCreateAccount, deleteErrorAlert, loading }) => {

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    const [file, setFile] = useState('')
    const [avatar, setAvatar] = useState(AVATAR);
    // const [userAvatar, setUserAvatar] = useState({});
    const [username, setUsername] = useState('');
    const [badFileExtension, setbadFileExtension] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [dni, setDni] = useState('');
    const [gender, setGender] = useState('');
    const [termsAndConds, setTermsAndConds] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassAgain, setShowPassAgain] = useState(false);
    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false)

    const [error, setError] = useState({
        usernameError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        dniError: false,
        genderError: false,
        phoneError: false,
        passwordError: false,
        passwordAgain: false
    })

    const isValidEmailAddress = (e) => {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(e)) {
            setError({ ...error, emailError: false })
            setEmail(e)
        } else {
            setError({ ...error, emailError: true })
        }
    };

    let params =
        error.usernameError === false &&
        username.length > 1 &&
        error.firstNameError === false &&
        firstName.length > 1 &&
        error.lastNameError === false &&
        lastName.length > 1 &&
        error.emailError === false &&
        email.length > 1 &&
        error.dniError === false &&
        dni.length >= 7 &&
        error.genderError === false &&
        gender.length > 1 &&
        error.phoneError === false &&
        phone.length > 13 &&
        error.passwordError === false &&
        password.length >= 6 &&
        error.passwordAgain === false &&
        termsAndConds === true;

    const formData = new FormData();
    const handleSubmit = (e) => {
        e.preventDefault();
        let account_ = { username, firstName, lastName, email, phone, password, dni, gender };
        account_.email = account_.email.toLowerCase()
        for (let y in account_) {
            formData.append(`${y}`, account_[y]);
        }
        formData.append('file', file);
        let account = formData;

        /**if (!account.get('file')) {
            } */

        if (account) {
            startCreateAccount(account)
        }
    };

    const handleUpload = (e) => {
        let extWhiteList = ['png', 'jpg', 'gif', 'jpeg'];
        //  let formData = new FormData();
        let reader = new FileReader();
        let file = e.target.files[0];
        let array = file.name.split('.');
        let fileExt = array[array.length - 1].toLowerCase();
        if (extWhiteList.indexOf(fileExt) < 0) {
            setbadFileExtension(true)
            return;
        } else {
            setbadFileExtension(false);
            setFile(file);
            reader.onloadend = () => {
                setAvatar(reader.result);
            }
            reader.readAsDataURL(file)

        }
    };

    function handleErrorDni(name, value) {
        if (name === 'dni') {
            if (value.length > 9 || value.length < 7 || value[0] == '0') {
                setError({ ...error, dniError: true })
            } else {
                setError({ ...error, dniError: false })
                setDni(value)
            }
        }
    };

    function handleErrorEmail(name, value) {
        if (name === 'mail') {
            if (value.length < 1) {
                setError({ ...error, emailError: true })
            } else {
                isValidEmailAddress(value)
            }
        }
    };

    function handleSelectGender(value) {
        if (value.length < 1) {
            setError({ ...error, genderError: true })
        } else {
            setError({ ...error, genderError: false })
            setGender(value)
        }
    }


    function handleErrors(name, value) {
        if (name === 'username') {
            if (value.length < 1) {
                setError({ ...error, usernameError: true })
            } else {
                setError({ ...error, usernameError: false })
                setUsername(value)
            }
        } else if (name === 'firstName') {
            if (value.length < 1) {
                setError({ ...error, firstNameError: true })
            } else {
                setError({ ...error, firstNameError: false })
                setFirstName(value)
            }
        } else if (name === 'lastName') {
            if (value.length < 1) {
                setError({ ...error, lastNameError: true })
            } else {
                setError({ ...error, lastNameError: false })
                setLastname(value)
            }
        } else if (name === 'phone') {
            if (value.length < 13) {
                setError({ ...error, phoneError: true })
            } else {
                setError({ ...error, phoneError: false })
            }
        } else if (name === 'password') {
            if (value.length < 6) {
                setError({ ...error, passwordError: true })
            } else {
                setError({ ...error, passwordError: false })
                setPassword(value)
            }
        }
        else if (name === 'dni') {
            if (value.length > 9 || value.length < 7 || value[0] == '0') {
                setError({ ...error, dniError: true })
            } else {
                setError({ ...error, dniError: false })
                setDni(value)
            }
        }
    };

    function handleTermsAndConditions() {
        setTermsAndConds(!termsAndConds);
    }

    function ifPasswordMatch(name, value) {
        if (value !== password) {
            setError({ ...error, passwordAgain: true })
        } else {
            setError({ ...error, passwordAgain: false })
        }
    }

    function handleShowPassword(id) {
        if (id === 'password') {
            setShowPass(!showPass)
        } else {
            setShowPassAgain(!showPassAgain)
        }
    }

    return (
        <div className='creat-account-content'>

            {created && <Redirect to='/emailvalidate' />}

            <form onSubmit={handleSubmit} >
                {badFileExtension && <div className='classColorModalError' style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3> ¡Hubo un error al cargar la foto! </h3>
                        <CloseIcon
                            onClick={(e) => { setbadFileExtension(false); setEmail('') }}
                            className='buttonColorModalError'
                        />
                    </div>
                    <label> Formatos permitidos 'png', 'jpg', 'gif', 'jpeg' . </label>
                </div>}
                <img src={avatar} className='img-user-avatar' alt='user-avatar' />

                <input type="file" onChange={(e) => handleUpload(e)} id='user-avatar' className='input-user-avatar' />

                <label htmlFor='user-avatar' className='label-user-avatar'> CARGAR FOTO </label>

                <div className='form-create-user-content' >

                    <InputComponent
                        text='Usuario'
                        inputName='username'
                        param={error.usernameError}
                        handleChange={handleErrors}
                        handleOnBlur={handleErrors}
                        inputType='text'
                        ph='Escriba un nombre de usuario'
                        errorText='Campo obligatorio'
                        autoFocus='autofocus'
                    />

                    <InputComponent
                        text='Nombre'
                        inputName='firstName'
                        param={error.firstNameError}
                        handleChange={handleErrors}
                        inputType='text'
                        ph='Escriba su nombre'
                        errorText='Campo obligatorio'
                        handleOnBlur={handleErrors}
                    />

                    <InputComponent
                        text='Apellido'
                        inputName='lastName'
                        param={error.lastNameError}
                        handleChange={handleErrors}
                        inputType='text'
                        ph='Escriba su apellido'
                        errorText='Campo obligatorio'
                        handleOnBlur={handleErrors}
                    />

                    <InputComponent
                        text='Correo electrónico'
                        inputName='mail'
                        param={error.emailError}
                        handleChange={handleErrors}
                        inputType='email'
                        ph='Escriba su correo electrónico'
                        errorText='Mail incompleto o inválido'
                        handleOnBlur={handleErrorEmail}
                    />

                    <InputComponent
                        text='DNI'
                        inputName='dni'
                        param={error.dniError}
                        handleChange={handleErrors}
                        inputType='number'
                        ph='Escriba su DNI'
                        errorText='DNI incompleto o inválido'
                        handleOnBlur={handleErrorDni}
                    />

                    <label htmlFor='gender'> Género </label>
                    <GenderSelect handleSelectGender={handleSelectGender} />
                    {error.genderError && <ErrorNotification errorText='Campo obligatorio' />}

                    <label htmlFor='telephone'> Teléfono </label>
                    <div style={{ width: '100%', margin: 'auto' }}>
                        <PhoneInput
                            inputProps={{ autoComplete: "no" }}
                            country={'ar'}
                            onlyCountries={['ar']}
                            masks={{ ar: '+.. ..........' }}
                            value={phone}
                            containerStyle={{ width: '100%', marginTop: 20 }}
                            inputStyle={{
                                borderRadius: 20, height: 40, border: 'solid 1px #5963cc',
                                background: 'rgba(89, 99, 204, 0.05)'
                            }}
                            buttonStyle={{ borderRadius: '20px 0 0 20px', border: 'solid 1px #5963cc' }}
                            onChange={e => { setPhone(e); handleErrors('phone', phone) }}
                            id='phone'
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {error.phoneError &&
                            <ErrorNotification errorText='Número incompleto o inválido' />}
                        <p> Ingrese su número sin el 0 y sin el 15. </p>
                    </div>

                    <div>
                        <InputComponent
                            text='Contraseña'
                            inputName='password'
                            param={error.passwordError}
                            handleChange={handleErrors}
                            inputType={showPass ? 'text' : 'password'}
                            ph='Escriba su contraseña'
                            errorText='Debe tener min. 6 caracteres'
                            handleOnBlur={handleErrors}
                        />
                        <button
                            onClick={() => handleShowPassword('password')}
                            style={{
                                background: 'transparent',
                                border: 'none',
                                position: 'absolute',
                                right: '50px',
                                outline: 'none',
                                padding: '0 0 1px 0'
                            }}
                            type='button'
                        >
                            <img
                                alt='show password'
                                className={error.passwordError ? 'eyeFromShowPasswordEror' : 'eyeFromShowPassword'}
                                src={error.passwordError ? showPasswordError : showPassword}
                            />
                        </button>
                    </div>

                    <div>
                        <InputComponent
                            text='Repita su contraseña'
                            inputName='passwordAgain'
                            param={error.passwordAgain}
                            handleChange={ifPasswordMatch}
                            inputType={showPassAgain ? 'text' : 'password'}
                            ph='Escriba su contraseña'
                            errorText='No coinciden las contraseñas'
                            handleOnBlur={handleErrors}
                        />
                        <button
                            onClick={() => handleShowPassword('passwordAgain')}
                            style={{
                                background: 'transparent',
                                border: 'none',
                                position: 'absolute',
                                right: '50px',
                                outline: 'none',
                                padding: '0 0 1px 0'
                            }}
                            type='button'
                        >
                            <img
                                alt='show password'
                                className={error.passwordAgain ? 'eyeFromShowPasswordEror' : 'eyeFromShowPassword'}
                                src={error.passwordAgain ? showPasswordError : showPassword}
                            />
                        </button>
                    </div>

                </div>

                <div style={{ width: '85%', display: 'flex', margin: 'auto', paddingTop: 20 }}>
                    <CustomCheckbox
                        attribute={{
                            id: 'TC',
                            value: true,
                            name: ''
                        }}
                        param={termsAndConds}
                        handleChange={handleTermsAndConditions}
                    />
                    <label className='styleLabelTC'>
                        Acepto los <span onClick={() => setOpenTermsAndConditions(true)} className='terms-and-cons'> términos y condiciones</span>.
                    </label>
                </div>

                {openTermsAndConditions && error.dniError == false && (
                    <Fragment>
                        <div className="terms-and-conditions-modal-container">
                            <div className="terms-and-conditions-content">
                                <Terms />
                                <button className='button' onClick={() => setOpenTermsAndConditions(false)}>Entendido</button>
                            </div>
                        </div>
                    </Fragment>

 

                )}

                {errorCreateAccount &&
                    <div className='classColorModalError' style={{ marginTop: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3> ¡Hubo un error al crear una cuenta! </h3>
                            <CloseIcon
                                onClick={(e) => { deleteErrorAlert(); setEmail('') }}
                                className='buttonColorModalError'
                            />
                        </div>
                        <label> El mail ya se encuentra registrado. </label>
                    </div>
                }

                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    marginTop: 15, marginBottom: 20
                }}>
                    <button
                        //onClick={handleSubmit}
                        disabled={params ? false : true}
                        className={params ? 'button-register-enable button' : 'button-register-disabled'}
                        type='submit'>
                        Registrarse
                    </button>
                </div>

                {loading &&
                    <div>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                }
            </form>
        </div>
    )
};

const CreatAccount = ({ history }) => {

    const BASE_URL = URL + 'users'

    const [created, setCreated] = useState(false);
    const [errorCreateAccount, setErrorCreateAccount] = useState(false);
    const [loading, setLoading] = useState(false);

    const header = new Headers();

    async function startCreateAccount(account) {
        try {
            setLoading(true)

            const response = await axios.post(BASE_URL, account, { headers: { 'Content-Type': 'multipart/form-data' } });
            const token = response.data.responseData[0].token;
            header.append('token', token);
            localStorage.setItem('token', token);
            if (token !== null) {
                setCreated(true)
            }
        } catch (e) {
            setErrorCreateAccount(true);
            setLoading(false);
        }
    }

    function deleteErrorAlert(e) {
        setErrorCreateAccount(false)
    }

    function goWelcome() {
        history.push('/welcome')
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {screenSizeWidth >= 1030 ?
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <NavBarWeb history={history} />
                    <div className='background-web-site-CA'>
                        <div className='background-web-site-container-CA' style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }}>
                            <div className='background-web-site-content-CA'>
                                <label> Ya estoy registrado, quiero ingresar a mi cuenta.</label>
                                <Link style={{ textDecoration: 'none' }} to='/welcome'><button className='create-account-button'> Iniciar sesión </button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='create-account-web-container'>
                        <div className='create-account-web-content'style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
                            <h1 className='creat-account-greeting'> ¡Bienvenido! </h1>
                            <div className='creat-your-account'>
                                <h1> Crea tu cuenta </h1>
                                <hr />
                            </div>
                            <FormCreatAccount
                                history={history}
                                startCreateAccount={startCreateAccount}
                                created={created}
                                loading={loading}
                                deleteErrorAlert={deleteErrorAlert}
                                errorCreateAccount={errorCreateAccount}
                            />
                        </div>
                    </div>
                </div>
                :
                <div style={{ width: '100%', height: '100%' }}>
                    <div className='create-account-web-container'>
                        <TopAppBar />
                        <div className='create-account-web-content'>
                            <h1 className='creat-account-greeting'> ¡bienvenido! </h1>
                            <div className='creat-your-account'>
                                <h1> Crea tu cuenta </h1>
                                <hr />
                            </div>
                            <FormCreatAccount
                                history={history}
                                startCreateAccount={startCreateAccount}
                                created={created}
                                deleteErrorAlert={deleteErrorAlert}
                                errorCreateAccount={errorCreateAccount}
                                loading={loading}
                            />
                            <div className='footer-creat-account'>
                                <p> Ya tengo una cuenta, <strong onClick={goWelcome}>quiero iniciar sesión.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CreatAccount;