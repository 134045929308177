import React from 'react';
import './BlogCard.css';

const BlogCard = ({ publication, history }) => {

    

    let text = publication.principalData.postSubtitle
    let TEXT = text.substring(0, 150) + '(...)'


    function handleOnClick() {
        history.push('/post?id=' + publication.id)
    }

    return (
        <div className='blog-card-container' >
            <div className='blog-card-content'>
                <div className='blog-card-img-container'>
                    <img src={publication.principalImg[0] && publication.principalImg[0].img.linkStorage.replace(/&amp;/g, '&')} alt='' className='blog-card-img' />
                </div>
                <div className='blog-card-text'>
                    <div className='blog-card-body'> 
                        <h3 className='blog-card-title'> {publication.principalData.postTitle} </h3>
                        <label className='blog-card-date'> {publication.date} </label>
                        <label className='blog-card-development'> 
                            { text.length > 150 ? TEXT : text }
                        </label>
                    </div>
                    <div className='blog-card-button-container'>
                        <button onClick={handleOnClick} > Leer más </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BlogCard;