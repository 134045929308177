import React from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Circle,
  InfoWindow,
  Marker
} from 'react-google-maps';
import CircularProgress from '@material-ui/core/CircularProgress';


const Maps = ({ property, priceData: { publishedFor, price } }) => {

  const { sellPrice, rentPrice, tmpPrice,
    sellCurrency, rentCurrency, tmpCurrency } = price


  return (
    <div>
      {property === null ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50vh'
          }}
        >
          <CircularProgress size={70} />
        </div>
      ) : (
          <GoogleMap
            ref={React.createRef()}
            center={{
              lat: parseFloat(property.address.longitude),
              lng: parseFloat(property.address.longitude)
            }}
            defaultZoom={15}
            options={{
              streetViewControl: false,
              mapTypeControl: false
            }}
          >
            <Marker
              position={{
                lat: parseFloat(property.address.latitude),
                lng: parseFloat(property.address.longitude)
              }}
              visible={false}
              key={1}
            >
              <InfoWindow
                position={{
                  lat: parseFloat(property.address.latitude),
                  lng: parseFloat(property.address.longitude)
                }}
                draggable={false}
              >
                <div className='maps-price'>
                  {publishedFor === 'sell' ? (
                    <div>
                      {`${sellCurrency} ${sellPrice}`}
                    </div>
                  ) : publishedFor === 'rent' ? (
                    <div>
                      {`${rentCurrency} ${rentPrice}`}
                    </div>
                  ) : (
                        <div>
                          {`${tmpCurrency} ${tmpPrice}`}
                        </div>
                      )}
                  <Circle
                    ref={React.createRef()}
                    center={{
                      lat: parseFloat(property.address.latitude),
                      lng: parseFloat(property.address.longitude)
                    }}
                    radius={200}
                    options={{ fillColor: '#e32085', strokeColor: 'transparent' }}
                  ></Circle>
                </div>
              </InfoWindow>
            </Marker>
          </GoogleMap>
        )}
    </div>
  );
};

export default withScriptjs(withGoogleMap(Maps));
