import React, { useState } from 'react';
import './Complaint.css'
import { Link } from 'react-router-dom';
import Modal from '../../../../commons/Modals/ModalWarning';

const ComplaintButton = ({ uploading, history }) => {

    let token = localStorage.getItem('token');

    // eslint-disable-next-line 
    const [ openModal, setOpenModal ] = useState(false);

    let propertyIdFromUrl = window.location.href.split('=')[1].split('?')[0];


    function handleActionButton() {
        history.push('/welcome')
    }

    return (
        <>

        { !token ?

            <>
                <Modal 
                    title='¡Necesitas iniciar sesión!'
                    text='Sólo usuarios logueados pueden realizar denuncias a propiedades. Por favor, inicia sesión para continuar.'
                    acept='Iniciar sesión'
                    cancel='Cancelar'
                    image={''}
                    styleButton='complaint-button'
                    close={true}
                    openModal={openModal}
                    actionButton={handleActionButton}
                    callToAction='Denunciar'
                />
            </>

            :

            <Link to={'/reportProperty?pId=' + propertyIdFromUrl} >
                <button className='complaint-button' disabled={uploading} >
                    denunciar
                </button>
            </Link>

        }

        </>
    )
}

export default ComplaintButton;