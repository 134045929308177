import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import Modal from '../UpdateBlog/modal'
import URL from '../../../../config/urls'
import { Alert } from '@material-ui/lab';
import axios from 'axios'
// import './Tables.css'



const useStyles = makeStyles({
  root: {
    width: '550px',
    marginLeft: "50px",
    marginTop: "40px"
  },
  container: {

  },
});


const useStyle = makeStyles(theme => ({
  root: {
    width: '25%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: "fixed",
    bottom: "3%",
    left: "3%",
    zIndex: 10000, 
  },
}));


export default function StickyHeadTable({ rowsData, name }) {

  const classe = useStyle();

  const [modal, setmodal] = useState(false)
  const [dataEmail, setdataEmail] = useState("")
  const [eliminated, seteliminated] = useState(false)

  let columns

    columns = [
      { id: 'rol', label: 'Rol' },
      { id: 'email', label: 'Email' },
      { id: 'delete', label: '' }
 
    ];
  
    const token = localStorage.getItem('tokenAdmin');


    const handleDelete = async() => {

    try {

        const request = await axios.delete(URL + 'admin/dashboard/deleteAdmin/' + dataEmail , { headers: { 'token': token } })
        setmodal(false)
        seteliminated(true)
      
      
    } catch (error) {

        console.log(error)
        setmodal(false)
      }
    }
  

  const handleYes = (email) => {
    setmodal(true)
    setdataEmail(email)
  }

  const handleNot = () => {
    setmodal(false)
  }

  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div >
    
    <Modal
      title="Estas seguro que queres borrar a este administrador?"
      // text="Podes publicarlo mas adelante si queres!"
      acept="Si"
      cancel="No"
      // image={alertIcon}
      noButton={true}
      close={false}
      open={modal}
      openModal={setmodal}
      actionButton={handleDelete}
      handleCloseOut={handleNot}
      
    /> 
  
    <Paper className={classes.root}>
      <TableContainer className="table-container-admin">
        <Table aria-label="sticky table">
          <TableHead  >
            <TableRow  >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  
                >
                 <strong  className="head-table-admin-prop" > {column.label} </strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              row["delete"] = true
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                  {columns.map((column) => {
                    let value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                      {
                        typeof value == "string" ? <strong className="strong-table-admin-prop" style={{width: "400px"}} > { value == 1 ? value = "Super Admin" : value == 2 ? value = "Administrador": value == 3 ? value = "Fotografo": value == 4 ? value = "Inversor" : value} </strong>:
                        <Button style={{color: "red"}} onClick={() => handleYes(row['email']) }>
                            <DeleteIcon/>
                        </Button>
                      } 
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>

    {
        eliminated && <div className={classe.root}>

        <Alert onClose={() => seteliminated(false)} variant="filled" severity="success">
          Eliminado exitosamente.
        </Alert>
      </div>
      }
    </div>
  );
}