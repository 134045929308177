import React, { useState } from 'react';
import './ConfirmationModal.css';
import CloseIcon from '@material-ui/icons/Close';

const ConfirmationModal = ({ title, description, modalType }) => {

    let classColor;
    let buttonColor;

    const [ open, setOpen ] = useState(true);

    if(modalType === 'error') {
        classColor = 'classColorModalError'
        buttonColor = 'buttonColorModalError'
    } else if( modalType === 'success') {
        classColor = 'classColorModalSuccess'
        buttonColor = 'buttonColorModalSuccess'
    }

    return (
        <div style={{ marginBottom: 20, marginTop: 20 }}>
            { open &&
                <div className={classColor}>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <h3> {title} </h3>
                        <CloseIcon
                        className={buttonColor} 
                        onClick={ (e) => setOpen(false)}
                        />
                    </div>
                    <label> {description} </label> 
                </div>
            }
        </div>
    )
}

export default ConfirmationModal;