import React from 'react';
import './Notifications.css';
import arrowBack from '../../../assets/images/arrowBack.svg';
import userAvatar from '../../../assets/images/user-image.jpg';
import trash from '../../../assets/images/trash.svg';

const NewMessage = () => {
    return (
        <div className='new-message-container'>
            <h1> Mensaje nuevo </h1>
            <div className='new-message-date'>
                <p> 21:30hs - </p>
                <p> 12/12/2020 </p>
            </div>
            <div className='new-message-user-data'>
                <img alt='user avatar' src={userAvatar} />
                <label> María Florencia </label>
            </div>
            <div className='new-message-text'>
                <label> Ante libero montes lectus class metus justo aptent vivamus sociis dui, curae himenaeos imperdiet viverra nisl egestas proin nibh cubilia, vulputate tincidunt nisi urna fringilla et turpis purus arcu. Aliquam aliquet et dui lectus venenatis class gravida penatibus.</label>
            </div>
            <div className='new-message-buttons-container'>
                <button className='new-message-delete-button'> borrar <img alt='delete' style={{ marginLeft: 5 }} src={trash} /></button>
                <button className='new-message-send-phone'><label> Enviar teléfono </label></button>
            </div>
        </div>
    )
}

const ApprovedContact = () => {
    return (
        <div className='new-message-container'>
            <h1> Contacto aprobado </h1>
            <div className='new-message-date'>
                <p> 21:30hs - </p>
                <p> 12/12/2020 </p>
            </div>
            <div className='new-message-user-data'>
                <img alt='user avatar' src={userAvatar} />
                <div>
                    <label> Jorge de la propiedad: </label>
                    <p> Alquiler - Departamento - Hipólito Yrigoyen, Córdoba. </p>
                    <h1> +54 9 351 5963579 </h1>
                </div>
            </div>
            <div className='new-message-buttons-container' style={{ marginTop: 15 }}>
                <button className='new-message-delete-button'> Llamar </button>
                <button className='new-message-send-phone'><label> Whatsapp </label></button>
            </div>
        </div>
    )
}


const Notifications = ({ history }) => {

    function goBack() {
        history.goBack();
    }

    return (
        <div>
            <div className="topBar">
                <img src={arrowBack} alt={arrowBack} className="filterIcon" onClick={goBack} />
                <h3> Notificaciones </h3>
            </div>
            <div className='notifications-first-view'>
                <NewMessage />
            </div>
            <ApprovedContact />
            <div className='notifications-first-view'>
                <ApprovedContact />
            </div>
            <NewMessage />
        </div>
    )
}

export default Notifications; 