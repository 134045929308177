import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '../ModalData/ModalData'
import ModalDenounce from '../DenouncedModal/DenouncedModal'
import ModalUser from '../ModalUser/ModalUser'
import { Context } from '../../../../../Context'
import './Tables.css'



const useStyles = makeStyles({
  root: {
    width: '980px',
    marginTop: "110px",
    marginLeft: "230px",
    // marginRight: "-200px"
  },
  container: {

  },
});

export default function StickyHeadTable({ rowsData, name, handle }) {

  const { modal } = useContext(Context);


  let columns

  if(name == "chats"){
    columns = [
      { id: 'propertyId', label: 'ID' },
      { id: 'fullNameInterested', label: 'Nombre interesado'},
      { id: 'fullNameOwner', label: 'Nombre dueño' },
      { id: 'lastDateMessages', label: 'Fecha' }
    ];
  }

  if(name == "denounceProperties"){

    columns = [
      { id: 'propertyCode', label: 'Codigo' },
      { id: 'propertyId', label: 'ID' },
      { id: 'numberDenounce', label: 'N° denuncias'}
    ];

  }

  if(name == "denounceUsers"){

    columns = [
      { id: 'userEmail', label: 'Email' },
      { id: 'userId', label: 'ID' },
      { id: 'numberDenounce', label: 'N° denuncias'}
    ];

  }

  if(name == "conectUsers"){
     columns = [
      { id: 'userEmail', label: 'Email' },
      { id: 'dateAccess', label: 'Fecha de conexión' },
      { id: 'userId', label: 'ID'}
    ];
  }

  if(name == "newUsers"){
    columns = [
      { id: 'userEmail', label: 'Email' },
      { id: 'dateCreate', label: 'Fecha de creación' },
      { id: 'userId', label: 'ID'}
    ];
  }


  

  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [dataRow, setdataRow] = useState([])

  const [openModal, setopenModal] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DataOfModal = (data) => {

    setdataRow(data)
    setopenModal(true)

  }


  return (
    <div>
      {
        modal == true && openModal && <ModalUser datos={dataRow} funct={setopenModal} handle={handle} />
      }
      {
       name == "chats" && openModal && <Modal datos={dataRow} funct={setopenModal} handle={handle} /> 
      }
      {
        name == "denounceProperties" && openModal && <ModalDenounce datos={dataRow} funct={setopenModal} handle={handle} />
      }
      {
        name == "denounceUsers" && openModal && <ModalDenounce datos={dataRow} funct={setopenModal} handle={handle} />
      }
    <Paper className={classes.root}>
      <TableContainer className="table-container-admin">
        <Table aria-label="sticky table">
          <TableHead  >
            <TableRow  >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                >
                 <strong  className="head-table-admin" > {column.label} </strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} onClick={ name == "denounceUsers" ? () => DataOfModal(row) : name == "denounceProperties" ? () => DataOfModal(row) : name == "chats" ? () => DataOfModal(row) : modal == true ? () => DataOfModal(row)  :  () => console.log("  ")} >
                       <strong className="strong-table-admin" style={{width: "400px"}} > {column.format && typeof value === 'number' ? column.format(value) : value} </strong>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="pagination-admin"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}