import React, { useState } from 'react';
import Maps from '../../commons/Maps/Maps';
import credentials from '../../../data/credentials';
import FilterOne from '../Filter/FilterOne'
import TopAppBar from '../../commons/TopBar/TopAppBar';
import FooterContact from '../../commons/FooterContact/FooterContact';
import InternalProperty from '../InternalProperty/InternalProperty';
import TopAppBarWeb from '../../commons/TopAppBarWeb/TopAppBarWeb';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapURL = 'https://maps.googleapis.com/maps/api/js?key=' + credentials.mapKey;   
 
const CustomMap = ({ history }) => {

    const [data, setData] = useState({
        publishedFor: '',
        price: '234',
    });

    const [property, setProperty] = useState(null);

    const dataFooter = (publishedFor, price) => {
        setData(prevState => {
            return {
                ...prevState,
                publishedFor: publishedFor,
                price: price,
            }
        });
    }

    const getProperty = property => {
        setProperty(property);
    }

    const ifMobileVersion = {
        height: '80vh',
        width: '100%',
        margin: 'auto',
        padding: '0px 20px 110px 20px'
    }

    let screenSizeWidth = window.innerWidth;

    return (
        <div>
            {screenSizeWidth < 1030 ?
                <>
                    <TopAppBar />
                    <FilterOne
                        map={<Maps
                            property={property}
                            priceData={data}
                            googleMapURL={mapURL}
                            containerElement={<div style={ifMobileVersion} />}
                            mapElement={<div style={{ height: '100%', borderRadius: '10px', marginTop: '10px' }} />}
                            loadingElement={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                                <CircularProgress size={40} />
                            </div>}
                            isMarkerShow
                        />}
                        internalProperty={<InternalProperty history={history} dataFooter={dataFooter} getProperty={getProperty} />}
                    />
                    <div className='footer-contact-footer'>
                        <FooterContact  property={property} history={history} publishedFor={data.publishedFor} price={data.price} />
                    </div>
                </>
                :
                <>
                    <TopAppBarWeb history={history} />
                    <InternalProperty
                        dataFooter={dataFooter}
                        history={history}
                        map={
                            <Maps
                                property={property}
                                priceData={data}
                                googleMapURL={mapURL}
                                containerElement={<div style={{
                                    height: '330px',
                                    width: '100%',
                                    margin: '20px 0 20px 0'
                                }} />}
                                mapElement={<div style={{ height: '100%', borderRadius: '10px', marginTop: '10px' }} />}
                                loadingElement={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
                                    <CircularProgress size={40} />
                                </div>}
                                isMarkerShow
                            />
                        }
                        getProperty={getProperty}
                    />
                </>
            }
        </div>
    )
};

export default CustomMap;