import 'date-fns';
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../config/urls'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chart from '../Graficos/Grafico'
import moment from 'moment';
import Modal from '../Modal/Modal'
import ModalData from '../Servicios/Contratos/ModalData/ModalData'

import './Picker.css'
import TotalCount from '../Common/TotalCount/TotalCount'


const useStyles = makeStyles({
    root: {
      width: '980px',
      marginTop: "110px",
      marginLeft: "230px",
    },
    container: {
  
    },
  });

export default function MaterialUIPickers({componente, palabra1, palabra2}) {

  let date = new Date();
  let date2 = new Date()

  date2.setMonth(date2.getMonth()-1);

  const [selectedDate, setSelectedDate] = React.useState(date2);
  const [selectedDateTwo, setSelectedDateTwo] = React.useState(date);

  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [dataModal, setdataModal] = useState([])
  const [openModal, setopenModal] = useState(false)
  const [contratoModal, setContratoModal] = useState(false)
  const [words, setwords] = useState([])
  const [datos, setdatos] = useState({})
  

  const getDataPerMonth = () => {

    setwords([])
    setdatos({})

    const dates = []
    const repeticiones = {};

    data.forEach((e) => {
      console.log(e.orderDate)
      
      if(e.orderDate && dates.includes(e.orderDate.slice(0,5)) ){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.orderDate && !dates.includes(e.orderDate.slice(0,5)) ) {
        dates.push(e.orderDate && e.orderDate.slice(0,5))
      
      }

      repeticiones[ e.orderDate && e.orderDate.slice(0,5) ] = ( repeticiones[ e.orderDate && e.orderDate.slice(0,5) ] || 0 ) + 1;
    
    })


    setwords(dates)
    setdatos(repeticiones)
  }

  const getDataChartPerYear = () => {

    const dates = []
    const repeticiones = {};


    data.forEach((e) => {

      if(e.orderDate && dates.includes(e.orderDate.slice(3,10)) ){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.orderDate && !dates.includes(e.orderDate.slice(3,10))) {
        dates.push(e.orderDate && e.orderDate.slice(3,10))
      
      }

      repeticiones[ e.orderDate && e.orderDate.slice(3,10) ] = ( repeticiones[ e.orderDate && e.orderDate.slice(3,10) ] || 0 ) + 1;
    
    })
    setwords(dates)
    setdatos(repeticiones)

  }


  useEffect(() => {

    let fecha1 = moment(selectedDate);
    let fecha2 = moment(selectedDateTwo);

    
    if(fecha1.diff(fecha2, 'days') >= -51){
      getDataPerMonth()
    }

    if(fecha1.diff(fecha2, 'days') < -51){
      getDataChartPerYear()
    }

  },[data])


  let columns

     columns = [
      { id: 'userEmail', label: 'Email' },
      { id: 'serviceStatus', label: 'Estado' },
      { id: 'orderDate', label: 'Fecha'}
    ];
  

  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const token = localStorage.getItem('tokenAdmin');

  const ReverseAction = () => {
    setloading(true)
   
    setdata(data.reverse())
    setTimeout(() => {
      setloading(false)
    }, 1000)

  }

  useEffect(() => {

    handleSend()

  },[openModal, selectedDate, selectedDateTwo, contratoModal])


  const DataOfModal = (data) => {

    if(palabra1 == "CONTRATOS" || palabra1 == "COMERCIALIZACION"){

      setdataModal(data)
      setContratoModal(true)

    } else {

      setdataModal(data)
      setopenModal(true)

    }

  }

  const handleSend = () => {
  
    let params = {
      selectedDate,
      selectedDateTwo
    }

    if(palabra1 == "CONTRATOS" || palabra1 == "COMERCIALIZACION"){


      try {
      setloading(true)

      const request = axios.post(URL + `admin/dashboard/${componente}`, params, { headers: { 'token': token } })
      .then((res) => {
        setloading(false)
        setdata(res.data.responseData[0])
        console.log(res.data.responseData[0])

      })
      .catch((err) => {
        console.log('Hubo un error')
        console.log(err)

      })
      
    } catch (error) {
      console.log('Hubo un error')
    }


    } else {


      try {
        setloading(true)

        const request = axios.get(URL + `admin/dashboard/${componente}`,  { headers: { 'token': token } })
        .then((res) => {
          setloading(false)
          setdata(res.data.responseData[0])
          console.log(res.data.responseData[0])

        })
        .catch((err) => {
          console.log('Hubo un error')
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }
    }


  }


  return (
      <div>

{
  contratoModal && <ModalData  datos={dataModal} func={setContratoModal}  />
}        

{

openModal && <Modal datos={dataModal} func={setopenModal} /> 

}

        <div style={{marginLeft: "980px", marginRight: "50px", marginTop: "-70px",}} >
          <TotalCount palabra1={palabra1} palabra2={palabra2} total={data.length} />
        </div>

      <div className="div-datepicker" style={{ marginTop: "-120px"}} >

       <DatePicker
           showPopperArrow={false}
           selected={selectedDate}
           onChange={(selectedDate) => setSelectedDate(selectedDate)}
           className='datePicker-1'
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           fixedHeight
       />        

         <ArrowForwardIosIcon style={{marginTop: "14px", fontSize: "small", marginLeft: "-50px", color: "gray"}} />
         <DatePicker
           showPopperArrow={false}
           selected={selectedDateTwo}
           onChange={(selectedDateTwo) => setSelectedDateTwo(selectedDateTwo)}
           minDate={selectedDate}
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           className='datePicker-2'
       />   
  
    </div>
    <Chart words={words} datos={datos} />


   {
     !loading && <Paper className={classes.root}>
     <TableContainer className="table-container-admin">
       <Table aria-label="sticky table">
         <TableHead  >
           <TableRow  >
 
               <TableCell

                 
               >
                <strong  className="head-table-admin" > { "Usuarios" } </strong>
               </TableCell>
               <TableCell

                 
               >
                <strong  className="head-table-admin" > { "Estado" } </strong>
               </TableCell>
               <TableCell
               >
                <button onClick={ReverseAction} style={{border: "none", backgroundColor: "transparent", textDecoration: "none", outline: "none", cursor: "pointer"}} > <strong  className="head-table-admin" > { "Fecha" } </strong> </button> 
               </TableCell>

           </TableRow>
         </TableHead>
         <TableBody >
           {!loading && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
             return (
               <TableRow hover role="checkbox" tabIndex={-1} key={row.code} >
                 {columns.map((column) => {
                   const value = row[column.id];
                   return (
                     <TableCell key={column.id} align={column.align} onClick={() => (DataOfModal(row) )} >
                       {
                         value == "Nuevo" ? <strong className="strong-table-admin" style={{width: "400px", color: "#F5556B", fontWeight: "bold"}} > {value} </strong> :  value == "Proceso" ? <strong className="strong-table-admin" style={{width: "400px", color: "#6B9BFF", fontWeight: "bold"}} > {value} </strong> :  value == "Habilitado" ? <strong className="strong-table-admin" style={{width: "400px", color: "#79E86D", fontWeight: "bold"}} > {value} </strong> : value == "Pendiente" ? <strong className="strong-table-admin" style={{width: "400px", color: "#6B9BFF", fontWeight: "bold"}} > {value} </strong> : value == "Finalizado" ? <strong className="strong-table-admin" style={{width: "400px", color: "#79E86D", fontWeight: "bold"}} > {value} </strong> : <strong className="strong-table-admin" style={{width: "400px"}} > {value} </strong> 
                       }
                     
                     </TableCell>
                   );
                 })}
               </TableRow>
             );
           })}
         </TableBody>
       </Table>
     </TableContainer>
     <TablePagination
       rowsPerPageOptions={[5, 10, 25]}
       component="div"
       count={data.length}
       rowsPerPage={rowsPerPage}
       page={page}
       onChangePage={handleChangePage}
       onChangeRowsPerPage={handleChangeRowsPerPage}
     />
   </Paper>
   } 


    {
        loading && <div className='messages-loading-screen'>
        <CircularProgress />
        </div>
    }
    {/* {

      openModal && <Modal datos={dataModal} /> 

    } */}
    </div>
  );
}