import React from 'react';
import './Cod.css'

const Cod = ({propertyCode}) => {


    return ( 
        <>
            { propertyCode.map((property, index) =>
                <label key={index} className='item-cod'> {property} </label>
            )}
        </>
    )
}

export default Cod;