import React from 'react'
import Select from '../../Common/Select/Select'
import DatePicker from '../../PickerTable/PickerTable'
import RouterColumn from '../../RouterColumn/RouterColumn'
import Modal from '../../Modal/Modal'

export default function Carteleria(){

    let word = [{ nombre: "Fotografos", link: "/admin/fotografo"}, { nombre: "Contratos", link: "/admin/contratos"},  { nombre: "Asesor C.", link:"/admin/comercializacion" }]

    
  return (
    <div>
           <RouterColumn  />
   
           <div>
             <Select Word={word} palabra={"Carteleria"}  />
           </div>
           <div>
             <DatePicker componente={"servicesRequest/posters"} palabra1={"CARTELERIA"} palabra2={"SOLICITADA"} style={{marginLeft: "800px", marginRight: "50px"}} />
           </div>
           {/* <Modal /> */}
       </div>
  )

}