import React, { Component, useRef, useState, useCallback, useContext  } from 'react';
import { Context } from "../../../Context";
import "./NewProperty.css";
import TopNewProperty from "./TopNewProperty";
import TopStepper from "./TopStepper";
import TopAppBar from "../../commons/TopBar/TopAppBar";
//import Services from '../../commons/Services/Services';
//import icon from '../../../assets/images/money-service.svg';
import axios from "axios";
import URL from "../../../config/urls";
import toolTip from "../../../assets/images/tool-tip.svg";
import exclamation from "../../../assets/images/exclamation.svg";
import NewPropertyTip from "./NewPropertyTip";
import NavBarWeb from "../../commons/TopAppBarWeb/TopAppBarWeb";
import Location from "../../commons/Location/Location";
import PropertyTypesSelector from "../../commons/SelectorsData/PropertyTypesSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
//import NumberFormat from 'react-number-format';
import ModalWarning from "../../commons/Modals/ModalWarning";
import tooltipValidated from "../../../assets/images/verified-user.svg";
import WrappedMap from '../../commons/Maps/WrappedMap'
import Geocode from "react-geocode";






//import { Input } from '@material-ui/core';

const Price = ({
  transactionPrice,
  transactionCurrency,
  newProperty,
  handleChange,
  checkError,
}) => (
  <div>
    <div className="medium-large-pc">
      <p>
        Tipo de moneda <span className="asterisk">*</span>
      </p>
      <select
        id={transactionCurrency}
        name={transactionCurrency}
        value={
          newProperty[transactionCurrency]
            ? newProperty[transactionCurrency]
            : ""
        }
        onChange={handleChange}
      >
        <option value="">Tipo de moneda</option>
        <option value="ARS">Pesos</option>
        <option value="USD">Dólares</option>
      </select>
    </div>
    <div className="medium-large-pc">
      <p>
        Precio <span className="asterisk">*</span>
      </p>
      {/* <NumberFormat thousandSeparator={true} id={transactionPrice} name={transactionPrice} placeholder="123,456" onBlur={checkError} value={newProperty[transactionPrice] ? newProperty[transactionPrice] : ''} onChange={handleChange} /> */}
      <input
        type="number"
        id={transactionPrice}
        name={transactionPrice}
        placeholder="123456"
        onBlur={checkError}
        value={
          newProperty[transactionPrice] ? newProperty[transactionPrice] : ""
        }
        onChange={handleChange}
      />
    </div>
  </div>
);



  

  

const NewProperty1 = ({ history }) => {
  const { newProperty, setNewProperty } = useContext(Context);
  const [loaderNext, setLoaderNext] = useState(false);

  const [modal, setModal] = useState({});
  const APIKEY = "AIzaSyDhMnK1gNw6jCUmcDll8YN7gWwV-BJdwHI"
  
  Geocode.setApiKey(APIKEY);
   
  Geocode.setLanguage("es"); 
  Geocode.setRegion("ar");
  Geocode.enableDebug();
  
  
   const [position, setPosition] = useState();
  
   const [adressPos, setadressPos] = useState({ lat: -31.41683179999999, lng: -64.1836021 });
  
   const [addresses, setAddresses] = useState([]); 

   const [addressPost, postAddress] = useState([]);

   const [estado, setestado] = useState(false)

    let addressInput = React.createRef();
  
   
  
  // aqui recibis la position del market 
    const updatePositionHandler = (pos)=>{
      setPosition(pos);

    }
  
    const getLocations = ()=>{
      setestado(true)
      document.getElementById("mapa").scrollIntoView();
      setAddresses([]);
      let formattedAddress = [];
      
      formattedAddress.push(newProperty.country,newProperty.state, newProperty.district, newProperty.neighborhood, newProperty.street, newProperty.number);
      formattedAddress.join("");
      //addressInput.current.value = formattedAddress
      Geocode.fromAddress(formattedAddress).then(
    response => {
      //const { lat, lng } = response.results[0].geometry.location;
      if (response.results.length == 1)
      {
        //Si retorna una sola direccion directamente coloca ese en el centro del mapa
        setadressPos(response.results[0].geometry.location);
      } 
      else
        {
        //Si retorna una sola direccion directamente coloca ese en el centro del mapa
          setAddresses(response.results)
        }
    },
    error => {
      console.error(error);
    }
  );
    }

    const locationsMaps = () => {
      setestado(true)
      document.getElementById("mapa").scrollIntoView();
      postAddress([]);
      let coordMaps = JSON.parse(JSON.stringify(position))
      



       Geocode.fromLatLng(coordMaps.lat, coordMaps.lng).then(
        response => {
          const address = response.results[0].formatted_address;
          
          // let addressData = response.results[0].address_components

          /* newProperty.country = addressData[5].long_name || ' '
          newProperty.state = addressData[4].long_name || ' '
          newProperty.district = addressData[3].long_name || ' '
          newProperty.neighborhood = addressData[2].long_name || ' ' */
          // newProperty.street = addressData[1].long_name || ' '
          // newProperty.number = addressData[0].long_name || ' '
        },
        error => {
          console.error(error);
        }
      ); 

      
    }
  
    const addressSelected = (item) => {
  
      setadressPos(item.geometry.location);
  
  }


  const handleChange = (e, max) => {
    const { name, value, type, checked } = e.target;


    hideError(name);

    if (type === "checkbox") {
      if (name === "tmpRent") {
        setNewProperty({
          ...newProperty,
          [name]: checked,
          sell: false,
          rent: false,
        });
      } else if (name === "sell" || name === "rent") {
        setNewProperty({ ...newProperty, [name]: checked, tmpRent: false });
      } else {
        setNewProperty({ ...newProperty, [name]: checked });
      }
    } else if (type === "number") {
      if (max) {
        if (max >= value.length) {
          setNewProperty({ ...newProperty, [name]: Math.abs(parseInt(value)) });
        }
      } else {
        setNewProperty({ ...newProperty, [name]: Math.abs(parseInt(value)) });
      }
    } else {
      if (type === "select-one") {
        setNewProperty({ ...newProperty, [name]: value });
        if (value === "") {
          checkError(e);
        }
      } else {
        setNewProperty({ ...newProperty, [name]: value });
      }
    }
  };

  const hideError = (name) => {
    (name === "sell" || name === "rent" || name === "tmpRent") &&
      (name = "transaction");

    let element = document.getElementById(name);
    element && element.classList.remove("error");
    let elementError = document.getElementById("error-" + name);
    elementError && elementError.remove();

    if (name === "transaction") {
      let prices = [
        "sellPrice",
        "rentPrice",
        "tmpPrice",
        "sellCurrency",
        "rentCurrency",
        "tmpCurrency",
      ];
      // eslint-disable-next-line
      prices.map((price) => {
        let element = document.getElementById(price);
        element && element.classList.remove("error");
        let elementError = document.getElementById("error-" + price);
        elementError && elementError.remove();
      });
    }
  };

  const showError = (name) => {
    let element;

    element = document.getElementById(name);

    if (!element.classList.contains("error")) {
      element.classList.add("error");
      let errorId = "error-" + name;
      if (name === "transaction") {
        element.insertAdjacentHTML(
          "afterend",
          `<p id=${errorId} class='error'><img src=${exclamation} class="icon-error" alt='Error' /> Falta seleccionar un checkbox</p>`
        );
      } else if (
        name === "sellCurrency" ||
        name === "rentCurrency" ||
        name === "tmpCurrency" ||
        name === "propertyType" ||
        name === "district" ||
        name === "state" ||
        name === "country"
      ) {
        element.insertAdjacentHTML(
          "afterend",
          `<p id=${errorId} class='error'><img src=${exclamation} class="icon-error" alt='Error' /> Falta seleccionar un elemento</p>`
        );
      } else {
        element.insertAdjacentHTML(
          "afterend",
          `<p id=${errorId} class='error'><img src=${exclamation} class="icon-error" alt='Error' /> Falta rellenar el campo</p>`
        );
      }
    }
  };

  const checkPostalError = (e) => {
    const { name, value } = e.target;

    if (value.length < 4) {
      showError(name);
    }
  };

  const checkError = (e) => {
    const { name, value } = e.target;

    if (value.length < 1) {
      showError(name);
    }
  };


  const checkErrorNext = () => {
    let scroll = true;

    if (!newProperty.sell && !newProperty.rent && !newProperty.tmpRent) {
      !newProperty.sellPrice && showError("sellPrice");
      !newProperty.sellPrice && showError("sellCurrency");
      showError("transaction");
      if (scroll) {
        document.getElementById("fields").scrollIntoView();
        scroll = false;
      }
    }

    let prices = [
      { transaction: "sell", attribute: "sellPrice" },
      { transaction: "rent", attribute: "rentPrice" },
      { transaction: "tmpRent", attribute: "tmpPrice" },
      { transaction: "sell", attribute: "sellCurrency" },
      { transaction: "rent", attribute: "rentCurrency" },
      { transaction: "tmpRent", attribute: "tmpCurrency" },
    ];
    // eslint-disable-next-line
    prices.map((price) => {
      let valueTransaction = newProperty[price.transaction];
      let valueAttribute = newProperty[price.attribute];
      if (valueTransaction && !valueAttribute) {
        showError(price.attribute);
        if (scroll) {
          document.getElementById(price.attribute).focus();
          scroll = false;
        }
      }
    });

    let attributes = [
      "propertyType",
      "street",
      "number",
      "district",
      "state",
      "postalCode",
      "country",
    ];
    // eslint-disable-next-line
    attributes.map((attribute) => {
      let valueAttribute = newProperty[attribute];
      if (typeof valueAttribute === "number") {
        if (!valueAttribute) {
          showError(attribute);
          if (scroll) {
            document.getElementById(attribute).focus();
            scroll = false;
          }
        }
      } else if(attribute === "postalCode"){
        if(valueAttribute.length < 4){
          showError(attribute);
          document.getElementById(attribute).focus();
          scroll = false;
        }
      } else {
        if (!valueAttribute ) {
          showError(attribute);
          if (scroll) {
            document.getElementById(attribute).focus();
            scroll = false;
          }
        }
      }
    });

    scroll && goNext();
  };

  const goNext = () => {

    let coordMaps = JSON.parse(JSON.stringify(position))
      


    let params = {
      mainData: {
        type: newProperty.propertyType,
        data: {
          onlyValidatedUsers: newProperty.onlyValidatedUsers,
          propertyType: newProperty.propertyType,
          servicesHired: {},
          servicesRequested: {},
          publishedFor: {
            sell: newProperty.sell,
            rent: newProperty.rent,
            tmpRent: newProperty.tmpRent,
          },
          valoration: {
            comments:"",
            stars: ""
          },
          price: {
            sellPrice: newProperty.sell && newProperty.sellPrice,
            rentPrice: newProperty.rent && newProperty.rentPrice,
            tmpPrice: newProperty.tmpRent && newProperty.tmpPrice,
            sellCurrency: newProperty.sell && newProperty.sellCurrency,
            rentCurrency: newProperty.rent && newProperty.rentCurrency,
            tmpCurrency: newProperty.tmpRent && newProperty.tmpCurrency,
          },
          expenses: {
            value: newProperty.value,
            currency: "ARS",
            description: newProperty.description,
          },
          address: {
            street: newProperty.street,
            number: newProperty.number,
            neighborhood: newProperty.neighborhood,
            district: newProperty.district,
            state: newProperty.state,
            postalCode: newProperty.postalCode,
            country: newProperty.country,
            latitude: coordMaps.lat,
            longitude: coordMaps.lng
          },
          amenities: {},
          accommodations: {},
        },
      },
    };


    let parametros = {
      mainData: {
        type: newProperty.propertyType,
        data: {
          onlyValidatedUsers: newProperty.onlyValidatedUsers,
          propertyType: newProperty.propertyType,

          publishedFor: {
            sell: newProperty.sell,
            rent: newProperty.rent,
            tmpRent: newProperty.tmpRent,
          },
          valoration: {
            comments:"",
            stars: ""
          },
          price: {
            sellPrice: newProperty.sell && newProperty.sellPrice,
            rentPrice: newProperty.rent && newProperty.rentPrice,
            tmpPrice: newProperty.tmpRent && newProperty.tmpPrice,
            sellCurrency: newProperty.sell && newProperty.sellCurrency,
            rentCurrency: newProperty.rent && newProperty.rentCurrency,
            tmpCurrency: newProperty.tmpRent && newProperty.tmpCurrency,
          },
          expenses: {
            value: newProperty.value,
            currency: "ARS",
            description: newProperty.description,
          },
          address: {
            street: newProperty.street,
            number: newProperty.number,
            neighborhood: newProperty.neighborhood,
            district: newProperty.district,
            state: newProperty.state,
            postalCode: newProperty.postalCode,
            country: newProperty.country,
            latitude: coordMaps.lat,
            longitude: coordMaps.lng
          },
          amenities: {},
          accommodations: {},
        },
      },
    };
    const token = localStorage.getItem("token");
    const headers = { headers: { token: token } };
    let BASE_URL;


    if (newProperty.action === "upload" && !newProperty.propertyId) {
      BASE_URL = URL + "properties";
      setLoaderNext(true);
      axios
        .post(BASE_URL, params, headers)
        .then((res) => {
          setLoaderNext(false);
          const data = res.data;
          setNewProperty({ ...newProperty, propertyId: data.propertyId });
          history.push("/newproperty/2");
        })
        .catch((err) => {
          setLoaderNext(false);
          err.response.data.errorCode === 2 &&
            (document.getElementById("error-response").innerHTML =
              `<img src=${exclamation} class="icon-error" alt="Error" /> ` +
              err.response.data.responseData[0]);
        });
    } else {
      BASE_URL = URL + "properties/" + newProperty.propertyId;
      setLoaderNext(true);
      axios
        .patch(BASE_URL, parametros, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          setLoaderNext(false);
          history.push("/newproperty/2");
        });
    }
  };

  const goPrev = () => {
    history.push("/myproperties");
  };

  const handleCloseOut = () => {
    setModal({ ...modal, open: false });
  };




  const showTooltipValidated = () => {
    setModal({
      ...modal,
      open: true,
      title: "¿Cuál es la diferencia entre un usuario no validado y validado?",
      text: `Un usuario validado realizó el proceso de verificar e-mail dentro de la plataforma. 
            Esto tiene como objetivo brindar seguridad tanto al propietario como al visitante. `,
      acept: "Entendido",
      cancel: "false",
      image: tooltipValidated,
      action: "handleClose",
    });
  };

  const tiptitle = "Carga de precio y valores.";
  const tiptext =
    "Para el valor de tu propiedad podés usar el tasador virtual (click aquí) que te ayudará a tener una referencia del precio de tu inmueble. Ademas te sugerimos que mientras mas detalles brindes sobre las expensas y demás importes, te ahorrarás tiempo y filtrarás mejor a tus visitantes.";
  const tiplink = "/appraiser";

  let screenSizeWidth = window.innerWidth;

  return (
    <div>
      <ModalWarning
        style={{ maxHeight: "500px" }}
        title={modal.title}
        text={modal.text}
        acept={modal.acept}
        cancel={modal.cancel}
        image={modal.image}
        noButton={true}
        close={true}
        openModal={modal.open}
        actionButton={modal.action}
        handleCloseOut={handleCloseOut}
      />
      {screenSizeWidth > 1030 ? <NavBarWeb history={history} /> : <TopAppBar />}
      <div className="new-property">
        <TopNewProperty
          step={1}
          action={newProperty.action}
          screenSizeWidth={screenSizeWidth}
        />
        <div className="properties-container-pc" style={{ marginTop: screenSizeWidth > 1030 ? "55px" : "0px" }} >
          <TopStepper step={1} />
          <div className="properties-inputs">
            <div className="subtitle-property">
              <h3>¡Es un gusto tenerte con nosotros!</h3>
              {newProperty.action === "upload" ? (
                <p>Cargá tu propiedad en sólo 5 pasos.</p>
              ) : (
                <p>Editá tu propiedad en sólo 5 pasos.</p>
              )}
              <p className="">
                Todos los campos con <span className="asterisk">*</span> son
                obligatorios
              </p>
            </div>
            <div className="fields" id="fields">
              <h3>
                Permiso para contactarse <span className="asterisk">*</span>
              </h3>
              <div className="container-checks">
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={newProperty.onlyValidatedUsers}
                    id="onlyValidatedUsers"
                    name="onlyValidatedUsers"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">
                    Acepto que me puedan contactar usuarios no validados y
                    validados.
                  </p>
                </label>
                <div className="validated">
                  <img src={toolTip} alt="Usuario validado" />
                  <p className="label-validated" onClick={showTooltipValidated}>
                    ¿Cúal es la diferencia de un usuario no validado y validado?
                  </p>
                </div>
              </div>
              <h3>
                Tipo de operación <span className="asterisk">*</span>
              </h3>
              <div className="container-checks third-pc" id="transaction">
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={newProperty.sell}
                    name="sell"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">Venta</p>
                </label>
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={newProperty.rent}
                    name="rent"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">Alquiler</p>
                </label>
                <label className="checks">
                  <input
                    type="checkbox"
                    checked={newProperty.tmpRent}
                    name="tmpRent"
                    onChange={handleChange}
                  />
                  <span className="checkmark"></span>
                  <p className="label-check">Alquiler temporario</p>
                </label>
              </div>
              <h3>
                Tipo de propiedad <span className="asterisk">*</span>
              </h3>
              <div className="medium-large-pc">
                <PropertyTypesSelector
                  stateContext={newProperty}
                  handleChange={handleChange}
                />
              </div>
              {newProperty.rent && newProperty.sell ? (
                <div>
                  <h3>
                    Precio de tu propiedad para venta{" "}
                    <span className="asterisk">*</span>
                  </h3>
                  <Price
                    transactionPrice="sellPrice"
                    transactionCurrency="sellCurrency"
                    newProperty={newProperty}
                    handleChange={handleChange}
                    checkError={checkError}
                  />
                  <h3>
                    Precio de tu propiedad para alquiler{" "}
                    <span className="asterisk">*</span>
                  </h3>
                  <Price
                    transactionPrice="rentPrice"
                    transactionCurrency="rentCurrency"
                    newProperty={newProperty}
                    handleChange={handleChange}
                    checkError={checkError}
                  />
                </div>
              ) : newProperty.rent ? (
                <div>
                  <h3>
                    Precio de tu propiedad <span className="asterisk">*</span>
                  </h3>
                  <Price
                    transactionPrice="rentPrice"
                    transactionCurrency="rentCurrency"
                    newProperty={newProperty}
                    handleChange={handleChange}
                    checkError={checkError}
                  />
                </div>
              ) : newProperty.tmpRent ? (
                <div>
                  <h3>
                    Precio de tu propiedad <span className="asterisk">*</span>
                  </h3>
                  <Price
                    transactionPrice="tmpPrice"
                    transactionCurrency="tmpCurrency"
                    newProperty={newProperty}
                    handleChange={handleChange}
                    checkError={checkError}
                  />
                </div>
              ) : (
                <div>
                  <h3>
                    Precio de tu propiedad <span className="asterisk">*</span>
                  </h3>
                  <Price
                    transactionPrice="sellPrice"
                    transactionCurrency="sellCurrency"
                    newProperty={newProperty}
                    handleChange={handleChange}
                    checkError={checkError}
                  />
                </div>
              )}
              {/* <Services icon={icon} title={title} text={text} /> */}
              {(newProperty.propertyType === "" ||
                newProperty.propertyType === "departamento" ||
                newProperty.propertyType === "casaCountry" ||
                newProperty.propertyType === "oficina" ||
                newProperty.propertyType === "condoHousing" ||
                newProperty.propertyType === "duplexPh" ||
                newProperty.propertyType === "cochera") && (
                <div>
                  <div className="medium-large-pc">
                    <p>Expensas</p>
                    {/* <NumberFormat thousandSeparator={true} id="expenses" name="value" placeholder="123,456" value={newProperty.value ? newProperty.value : ''} onChange={handleChange} /> */}
                    <input
                      type="number"
                      id="expenses"
                      name="value"
                      placeholder="123456"
                      value={newProperty.value ? newProperty.value : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <p>¿Que incluyen las expensas?</p>
                  <textarea
                    placeholder="Breve descripción"
                    id="description"
                    name="description"
                    maxLength="100"
                    value={
                      newProperty.description ? newProperty.description : ""
                    }
                    onChange={handleChange}
                  />
                  <p className="final-text-smooth">
                    {100 - newProperty.description.length} caracteres restantes
                  </p>
                </div>
              )}
              <h3>
                Datos de tu dirección <span className="asterisk">*</span>
              </h3>
              <Location
                stateContext={newProperty}
                setStateContext={setNewProperty}
                upload={true}
                handleChangeFather={handleChange}
              />
              <div className="medium-large-pc">
                <p>Barrio</p>
                <input
                  type="text"
                  id="neighborhood"
                  name="neighborhood"
                  placeholder="Barrio"
                  value={
                    newProperty.neighborhood ? newProperty.neighborhood : ""
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="medium-large-pc">
                <p>
                  Nombre de la calle <span className="asterisk">*</span>
                </p>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Calle"
                  onBlur={checkError}
                  value={newProperty.street ? newProperty.street : ""}
                  onChange={handleChange}
                />
              </div>
              <div className="medium-large-pc">
                <p>
                  Número de la calle <span className="asterisk">*</span>
                </p>
                <input
                  type="number"
                  maxLength="2"
                  id="number"
                  name="number"
                  placeholder="6200"
                  onBlur={checkError}
                  value={newProperty.number ? newProperty.number : ""}
                  onChange={(e) => handleChange(e, 6)}
                />
              </div>
              <div className="medium-large-pc">
                <p>
                  Código postal <span className="asterisk">*</span>
                </p>
                <input
                  type="text"
                  maxLength="4"
                  id="postalCode"
                  name="postalCode"
                  placeholder="1675"
                  onBlur={checkPostalError}
                  value={newProperty.postalCode ? newProperty.postalCode : ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <NewPropertyTip title={tiptitle} text={tiptext} link={tiplink} />
        </div>
        <div className="buttons">
          {loaderNext ? (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "auto",
                paddingBottom: "10px",
              }}
            >
              <CircularProgress size={30} />
            </div>
          ) : (
            <>

              <button style={{cursor: "pointer", width: "300px"}} id="mapa" className = "button-map" onClick={getLocations}>Comprobar dirección en el mapa</button>
              {/* <button id="mapa" className = "button-map" onClick={locationsMaps}>USAR UBICACION DEL MAPA</button> */}
              <div>
              <br/>
              <div className="container-checks" >
              <p><strong style={{color: "#FF0080"}} >En caso de no coincidir la dirección con la ubicación en el mapa por favor coloque el puntero donde usted desee</strong></p>  
              <label className="checks">
                  <input
                    type="checkbox"
                    // checked={locationsMaps}
                    // name="tmpRent"
                    // onChange={handleChange}}
                    onClick={ () => locationsMaps()}
                  />
                  <span className="checkmark"></span>
                  <p style={{paddingTop: "10px"}} className="label-check"><strong>USAR UBICACION DEL MAPA</strong></p>
                </label>
                </div>  

              </div>
            </>
          )}
        </div>
        <div>
    <ul>
    {
      addresses.length > 0 &&  <p><strong  >Opciones sugeridas: </strong></p> 
    }
    {addresses.map(function(item, i){
      
      return (
        <div>     
      <button id="mapa" className = "button-map" onClick={getLocations}><li key={i} style={{listStyle:"none"}}  onClick={()=> addressSelected(item)}>{item.formatted_address}</li> </button>
      </div>
      )
    })}
   </ul>

   <div style={{height: "450px", width: "90%", marginLeft: "auto", marginRight: "auto"}} >
  <WrappedMap
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${APIKEY}`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `100%` }} />}
    mapElement={<div style={{ height: `100%` }} />} 
    updatePosition={updatePositionHandler}
    defaultLocation = {adressPos}
  />
  </div>
  {/* <p>Arrastre el mapa hasta posicionarse sobre su ubicacion</p> */}
  {/* <p style={{ width: "90%", marginLeft: "auto", marginRight: "auto"}} ><strong>Verificá la ubicación ya que es posible que no coincida la dirección con la geolocalización</strong></p> */}

  <div className="buttons">
          {loaderNext ? (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "auto",
                paddingBottom: "10px",
              }}
            >
              <CircularProgress size={30} />
            </div>
          ) : (
            <div style={{paddingTop: "20px"}} >
              <button className="prev" type="button" onClick={goPrev}>
                atrás
              </button>
             
           
              <button className="next" type="submit" onClick={estado ? checkErrorNext: null}>
                siguiente
              </button>
              <div>

              </div>
            </div>
          )}
        </div>
  </div>
      </div>
    </div>
  );
};

export default NewProperty1;