import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Form from './Form.css'
import URL from '../../../../config/urls'
import Modal from './Modal'
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
    root: {
      width: '25%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      position: "fixed",
      bottom: "3%",
      left: "3%",
      zIndex: 10000, 
    },
  }));

export default function Formulario() {

    const classes = useStyles();

    const [username, setusername] = useState("")
    const [email, setemail] = useState("")
    const [pass, setpass] = useState("")
    const [selected, setselected] = useState("")
    const [open, setopen] = useState(false)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState("")
    const [error, seterror] = useState(false)
    const [emailError, setemailError] = useState(false)


    const token = localStorage.getItem('tokenAdmin');

    function validateEmail(word) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(word).toLowerCase());
    }
  
    const handleSend = (req, res) => {
        
        if(username == "" || pass == "" || selected == "" || email == ""){
            seterror(true)
            return console.log("Campos necesarios")
        }
        if(!validateEmail(email)){
            setemailError(true)
        }

        setopen(false)

        try {
            setloading(true)

            let params = {
                username,
                password:pass,
                email,
                rol: selected
            }
            
            const request = axios.post(URL + 'admin/dashboard/createAdmin', params, { headers: { 'token': token } })
            .then((res) => {
                setdata('Creado exitosamente')
                setopen(true)
                setloading(false)
            })
            .catch((err) => {
                setdata('Hubo un error')
                setopen(true)
                setloading(false)
                console.log(err)
            })

        } catch (error) {
            
            console.log(error)

        }

    }


    return (
        <div style={{width: "400px"}}>
        <h3>Username</h3>
        <input type="text" className="data-input" placeholder="Username" onChange={(username) => setusername(username.target.value)} />
        <h3>Email</h3>
        <input type="email" className="data-input" placeholder="Email" onChange={(email) => setemail(email.target.value)} />
        <h3>Password</h3>
        <input type="text" className="data-input" placeholder="Password" onChange={(pass) => setpass(pass.target.value) } />
        <h3>Rol</h3>
        <select className="data-select-admin" onChange={(e) => setselected(e.target.value)} >
            <option>Seleccione una opción</option>
            <option value="1" >Super administrador</option>
            <option value="2" >Administrador</option>
            <option value="3" >Fotografo</option>
            <option value="4" >Inversor</option>
        </select>

        {
            loading ? <CircularProgress style={{ marginLeft: "220px" }} /> : <button className="buttonSearch" style={{width: "400px"}} onClick={handleSend} > CREAR </button>
        }
        
        {
         <Modal
         title={data}
         acept='Ok'  
         noButton={true}
         close={true}
         openModal={open}
         handleCloseOut={ setopen }
         
        />
        }
        {
        error && <div className={classes.root}>

        <Alert onClose={() => seterror(false)} variant="filled" severity="error">
          Todos los campos son obligatorios
        </Alert>
      </div>
      }
    {
        emailError && <div className={classes.root}>

        <Alert onClose={() => setemailError(false)} variant="filled" severity="error">
         Formato de email incorrecto
        </Alert>
      </div>
      }

        </div>
    )

}

{/* <ModalWarning
title='¿Está seguro de denunciar esta propiedad?'
acept='Estoy seguro'
cancel='Cancelar'
image={alertforbidden}
noButton={true}
close={true}
openModal={openFirstModal}
actionButton={sendReport}
/> */}