import React, {useState} from 'react';
import '../components/Services.css';
import ModalWarning from '../../../commons/Modals/ModalWarning';
import alertforbidden from '../../../../assets/images/forbidden-property.svg';
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';


const ServiceBigCard = ({ service, handleOnClick, button, properties, loading }) => {
    
    
    let history = useHistory();

    const [modal, setModal] = useState({});


    /* const handleCloseOut = () => {
        setModal({ ...modal, open: false });
    } */

    const token = localStorage.getItem('token')



    const validateProperties = (e) => {

        if(properties == undefined){
            console.log('Entra')
            console.log(service.path);
           return history.push(`/${service.path}`)
        }

        const token = localStorage.getItem('token')

        if(properties.length > 0 || !token) {
            handleOnClick(service.id)
        } else {
            console.log("error");
            setModal({
                ...modal,
                open: true,
                title: 'ERROR AL ADQUIRIR EL SERVICIO',
                text: 'No tenes propiedades registradas. Para adquirir un servicio debes publicar una propiedad.',
                acept: 'Entendido',
                cancel: 'false',
                image: alertforbidden,
                action: 'handleClose',
              });
        }
        
    }

    return (
        <div className='serviceBigCardContainer' >
            <div className='serviceBigCardContent'>
                <div className='serviceBigCardHeader'>
                    <img src={service.img} alt={'service' + service.img } />
                </div>
                <div className='serviceBigCardDescriptionContent'>
                    <label> { service.type } </label>
                    <h3> { service.title } </h3>
                    <label> 
                        { service.description }
                    </label>
                    <div style={{marginTop: "5px"}} >
                    <strong style={{fontSize: "11px"}} >* Servicio unicamente activo para la ciudad de Cordoba y Villa Allende. </strong>
                    <br/>
                    <strong style={{fontSize: "11px"}} >* Para adquirir estos servicios, ingresá y cargá tu propiedad. </strong>
                        
                    </div>
                    {/* {
                        service.title == "Cartelería" && <div>
                            <br/>
                        </div>
                    } */}
                    {/* { loading &&
                    <div className='serviceBigCardButtonContainer'>
                     <CircularProgress color="inherit" />
                     </div>
                  } */}
                    <div className="bottom-btn-service" >
                    { (
                       token && button && 
                    !loading 
                    ) ?
                    <div className={(service.id == 203 || service.id == 204) ? 'margin-data-serv-top-btn serviceBigCardButtonContainer' : 'serviceBigCardButtonContainer'}>
                        <button  onClick={ (e) => validateProperties(e)} > { service.action } </button>
                    </div>
                     :  (
                        !token&& 
                        !loading 
                    ) ?
                     <div className={(service.id == 203 || service.id == 204) ? 'margin-data-serv-top-btn serviceBigCardButtonContainer' : 'serviceBigCardButtonContainer'}>
                        <button style={{width: "200px"}}  onClick={ (e) => validateProperties(e)}>   INGRESAR   </button>
                    </div>
                     : loading ?
                     <div className={(service.id == 203 || service.id == 204) ? 'margin-data-serv-top-btn serviceBigCardButtonContainer' : 'serviceBigCardButtonContainer'}>
                     <CircularProgress color="inherit" />
                     </div> : null
                    }
{/* 
                    { (
                        !token&& 
                        !loading 
                    ) &&
                    <div className='serviceBigCardButtonContainer'>
                        <Link to="/welcome" ><button style={{width: "200px"}}>   INGRESAR   </button></Link>
                    </div>
                    } */}
                    </div>
                </div>
                <div>
                    <ModalWarning
                        title={modal.title}
                        text={modal.text}
                        acept={modal.acept}
                        cancel={modal.cancel}
                        image={modal.image}
                        noButton={true}
                        close={true}
                        openModal={modal.open}
                        actionButton={modal.action}
                    />
                </div>
            </div>
        </div>
    )
};

export default ServiceBigCard;