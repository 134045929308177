import React, { useState, useEffect, useContext } from 'react';
import "./InternalProperty.css";
import URL from '../../../config/urls';
import axios from 'axios';
import { Context } from '../../../Context';
import CircularProgress from '@material-ui/core/CircularProgress';

//COMPONENTS 
import ExpansionPanels from "../../commons/ExpansionPanels/ExpansionPanels";
import Amenities from '../../commons/ExpansionPanels/components/Amenities/Amenities';
import Accommodations from '../../commons/ExpansionPanels/components/Accommodations/Accommodations';
import Furnisheds from '../../commons/ExpansionPanels/components/Furnisheds/Furnisheds';
import Complaint from '../../commons/ExpansionPanels/components/Complaint/Complaint';
import Cod from '../../commons/ExpansionPanels/components/Cod/Cod';
// import Swippeable from './components/Swipeable/Swipeable';
import InternalCard from './components/Swipeable/InternalCard/InternalCard';
import ModCons from '../../commons/ExpansionPanels/components/ModCons/ModCons';
import Modal from '../../commons/Modals/ModalWarning';

const InternalProperty = ({ dataFooter, uploading, getProperty, map, history }) => {
  
  let propertyIdFromUrl;
  let transactionFromUrl;
  if (!uploading) {
    propertyIdFromUrl = window.location.href.split('=')[1].split('?')[0];
    transactionFromUrl = window.location.href.split('=')[2];
  }

  const [modalOn, setModalOn] = useState(false);
  const [property, setProperty] = useState(null);
  const [amenities, setAmenities] = useState(null);
  const [accommodations, setAccommodations] = useState(null);
  const [furnisheds, setFurnisheds] = useState(null);
  const [expenses, setExpenses] = useState(null)
  const [expensesDescription, setExpensesDescription] = useState(null)
  const { newProperty } = useContext(Context);

  useEffect(() => {

    const token = localStorage.getItem('token');
    const headers = { headers: { "token": token, } };


    // if (propertyIdFromUrl) {
    //   let BASE_URL_EST = URL + 'properties/updateStats/' + propertyIdFromUrl;
    //   axios.post(BASE_URL_EST,headers)
    //     .then(() => {
    //     })
    //   .catch(() => {
    //     console.log('Hubo un error')
    //   })  
    // }

    let BASE_URL = uploading ? `${URL}properties/propertyId/${newProperty.propertyId}` : URL + 'properties/propertyIdCard/' + propertyIdFromUrl;

    axios.get(BASE_URL)
      .then(response => {
        if(response.data.propertyBan){
          
          setModalOn(true)
          // return history.push('/')
          setTimeout(() => {
            setModalOn(false)
            history.push('/')
          }, 4000)
        } else {
          if (propertyIdFromUrl) {
            let BASE_URL_EST = URL + 'properties/updateStats/' + propertyIdFromUrl;
            axios.post(BASE_URL_EST,headers)
              .then(() => {
              })
            }
            const data = response.data;
             setProperty(data.responseData)
             setAmenities(data.responseData.amenities)
             setAccommodations(data.responseData.accommodations)
             setFurnisheds(data.responseData.furnished)
             getProperty(data.responseData);
             setExpenses(data.responseData.expenses)
             setExpensesDescription(data.responseData.expenses.description)

        }

      }) 
      .catch(e => {
        console.log('La petición no se ha realizado con éxito')
      })
// eslint-disable-next-line
  }, []);

  let screenSizeWidth = window.innerWidth;

  // Checking if the property contains amenities 
  if (amenities) {
    let amenitiesKeys = Object.keys(amenities).map(key => amenities[key])
    let filteredAmenities = amenitiesKeys.filter(key => key !== false)

    // If all the property amenities are false, we will set the amenities state to null so we can conditionally render DATA_PANELS 
    filteredAmenities.length < 1 && setAmenities(null) 
  }

  // Checking if the property contains accommodations
  if (accommodations) {
    let accommodationsKeys = Object.keys(accommodations).map(key => accommodations[key])
    let filteredAccommodations = accommodationsKeys.filter(key => key !== '')

    filteredAccommodations.length < 1 && setAccommodations(null)
  }

  // Checking if the property contains furniture
  if (furnisheds) {
    let furnishedsKeys = Object.keys(furnisheds).map(key => furnisheds[key])
    
    //@TODO 
    //============================================//
    // furnishedKeys will always return an empty array since funisheds are not being loaded when listing a new property. 
    // If this feature is added in the future, check if the data type is an array or an object to apply the correct methods
    //============================================//

    furnishedsKeys.length < 1 && setFurnisheds(null)
  }

  // Checking if the property contains commodities

   

  let DATA_PANELS = [
    (property ) &&
    {
      "title": "Descripción",
      "description": property.propertyDescription !== '' ? property.propertyDescription : false,
    },

    
    {
      "title": "Incluido en las expensas",
    "description": expensesDescription && expenses ? <div>
      <p>{expensesDescription}</p> 
      <p>{expenses.currency && expenses.currency} {expenses.value && expenses.value}</p> 
      </div> : false,
    },
  
    {
      "title": "Comodidades",
      "description": <ModCons property={property} />
    },
    {
      "title": "Ambientes",
      "description": accommodations ? <div className='items-room-container'> <Accommodations accommodations={accommodations} /> </div> : false
    },
    {
      "title": "Amenities",
      "description": amenities ?  <div className='items-room-container'> <Amenities amenities={amenities} /> </div> : false
    },
    {
      "title": "Amoblados",
      "description":  furnisheds ? <div className='items-room-container'> <Furnisheds furnisheds={furnisheds} /> </div> : false
    },
    {
      "title": "Código",
      "description": "El código de propiedad es una identificación única de cada inmueble. La función principal del código es diferenciar las diferentes propiedades, ya sea en los carteles de vía pública entregados por HiPropi, o en usos digitales dentro de la plataforma.",
      "assets": <Cod propertyCode={property && property.propertyCode} />
    },
    {
      "title": "Denunciar",
      "description": "HiPropi es una plataforma que vincula a particulares con particulares, por lo que está prohibido que las inmobiliarias formen parte de nuestra comunidad. Las denuncias de propiedad sirven para penalizar este tipo de actividades comerciales, como así tambien cuando existan propiedades duplicadas.",
      "assets": <Complaint history={history} uploading={uploading} />
    }
  ]
  let FILTERED_DATA_PANELS = []
  if (DATA_PANELS.length > 1) {
   for (const object of DATA_PANELS) {
     
    if (object && object.description) {
      FILTERED_DATA_PANELS.push(object)
    }

   }
  }

  const handleCloseError = () => {
    history.push('/')
  }

  return (
    <div className='internalPropertyCardFullContainerWeb'>


<div >

    { modalOn && (<Modal
                    noButton={true}
                    title='Esta propiedad ha sido denunciada y no se puede acceder'
                    text='En breve será redirigido'
                    acept={false}
                    openModal={modalOn}
                    // actionButton={() => handleCloseError()}
                    // onClick = {() => handleCloseError()}
                    // handleCloseOut={() => handleCloseError()}
                    cancel='false'
                    close={true}
                />)
    
     }

</div>
      {property === null ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50vh' }}>
          <CircularProgress size={70} />
        </div> :
        <div className={`${!uploading && 'view-content'}`}>
          <InternalCard
            property={property}
            history={history}
            publishedFor={
              uploading ?
                newProperty.sell ? 'sell' :
                  newProperty.rent ? 'rent' :
                    'tmpRent' :
                transactionFromUrl
            }
            dataFooter={dataFooter}
            expansionPanelsData={FILTERED_DATA_PANELS}
            map={map} 
          />
         
          { screenSizeWidth < 1030 &&
          <div className='internalPropertyPanelsWeb'>
            {FILTERED_DATA_PANELS.map((item, i) => item && <ExpansionPanels key={i} item={item} property={property} />)}
          </div>
          }

        </div>
      }
    </div>
  )
}

export default InternalProperty;