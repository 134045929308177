import React from 'react';
import Stepper from './Stepper';
import StepperPc from './StepperPc';

export default function TopNewProperty(props) {

    let screenSizeWidth = window.innerWidth;

    return (
        <div className="top-new-property-stepper">
            {
                screenSizeWidth >= 1030 ?
                    <StepperPc step={props.step - 1} /> :
                    <Stepper step={props.step} />
            }
        </div>
    )
}