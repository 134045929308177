import Axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../../../config/urls";
import TopBar from "../../commons/BlueTopBar/BlueTopBar";
import TopAppBarWeb from "../../commons/TopAppBarWeb/TopAppBarWeb";
import Checkout from './useScript'
import CircularProgress from '@material-ui/core/CircularProgress';
import imageData from '../TwoWaysToPay/img/hands.svg'
import './form-data-user.css'


const FormUserDataTransf = ({ history }) => {
  let screenSizeWidth = window.innerWidth;

  const [telefono, settelefono] = useState("");
  const [alter, setalter] = useState("");
  const [email, setemail] = useState("");
  const [nombreCuenta, setNombreCuenta] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('token')

  const handleOnCLick = async() => {
    if(telefono.trim().length == 0 || email.trim().length == 0 || nombreCuenta.trim().length == 0){
      setError(true)
      return
    } else {
      setError(false)
      const dataName = localStorage.getItem('producto')


  
      const params = {
        itemName: dataName,
        itemPrice: 2000,
        itemQuantity: 1,
        telefono,
        alter,
        email,
        nombreCuenta
      }
  
      try {
  
        const {data} = await Axios.post(URL + "transfer/pay", params, { headers: {token} })
        
        if(data){
          localStorage.setItem('serviceBuy', 'TRAN')
          history.push('/pay/successful')
        }
  
      } catch (error) {
        console.log(error)
      }
    }

  }

  return (
    <div>
      {screenSizeWidth > 1030 ? (
        <TopAppBarWeb history={history} />
      ) : (
        <TopBar title="Datos de usuario" link="/" />
      )}
      <div className="services-web-container-card">
        <div className="services-web-content-card">
          <div className="servicesContainer">
            {screenSizeWidth > 1030 && (
              <div
                className="report-title-container"
                style={{ margin: "auto" }}
                style={{
                  marginTop: screenSizeWidth > 1030 ? "35px" : "0px",
                  marginBottom: "30PX",
                }}
              >
                <label> Datos de usuario </label>
              </div>
            )}
            { (error && screenSizeWidth > 1030 ) &&
            <div style={{width: "100%"}}  className="data-container-contain" >
            <div style={{width: "100%"}} className='classColorModalError'>
                        <div>
                            <h3 style={{display: "flex", justifyContent: "center"}} > ¡Hubo un error! </h3>

                        </div>
                        <label style={{textAlign: "center"}}  > Debes completar los campos obligatorios con tus datos para procesar tu compra. </label>
                    </div>
                    </div>
             } 
            <div className="container-flex-data-transfer">
              
            <div className="contain-flex-data-transfer" >
              <div className="div-str-data-pay-trasfer">
              <img className='img-str-pay-trasnfer' src={imageData} />
              <strong style={{color: "#3483FA"}} > Transferencia bancaria</strong>

              </div>
              <p className="data-parrafo-info-abonar">
                Para abonar tu servicio, realizá tu transferencia o depósito bancario a la siguiente cuenta
              </p>
              <p className="parrafo-data-tranfer" >
                <span className="span-tranfer-data">Numero de sucursal: 469</span>
                <span className="span-tranfer-data">Numero de cuenta: 354379/1</span>
                <span className="span-tranfer-data">Cbu: 0720469688000035437918</span>
                <span className="span-tranfer-data">Titular: Kinleiner Óscar Eduardo</span>
                <span className="span-tranfer-data">Cuit:  20-23684002-7</span>
                
              </p>
              <p className="data-parrafo-info-abonar">
                Una vez realizado el pago, contactate con nosotros al 351 239-9518 y envianos tu comprobante.
              </p>
            </div> 

            <div className="text-preguntas data-container-flex">
            <strong className="data-container-strong-data">Completá tus datos</strong>
             <br/>
            { (error && screenSizeWidth < 1030 ) &&
            <div style={{width: "100%"}}  className="data-container-contain" >
            <div style={{width: "100%"}} className='classColorModalError'>
                        <div>
                            <h3 style={{display: "flex", justifyContent: "center"}} > ¡Hubo un error! </h3>

                        </div>
                        <label style={{textAlign: "center"}}  > Debes completar los campos obligatorios con tus datos para procesar tu compra. </label>
                    </div>
                    </div>
             } 
              <div className="data-container-contain" >
              <span className="user-info-data" ></span>
              <input
                type="number"
                className="data-input data-form-user transfer-payment"
                
                placeholder="Telefono*"
                onChange={(username) => settelefono(username.target.value)}
              />
              </div>
              <div className="data-container-contain" >
              <span  className="user-info-data"></span>
              <input
                type="number"
                className="data-input data-form-user transfer-payment"
                placeholder="Telefono alternativo"
                onChange={(email) => setalter(email.target.value)}
              />
              </div>
              <div className="data-container-contain" >
              <span  className="user-info-data"></span>
              <input
                type="email"
                className="data-input data-form-user transfer-payment"
                placeholder="Email*"
                onChange={(pass) => setemail(pass.target.value)}
              />
            </div>

            <div className="data-container-contain" >
              <span  className="user-info-data"></span>
              <input
                type="text"
                className="data-input data-form-user transfer-payment"
                placeholder="Nombre de titular de cuenta*"
                onChange={(pass) => setNombreCuenta(pass.target.value)}
              />
            </div>


            {
               loading ?
                  <div className='serviceBigCardButtonContainer'>
                    <CircularProgress color="inherit" />
                  </div> : 
                  <button className="buttonSearch button-send-data" id="checkout-btn"  style={{width: "300px", marginTop: "40px"}} onClick={handleOnCLick}  > Enviar </button>
             } 
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormUserDataTransf;
