
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const MyMobileStepper = withStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    padding: 0,
  },
  progress: {
    width: "100%",
    border: "1px solid #5963cc",
    height: "10px",
    borderRadius: "100px",
    backgroundColor: "#eeeffa",
  },
  dotActive: {
    color: "red",
  }
})(MobileStepper);

export default function ProgressMobileStepper(props) {
  const activeStep = props.step;

  return (
    <MyMobileStepper
      variant="progress"
      steps={7}
      position="static"
      activeStep={activeStep}
    />
  );
}