import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import URL from '../../../config/urls';

const Division = ({ name, division, address, loader, stateContext, handleChange }) => {


  return (
    loader[division] ?
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
        <CircularProgress size={20} />
      </div> :
      <select id={division} name={division} value={stateContext[division] ? stateContext[division] : ''} onChange={handleChange} style={{ marginBottom: '10px' }}>
        <option value=""> {"Seleccione una opcion"} </option>
        {/* <option value=""> {name} </option> */}
        {
          address[division].map((div, id) => {
            return <option key={id} value={div.name}>{div.name}</option>
          })
        }
      </select>

  )
}




const SelectAutocomplete = ({ name, division, address, loader, stateContext, handleChange, getDistricts, setStateContext }) => {

  const [error, setError] = useState(false)

  const stateNames = [];
  const districtsNames = [];
  // eslint-disable-next-line 
  address.state.map((state) => {
    stateNames.push(state.name)
  })


  const getDistrictsByState = (e) => {
 
    const { name, value } = e.target;
    if (name === 'state') {
      if (stateNames.includes(value)) {
        setStateContext({ ...stateContext, [name]: value, district: '' });
        getDistricts('Argentina', stateContext.state);
      }
    }
  }

  address.district.map((district) => {
    districtsNames.push(district.name)
  })

  const checkClick = (e) => {
    const { value, name } = e.target;

    if (name === "state" && !stateNames.includes(value)) {
      setStateContext({ ...stateContext, [name]: '', district: '' });
      setError(true)
    } else if (name === "district" && !districtsNames.includes(value)){
      setStateContext({ ...stateContext, [name]: '' });
      setError(true)
    } else {
      setError(false)
    }
  }


  return ( 
    loader[division] ?
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
        <CircularProgress size={20} />
      </div> :
      <div>
        <select id={division} id={division} onClick={getDistrictsByState} onBlurCapture={checkClick}  name={division} value={stateContext[division] ? stateContext[division] : ''} onChange={handleChange} style={{ border: 'none', margin: '6px 0 10px 0' }} placeholder={name}  style={{ marginBottom: '10px' }}>
        <option value=""> {"Provincia"} </option>

        {
          address[division].map((div, id) => {
            return <option  key={id} value={div.name} >{div.name}</option>
          })
        }
      </select>
        {/* <input autoComplete='no' list={'list' + division} id={division} name={division} value={stateContext[division] ? stateContext[division] : ''} onChange={handleChange} style={{ border: 'none', margin: '6px 0 10px 0' }} placeholder={name} onSelect={getDistrictsByState} />
        <datalist id={'list' + division}>
          {
            address[division].map((div, id) => {
              return <option key={id} value={div.name}/>
            })
          }
        </datalist> */}
        {
          error && <p style={{color: '#e84141', fontSize: '16px', fontWeight: '600', margin: 0, padding: 0}}>Seleccione uno de la lista por favor.</p>
        }
      </div>
  )
}



const InputAutocomplete = ({ name, division, address, loader, stateContext, handleChange, getDistricts, setStateContext }) => {

  const [error, setError] = useState(false)

  const stateNames = [];
  const districtsNames = [];
  // eslint-disable-next-line 
  address.state.map((state) => {
    stateNames.push(state.name)
  })


  const getDistrictsByState = (e) => {

    const { name, value } = e.target;
    if (name === 'state') {
      if (stateNames.includes(value)) {
        setStateContext({ ...stateContext, [name]: value, district: '' });
        getDistricts('Argentina', stateContext.state);
      }
    }
  }

  address.district.map((district) => {
    districtsNames.push(district.name)
  })

  const checkClick = (e) => {
    const { value, name } = e.target;

    if (name === "state" && !stateNames.includes(value)) {
      setStateContext({ ...stateContext, [name]: '', district: '' });
      setError(true)
    } else if (name === "district" && !districtsNames.includes(value)){
      setStateContext({ ...stateContext, [name]: '' });
      setError(true)
    } else {
      setError(false)
    }
  }

  

    return (
      loader[division] ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', paddingBottom: '10px' }}>
         
          <CircularProgress size={20} />
        </div> :
        <div  >
          <input className='input-select-location' autoComplete='no' list={'list' + division} id={division} name={division} value={stateContext[division] ? stateContext[division] : ''} onChange={handleChange} style={{ margin: '6px 0 10px 0' }} placeholder={name} onSelect={getDistrictsByState} onBlur={checkClick}/>
          <datalist id={'list' + division}>
            {
              address[division].map((div, id) => {
                return <option key={id} value={div.name}/>
              })
            }
          </datalist>
          {
            error && <p style={{color: '#e84141', fontSize: '16px', fontWeight: '600', margin: 0, padding: 0}}>Seleccione uno de la lista por favor.</p>
          }
        </div>
    )



}


const Location = ({ stateContext, setStateContext, upload, handleChangeFather, Selected, signal }) => {
  const [address, setAddress] = useState({
    country: [],
    state: [],
    district: [],
  });
  const [loader, setLoader] = useState({
    country: false,
    state: false,
    district: false
  })

  const handleChange = (e) => {
 

    const { name, value } = e.target;
    if (name === 'country') {
      setStateContext({ ...stateContext, [name]: value, state: '', district: '' })
    } else if (name === 'state') {
      setStateContext({ ...stateContext, [name]: value, district: '' });
    } else {
      setStateContext({ ...stateContext, [name]: value });
    }

    upload && handleChangeFather(e);
  }

  useEffect(() => {
    getCountries();
    setStateContext({ ...stateContext, country: 'Argentina' });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setAddress({ ...address, state: [], district: [] });
    getStates(stateContext.country);
    // eslint-disable-next-line
  }, [stateContext.country]);

  useEffect(() => {
    setAddress({ ...address, district: [] });
    getDistricts(stateContext.country, stateContext.state);
    getDistricts('Argentina', stateContext.state);
    // eslint-disable-next-line
  }, [stateContext.state]);

  const getCountries = () => {
    setLoader(loader => { return { ...loader, country: true } });
    const BASE_URL = URL + 'locations/countries';
    axios.get(BASE_URL)
      .then(res => {
        const data = res.data.responseData;
        setAddress(address => { return { ...address, country: data.countries } });
        setLoader(loader => { return { ...loader, country: false } });
        stateContext.country && getStates(stateContext.country);
      });
  }

  const getStates = (country) => {
    country = 'Argentina'
    country && setLoader(loader => { return { ...loader, state: true } });
    const BASE_URL = URL + `locations/countryStates/${country}`;
    country && axios.get(BASE_URL)
      .then(res => {
        const data = res.data.responseData;
        setAddress(address => { return { ...address, state: data.states } });
        country && setLoader(loader => { return { ...loader, state: false } });
        stateContext.state && getDistricts(country, stateContext.state);
      });
  }

  const getDistricts = (country, state) => {
    (country && state) && setLoader(loader => { return { ...loader, district: true } });
    const BASE_URL = URL + `locations/countryStateDistricts/${country}/${state}`;
    (country && state) && axios.get(BASE_URL)
      .then(res => {
        const data = res.data.responseData;
        setAddress(address => { return { ...address, district: data.districts } });
        (country && state) && setLoader(loader => { return { ...loader, district: false } });
      });
  }

  return (
    <div>
      {
        upload ?
          <div>
            <div className="medium-large-pc">
              <p>País <span className="asterisk">*</span></p>
              <Division name='Argentina' id='country' division='country' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} />
            </div>
            <div className="medium-large-pc">
              <p>Provincia <span className="asterisk">*</span></p>
              <SelectAutocomplete name='Provincia' division='state' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
            <div className="medium-large-pc">
              <p>Ciudad <span className="asterisk">*</span></p>
              <InputAutocomplete name='Ciudad' division='district' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
          </div> :
          Selected ? 
          <div>
            {/* <Division name='Argentina' division='country' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} /> */}
            <div className="medium-large-pc">
              <p>Provincia</p>
              <SelectAutocomplete name='Provincia' division='state' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
            <div className="medium-large-pc">
              <p>Ciudad </p>
              <InputAutocomplete name='Ciudad' division='district' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
          </div>:
          signal ? 

          <div>
            <div className="medium-large-pc">
              <p>País <span className="asterisk">*</span></p>
              <Division name='Argentina' id='country' division='country' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} />
            </div>
            <div className="medium-large-pc">
              <p>Provincia <span className="asterisk">*</span></p>
              <SelectAutocomplete name='Provincia' division='state' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
            <div className="medium-large-pc">
              <p>Ciudad <span className="asterisk">*</span></p>
              <InputAutocomplete name='Ciudad' division='district' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            </div>
          </div> :

            <div>
            <Division name='Argentina' division='country' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} />
            <SelectAutocomplete name='Provincia' division='state' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
            <InputAutocomplete name='Ciudad' division='district' address={address} loader={loader} stateContext={stateContext} handleChange={handleChange} setStateContext={setStateContext} getDistricts={getDistricts} />
          </div>
      }
    </div>
  )
}

export default Location
