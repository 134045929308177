import 'date-fns';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../Context'
import DatePicker from "react-datepicker";
import axios from 'axios'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import URL from '../../../../config/urls'
import './Propiedades.css'
import TotalCount from '../Common/TotalCount/TotalCount'
import Selected from './Selected'
import Tables from './Tables'
import Chart from '../Graficos/Grafico'
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';


export default function MaterialUIPickers({componente, palabra1, palabra2}) {
  // The first commit of Material-UI
  let date = new Date();
  let date2 = new Date()
  // let d = date2.setDate( date2.getDate() - 30 )
  date2.setMonth(date2.getMonth()-1);

  const [selectedDate, setSelectedDate] = React.useState(date2);
  const [selectedDateTwo, setSelectedDateTwo] = React.useState(date);
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState([])
  const [dataprop, setdataprop] = useState(0)
  const [words, setwords] = useState([])
  const [datos, setdatos] = useState({})
  

  const getDataPerMonth = () => {

    setwords([])
    setdatos({})

    const dates = []
    const repeticiones = {};

    data.forEach((e) => {

      
      if(e.dateCreate && dates.includes(e.dateCreate.slice(0,5)) ){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.dateCreate && !dates.includes(e.dateCreate.slice(0,5)) ) {
        dates.push(e.dateCreate && e.dateCreate.slice(0,5))
      
      }

      repeticiones[ e.dateCreate && e.dateCreate.slice(0,5) ] = ( repeticiones[ e.dateCreate && e.dateCreate.slice(0,5) ] || 0 ) + 1;
    
    })


    setwords(dates)
    setdatos(repeticiones)
  }

  const getDataChartPerYear = () => {

    const dates = []
    const repeticiones = {};


    data.forEach((e) => {

      if(e.dateCreate && dates.includes(e.dateCreate.slice(3,10)) ){
        console.log('Valor ingresado anteriormente')
      } 
      if( e.dateCreate && !dates.includes(e.dateCreate.slice(3,10))) {
        dates.push(e.dateCreate && e.dateCreate.slice(3,10))
      
      }

      repeticiones[ e.dateCreate && e.dateCreate.slice(3,10) ] = ( repeticiones[ e.dateCreate && e.dateCreate.slice(3,10) ] || 0 ) + 1;
    
    })
    setwords(dates)
    setdatos(repeticiones)

  }


  useEffect(() => {

    let fecha1 = moment(selectedDate);
    let fecha2 = moment(selectedDateTwo);


    
    if(fecha1.diff(fecha2, 'days') >= -51){
      getDataPerMonth()
    }

    if(fecha1.diff(fecha2, 'days') < -51){
      getDataChartPerYear()
    }


    // getDataChartPerYear()
    // getDataPerMonth()
  },[data])


  const { propSelect, setPropSelect,     
    setdataBuscador, 
    dataBuscador,
    setmodal } = useContext(Context);

  const { district, state, condicion, tipo_prop } = propSelect

  const token = localStorage.getItem('tokenAdmin');

  const handleDelete = (e) => {
    setPropSelect({
      ...propSelect,
      [e.target.name]: ""
    })
  }

  const handleGetProp = () => {

    try {
      
      const request = axios.get(URL + `admin/dashboard/allProperties`, { headers: { 'token': token } })
      .then((res) => {

        setdataprop(res.data.responseData[0].numberOfProperties)

        // setdataprop(res.data.responseData[0])

      })
      .catch((err) => {
        console.log('Hubo un error')
        console.log(err)

      })

    } catch (error) {
      
      console.log(error)

    }

  }


  const handleSend = () => {
  
    setmodal(false)
    setdataBuscador([])

    let params = {
      selectedDate,
      selectedDateTwo,
      district,
      state, 
      condicion, 
      tipo_prop
    }


      try {
        setloading(true)
        const request = axios.post(URL + `admin/dashboard/propertiesByDate`, params, { headers: { 'token': token } })
        .then((res) => {
          setdata(res.data.responseData[0])
          setloading(false)
        })
        .catch((err) => {
          console.log(err)

        })
        
      } catch (error) {
        console.log('Hubo un error')
      }

  }

  useEffect(() => {

    handleSend()
    handleGetProp()
  },[])



  return (
      <div>
        <div style={{marginLeft: "1050px", marginRight: "50px", marginTop: "-70px",}} >
          <TotalCount palabra1={palabra1} palabra2={palabra2} total={dataprop} />
        </div>
        <div style={{marginLeft: "900px", marginRight: "50px", marginTop: "-142px",}} >
          <TotalCount palabra1={palabra1} palabra2={"FILTRADAS"} total={data.length} />
        </div>


      <div className="div-datepicker" style={{ marginTop: "-120px"}} >

       <DatePicker
           showPopperArrow={false}
           selected={selectedDate}
           onChange={(selectedDate) => setSelectedDate(selectedDate)}
           className='datePicker-1'
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           fixedHeight
       />        

         <ArrowForwardIosIcon style={{marginTop: "14px", fontSize: "small", marginLeft: "-50px", color: "gray"}} />
         <DatePicker
           showPopperArrow={false}
           selected={selectedDateTwo}
           onChange={(selectedDateTwo) => setSelectedDateTwo(selectedDateTwo)}
           minDate={selectedDate}
           dateFormat="dd/MM/yyyy"
           placeholderText='Seleccione una fecha'
           className='datePicker-2'
       />   
  
    </div>
  
    <div className="div-datepicker" style={{ marginTop: "20px"}} >
      <Selected word="Tipo de propiedad" width="180px" />
    </div>
    <div className="div-datepicker-small" style={{ marginTop: "-95px"}} >
      <Selected word="Ubicación"  />
    </div>
    <div className="div-datepicker-small" style={{ marginTop: "20px"}} >
      <Selected word="Condición" />
    </div>
    <div className="div-options" >

  <div style={{ width: "350px"}} >

  {
    district != "" && <div className="filterActive"  > 

    <div className="center">
   <span>{ district }</span>
   <CloseIcon className="delete-icon" onClick={() => setPropSelect({
      ...propSelect,
      district: ""
    })} /> 
   </div>
   
   </div>
  }



{
    state != "" && <div className="filterActive"  > 

    <div className="center">
   <span>{ state }</span>
   <CloseIcon className="delete-icon" onClick={() => setPropSelect({
      ...propSelect,
      state: ""
    })} /> 
   </div>
   
   </div>
}


{
    condicion != "" && <div className="filterActive"  > 

    <div className="center">
   <span>{ condicion }</span>
   <CloseIcon className="delete-icon" onClick={() => setPropSelect({
      ...propSelect,
      condicion: ""
    })} /> 
   </div>
   
   </div>
}  

{
    tipo_prop != "" && <div className="filterActive"  > 

    <div className="center">
   <span>{ tipo_prop }</span>
   <CloseIcon className="delete-icon" onClick={() => setPropSelect({
      ...propSelect,
      tipo_prop: ""
    })} /> 
   </div>
   
   </div>
}  


  </div>



<div style={{display: "flex", flexDirection: "row-reverse", paddingLeft: "100px"}} >
<button className="buttonSearch" onClick={handleSend} > BUSCAR </button>
</div>

</div>

  <Chart words={words} datos={datos} />

   {
     !loading && <Tables name={componente} rowsData={ dataBuscador.length >= 1 ? dataBuscador : data} handle={handleSend} />
   } 

    {
        loading && <div style={{marginTop: "100px"}} className='messages-loading-screen'>
        <CircularProgress />
        </div>
    }
    </div>
  );
}