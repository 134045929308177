import React from 'react';
import { Link } from 'react-router-dom';
import arrowBack from '../../../assets/images/arrowBack.svg';

const BlueTopBar = ({ title, link }) => {
    return (
        <div className="topBar" style={{ position: 'fixed', width: '100%', zIndex: 2, top: 0 }}>
            <div className='blue-top-bar-img-container'>
                <Link 
                to={link}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}
                >
                    <img src={arrowBack} alt={arrowBack} className="filterIcon" />
                </Link>
            </div>
            <h3> {title} </h3>
        </div>
    )
};

export default BlueTopBar;